import { HotTable } from '@handsontable/react-wrapper';
import Handsontable from 'handsontable';
import { CellValue, RangeType } from 'handsontable/common';
import "handsontable/dist/handsontable.full.min.css";
import { DropdownEditor } from 'handsontable/editors';
import { ColumnSettings } from 'handsontable/settings';
import 'handsontable/styles/ht-theme-main.min.css';
import { toNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import {
  BudgetPricingStrategyModel,
  GetCustomerDataResponse,
  GetCustomerSettingsResponse,
  UpdateBudgetModel
} from '../../../../../Generated/Commodity-Management-Api';
import { months, monthToNumber } from '../../../../../Helpers';
import { useLoadingState } from '../../../../../Hooks';
import { RootState, useApplicationSelector } from '../../../../../Redux/ReduxStore';
import { StoneXRow } from '../../../../StoneX';
import { StoneXDivider } from '../../../../StoneX/StoneXDivider';
import StoneXMainPage from '../../../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXNumber } from '../../../../StoneXMui';
import BudgetSelectionForm from './BudgetSelectionForm';
import { BudgetRow } from './BudgetTypes';

export default function UpdateBudgetsPage() {
  const { enqueueSnackbar } = useSnackbar();

  const customerData = useApplicationSelector((state: RootState): GetCustomerDataResponse => state.commodityManagement.customerData);
  const customerSettings = useApplicationSelector((state: RootState): GetCustomerSettingsResponse => state.commodityManagement.customerSettings);

  const [pricingStrategies, setPricingStrategies] = useState<BudgetPricingStrategyModel[] | null>();
  const [budgets, setBudgets] = useState<BudgetRow[]>([]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [pricingStrategy, setPricingStrategy] = useState<BudgetPricingStrategyModel>();
  const [columnsToHide, setColumnsToHide] = useState<number[]>([0, 1, 2, 3, 4, 5, 6]);

  //init data on load
  const pricingStrategyLoadingState = useLoadingState();
  const budgetSaveLoadingState = useLoadingState();

  // Manually hide contract dates for now, for Seminole. Will consider proper implementation once we understand other customer requirements.
  const hideContractDate = useMemo(() => true, []);
  const hotRef = useRef<any>(null);

  useEffect(() => {
    CommodityManagementApi.listPricingStrategies(pricingStrategyLoadingState.setLoadingState).then((q) => setPricingStrategies(q.data.rows));
  }, []);

  const updateBudgets = (): void => {
    const hotInstance = hotRef.current.hotInstance;

    let hasError = false;
    hotInstance.validateCells((valid: boolean) => {
      hasError = !valid;
    });

    if (hasError) {
      enqueueSnackbar('Please fill in all required fields.', { variant: 'error' });
      return;
    }

    var updateBudgetsModel = {
      budgets: budgets.map((budget, index) => {
        // Find corresponding row in hot table data
        let rowIndex = index; // Default to same index
        if (budget.budgetId) {
          // If budget has an ID, find its row in the hot table
          const data = hotInstance.getData();
          rowIndex = data.findIndex((row: any[]) => 
            hotInstance.getDataAtRowProp(data.indexOf(row), 'budgetId') === budget.budgetId
          );
          if (rowIndex === -1) rowIndex = index; // Fallback to index if not found
        }

        const budgetYear = hotInstance.getDataAtRowProp(rowIndex, 'budgetDate.year') ?? undefined;
        const budgetMonth = monthToNumber(hotInstance.getDataAtRowProp(rowIndex, 'budgetDate.month')) ?? undefined;
        const budgetDate = {year: budgetYear, month: budgetMonth};

        const contractYear = hotInstance.getDataAtRowProp(rowIndex, 'contractDate.year') ?? undefined;
        const contractMonth = monthToNumber(hotInstance.getDataAtRowProp(rowIndex, 'contractDate.month')) ?? undefined;
        const contractDate = contractYear && contractMonth ? {year: contractYear, month: contractMonth} : undefined;

        const volume = hotInstance.getDataAtRowProp(rowIndex, 'volume') ? 
          toNumber(hotInstance.getDataAtRowProp(rowIndex, 'volume')) : undefined;

        const budgetPrices = budget.budgetPrices?.map((component, index) => ({
          budgetPriceId: component.budgetPriceId ?? 0,
          componentType: component.componentType,
          price: hotInstance.getDataAtRowProp(rowIndex, `budgetPrices.${index}.price`) 
            ? toNumber(hotInstance.getDataAtRowProp(rowIndex, `budgetPrices.${index}.price`)) 
            : undefined,
          unit: budget.unit
        }));

        return {
          budgetId: budget.budgetId,
          pricingStrategyId: budget.pricingStrategyId,
          commodityId: budget.commodityId,
          locationId: budget.locationId,
          counterpartyId: budget.counterpartyId,
          unit: budget.unit,
          currency: budget.currency,
          budgetDate,
          contractDate,
          volume,
          budgetPrices,
        } as UpdateBudgetModel;
      })
    };

    setIsSaving(true);
    CommodityManagementApi.updateBudgets(budgetSaveLoadingState.setLoadingState, updateBudgetsModel)
      .then(res => {
        enqueueSnackbar('Budgets were successfully updated', {variant: 'success'});
        setIsInEditMode(false);
        setIsSaving(false);
      })
      .catch(err => {
        enqueueSnackbar('An error occurred while saving the budgets', {variant: 'error'});
        setIsInEditMode(false);
        setIsSaving(false);
      });
  };

  function updatePricingStrategy(budgets: BudgetRow[]) {
    if (pricingStrategy?.budgetPricingStrategyId !== budgets?.[0]?.pricingStrategyId) {
      setPricingStrategy(pricingStrategies?.find(x => x.budgetPricingStrategyId == budgets?.[0]?.pricingStrategyId));
    }
  }
  
  useEffect(() => updatePricingStrategy(budgets), [budgets]);

  class CustomDropdown extends DropdownEditor {
    limitDropdownIfNeeded(spaceAvailable: any, dropdownHeight: any): void {
      super.limitDropdownIfNeeded(spaceAvailable, dropdownHeight);
      const newHeight = (this.cellProperties?.source?.length ?? 0) * 40;
      this.htEditor.updateSettings({ height: newHeight + 2, width: '100%' });
    }
  }

  const VolumeRenderer = function (instance: any, TD: any, row: any, col: any, prop: any, value: any, cellProperties: any) {
    const root = createRoot(TD);
    const element = <StoneXNumber number={value} className={cellProperties.readOnly ? 'htDimmed' : undefined}/>;
    root.render(element);
  };


  function yearValidator(value: CellValue, callback: (valid: boolean) => void) {
    const year = parseInt(value as string, 10);
    const isValidYear = !isNaN(year) && year > 0;
  
    if (value === null || value === undefined || value === '' || !isValidYear) {
      callback(false); // Invalid
    } else {
      callback(true); // Valid
    }
  }

  function numericValidator(value: CellValue, callback: (valid: boolean) => void) {
    const number = parseFloat(value as string);
    const isValidVolume = !isNaN(number) && number > 0;
  
    if (value === null || value === undefined || value === '' || !isValidVolume) {
      callback(false); // Invalid
    } else {
      callback(true); // Valid
    }
  }

// Register custom editor and validators
Handsontable.editors.registerEditor('customDropdown', CustomDropdown);
Handsontable.validators.registerValidator('yearValidator', yearValidator);
Handsontable.validators.registerValidator('numericValidator', numericValidator);
Handsontable.renderers.registerRenderer('volumeRenderer', VolumeRenderer);

const columns: ColumnSettings[] = useMemo(() => [
  {
    data: 'budgetId',
    readOnly: true,
    className: 'htHidden'
  },
  {
    data: 'pricingStrategyId',
    readOnly: true,
    className: 'htHidden'
  },
  {
    data: 'commodityId',
    readOnly: true,
    className: 'htHidden'
  },
  {
    data: 'counterpartyId',
    readOnly: true,
    className: 'htHidden'
  },
  {
    data: 'locationId',
    readOnly: true,
    className: 'htHidden'
  },
  {
    data: 'unit',
    readOnly: true,
    className: 'htHidden'
  },
  {
    data: 'currency',
    readOnly: true,
    className: 'htHidden'
  },
  {
    data: 'budgetDate.month',
    readOnly: true,
    validator: (value, callback) => callback(!!value && months.includes(value))
  },
  {
    data: 'budgetDate.year',
    className: 'numeric',
    readOnly: true,
    validator: 'yearValidator', 
  },
  {
    data: 'contractDate.month',
    type: 'dropdown',
    editor: 'customDropdown',
    source: months,
    validator: (value, callback) => {
      if (hideContractDate) {
        return callback(true);
      }
      callback(!!value && months.includes(value));
    }
  },
  {
    data: 'contractDate.year',
    className: 'numeric',
    validator: (value, callback) => {
      if (hideContractDate) {
        return callback(true);
      }
      yearValidator(value, (isValid) => callback(!!value && isValid));
    }
  },
  {
    data: 'volume',
    className: 'numeric',
    validator: 'numericValidator',
    renderer: 'volumeRenderer',
  },
  ...(budgets[0]?.budgetPrices?.map((component, index) => ({
    data: `budgetPrices.${index}.price`,
    className: 'numeric',
    validator: 'numericValidator',
  })) ?? []),
], [budgets, hideContractDate, pricingStrategy]);

 function updateColumnsToHide() {
  const hotInstance = hotRef.current?.hotInstance;
  
  if (!hotInstance) {
    setColumnsToHide([0, 1, 2, 3, 4, 5, 6]);
    return;
  }

  let hidden = [0, 1, 2, 3, 4, 5, 6];

  if (hideContractDate) {
    const contractYearTypeColIndex = hotInstance.propToCol('contractDate.year');
    const contractMonthTypeColIndex = hotInstance.propToCol('contractDate.month');
    hidden.push(...[contractYearTypeColIndex, contractMonthTypeColIndex]);
  }

  setColumnsToHide(hidden);
}

function beforePaste(data: CellValue[][], coords: RangeType[]) {
  const monthMap: { [key: string]: number } = {
    "january": 1, "jan": 1,
    "february": 2, "feb": 2,
    "march": 3, "mar": 3,
    "april": 4, "apr": 4,
    "may": 5,
    "june": 6, "jun": 6,
    "july": 7, "jul": 7,
    "august": 8, "aug": 8,
    "september": 9, "sep": 9,
    "october": 10, "oct": 10,
    "november": 11, "nov": 11,
    "december": 12, "dec": 12
  };

  const hotInstance = hotRef.current.hotInstance;
  const budgetMonthCol = hotInstance.propToCol('budgetDate.month');
  const contractMonthCol = hotInstance.propToCol('contractDate.month');
  const volumeCol = hotInstance.propToCol('volume');
  const componentPriceCols = budgets[0]?.budgetPrices?.map((_, index) => hotInstance.propToCol(`budgetPrices.${index}.price`)) ?? [];

  coords.forEach((range) => {
    const startCol = range.startCol;

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        const value = String(data[i][j]).toLowerCase();

        // Process month columns
        if (startCol === budgetMonthCol || startCol === contractMonthCol) {
          if (monthMap[value] !== undefined) {
            data[i][j] = months[monthMap[value] - 1];
          }
          const monthNum = parseInt(value);
          if (!isNaN(monthNum) && monthNum >= 1 && monthNum <= 12) {
            data[i][j] = months[monthNum - 1];
          }
        }

        // Process numeric columns
        if (startCol === volumeCol || componentPriceCols.includes(startCol)) {
          data[i][j] = value.replace(/[^0-9.]/g, '');
        }
      }
    }
  });
}


  const cells = (row: any, col: any, prop: any) => {

    const cellProperties = {} as any;
    const hotInstance = hotRef.current.hotInstance;

    if (isSaving) {
      cellProperties.readOnly = true;
      return cellProperties;
    }
  
    return cellProperties;
  };

  return (
    <StoneXMainPage>
      <StoneXRow>
        <h1>Add Budgets</h1>
      </StoneXRow>

      {customerSettings && customerData && pricingStrategies && (
        <BudgetSelectionForm
          customerSettings={customerSettings!}
          customerData={customerData}
          pricingStrategies={pricingStrategies!}
          isInEditMode={isInEditMode}
          isSaving={budgetSaveLoadingState.isLoading()}
          onChangeIsInEditMode={setIsInEditMode}
          onBudgetsUpdated={(budgets: BudgetRow[]) => setBudgets(budgets)}
          onEditStart={() => setIsInEditMode(true)}
          onEditCancel={() => setIsInEditMode(false)}
          onSave={updateBudgets}
        />
      )}

      <StoneXDivider />

      {isInEditMode && (
        <HotTable
        ref={hotRef}
        data={budgets.map(budgets => ({
          ...budgets,
          budgetDate: {
        ...budgets.budgetDate,
        month: budgets.budgetDate?.month ? months[budgets.budgetDate.month - 1] : null
          },
          contractDate: {
        ...budgets.contractDate,
        month: budgets.contractDate?.month ? months[budgets.contractDate.month - 1] : null
          }
        }))}
        stretchH="all"
        width="100%"
        height="auto"
        colHeaders={[
          "Budget Id",
          "Budget Pricing Strategy Id",
          "Commodity Id",
          "Counterparty Id",
          "Location Id",
          "Unit",
          "Currency",
          "Budget Month",
          "Budget Year",
          "Contract Month",
          "Contract Year",
          "Quantity",
          ...(budgets[0]?.budgetPrices?.map((component: any) => component.componentType!) ?? []),
        ]}
        columns={columns}
        autoWrapRow={true}
        autoWrapCol={true}
        rowHeights={40}
        columnHeaderHeight={48}
        minSpareRows={0}
        hiddenColumns={{
          columns: columnsToHide,
          indicators: false,
          copyPasteEnabled: false
        }}
        cells={cells}
        init={updateColumnsToHide}
        // afterChange={handleAfterChange} 
        // afterColumnResize={alignAverageDivs}
        // afterRemoveRow={calculateAverage}
        // afterCreateRow={calculateAverage}
        // afterRefreshDimensions={alignAverageDivs}
        // afterColumnSequenceChange={alignAverageDivs}
        beforePaste={beforePaste}
        licenseKey="non-commercial-and-evaluation"
      />
      )}
    </StoneXMainPage>
  );
  
}