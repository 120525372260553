
import { RootState, useApplicationSelector } from '../../Redux/ReduxStore';
import { HotTable } from '@handsontable/react-wrapper';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/styles/handsontable.css';
import 'handsontable/styles/ht-theme-main.css';

// register Handsontable's modules
registerAllModules();

export default function Dashboard() {

  const access = useApplicationSelector((state: RootState): boolean => state.userAccess.hasAccess);
  
  return <></>
}
