import { Button, ButtonGroup } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { CommodityManagementApi } from '../../../../../../Apis/Apis';
import { BudgetVarianceModel, ContractDate, CustomerReportModel } from '../../../../../../Generated/Commodity-Management-Api';
import { ContractDateHelper } from '../../../../../../Helpers';
import { deepMerge } from '../../../../../../Helpers/ObjectHelper';
import { useLoadingState } from '../../../../../../Hooks';
import { RootState, useApplicationSelector } from '../../../../../../Redux/ReduxStore';
import { GroupingOption } from '../../../../../../Types/GridRow';
import { StoneXLoading, StoneXRow } from '../../../../../StoneX';
import { StoneXButton, StoneXContractMonthPicker, StoneXDatePicker, StoneXSelect } from '../../../../../StoneXMui';
import { ReportProps } from '../../ReportsPage';
import { BudgetVarianceModelToRowTransformer } from '../Variance/BudgetVarianceModelToRowTransformer';
import SummaryReportTable from './SummaryReportTable';
import TotalHedgeSummaryTable from './TotalHedgeSummaryTable';
import { SummaryReportConfiguration, SummaryReportRow, SummaryReportUserOverride } from './Types';
import { PlayArrow } from '@mui/icons-material';

export default function SummaryReport(props: ReportProps) {
  const { customerName, customerReportId, customerSettings } = props;

  const customerReport = useApplicationSelector(
    (state: RootState): CustomerReportModel => state.commodityManagement.customerReports.find((x: any) => x.customerReportId === customerReportId)!,
  );

  const [quoteDate, setQuoteDate] = useState<Date | null>(props.quoteDate);
  const quoteDateString = useMemo(() => (quoteDate ? dayjs(quoteDate).format('YYYY-MM-DD') : null), [quoteDate]);
  const [budgetStartMonth, setBudgetStartMonth] = useState<ContractDate | null>(null);
  const [budgetEndMonth, setBudgetEndMonth] = useState<ContractDate | null>(null);

  const reportLoadingState = useLoadingState();
  const [rows, setRows] = useState<SummaryReportRow[]>([]);
  const [budgetVariances, setBudgetVariances] = useState<BudgetVarianceModel[]>([]);

  const reportConfiguration = useMemo(() => JSON.parse(customerReport.configuration!) as SummaryReportConfiguration, [customerReport]);
  const userOverrides = useMemo(
    () => (customerReport.userOverrides ? (JSON.parse(customerReport.userOverrides) as SummaryReportUserOverride) : undefined),
    [customerReport],
  );
  const reportSettings = useMemo(() => deepMerge(reportConfiguration, userOverrides), [reportConfiguration, userOverrides]);

  function runReport() {
    const startYear = budgetStartMonth?.year;
    const startMonth = budgetStartMonth ? budgetStartMonth.month! + 1 : undefined;
    const endYear = budgetEndMonth?.year;
    const endMonth = budgetEndMonth ? budgetEndMonth.month! : undefined;

    CommodityManagementApi.getVarianceReport(
      reportLoadingState.setLoadingState,
      quoteDateString!,
      customerReport.customerReportId,
      startYear!,
      startMonth,
      undefined,
      endYear!,
      endMonth,
    ).then((res) => {
      setBudgetVariances(res.data.rows! ?? []);
    });
  }

  function updateStartAndEndDates() {
    if (reportSettings && customerSettings) {
      const currentMonth = dayjs().month() + 1;
      const currentYear = dayjs().year();

      const startMonth = (() => {
        const startMonthOffset = reportSettings.inputs?.budgetStartDate?.offset ?? 0;

        switch (reportSettings.inputs?.budgetStartDate?.strategy ?? 'fiscalYearStart') {
          case 'currentMonth':
            return currentMonth + startMonthOffset;
          case 'fiscalYearStart':
            return customerSettings.defaultActiveMonthStart!.month! + startMonthOffset;
        }
      })();

      // If the current month is the roll off month, then the default year is for next month
      const startYear =
        reportSettings.inputs?.budgetStartDate?.rollOffInToNewYearMonth && currentMonth >= reportSettings.inputs?.budgetStartDate?.rollOffInToNewYearMonth
          ? currentYear + 1
          : currentYear;

      const startBudgetMonth: ContractDate = { year: startYear, month: 1 };
      const endBudgetDate: ContractDate | null = ContractDateHelper.addMonths(
        startBudgetMonth,
        (reportSettings.inputs?.budgetEndDate?.offsetFromStartMonth ?? 36) - 1,
      );

      setBudgetStartMonth(startBudgetMonth);
      setBudgetEndMonth(endBudgetDate);
    } else {
      setBudgetStartMonth(null);
      setBudgetEndMonth(null);
    }
  }

  function updateReportRows() {
    // Filter out undefined groupings
    const selectedGroupings = [reportSettings?.behavior?.grouping?.[0], reportSettings?.behavior?.grouping?.[1], 'BudgetMonth' as GroupingOption]
      .filter((x) => !!x)
      .map((x) => x!);

    let variances = budgetVariances.map((b) => BudgetVarianceModelToRowTransformer.budgetVarianceModelToRow(b, new Date(quoteDate!)));

    BudgetVarianceModelToRowTransformer.updatePaths(variances, selectedGroupings);

    // This shows the totals on each grouped row
    let aggregatedRows = BudgetVarianceModelToRowTransformer.getAggregatedRows(
      variances,
      selectedGroupings.length,
      BudgetVarianceModelToRowTransformer.aggregateVarianceReportRows,
    );

    // Get totals by month
    let monthTotals = BudgetVarianceModelToRowTransformer.getAggregatedRowsGrouped(
      variances,
      selectedGroupings.length - 2,
      BudgetVarianceModelToRowTransformer.aggregateVarianceReportRows,
      (x) => x.budgetDate!.label!,
      'Totals by month',
    );
    monthTotals.forEach((x) => (x.isTotalByMonth = true));

    setRows([...variances, ...aggregatedRows, ...monthTotals]);
  }

  useEffect(updateReportRows, [budgetVariances, customerReport]);
  useEffect(updateStartAndEndDates, [reportSettings, customerSettings]);

  return (
    <div>
      <StoneXRow extraHorizontalSpacing>
        {reportSettings?.inputs?.quoteDate?.show && <StoneXDatePicker onChange={setQuoteDate} label="Quote Date" value={quoteDate} width="small" />}
        {reportSettings?.inputs?.budgetStartDate?.show && (
          <StoneXContractMonthPicker value={budgetStartMonth} label="Budget Start Month" onChange={setBudgetStartMonth} />
        )}
        {reportSettings?.inputs?.budgetEndDate?.show && (
          <StoneXContractMonthPicker value={budgetEndMonth} label="Budget End Month" onChange={setBudgetEndMonth} />
        )}
        
        <ButtonGroup>
          <StoneXButton variant="outlined" onClick={runReport} startIcon={<PlayArrow />} disabled={reportLoadingState.isLoading()}>Run</StoneXButton>
        </ButtonGroup>

      </StoneXRow>

      <StoneXLoading show={reportLoadingState.isLoading()} />
      {reportLoadingState.isLoaded() && (
        <>
          <SummaryReportTable rows={rows} />
          <br />
          <TotalHedgeSummaryTable rows={rows} />
        </>
      )}
    </div>
  );
}
