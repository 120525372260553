import { CellClassParams, ColDef, ColGroupDef, IAggFuncParams, ICellRendererParams, ValueFormatterFunc, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReactProps } from 'ag-grid-react';
import { useEffect, useState } from 'react';
import { StoneXGrid } from '../../../../../StoneX';
import { StoneXNumber } from '../../../../../StoneXMui';
import { SummaryReportRow } from './Types';

interface SummaryReportTableProps {
  rows: SummaryReportRow[];
}

export default function SummaryReportTable(props: SummaryReportTableProps) {
  const { rows } = props;

  const [columns, setColumns] = useState<(ColDef<SummaryReportRow> | ColGroupDef<SummaryReportRow>)[]>();

  type SummaryReportColumn = 'BudgetMonth' | 'TotalMtm' | 'HedgedPrice' | 'HedgedPercent' | 'FixedMarketValue' | 'OptionMtm' | 'NetOptionPremium';

  interface SummaryColumnConfiguration {
    colId: string;
    headerName?: string; // This is what appears on the table header
    columnName?: string; // This is a unique column name to distinguish from other versions of the same column
    parentHeader?: string;
    field?: string;
    valueGetter?: (params: ValueGetterParams<SummaryReportRow>) => null | string | number | undefined | any;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    sortable?: boolean;
    flex?: number;
    hide?: boolean;
    filter?: string;
    cellClass?: string | string[] | ((params: CellClassParams<SummaryReportRow, any>) => string | string[] | undefined);
    aggFunc?: string | ((params: IAggFuncParams<SummaryReportRow, any>) => any) | null | undefined;
    valueFormatter?: (params: ValueFormatterParams<SummaryReportRow>) => string | ValueFormatterFunc<SummaryReportRow, any>;
    cellRenderer?: (params: ICellRendererParams<SummaryReportRow>) => any;
  }

  const priceWidth = 100;
  const volumeWidth = 110;
  const valueWidth = 120;
  const percentWidth = 90;

  const priceCellRenderer = (params: ICellRendererParams<SummaryReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value} decimals={4}></StoneXNumber>;
  const volumeCellRenderer = (params: ICellRendererParams<SummaryReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value}></StoneXNumber>;
  const valueCellRenderer = (params: ICellRendererParams<SummaryReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value} colored></StoneXNumber>;
  const percentCellRenderer = (params: ICellRendererParams<SummaryReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value} percent></StoneXNumber>;

  const summaryColumn: { [key in SummaryReportColumn]: SummaryColumnConfiguration } = {
    BudgetMonth: {
      colId: 'Budget Month',
      headerName: 'Hedge Period',
      field: 'budgetDate.label',
      maxWidth: 150,
    },
    TotalMtm: {
      colId: 'TotalMtm',
      headerName: 'Total Mtm Gain/Loss',
      valueGetter: (params: ValueGetterParams<SummaryReportRow>) => params.data?.markToMarketValue,
      minWidth: valueWidth,
      flex: 1,
      cellRenderer: valueCellRenderer,
    },
    HedgedPrice: {
      colId: 'HedgedPrice',
      headerName: 'Total Hedge Price($/Dth)',
      field: 'underlyingHedgedPrice',
      minWidth: priceWidth,
      flex: 1,
      cellRenderer: priceCellRenderer,
    },
    HedgedPercent: {
      colId: 'HedgedPercent',
      headerName: 'Hedged Percent',
      field: 'underlyingHedgedPercent',
      minWidth: percentWidth,
      flex: 1,
      cellRenderer: percentCellRenderer,
    },
    FixedMarketValue: {
      colId: 'fixedMarketValue',
      headerName: 'Fixed Mtm',
      field: 'fixedMarketValue',
      minWidth: valueWidth,
      flex: 1,
      cellRenderer: valueCellRenderer,
    },
    OptionMtm: {
      colId: 'OptionMtm',
      headerName: 'Option Mtm',
      field: 'optionMarketValue',
      minWidth: valueWidth,
      flex: 1,
      cellRenderer: valueCellRenderer,
    },
    NetOptionPremium: {
      colId: 'NetOptionPremium',
      headerName: 'Net Option Premium',
      field: 'netOptionPremium',
      minWidth: valueWidth,
      flex: 1,
      cellRenderer: valueCellRenderer,
    },
  };

  /*const gridOptions: AgGridReactProps = {
        grandTotalRow: 'bottom',
        treeData: true,
        getDataPath: (data: SummaryReportRow) => data.path
    };*/
  const gridOptions = {
    treeData: true,
    getDataPath: (data: SummaryReportRow) => data.path,
    autoGroupColumnDef: 'BudgetYear',
  } as AgGridReactProps<SummaryReportRow>;

  function updateColumns() {
    const columnDefs: (ColDef<SummaryReportRow> | ColGroupDef<SummaryReportRow>)[] = [];
    var column = summaryColumn['BudgetMonth'];
    var columnDef = column as ColDef<SummaryReportRow>;

    columnDefs.push(columnDef);

    column = summaryColumn['TotalMtm'];
    columnDef = column as ColDef<SummaryReportRow>;
    columnDefs.push(columnDef);

    column = summaryColumn['HedgedPrice'];
    columnDef = column as ColDef<SummaryReportRow>;
    columnDefs.push(columnDef);

    column = summaryColumn['HedgedPercent'];
    columnDef = column as ColDef<SummaryReportRow>;
    columnDefs.push(columnDef);

    column = summaryColumn['FixedMarketValue'];
    columnDef = column as ColDef<SummaryReportRow>;
    columnDefs.push(columnDef);

    column = summaryColumn['OptionMtm'];
    columnDef = column as ColDef<SummaryReportRow>;
    columnDefs.push(columnDef);

    column = summaryColumn['NetOptionPremium'];
    columnDef = column as ColDef<SummaryReportRow>;
    columnDefs.push(columnDef);

    setColumns(columnDefs);
  }

  useEffect(updateColumns, []);
  useEffect(() => console.log(rows), [rows]);

  return (
    <StoneXGrid
      columns={columns!}
      rows={rows}
      gridOptions={gridOptions}
      chartButtonDisplayCondition={(x) => x.data !== undefined}
      expandCollapseRowCondition={(x) => x.data !== undefined}
      groupColumnWidth={250}
    />
  );
}
