import { BarChart } from '@mui/icons-material';
import { Button, ButtonGroup, IconButton } from '@mui/material';
import { CellClassParams, ColDef, GetRowIdParams, GridOptions, ICellRendererParams, IRowNode, ITooltipComp, ITooltipParams } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { deepMerge } from '../../../Helpers/ObjectHelper';
import GroupCellRenderer from './GroupCellRenderer';

export interface StoneXGridProps<T> {
  rows: T[];
  columns: ColDef<T>[];
  groupColumnWidth?: number | undefined;
  onlyShowGroupLabels?: boolean;
  gridOptions?: AgGridReactProps;
  hideGridOptions?: boolean;
  pattern?: 'alternate' | 'nested';
  getRowId?: (params: GetRowIdParams<T, any>) => any;
  groupCellInnerRenderer?: (params: ICellRendererParams<T, any>) => ReactNode;
  onChartClick?: (path: string[] | undefined) => void;
  chartButtonDisplayCondition?: (params: ICellRendererParams<T, any>) => boolean;
  expandCollapseRowCondition?: (params: IRowNode<T>) => boolean;
}

export default forwardRef<unknown, StoneXGridProps<any>>(function StoneXGrid<T>(props: StoneXGridProps<T>, ref: React.Ref<unknown>) {
// export default function StoneXGrid<T>(props: StoneXGridProps<T>) {
  const { columns, rows, groupColumnWidth, onlyShowGroupLabels, hideGridOptions, pattern = 'nested', groupCellInnerRenderer, onChartClick, chartButtonDisplayCondition, expandCollapseRowCondition, getRowId, gridOptions } = props;

  const gridContainerRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const floatingScrollbarViewport = useRef<HTMLDivElement>(null);
  const floatingScrollbarContainer = useRef<HTMLDivElement>(null);
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);

  useImperativeHandle(ref, () => gridRef.current);

  class TooltipRenderer implements ITooltipComp {
    eGui: any;
    init(params: ITooltipParams & { color: string }) {
        const eGui = (this.eGui = document.createElement('div'));
        const color = params.color || '#999';

        eGui.classList.add('ag-grid-tooltip');
        
        //@ts-ignore
        eGui.style['background-color'] = color;
        eGui.innerHTML = `
            <div><b>Custom Tooltip</b></div>
            <div>${params.value}</div>
        `;
    }

    getGui() {
        return this.eGui;
    }
  }

  function toggleExpanded() {
    if (isExpanded) {
      gridRef?.current?.api?.collapseAll();
    } else {
      gridRef?.current?.api?.expandAll();
    }

    setIsExpanded(!isExpanded);
  }

  function expandOneLevel() {
    if (!gridRef.current) {
      return;
    }

    const api = gridRef.current!.api;

    let lowestLevelNotExpanded: number | undefined = undefined;

    api.forEachNode((node) => {
      if (!node.isExpandable()) {
        return;
      }

      if (expandCollapseRowCondition !== undefined && !expandCollapseRowCondition(node)) {
        return;
      }

      if (lowestLevelNotExpanded == undefined && !node.expanded) {
        lowestLevelNotExpanded = node.level;
      }

      if (lowestLevelNotExpanded == undefined) {
        return;
      }

      if (!node.expanded && node.level < lowestLevelNotExpanded) {
        lowestLevelNotExpanded = node.level;
      }
    });

    if (lowestLevelNotExpanded !== undefined) {
      api.forEachNode((node) => {
        if (expandCollapseRowCondition !== undefined && !expandCollapseRowCondition(node)) {
          return;
        }

        if (node.level <= lowestLevelNotExpanded! && !node.expanded) {
          node.setExpanded(true);
        }
      });
    }

    api.onGroupExpandedOrCollapsed();
  }

  function collapseOneLevel() {
    if (!gridRef.current) {
      return;
    }

    const api = gridRef.current!.api;

    let highestLevelExpanded: number | undefined = undefined;

    api.forEachNode((node) => {
      if (!node.isExpandable()) {
        return;
      }

      if (expandCollapseRowCondition !== undefined && !expandCollapseRowCondition(node)) {
        return;
      }

      if (highestLevelExpanded === undefined && node.expanded) {
        highestLevelExpanded = node.level;
      }

      if (highestLevelExpanded == undefined) {
        return;
      }

      if (node.expanded && node.level > highestLevelExpanded) {
        highestLevelExpanded = node.level;
      }
    });

    if (highestLevelExpanded !== undefined) {
      api.forEachNode((node) => {
        if (expandCollapseRowCondition !== undefined && !expandCollapseRowCondition(node)) {
          return;
        }

        if (node.level >= highestLevelExpanded! && node.expanded) {
          node.setExpanded(false);
        }
      });
    }

    api.onGroupExpandedOrCollapsed();
  }

  function toggleFullScreen() {
    setIsFullScreen(!isFullScreen);
  }

  function autoFit() {
    gridRef.current?.api?.autoSizeAllColumns();
  }

  function GroupHeaderCellRenderer<T>(props: { params: ICellRendererParams<T, any> }) {
    return (
      <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <span>Group</span>
        {onChartClick !== undefined && (
          <IconButton sx={{ color: 'var(--button-primary-color)' }} onClick={() => onChartClick(undefined)}>
            <BarChart />
          </IconButton>
        )}
      </span>
    );
  }

  function getRowClass(params: any) {
    if (pattern == 'nested') {
      return params.node.level % 2 == 0 ? 'ag-row-color-1' : 'ag-row-color-2';
    }

    if (pattern == 'alternate') {
      return params.node.rowIndex % 2 == 0 ? 'ag-row-color-1' : 'ag-row-color-2';
    }
  }

  function updateCustomScrollbarDisplay() {
    if (!gridContainerRef.current || !floatingScrollbarViewport.current) {
      return;
    } 

    const agGridScrollViewport = gridContainerRef.current.querySelector('.ag-body-horizontal-scroll-viewport');
    
    if (!agGridScrollViewport) {
      return;
    }

    const gridBodyContainer = gridContainerRef.current.querySelector('.ag-body-viewport');
    if (agGridScrollViewport && gridBodyContainer) {
      const isHorizontalScrollEnabled = agGridScrollViewport.scrollWidth > agGridScrollViewport.clientWidth;
      const agGridScrollbarRect = agGridScrollViewport.getBoundingClientRect();
      const pageHeight = (window.innerHeight || document.documentElement.clientHeight);
      const rectIsInView = agGridScrollbarRect.top >= 0 && agGridScrollbarRect.bottom <= pageHeight;

      if (!isHorizontalScrollEnabled || !rectIsInView) {
        floatingScrollbarViewport.current.style.display = 'block';
      }
      else {
        floatingScrollbarViewport.current.style.display = 'none';
      }

    }
  }

  function synchronizeCustomScrollbarWithAgGrid() {
    if (!gridContainerRef.current || !floatingScrollbarViewport.current) {
      return;
    } 

    const agGridScrollViewport = gridContainerRef.current.querySelector('.ag-body-horizontal-scroll-viewport');
    const agGridScrollContainer = gridContainerRef.current.querySelector('.ag-body-horizontal-scroll-container');   
    
    if (!agGridScrollViewport || !agGridScrollContainer) {
      return;
    }

    floatingScrollbarViewport.current.scrollLeft = agGridScrollViewport.scrollLeft;
    floatingScrollbarViewport.current!.style.width = window.getComputedStyle(agGridScrollViewport).width;
    floatingScrollbarContainer.current!.style.width = window.getComputedStyle(agGridScrollContainer).width;
  }

  function synchronizeAgGridScrollbarWithCustom() {
    if (!gridContainerRef.current || !floatingScrollbarViewport.current) {
      return;
    }

    const agGridScrollViewport = gridContainerRef.current.querySelector('.ag-body-horizontal-scroll-viewport');

    if (!agGridScrollViewport) {
      return;
    }

    agGridScrollViewport.scrollLeft = floatingScrollbarViewport.current.scrollLeft;
  }

  useEffect(() => {

    if (!gridContainerRef.current || !floatingScrollbarViewport.current) {
      return;
    }

    const agGridScrollViewport = gridContainerRef.current.querySelector('.ag-body-horizontal-scroll-viewport');
    const horizontalScroll = floatingScrollbarViewport.current;

    if (!agGridScrollViewport) {
      return;
    }

    agGridScrollViewport.addEventListener('scroll', synchronizeCustomScrollbarWithAgGrid);
    floatingScrollbarViewport.current.addEventListener('scroll', synchronizeAgGridScrollbarWithCustom);
    window.addEventListener('resize', synchronizeCustomScrollbarWithAgGrid);
    gridRef.current?.api.addEventListener('bodyHeightChanged', synchronizeCustomScrollbarWithAgGrid);
    gridRef.current?.api.addEventListener('columnResized', synchronizeCustomScrollbarWithAgGrid);
    window.addEventListener('scroll', updateCustomScrollbarDisplay);

    return () => {
      agGridScrollViewport.removeEventListener('scroll', synchronizeCustomScrollbarWithAgGrid);
      horizontalScroll.removeEventListener('scroll', synchronizeAgGridScrollbarWithCustom);
      window.removeEventListener('resize', synchronizeCustomScrollbarWithAgGrid);
      gridRef.current?.api.removeEventListener('bodyHeightChanged', synchronizeCustomScrollbarWithAgGrid);
      gridRef.current?.api.removeEventListener('columnResized', synchronizeCustomScrollbarWithAgGrid);
      window.removeEventListener('scroll', updateCustomScrollbarDisplay);
  }

  }, [gridContainerRef.current, floatingScrollbarViewport]);

  useEffect(() => {
    gridRef.current?.api?.setGridOption('domLayout', isFullScreen ? 'normal' : gridOptions?.domLayout ?? 'autoHeight');
  }, [isFullScreen]);

  const defaultGridOptions: GridOptions<T> = useMemo(() => {
    return {
      animateRows: false,
      rowHeight: 32,
      domLayout: gridOptions?.domLayout ?? 'autoHeight',
      suppressMenuHide: true,
      suppressDragLeaveHidesColumns: true,
      suppressHorizontalScroll: false,
      suppressCellFocus: true,
      getRowId: getRowId,
      enableRangeSelection: true,
      getRowClass: getRowClass,
      defaultColDef: {
        menuTabs: ['filterMenuTab'], // Show only the filter tab in the column menu
        tooltipComponent: TooltipRenderer
      },
      autoGroupColumnDef: {
        cellRenderer: (params: ICellRendererParams) => (
          <GroupCellRenderer
            params={params}
            innerRenderer={groupCellInnerRenderer}
            onChartClick={onChartClick}
            chartButtonDisplayCondition={chartButtonDisplayCondition}
            onlyShowGroupLabel={onlyShowGroupLabels}
          />
        ),
        headerName: 'Group',
        headerComponent: onChartClick ? GroupHeaderCellRenderer : undefined,
        // minWidth: groupColumnWidth ?? 100,
        flex: 1,
        pinned: true,
        cellClass: (params: CellClassParams<T, any>) => (params.node.level == 0 ? 'bold' : undefined),
        cellRendererParams: {
          suppressCount: true,
        },
      },
      tooltipInteraction: true,
      tooltipShowDelay: 0,
      tooltipHideDelay: 10000
    };
  }, [isFullScreen]);

  const mergedGridOptions = useMemo(() => {
    const result = deepMerge(defaultGridOptions, props.gridOptions);
    return result;
  }, [props.gridOptions, defaultGridOptions]);

  return (
    <div className={isFullScreen ? 'ag-theme-alpine fullscreen-box' : undefined}>
      { !hideGridOptions &&
        <ButtonGroup>
        <Button variant="text" onClick={toggleFullScreen}>{isFullScreen ? 'Minimize' : 'Full Screen'}</Button>
        <Button variant="text" onClick={toggleExpanded}>{isExpanded ? 'Collapse All' : 'Expand All'}</Button>
        <Button variant="text" onClick={expandOneLevel}>Expand</Button>
        <Button variant="text" onClick={collapseOneLevel}>Collapse</Button>
        <Button variant="text" onClick={autoFit}>Auto Fit Columns</Button>
        </ButtonGroup>
      }
      
      <div ref={gridContainerRef} className="ag-theme-alpine" style={isFullScreen ? { width: '100%', height: '100%' } : { width: 'auto', height: 'auto' }}>
        <AgGridReact ref={gridRef} columnDefs={columns!} gridOptions={mergedGridOptions} rowData={rows} />
        <div ref={floatingScrollbarViewport} className="ag-custom-horizontal-scroll">
          <div ref={floatingScrollbarContainer} className="ag-custom-horizontal-scroll-inner" />
        </div>
      </div>
    </div>
  );
})
