import { AxiosResponse } from 'axios';
import { FileResponse } from '../Generated/Commodity-Management-Api';

export function downloadFile(res: AxiosResponse<FileResponse, any>): void {
  try {
    const contentDispositionHeader = res.headers['content-disposition'];
    const fileName = contentDispositionHeader
      ? contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/"/g, '')
      : 'KnowRisk Export';

    const url = window.URL.createObjectURL(
      new Blob([res.data as any], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
    );

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}