import { Currency } from '../../../Generated/Commodity-Management-Api';

interface StoneXNumberProps {
  number?: number;
  decimals?: number;
  colored?: boolean;
  flipped?: boolean;
  percent?: boolean;
  currency?: Currency;
  standardNotation?: boolean;
  className?: string;
}

export function StoneXNumberFormat(props: StoneXNumberProps) : string {

  const { number, decimals = 0, colored = false, flipped = false, percent = false, currency, standardNotation } = props;

  const displayNumber = standardNotation
    ? getNumberWithStandardNotation(number, decimals, percent, currency, flipped)
    : getNumberWithAccountingNotation(number, decimals, percent, currency, flipped)
  
    return displayNumber;
}

export function StoneXNumber(props: StoneXNumberProps) {
  
  const { number, colored = false, flipped = false } = props;

  const displayNumber = StoneXNumberFormat(props);
  
  const colorClass = `${getColorClass(number, colored, flipped) || ''} ${props.className || ''}`.trim();

  return (
    <span className={colorClass} style={{width: '100%', textAlign: 'right'}}>
      {displayNumber}
    </span>
  );
}

function getFormattedNumber(number: number | undefined, decimals: number, percent: boolean, currency: Currency | undefined, flipped: boolean) {
  if (number === undefined) return '-';

  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  };

  if (percent) {
    options.style = 'percent';
  } else if (currency) {
    options.style = 'currency';
    options.currency = currency;
    options.currencyDisplay = 'symbol';
  }

  return Math.abs(number).toLocaleString(undefined, options);
}

function getNumberWithStandardNotation(number: number | undefined, decimals: number, percent: boolean, currency: Currency | undefined, flipped: boolean): string {
  const isNegative = number !== undefined && number < 0;
  const formattedNumber = getFormattedNumber(number, decimals, percent, currency, flipped);
  return isNegative ? `-${formattedNumber}` : formattedNumber;
}

function getNumberWithAccountingNotation(number: number | undefined, decimals: number, percent: boolean, currency: Currency | undefined, flipped: boolean): string {
  const isNegative = number !== undefined && number < 0;
  const formattedNumber = getFormattedNumber(number, decimals, percent, currency, flipped);
  return isNegative ? `(${formattedNumber})` : formattedNumber;
}

// function getNumberWithAccountingNotation(number: number | undefined, decimals: number, percent: boolean, flipped: boolean) {
//   var formattedNumber = number == undefined
//     ? '-'
//     : Math.abs(number).toLocaleString(undefined, { style: percent ? 'percent' : undefined, minimumFractionDigits: decimals, maximumFractionDigits: decimals });

//   const isNegative = number !== undefined && (flipped ? number > 0 : number < 0);

//   const displayNumber = isNegative ? `(${formattedNumber})` : formattedNumber;

//   return displayNumber;
// }

// function getNumberWithStandardNotation(number: number | undefined, decimals: number, percent: boolean) {
//   return number == undefined
//     ? '-'
//     : number.toLocaleString(undefined, { style: percent ? 'percent' : undefined, minimumFractionDigits: decimals, maximumFractionDigits: decimals });
// }

function getColorClass(number: number | undefined, colored: boolean, flipped: boolean) {
  if (!colored || number === undefined || number === 0) return undefined;
  if (flipped) {
    return number < 0 ? 'green' : 'red';
  }
  else {
    return number > 0 ? 'green' : 'red';
  }
}

function getSymbol(code: string): string | undefined {
  const currencySymbols: { [index: string]: string } = {
    USD: '$', // US Dollar
    CAD: 'C$', // Canadian Dollar
    EUR: '€', // Euro
    AUD: 'A$', // Australian Dollar
    GBP: '£', // British Pound
    BRL: 'R$', // Brazilian Real
    NZD: 'NZ$', // New Zealand Dollar
    MXN: 'MX$', // Mexican Peso
  };
  return currencySymbols[code];
}