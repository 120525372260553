import { Box, Button, Card, CardContent, List } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
// import 'ag-grid-community/styles//ag-grid.css';
// import 'ag-grid-community/styles//ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { debounce, flatMap, groupBy, some, uniq } from 'lodash';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useResizeListener } from '../../../../../Hooks';
import {
  MarketViewProductModel,
  MarketViewQueryCalendarStripAverageResponse,
  MarketViewQueryForwardCurveResponse,
  MarketViewQueryPriceResponse,
  MarketViewQueryRollingMonthAheadResponse,
  MarketViewQuerySettlementsResponse,
  MarketViewQueryStripAverageResponse,
} from '../../../../../Generated/Mark-It-View-Api';
import { MarkItViewQueryResult, PriceQueryType } from '../../../../../Types';
import './../../MarketView.scss';
import { StoneXRow } from '../../../../StoneX';
import { StoneXNumber } from '../../../../StoneXMui';
require('highcharts/modules/exporting')(Highcharts);
let numeral = require('numeraljs');

interface QueryDisplayProps {
  queries: MarkItViewQueryResult[];
}

type QuoteDate = string;
type Price = number | null | undefined;
type GridCell = ReactNode;

interface PriceData {
  price?: Price;
  chartLabel?: string | null;
  gridCell?: GridCell;
}

type Row = { quoteDate: QuoteDate; columns: { [key: QuoteDate]: PriceData } };

type QuoteDateValues = Record<QuoteDate, PriceData>;

type Series = {
  queryId: number | string;
  label: QuoteDate;
  queryType: PriceQueryType;
  product: MarketViewProductModel;
  quoteDateValues: QuoteDateValues;
  showInChart: boolean;
  showInGrid: boolean;
};

export default function QueryDisplay(props: QueryDisplayProps) {
  const { queries } = props;

  const [allQuerySeries, setAllQuerySeries] = useState<Series[]>([]);
  const [productYAxes, setProductYAxes] = useState<{ [productId: number]: 0 | 1 }>({});
  const [products, setProducts] = useState<{ [productId: number]: MarketViewProductModel }>({});

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([{ headerName: 'QuoteDate', field: 'quoteDate' }]);
  const [rowData, setRowData] = useState<Row[]>([]);
  const [showingChartControls, setShowingChartControls] = useState(false);

  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const chartContainerRef = useRef(null);
  const chartContainerDimensions = useResizeListener(chartContainerRef);

  const chartSizeUpdate = useMemo(
    () =>
      debounce(() => {
        chartRef.current?.chart.setSize(chartContainerDimensions.x, chartContainerDimensions.y);
      }, 50),
    [chartContainerDimensions],
  );

  useEffect(() => {
    chartSizeUpdate();

    return () => {
      chartSizeUpdate.cancel();
    };
  }, [chartContainerDimensions]);

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({});

  const defaultOptions: Highcharts.Options = {
    // chart: {
    //   zoomType: 'x',
    // },
    title: {
      text: 'Prices',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value:%b %Y}',
        rotation: -45,
      },
    },
    yAxis: [
      {
        title: {
          text: undefined,
        },
      },
      {
        title: {
          text: undefined,
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    // exporting: {
    //   enabled: true
    // }
    exporting: {
      menuItemDefinitions: {
        // Custom definition
        label: {
          onclick: function () {},
          text: 'Edit Axis',
        },
      },
      buttons: {
        contextButton: {
          enabled: true,
          menuItems: ['downloadPNG', 'downloadSVG', 'separator', 'label'],
        },
      },
    },
    //   colors: StoneXColors,
  };

  const dateTimeToTimestamp = (date: string) => dayjs(date).unix() * 1000;

  const toggleYAxis = (productId: number) => {
    const newAxis: 0 | 1 = productYAxes[productId] == 0 ? 1 : 0;
    const newAxes = { ...productYAxes, [productId]: newAxis };
    setProductYAxes(newAxes);
  };

  const dailyPricesToArray = (quoteDatePrices: QuoteDateValues): [number, Price][] => {
    const prices: [number, Price][] = [];

    for (const quoteDate in quoteDatePrices) {
      const quoteDateValue = quoteDatePrices[quoteDate];
      let timestamp = dateTimeToTimestamp(quoteDate);
      prices.push([timestamp, quoteDateValue.price]);
    }

    return prices.sort((a, b) => a[0] - b[0]);
  };

  const historicalSeriesToChartSeries = (series: Series): Highcharts.SeriesOptionsType => {
    return {
      type: 'line',
      name: series.label,
      data: dailyPricesToArray(series.quoteDateValues),
      yAxis: productYAxes[series.product.productId],
    } as Highcharts.SeriesOptionsType;
  };

  const buildHistoricalChart = (allSeries: Series[]) => {
    let chartSeries: Highcharts.SeriesOptionsType[] = [];

    for (const series of allSeries) {
      const seriesAsHighchartSeries = historicalSeriesToChartSeries(series);
      chartSeries = [...chartSeries, seriesAsHighchartSeries];
    }

    const options: Highcharts.Options = {
      ...defaultOptions,
      series: chartSeries,
    };

    setChartOptions(options);
  };

  const forwardPricesToArray = (query: MarketViewQueryForwardCurveResponse): [number, Price][] => {
    const prices: [number, Price][] = [];

    query.rows!.forEach((q) => {
      const date = new Date(q.contractDate!.year!, q.contractDate!.month!, 1).toString();
      const timestamp = dateTimeToTimestamp(date);
      prices.push([timestamp!, q.price]);
    });

    return prices;
  };

  const forwardQueryToChartSeries = (query: MarkItViewQueryResult) => {
    return {
      type: 'line',
      name: query.product.name,
      data: forwardPricesToArray(query.query as MarketViewQueryForwardCurveResponse),
      yAxis: productYAxes[query.product.productId],
    } as Highcharts.SeriesOptionsType;
  };

  const buildForwardChart = (queries: MarkItViewQueryResult[]) => {
    let chartSeries: Highcharts.SeriesOptionsType[] = [];

    for (const query of queries) {
      chartSeries.push(forwardQueryToChartSeries(query));
    }

    const options: Highcharts.Options = {
      ...defaultOptions,
      series: chartSeries,
    };

    setChartOptions(options);
  };

  const buildChart = (allSeries: Series[]) => {
    //historical price set up comes from the series data, but forward comes from the original queries
    const historicalSeries = allSeries.filter((q) => q.queryType !== PriceQueryType.ForwardCurve);
    const forwardQueries = queries.filter((q) => q.queryType === PriceQueryType.ForwardCurve);

    //can't chart a mixture of both forward & regular curves.
    const omitForwardCurves = some(historicalSeries);

    if (omitForwardCurves) {
      buildHistoricalChart(historicalSeries);
    } else {
      buildForwardChart(forwardQueries);
    }
  };

  const getQueryPriceSeries = (queryId: number, product: MarketViewProductModel, query: MarketViewQueryPriceResponse): Series[] => {
    const allContractDates = flatMap(query.rows, (q) => q.contractPrices!.map((r) => r.contractDate!.label!));
    const contractDates = uniq(allContractDates);

    let series: Series[] = [];

    contractDates?.forEach((contractDate, index) => {
      let quoteDatePrices: QuoteDateValues = {};

      query.rows?.forEach((q) => {
        const price = q.contractPrices?.find((r) => r.contractDate!.label! === contractDate)?.price;
        quoteDatePrices[q.quoteDate!] = {
          price: price
        };
      });

      const seriesData: Series = {
        queryId: `${queryId}_${index}`,
        label: contractDate,
        quoteDateValues: quoteDatePrices,
        product: product,
        queryType: PriceQueryType.Price,
        showInChart: true,
        showInGrid: true
      };

      series.push(seriesData);
    });

    return series;
  };

  const getAverageStripSeries = (queryId: number, product: MarketViewProductModel, query: MarketViewQueryStripAverageResponse): Series[] => {
    const dailyPrices: QuoteDateValues = {};
    let series: Series[] = [];

    query.rows?.forEach((q) => {
      dailyPrices[q.quoteDate!] = {price: q.price};
    });

    const seriesData: Series = {
      queryId: queryId,
      label: `${dayjs(query.quoteDateRange!.start).format('YYYY MMM')} - ${dayjs(query.quoteDateRange!.end).format('YYYY MMM')} Avg`,
      quoteDateValues: dailyPrices,
      product: product,
      queryType: PriceQueryType.StripAverage,
      showInChart: true,
      showInGrid: true
    };

    series.push(seriesData);

    return series;
  };

  const getCalendarStripAverageSeries = (queryId: number, product: MarketViewProductModel, query: MarketViewQueryCalendarStripAverageResponse): Series[] => {
    const years = uniq(query.rows?.flatMap((q) => q.yearPrices!.map((r) => r.year!)));

    console.log('years: ', years);

    let series: Series[] = [];

    years?.map((y) => {
      let dailyPrices: QuoteDateValues = {};

      query.rows?.forEach((q) => {
        const price = q.yearPrices?.find((r) => r.year === y)?.price;
        dailyPrices[q.quoteDate!] = {price: price};
      });

      const seriesData: Series = {
        queryId: `${queryId}_${y}`,
        label: y + '',
        quoteDateValues: dailyPrices,
        product: product,
        queryType: PriceQueryType.CalendarStripAverage,
        showInChart: true,
        showInGrid: true
      };

      series.push(seriesData);
    });

    return series;
  };

  const getRollingMonthAheadSeries = (queryId: number, product: MarketViewProductModel, query: MarketViewQueryRollingMonthAheadResponse): Series[] => {
    const dailyPrices: QuoteDateValues = {};
    let series: Series[] = [];

    query.rows?.forEach((q) => {
      dailyPrices[q.quoteDate!] = {price: q.price};
    });

    const seriesData: Series = {
      queryId: queryId,
      label: `Rolling ${query.monthsAhead} ${query.monthsAhead == 1 ? 'Month' : 'Months'}`,
      quoteDateValues: dailyPrices,
      product: product,
      queryType: PriceQueryType.StripAverage,
      showInChart: true,
      showInGrid: true
    };

    series.push(seriesData);

    return series;
  };

  const getForwardCurveSeries = (queryId: number, product: MarketViewProductModel, query: MarketViewQueryForwardCurveResponse): Series[] => {
    let series: Series[] = [];

    query.rows?.forEach((q) => {
      const dailyPrices: QuoteDateValues = {};

      dailyPrices[query.quoteDate!] = {price: q.price};

      const seriesData: Series = {
        queryId: `${queryId}_${q.contractDate!.label!}`,
        label: q.contractDate!.label!,
        quoteDateValues: dailyPrices,
        product: product,
        queryType: PriceQueryType.ForwardCurve,
        showInChart: true,
        showInGrid: true
      };

      series.push(seriesData);
    });

    return series;
  };

  const getSettlementsSeries = (queryId: number, product: MarketViewProductModel, query: MarketViewQuerySettlementsResponse): Series[] => {
    const dailyPrices: QuoteDateValues = {};
    const dailyValues: QuoteDateValues = {};
    let series: Series[] = [];

    query.rows?.forEach((q) => {
      dailyPrices[q.quoteDate!] = {price: q.price};
      dailyValues[q.quoteDate!] = {price: null, chartLabel: q.contractDate.label, gridCell: <>{q.contractDate.label}</>}
    });

    const priceSeriesData: Series = {
      queryId: queryId,
      label: `Settlement Prices`,
      quoteDateValues: dailyPrices,
      product: product,
      queryType: PriceQueryType.StripAverage,
      showInChart: true,
      showInGrid: true
    };

    const contractSeriesData: Series = {
      queryId: queryId + '_settlement_months',
      label: `Settlement Month`,
      quoteDateValues: dailyValues,
      product: product,
      queryType: PriceQueryType.StripAverage,
      showInChart: false,
      showInGrid: true
    };

    series.push(contractSeriesData);
    series.push(priceSeriesData);

    return series;
  };

  const buildQueryPriceDict = (queries: MarkItViewQueryResult[]): void => {
    let allSeriesDict: Series[] = [];

    queries.forEach((q) => {
      switch (q.queryType) {
        case PriceQueryType.Price:
          allSeriesDict = [...allSeriesDict, ...getQueryPriceSeries(q.id, q.product, q.query as MarketViewQueryPriceResponse)];
          break;
        case PriceQueryType.StripAverage:
          allSeriesDict = [...allSeriesDict, ...getAverageStripSeries(q.id, q.product, q.query as MarketViewQueryStripAverageResponse)];
          break;
        case PriceQueryType.CalendarStripAverage:
          allSeriesDict = [...allSeriesDict, ...getCalendarStripAverageSeries(q.id, q.product, q.query as MarketViewQueryCalendarStripAverageResponse)];
          break;
        case PriceQueryType.RollingMonthAhead:
          allSeriesDict = [...allSeriesDict, ...getRollingMonthAheadSeries(q.id, q.product, q.query as MarketViewQueryRollingMonthAheadResponse)];
          break;
        case PriceQueryType.Rolling12MonthStrip:
          allSeriesDict = [...allSeriesDict, ...getRollingMonthAheadSeries(q.id, q.product, q.query as MarketViewQueryRollingMonthAheadResponse)];
          break;
        case PriceQueryType.ForwardCurve:
          allSeriesDict = [...allSeriesDict, ...getForwardCurveSeries(q.id, q.product, q.query as MarketViewQueryForwardCurveResponse)];
          break;
        case PriceQueryType.Settlement:
          allSeriesDict = [...allSeriesDict, ...getSettlementsSeries(q.id, q.product, q.query as MarketViewQuerySettlementsResponse)];
          break;
      }
    });

    setAllQuerySeries(allSeriesDict);
  };

  const getAllQuoteDates = (allSeries: Series[]): string[] => {
    let allQuoteDates: string[] = [];

    for (const series of allSeries) {
      allQuoteDates = [...allQuoteDates, ...Object.keys(series.quoteDateValues)];
    }

    const uniqueQuoteDates = uniq(allQuoteDates).sort();
    return uniqueQuoteDates;
  };

  const queriesInGridDataFormat = (series: Series[]): Row[] => {
    const allQuoteDates = getAllQuoteDates(series);
    let rows: Row[] = [];

    allQuoteDates.forEach((qd) => {
      // A list of PriceData for each query.
      let quoteDateColumns: { [key: string]: PriceData } = {};

      for (const index in series) {
        const price = series[index].quoteDateValues[qd];
        quoteDateColumns[series[index].queryId] = price;
      }

      const row: Row = {
        quoteDate: qd,
        columns: quoteDateColumns,
      };

      rows.push(row);
    });

    return rows;
  };

  const buildTable = (allSeries: Series[]) => {

    allSeries = allSeries.filter(x => x.showInGrid);

    const dataInGridFormat = queriesInGridDataFormat(allSeries);
    setRowData(dataInGridFormat);

    const seriesGrouping =  groupBy(allSeries, x => x.product.name);
    const groupedColumns = Object.entries(seriesGrouping).map(([groupingLabel, groupedSeries]: [string, Series[]]) => {
      return {
        headerName: groupingLabel,
        children: groupedSeries.map((series, index) => {
          return {
            headerName: series.label,
            cellRenderer: (params: ICellRendererParams<Row>) => {

              const cellData = params.data?.columns[series.queryId];

              if (cellData?.gridCell) {
                return cellData.gridCell;
              }
              else {
                return <StoneXNumber number={cellData?.price ?? undefined} decimals={3}/>
              }

            },
          };
        }),
      };
    })

    const quoteDateHeader = {
      headerName: 'Quote Date',
      field: 'quoteDate',
      valueFormatter: (params: any) => dayjs(params.value).format('L'),
    };

    setColumnDefs([quoteDateHeader, ...groupedColumns]);
  };

  const setDefaultProductYAxes = (allSeries: Series[]) => {
    //just set all products to y-axis 1
    const axes: { [productId: number]: 0 | 1 } = {};

    allSeries.forEach((s) => {
      if (axes[s.product.productId]) return;
      axes[s.product.productId] = 0;
    });

    setProductYAxes(axes);
  };

  const setProductsDict = (queries: MarkItViewQueryResult[]) => {
    const products: { [productId: number]: MarketViewProductModel } = {};

    queries.forEach((q) => {
      products[q.product.productId] = q.product;
    });

    setProducts(products);
  };

  useEffect(() => {
    buildQueryPriceDict(queries);
    setProductsDict(queries);
  }, [queries]);

  useEffect(() => {
    setDefaultProductYAxes(allQuerySeries);
    buildChart(allQuerySeries);
    buildTable(allQuerySeries);
  }, [allQuerySeries]);

  useEffect(() => {
    buildChart(allQuerySeries);
  }, [productYAxes]);

  return (
    <>
      <StoneXRow align="start">
        <div style={{ width: '250px' }}>
          <Button
            size="small"
            onClick={(e: any) => {
              setShowingChartControls((p) => !p);
            }}
          >
            {showingChartControls ? 'Hide Controls' : 'Show Controls'}
          </Button>
          {showingChartControls && (
            <Box sx={{ width: '250px' }}>
              {Object.values(products).map((q) => (
                <List key={q.productId} style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{q.name}</div>
                  {/* <IconButton edge='end' size="small" onClick={() => toggleYAxis(q.productId)}>
                      {productYAxes[q.productId] == 1 ? <LooksOne /> : <LooksTwo />}
                    </IconButton> */}
                  <Button variant="outlined" onClick={(e: any) => toggleYAxis(q.productId)} size="small">
                    {productYAxes[q.productId] + 1}
                  </Button>
                </List>
                // <ListItem>
                //   <ListItemText primary={q.name} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}></ListItemText>

                // </ListItem>
              ))}
            </Box>
          )}
        </div>
      </StoneXRow>
      <StoneXRow>
        <div ref={chartContainerRef} style={{ width: '1500px', justifySelf: 'center', overflow: 'auto', resize: 'both', padding: 0, margin: 0, zIndex: 2 }}>
          <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} />
        </div>
      </StoneXRow>
      <div className="ag-theme-alpine" style={{ height: '800px' }}>
        <AgGridReact columnDefs={columnDefs} rowData={rowData} />
      </div>
    </>
  );
}
