import { ArrowLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { includes } from 'lodash';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MarketViewApplication } from '../../Generated/Mark-It-View-Api';
import { RootState, useApplicationSelector } from '../../Redux/ReduxStore';
import CommodityManagementSiteSelector from '../MainContainer/CommodityManagement/CommodityManagementSiteSelector';
import { INavProps } from './InterfaceNav';
import './SidebarNavigation.scss';

interface NavigationLink {
  id: string;
  type: 'Link';
  url: string;
  label: string;
  end?: boolean;
  subMenuItems?: NavigationLink[];
  showCondition?: () => boolean;
}

interface NavigationHeader {
  id: string;
  type: 'Header';
  headerName: string;
  subMenuItems: NavigationLink[];
  showCondition?: () => boolean;
}

interface NavigationHorizontalBreak {
  id: string;
  type: 'HorizontalBreak';
}

type NavigationItem = NavigationLink | NavigationHeader | NavigationHorizontalBreak;

export const SidebarNavigation: React.FC<INavProps> = () => {
  const access = useApplicationSelector((state: RootState) => state.userAccess.hasAccess);
  const userAccess = useApplicationSelector((state: RootState) => state.userAccess);

  const [navigationItems, setNavigationItems] = useState<NavigationItem[]>([]);
  const [collapsedItems, setCollapsedItems] = useState<Record<string, boolean>>({});

  const userHasCommodityManagementSite = () => userAccess.commodityManagementUserSiteAccesses?.length > 0;
  const userHasApplication = (application: MarketViewApplication) => includes(userAccess.marketViewApplications, application);
  const userIsAdmin = () => userAccess.isAdmin;

  const toggleCollapse = (id: string) => {
    setCollapsedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    const savedCollapsedItems = localStorage.getItem('navBarCollapsedItems');
    if (savedCollapsedItems) {
      setCollapsedItems(JSON.parse(savedCollapsedItems));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('navBarCollapsedItems', JSON.stringify(collapsedItems));
  }, [collapsedItems]);

  useEffect(() => {
    const items: NavigationItem[] = [
      {
        id: 'Commodity-Management-Header',
        type: 'Header',
        headerName: 'Commodity Management',
        showCondition: userHasCommodityManagementSite,
        subMenuItems: [
          { id: 'Commodity-Management-Reports', type: 'Link', url: '/commodity-management/reports', label: 'Reports' },
          {
            id: 'Commodity-Management-Budgets',
            type: 'Link',
            url: '/commodity-management/budgets',
            label: 'Budgets',
            subMenuItems: [
              { id: 'Commodity-Management-Update-Budgets', type: 'Link', url: '/commodity-management/budgets/update', label: 'Add Budgets', end: true },
            ],
          },
          {
            id: 'Commodity-Management-Positions',
            type: 'Link',
            url: '/commodity-management/positions',
            label: 'Positions',
            end: true,
            subMenuItems: [
              { id: 'Commodity-Management-Update-Positions', type: 'Link', url: '/commodity-management/positions/update', label: 'Add Positions', end: true },
            ],
          },
          { id: 'Commodity-Management-Notes', type: 'Link', url: '/commodity-management/notes', label: 'Notes' },
          { id: 'Commodity-Management-Settings', type: 'Link', url: '/commodity-management/settings', label: 'Settings' },
        ],
      },
      {
        id: 'Tools-Header',
        type: 'Header',
        headerName: 'Tools',
        subMenuItems: [
          {
            id: 'Tools-Mark-It-View',
            type: 'Link',
            url: '/market-view',
            label: 'Mark-It-View',
            showCondition: () => userHasApplication(MarketViewApplication.MarketView),
          },
          {
            id: 'Tools-Mark-It-Deciles',
            type: 'Link',
            url: '/deciles',
            label: 'Deciles',
            showCondition: () => userHasApplication(MarketViewApplication.Deciles),
          },
        ],
      },
      {
        id: 'Administration-Header',
        type: 'Header',
        headerName: 'Administration',
        showCondition: userIsAdmin,
        subMenuItems: [
          { id: 'Administration-Sites', type: 'Link', url: '/admin/sites', label: 'Sites' },
          { id: 'Administration-Users', type: 'Link', url: '/admin/users', label: 'Users' },
          { id: 'Administration-Commodities', type: 'Link', url: '/admin/commodities', label: 'Commodities' },
        ],
      },
    ];

    setNavigationItems(items);
  }, [userAccess]);

  const renderNavigationItem = (item: NavigationItem) => {
    if (item.type === 'Link') {
      const { id, url, label, end, subMenuItems, showCondition } = item as NavigationLink;

      if (showCondition && !showCondition()) return null;

      const isCollapsed = collapsedItems[id] || false;

      return (
        <React.Fragment key={id}>
          <li className="nav-item">
            <NavLink
              end={end}
              className={(props) => (props.isActive ? 'nav-link active' : 'nav-link')}
              to={url}
            >
              <span className="aside-text">{label}</span>
            </NavLink>
            {subMenuItems && (
              <IconButton onClick={() => toggleCollapse(id)} style={{ marginLeft: '8px' }} size="small">
                <ArrowLeft
                  style={{
                    transform: isCollapsed ? 'rotate(0deg)' : 'rotate(-90deg)',
                  }}
                />
              </IconButton>
            )}
          </li>
          {!isCollapsed && subMenuItems && (
            <ul className="sub-menu">{subMenuItems.map(renderNavigationItem)}</ul>
          )}
        </React.Fragment>
      );
    }

    if (item.type === 'Header') {
      const { id, headerName, subMenuItems, showCondition } = item as NavigationHeader;

      if (showCondition && !showCondition()) return null;

      const visibleSubMenuItems = subMenuItems.filter(
        (subItem) => !subItem.showCondition || subItem.showCondition()
      );

      if (visibleSubMenuItems.length === 0) return null;

      const isCollapsed = collapsedItems[id] || false;

      return (
        <div key={id} className="header-container">
          <li className="nav-header-item" >
            <span className="nav-header">{headerName}</span>
            <IconButton onClick={() => toggleCollapse(id)} size="small">
              <ArrowLeft
                style={{
                  transform: isCollapsed ? 'rotate(0deg)' : 'rotate(-90deg)',
                }}
              />
            </IconButton>
          </li>
          {!isCollapsed && (
            <div className="header-sub-menu">
              {visibleSubMenuItems.map(renderNavigationItem)}
              <hr />
            </div>
          )}
        </div>
      );
    }

    if (item.type === 'HorizontalBreak') {
      return <hr key={item.id} />;
    }

    return null;
  };

  if (!access || navigationItems.length <= 1) {
    return null;
  }

  return (
    <aside id="menu">
      <ul>
        <span style={{ margin: 10 }}>
          <CommodityManagementSiteSelector />
        </span>
        <br />
        {navigationItems.map(renderNavigationItem)}
      </ul>
    </aside>
  );
};

export default SidebarNavigation;
