import { Button, ButtonGroup } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { CommodityManagementApi } from '../../../../../../Apis/Apis';
import { Constants } from '../../../../../../Core/Constants';
import { ContractDate, CustomerReportModel, ExpiryStatus, PositionModel } from '../../../../../../Generated/Commodity-Management-Api';
import { ModelToRowTransformer } from '../../../../../../Helpers/ModelToRowTransformer';
import { deepMerge } from '../../../../../../Helpers/ObjectHelper';
import { useLoadingState } from '../../../../../../Hooks';
import { RootState, useApplicationSelector } from '../../../../../../Redux/ReduxStore';
import { GroupingOption } from '../../../../../../Types/GridRow';
import { StoneXLoading, StoneXRow } from '../../../../../StoneX';
import { StoneXButton, StoneXContractMonthPicker, StoneXDatePicker, StoneXSelect } from '../../../../../StoneXMui';
import { ReportProps } from '../../ReportsPage';
import PositionReportSettingsModal from '../PositionReport/PositionReportSettingsModal';
import PositionReportSummaryDisplay from '../PositionReport/PositionReportSummaryDisplay';
import PositionReportTable from '../PositionReport/PositionReportTable';
import { PositionReportConfiguration, PositionReportRow, PositionReportUserOverride } from '../PositionReport/Types';
import { PlayArrow, Download } from '@mui/icons-material';
import { downloadFile } from '../../../../../../Helpers';

export function ProfitLossReport(props: ReportProps) {
  const { customerName, customerReportId, customerSettings } = props;

  const customerReport = useApplicationSelector((state: RootState): CustomerReportModel => state.commodityManagement.customerReports.find((x: any) => x.customerReportId === customerReportId)!);

  const [quoteDate, setQuoteDate] = useState<Date | null>(props.quoteDate);
  const quoteDateString = useMemo(() => (quoteDate ? dayjs(quoteDate).format('YYYY-MM-DD') : null), [quoteDate]);
  const [budgetStartMonth, setBudgetStartMonth] = useState<ContractDate | null>(null);
  const [budgetEndMonth, setBudgetEndMonth] = useState<ContractDate | null>(null);

  const reportLoadingState = useLoadingState();
  const reportDownloadingState = useLoadingState();
  const reportIsLoading = reportLoadingState.isLoading() || reportDownloadingState.isLoading();

  const [positions, setPositions] = useState<PositionModel[]>([]);
  const [positionsClosed, setPositionsClosed] = useState<PositionModel[]>([]);
  const [rows, setRows] = useState<PositionReportRow[]>([]);
  const [rowsClosed, setRowsClosed] = useState<PositionReportRow[]>([]);

  const reportConfiguration = useMemo(() => JSON.parse(customerReport.configuration!) as PositionReportConfiguration, [customerReport]);
  const userOverrides = useMemo(() => (customerReport.userOverrides ? (JSON.parse(customerReport.userOverrides) as PositionReportUserOverride) : undefined), [customerReport]);
  const reportSettings = useMemo(() => deepMerge(reportConfiguration, userOverrides), [reportConfiguration, userOverrides]);

  const [groupingOptions, setGroupingOptions] = useState<GroupingOption[]>([]);

  const [isConfiguringReport, setIsConfiguringReport] = useState<boolean>(false);

  const getReport = (download?: boolean) => {
    return CommodityManagementApi.getMarkToMarketReport(
      download ? reportDownloadingState.setLoadingState : reportLoadingState.setLoadingState,
      quoteDateString!,
      customerReport.customerReportId,
      budgetStartMonth ? ExpiryStatus.All : ExpiryStatus.Open,
      budgetStartMonth?.year,
      budgetStartMonth ? budgetStartMonth.month! + 1 : undefined,
      undefined,
      budgetEndMonth?.year,
      budgetEndMonth?.month,
      undefined,
      undefined,
      download ? {responseType: 'blob', headers: {'ContentType': Constants.MediaType.Excel}} : undefined,
    );
  }

  function runReport() {
    CommodityManagementApi.getMarkToMarketReport(
      reportLoadingState.setLoadingState,
      quoteDateString!,
      customerReport.customerReportId,
      budgetStartMonth ? ExpiryStatus.All : ExpiryStatus.Open,
      budgetStartMonth?.year,
      budgetStartMonth ? budgetStartMonth.month! + 1 : undefined,
      undefined,
      budgetEndMonth?.year,
      budgetEndMonth?.month,
      undefined,
      undefined
    ).then((res) => {
      setPositions(res.data.rows?.filter(x => x.isExpired !== null && x.isExpired !== undefined && !x.isExpired) ?? []);
      setPositionsClosed(res.data.rows?.filter(x => x.isExpired !== null && x.isExpired !== undefined && x.isExpired) ?? []);
    });
  }

  function downloadToExcel() {
    CommodityManagementApi.getMarkToMarketExcelReport(
      reportDownloadingState.setLoadingState,
      quoteDateString!,
      customerReport.customerReportId,
      budgetStartMonth ? ExpiryStatus.All : ExpiryStatus.Open,
      budgetStartMonth?.year,
      budgetStartMonth ? budgetStartMonth.month! + 1 : undefined,
      undefined,
      budgetEndMonth?.year,
      budgetEndMonth?.month,
      undefined,
      undefined,
      {responseType: 'blob', headers: {'ContentType': Constants.MediaType.Excel}}
    ).then(downloadFile);
  }

  function updateReportRows() {
    const rows: PositionReportRow[] = positions.map(ModelToRowTransformer.convertModelToTableRow);

    // Filter out undefined groupings
    const selectedGroupings = [reportSettings?.behavior?.grouping?.[0], reportSettings?.behavior?.grouping?.[1]].filter((x) => !!x).map((x) => x!);

    // Update the path for each row based on groupings
    ModelToRowTransformer.updatePaths(rows, selectedGroupings);

    // Add positionId to path to make each path unique
    rows.forEach((x) => x.path.push(x.positionId?.toString() ?? ''));

    setRows(rows);
  }

  function updateReportRowsClosed() {
    const rows: PositionReportRow[] = positionsClosed.map(ModelToRowTransformer.convertModelToTableRow);

    // Filter out undefined groupings
    const selectedGroupings = [reportSettings?.behavior?.grouping?.[0], reportSettings?.behavior?.grouping?.[1]].filter((x) => !!x).map((x) => x!);

    // Update the path for each row based on groupings
    ModelToRowTransformer.updatePaths(rows, selectedGroupings);

    // Add positionId to path to make each path unique
    rows.forEach((x) => x.path.push(x.positionId?.toString() ?? ''));

    setRowsClosed(rows);
  }

  function updateGroupingOptions() {
    const groupingOptions: GroupingOption[] = ['BudgetMonth'];

    if (customerSettings.positionsByLocation) {
      groupingOptions.push('BudgetingTier');
      groupingOptions.push('Tiers');
    }

    if (customerSettings.positionsByCounterparty) {
      groupingOptions.push('Counterparty');
    }

    setGroupingOptions(groupingOptions);
  }

  function closeModal() {
    setIsConfiguringReport(false);
  }

  useEffect(updateReportRows, [positions, customerReport]);
  useEffect(updateReportRowsClosed, [positionsClosed, customerReport]);
  useEffect(updateGroupingOptions, []);

  return (
    <div>
      <StoneXRow extraHorizontalSpacing>
        {reportSettings?.inputs?.quoteDate?.show && <StoneXDatePicker onChange={setQuoteDate} label="Quote Date" value={quoteDate} width="small" />}
        {reportSettings?.inputs?.budgetStartDate?.show && <StoneXContractMonthPicker value={budgetStartMonth} label="Budget Start Month" onChange={setBudgetStartMonth} />}
        {reportSettings?.inputs?.budgetEndDate?.show && <StoneXContractMonthPicker value={budgetEndMonth} label="Budget End Month" onChange={setBudgetEndMonth} /> }
                
        <ButtonGroup>
          <StoneXButton variant="outlined" onClick={runReport} startIcon={<PlayArrow />} disabled={reportIsLoading}>Run</StoneXButton>
          <StoneXButton variant="outlined" onClick={downloadToExcel} startIcon={<Download />} isWorking={reportDownloadingState.isLoading()} disabled={reportIsLoading}>Excel</StoneXButton>
        </ButtonGroup>

        <Button className="pull-right" variant="outlined" onClick={() => setIsConfiguringReport(true)}>Configure Report</Button>
      </StoneXRow>
      
      <br />

      <StoneXLoading show={reportLoadingState.isLoading()} />
      {reportLoadingState.isLoaded() && (
        <>
          <StoneXRow align="center">
            <h2>
              {customerName} {customerReport.reportName} - Closed
            </h2>
            <PositionReportSummaryDisplay
              positions={positionsClosed}
              quoteDateString={quoteDateString!}
              showNetOptionPremium={reportSettings?.behavior?.netOptionPremiums ?? false}
            />
          </StoneXRow>
              
          {positionsClosed.length > 0 
            ? <PositionReportTable columnsToShow={reportSettings.columns?.active} rows={rowsClosed} />
            : <p>Select a budget start month and run again to calcualate mark-to-market for closed positions.</p>
          }

          <br />

          <StoneXRow align="center">
            <h2>
              {customerName} {customerReport.reportName} - Open
            </h2>
            <PositionReportSummaryDisplay
              positions={positions}
              quoteDateString={quoteDateString!}
              showNetOptionPremium={reportSettings?.behavior?.netOptionPremiums ?? false}
            />
          </StoneXRow>

          <PositionReportTable columnsToShow={reportSettings.columns?.active} rows={rows} />
        </>
      )}

      <PositionReportSettingsModal
        open={isConfiguringReport}
        customerReport={customerReport}
        groupingOptions={groupingOptions}
        onClose={closeModal}
        onSave={closeModal}
      />
    </div>
  );
}
