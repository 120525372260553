export function numberToShortMonth(year: number): string | undefined {
  switch (year) {
    case 1:
      return 'Jan';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Apr';
    case 5:
      return 'Mar';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Aug';
    case 9:
      return 'Sep';
    case 10:
      return 'Oct';
    case 11:
      return 'Nov';
    case 12:
      return 'Dec';
    default:
      return undefined;
  }
}

export function numberToMonth(month: number): string | undefined {
  switch (month) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return undefined;
  }
}

export function monthToNumber(month: string): number | undefined {
  switch (month?.toLowerCase()) {
    case 'january':
    case 'jan':
      return 1;
    case 'february':
    case 'feb':
      return 2;
    case 'march':
    case 'mar':
      return 3;
    case 'april':
    case 'apr':
      return 4;
    case 'may':
      return 5;
    case 'june':
    case 'jun':
      return 6;
    case 'july':
    case 'jul':
      return 7;
    case 'august':
    case 'aug':
      return 8;
    case 'september':
    case 'sep':
      return 9;
    case 'october':
    case 'oct':
      return 10;
    case 'november':
    case 'nov':
      return 11;
    case 'december':
    case 'dec':
      return 12;
    default:
      return undefined;
  }
}

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
