import { ColDef, ColGroupDef } from "ag-grid-community";
import 'ag-grid-enterprise';
import { Currency, ExpiryStatus, PositionModel } from '../../../../../../Generated/Commodity-Management-Api';
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState, useRef } from "react";
import { StoneXGrid } from '../../../../../StoneX';
import { CellClassParams, IAggFuncParams, ICellRendererParams, ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import { StoneXNumber } from "../../../../../StoneXMui";
import { SummaryReportRow } from './Types';
import { sum } from "lodash";

interface SummaryReportTableProps {
    rows: SummaryReportRow[];
}

export default function TotalHedgeSummaryTable(props: SummaryReportTableProps) {

    const { rows } = props;


    const gridApiRef = useRef<AgGridReact>(null);

    const [totalFixedMtm, setTotalFixedMtm] = useState<number>();
    const [totalOptionMtm, setTotalOptionMtm] = useState<number>();
    const [totalMtm, setTotalMtm] = useState<number>();
    const [totalOptionPremium, setTotalPremium] = useState<number>();
    const valueCellRenderer = (params: ICellRendererParams<SummaryReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value} colored></StoneXNumber>;

    const [rowData, setRowData] = useState([
        { product: "Fixed", mtm: totalFixedMtm, premium: 0 },
        { product: "Call", mtm: totalOptionMtm, premium: totalOptionPremium },
        { product: "Total", mtm: totalMtm, premium: totalOptionPremium },
    ]);
    function updateTotals() {
        setTotalFixedMtm(sum(rows.map(x => x.fixedMarketValue ?? 0)));
        setTotalOptionMtm(sum(rows.map(x => x.optionMarketValue ?? 0)));
        setTotalMtm(sum(rows.map(x => x.markToMarketValue ?? 0)));
        setTotalPremium(sum(rows.map(x => x.netOptionPremium ?? 0)));
        setRowData ([
            { product: "Fixed", mtm: totalFixedMtm, premium: 0 },
            { product: "Call", mtm: totalOptionMtm, premium: totalOptionPremium },
            { product: "Total", mtm: totalMtm, premium: totalOptionPremium },
        ])
        if (gridApiRef.current?.api != undefined) {
            gridApiRef.current?.api.setRowData(rowData);
        }
    }


    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { field: "product", headerName: "Product Type", flex: 1},
        { field: "mtm", headerName: "MTM Gain/Loss", cellRenderer: valueCellRenderer, flex: 1 },
        { field: "premium", headerName: "Net Option Premium Expense/(Credit)", cellRenderer: valueCellRenderer, flex: 1 },
    ]);

    useEffect(updateTotals, [rows]);

    return <StoneXGrid
        hideGridOptions
        ref={gridApiRef}
        rows={rowData}
        columns={colDefs} 
        />
}