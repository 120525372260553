import { Button, CircularProgress } from "@mui/material";
import { StoneXRow } from "../../../StoneX";
import { StoneXModal } from "../../../StoneXMui";
import { NoteModel } from "../../../../Generated/Commodity-Management-Api";
import { CommodityManagementApi } from "../../../../Apis/Apis";
import { useSnackbar } from "notistack";
import { useLoadingState } from "../../../../Hooks";

interface NoteDeleteModalProps {
  note: NoteModel;
  open: boolean;
  onClose: () => void;
  onDelete: (note: NoteModel) => void;
}

export function NoteDeleteModal(props: NoteDeleteModalProps) {

  const deleteLoadingState = useLoadingState();
  const { note, open, onClose, onDelete} = props;

  const { enqueueSnackbar } = useSnackbar();

  function deleteNote() {
    CommodityManagementApi.deleteNote(deleteLoadingState.setLoadingState, note.noteId.toString())
    .then(res => {
      enqueueSnackbar('Note has been successfully deleted', {variant: 'success'});
      onDelete(note);
    })
    .catch(res => {
      enqueueSnackbar('Failed to delete note', {variant: 'error'});
    });
  }

  return <StoneXModal width='450px' header='Delete Note' open={open} onClose={onClose}>
    <StoneXRow>
      Are you sure you want to delete this note?
    </StoneXRow>
    
    <StoneXRow justify="end" style={{minHeight: '80px'}}>
      <Button variant="outlined" onClick={onClose}>Cancel</Button>
      <Button disabled={deleteLoadingState.isLoading()} onClick={deleteNote}>Delete {deleteLoadingState.isLoading() && <> &nbsp; <CircularProgress size='1em'/> </>}</Button>
    </StoneXRow>
  </StoneXModal>
}