/* tslint:disable */
/* eslint-disable */
/**
 * Raven CommodityManagement Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

import { LoadingState } from '../../Types';

/**
 * 
 * @export
 * @interface ActualModel
 */
export interface ActualModel {
    /**
     * 
     * @type {number}
     * @memberof ActualModel
     */
    'actualId': number;
    /**
     * 
     * @type {CommodityModel}
     * @memberof ActualModel
     */
    'commodity': CommodityModel;
    /**
     * 
     * @type {CounterpartyModel}
     * @memberof ActualModel
     */
    'counterparty': CounterpartyModel;
    /**
     * 
     * @type {LocationModel}
     * @memberof ActualModel
     */
    'location': LocationModel;
    /**
     * 
     * @type {Array<ActualPriceModel>}
     * @memberof ActualModel
     */
    'actualPrices': Array<ActualPriceModel>;
    /**
     * 
     * @type {number}
     * @memberof ActualModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ActualModel
     */
    'volume': number;
    /**
     * 
     * @type {Unit}
     * @memberof ActualModel
     */
    'unit': Unit;
}
/**
 * 
 * @export
 * @interface ActualPriceModel
 */
export interface ActualPriceModel {
    /**
     * 
     * @type {number}
     * @memberof ActualPriceModel
     */
    'actualPriceId': number;
    /**
     * 
     * @type {ComponentType}
     * @memberof ActualPriceModel
     */
    'componentType': ComponentType;
    /**
     * 
     * @type {number}
     * @memberof ActualPriceModel
     */
    'price': number;
    /**
     * 
     * @type {Unit}
     * @memberof ActualPriceModel
     */
    'unit': Unit;
}
/**
 * 
 * @export
 * @interface AddCommodityResponse
 */
export interface AddCommodityResponse {
    /**
     * 
     * @type {CommodityModel}
     * @memberof AddCommodityResponse
     */
    'commodity'?: CommodityModel;
}
/**
 * 
 * @export
 * @interface AddCounterpartyResponse
 */
export interface AddCounterpartyResponse {
    /**
     * 
     * @type {CounterpartyModel}
     * @memberof AddCounterpartyResponse
     */
    'counterparty'?: CounterpartyModel;
}
/**
 * 
 * @export
 * @interface AddLocationResponse
 */
export interface AddLocationResponse {
    /**
     * 
     * @type {LocationModel}
     * @memberof AddLocationResponse
     */
    'location'?: LocationModel;
}
/**
 * 
 * @export
 * @interface AddMarketViewSavedQueryResponse
 */
export interface AddMarketViewSavedQueryResponse {
    /**
     * 
     * @type {MarkItViewSavedQueryModel}
     * @memberof AddMarketViewSavedQueryResponse
     */
    'savedQuery'?: MarkItViewSavedQueryModel;
}
/**
 * 
 * @export
 * @interface AddMarketViewUserFavoritedProductRequest
 */
export interface AddMarketViewUserFavoritedProductRequest {
    /**
     * 
     * @type {number}
     * @memberof AddMarketViewUserFavoritedProductRequest
     */
    'productId': number;
}
/**
 * 
 * @export
 * @interface AddMarketViewUserResponse
 */
export interface AddMarketViewUserResponse {
    /**
     * 
     * @type {MarketViewUserModel}
     * @memberof AddMarketViewUserResponse
     */
    'user'?: MarketViewUserModel;
}
/**
 * 
 * @export
 * @interface AddMarketViewVolumeResponse
 */
export interface AddMarketViewVolumeResponse {
    /**
     * 
     * @type {number}
     * @memberof AddMarketViewVolumeResponse
     */
    'volumeId'?: number;
}
/**
 * 
 * @export
 * @interface AddNoteRequest
 */
export interface AddNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof AddNoteRequest
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface AddNoteResponse
 */
export interface AddNoteResponse {
    /**
     * 
     * @type {NoteModel}
     * @memberof AddNoteResponse
     */
    'note'?: NoteModel;
}
/**
 * 
 * @export
 * @interface AddPortfolioResponse
 */
export interface AddPortfolioResponse {
    /**
     * 
     * @type {PortfolioModel}
     * @memberof AddPortfolioResponse
     */
    'portfolio'?: PortfolioModel;
}
/**
 * 
 * @export
 * @interface AddTierResponse
 */
export interface AddTierResponse {
    /**
     * 
     * @type {TierModel}
     * @memberof AddTierResponse
     */
    'tier'?: TierModel;
}
/**
 * 
 * @export
 * @interface AgreeToMarketViewTermsAndConditionsRequest
 */
export interface AgreeToMarketViewTermsAndConditionsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AgreeToMarketViewTermsAndConditionsRequest
     */
    'agree': boolean;
    /**
     * 
     * @type {number}
     * @memberof AgreeToMarketViewTermsAndConditionsRequest
     */
    'version'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Application = {
    Demeter: 'Demeter',
    FarmAdvantage: 'FarmAdvantage',
    MyStoneXMobile: 'MyStoneXMobile',
    CommodityManagement: 'CommodityManagement'
} as const;

export type Application = typeof Application[keyof typeof Application];


/**
 * 
 * @export
 * @enum {string}
 */

export const AuditTrailAction = {
    Added: 'Added',
    Deleted: 'Deleted',
    Modified: 'Modified'
} as const;

export type AuditTrailAction = typeof AuditTrailAction[keyof typeof AuditTrailAction];


/**
 * 
 * @export
 * @interface AuditTrailModel
 */
export interface AuditTrailModel {
    /**
     * 
     * @type {string}
     * @memberof AuditTrailModel
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof AuditTrailModel
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof AuditTrailModel
     */
    'entityType': string;
    /**
     * 
     * @type {number}
     * @memberof AuditTrailModel
     */
    'entity': number;
    /**
     * 
     * @type {AuditTrailAction}
     * @memberof AuditTrailModel
     */
    'userAction': AuditTrailAction;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof AuditTrailModel
     */
    'properties': { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface BudgetModel
 */
export interface BudgetModel {
    /**
     * 
     * @type {number}
     * @memberof BudgetModel
     */
    'budgetId': number;
    /**
     * 
     * @type {string}
     * @memberof BudgetModel
     */
    'quoteDate'?: string | null;
    /**
     * 
     * @type {CommodityModel}
     * @memberof BudgetModel
     */
    'commodity': CommodityModel;
    /**
     * 
     * @type {BudgetPricingStrategyModel}
     * @memberof BudgetModel
     */
    'budgetPricingStrategy': BudgetPricingStrategyModel;
    /**
     * 
     * @type {CounterpartyModel}
     * @memberof BudgetModel
     */
    'counterparty': CounterpartyModel;
    /**
     * 
     * @type {LocationModel}
     * @memberof BudgetModel
     */
    'location': LocationModel;
    /**
     * 
     * @type {Array<LocationModel>}
     * @memberof BudgetModel
     */
    'locationHierarchy': Array<LocationModel>;
    /**
     * 
     * @type {ActualModel}
     * @memberof BudgetModel
     */
    'actual': ActualModel;
    /**
     * 
     * @type {Array<PositionModel>}
     * @memberof BudgetModel
     */
    'positions': Array<PositionModel>;
    /**
     * 
     * @type {BudgetRoundModel}
     * @memberof BudgetModel
     */
    'budgetRoundModel': BudgetRoundModel;
    /**
     * 
     * @type {Array<BudgetPriceModel>}
     * @memberof BudgetModel
     */
    'budgetPrices': Array<BudgetPriceModel>;
    /**
     * 
     * @type {number}
     * @memberof BudgetModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetModel
     */
    'volume': number;
    /**
     * 
     * @type {Unit}
     * @memberof BudgetModel
     */
    'unit': Unit;
    /**
     * 
     * @type {Currency}
     * @memberof BudgetModel
     */
    'currency': Currency;
    /**
     * 
     * @type {number}
     * @memberof BudgetModel
     */
    'marketPrice'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetModel
     */
    'isExpired'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetModel
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {ContractDate}
     * @memberof BudgetModel
     */
    'budgetDate': ContractDate;
    /**
     * 
     * @type {ContractDate}
     * @memberof BudgetModel
     */
    'contractDate': ContractDate;
    /**
     * 
     * @type {number}
     * @memberof BudgetModel
     */
    'fiscalYear': number;
    /**
     * 
     * @type {Array<ComponentMarketPrice>}
     * @memberof BudgetModel
     */
    'componentMarketPrices': Array<ComponentMarketPrice>;
    /**
     * 
     * @type {ContractDate}
     * @memberof BudgetModel
     */
    'effectiveContractDate': ContractDate;
}
/**
 * 
 * @export
 * @interface BudgetPriceModel
 */
export interface BudgetPriceModel {
    /**
     * 
     * @type {number}
     * @memberof BudgetPriceModel
     */
    'budgetPriceId': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetPriceModel
     */
    'budgetId': number;
    /**
     * 
     * @type {ComponentType}
     * @memberof BudgetPriceModel
     */
    'componentType': ComponentType;
    /**
     * 
     * @type {number}
     * @memberof BudgetPriceModel
     */
    'price': number;
    /**
     * 
     * @type {Unit}
     * @memberof BudgetPriceModel
     */
    'unit': Unit;
}
/**
 * 
 * @export
 * @interface BudgetPricingComponentModel
 */
export interface BudgetPricingComponentModel {
    /**
     * 
     * @type {number}
     * @memberof BudgetPricingComponentModel
     */
    'budgetPricingComponentId': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetPricingComponentModel
     */
    'budgetPricingStrategyId': number;
    /**
     * 
     * @type {ComponentType}
     * @memberof BudgetPricingComponentModel
     */
    'componentType': ComponentType;
    /**
     * 
     * @type {MarketPriceProvider}
     * @memberof BudgetPricingComponentModel
     */
    'marketPriceProvider'?: MarketPriceProvider;
    /**
     * 
     * @type {number}
     * @memberof BudgetPricingComponentModel
     */
    'marketViewProductId'?: number | null;
}
/**
 * 
 * @export
 * @interface BudgetPricingStrategyModel
 */
export interface BudgetPricingStrategyModel {
    /**
     * 
     * @type {number}
     * @memberof BudgetPricingStrategyModel
     */
    'budgetPricingStrategyId': number;
    /**
     * 
     * @type {string}
     * @memberof BudgetPricingStrategyModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BudgetPricingStrategyModel
     */
    'commodityId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BudgetPricingStrategyModel
     */
    'locationId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BudgetPricingStrategyModel
     */
    'counterpartyId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetPricingStrategyModel
     */
    'isLastDay': boolean;
    /**
     * 
     * @type {Unit}
     * @memberof BudgetPricingStrategyModel
     */
    'bushelUnit'?: Unit;
    /**
     * 
     * @type {number}
     * @memberof BudgetPricingStrategyModel
     */
    'bushelVolume'?: number | null;
    /**
     * 
     * @type {Unit}
     * @memberof BudgetPricingStrategyModel
     */
    'contractUnit'?: Unit;
    /**
     * 
     * @type {number}
     * @memberof BudgetPricingStrategyModel
     */
    'contractVolume'?: number | null;
    /**
     * 
     * @type {Array<BudgetPricingComponentModel>}
     * @memberof BudgetPricingStrategyModel
     */
    'components': Array<BudgetPricingComponentModel>;
}
/**
 * 
 * @export
 * @interface BudgetRoundModel
 */
export interface BudgetRoundModel {
    /**
     * 
     * @type {number}
     * @memberof BudgetRoundModel
     */
    'budgetRoundId': number;
    /**
     * 
     * @type {string}
     * @memberof BudgetRoundModel
     */
    'budgetRoundName': string;
}
/**
 * 
 * @export
 * @interface BudgetVarianceModel
 */
export interface BudgetVarianceModel {
    /**
     * 
     * @type {Array<VarianceComponent>}
     * @memberof BudgetVarianceModel
     */
    'components': Array<VarianceComponent>;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'allInHedgedPrice': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'allInHedgedValue': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'allInHedgedVolume': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'allInHedgedPercent': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'budgetPrice': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'budgetVolume': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'marketPrice': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'blendedPrice': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'blendedValue': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'budgetValue': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'effectiveBudgetVolume': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'hedgedValue': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'marketValue': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'openValue': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'profitAndLossPrice': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'profitAndLossValue': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'varianceToBudgetPrice': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'varianceToBudgetValue': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'varianceToMarketPrice': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'varianceToMarketValue': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'markToMarketPrice': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'markToMarketValue': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'budgetId': number;
    /**
     * 
     * @type {string}
     * @memberof BudgetVarianceModel
     */
    'quoteDate'?: string | null;
    /**
     * 
     * @type {CommodityModel}
     * @memberof BudgetVarianceModel
     */
    'commodity': CommodityModel;
    /**
     * 
     * @type {BudgetPricingStrategyModel}
     * @memberof BudgetVarianceModel
     */
    'budgetPricingStrategy': BudgetPricingStrategyModel;
    /**
     * 
     * @type {CounterpartyModel}
     * @memberof BudgetVarianceModel
     */
    'counterparty': CounterpartyModel;
    /**
     * 
     * @type {LocationModel}
     * @memberof BudgetVarianceModel
     */
    'location': LocationModel;
    /**
     * 
     * @type {Array<LocationModel>}
     * @memberof BudgetVarianceModel
     */
    'locationHierarchy': Array<LocationModel>;
    /**
     * 
     * @type {ActualModel}
     * @memberof BudgetVarianceModel
     */
    'actual': ActualModel;
    /**
     * 
     * @type {Array<PositionModel>}
     * @memberof BudgetVarianceModel
     */
    'positions': Array<PositionModel>;
    /**
     * 
     * @type {BudgetRoundModel}
     * @memberof BudgetVarianceModel
     */
    'budgetRoundModel': BudgetRoundModel;
    /**
     * 
     * @type {Array<BudgetPriceModel>}
     * @memberof BudgetVarianceModel
     */
    'budgetPrices': Array<BudgetPriceModel>;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'volume': number;
    /**
     * 
     * @type {Unit}
     * @memberof BudgetVarianceModel
     */
    'unit': Unit;
    /**
     * 
     * @type {Currency}
     * @memberof BudgetVarianceModel
     */
    'currency': Currency;
    /**
     * 
     * @type {boolean}
     * @memberof BudgetVarianceModel
     */
    'isExpired'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BudgetVarianceModel
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {ContractDate}
     * @memberof BudgetVarianceModel
     */
    'budgetDate': ContractDate;
    /**
     * 
     * @type {ContractDate}
     * @memberof BudgetVarianceModel
     */
    'contractDate': ContractDate;
    /**
     * 
     * @type {number}
     * @memberof BudgetVarianceModel
     */
    'fiscalYear': number;
    /**
     * 
     * @type {Array<ComponentMarketPrice>}
     * @memberof BudgetVarianceModel
     */
    'componentMarketPrices': Array<ComponentMarketPrice>;
    /**
     * 
     * @type {ContractDate}
     * @memberof BudgetVarianceModel
     */
    'effectiveContractDate': ContractDate;
}
/**
 * 
 * @export
 * @interface CommodityManagementSiteAccessModel
 */
export interface CommodityManagementSiteAccessModel {
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteAccessModel
     */
    'siteGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteAccessModel
     */
    'siteName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityManagementSiteAccessModel
     */
    'isSiteManager'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityManagementSiteAccessModel
     */
    'accessDisabled'?: boolean;
    /**
     * 
     * @type {Array<CommodityManagementUserPermissions>}
     * @memberof CommodityManagementSiteAccessModel
     */
    'permissions'?: Array<CommodityManagementUserPermissions> | null;
}
/**
 * 
 * @export
 * @interface CommodityManagementSiteModel
 */
export interface CommodityManagementSiteModel {
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteModel
     */
    'siteGuid': string;
    /**
     * 
     * @type {number}
     * @memberof CommodityManagementSiteModel
     */
    'oldId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteModel
     */
    'siteName': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteModel
     */
    'customerName': string;
}
/**
 * 
 * @export
 * @interface CommodityManagementSiteUserModel
 */
export interface CommodityManagementSiteUserModel {
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteUserModel
     */
    'commodityManagementUserGuid': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteUserModel
     */
    'stoneXOktaUserGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteUserModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteUserModel
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteUserModel
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityManagementSiteUserModel
     */
    'isAdministrator': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteUserModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteUserModel
     */
    'siteGuid': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityManagementSiteUserModel
     */
    'siteName': string;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityManagementSiteUserModel
     */
    'isSiteManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommodityManagementSiteUserModel
     */
    'accessDisabled': boolean;
    /**
     * 
     * @type {Array<CommodityManagementUserPermissions>}
     * @memberof CommodityManagementSiteUserModel
     */
    'permissions': Array<CommodityManagementUserPermissions>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CommodityManagementUserPermissions = {
    ManagePosition: 'ManagePosition',
    ValidatePosition: 'ValidatePosition',
    ManageCommodity: 'ManageCommodity',
    ManageBudget: 'ManageBudget',
    ManageAccount: 'ManageAccount',
    ManagePortfolio: 'ManagePortfolio',
    ManageCounterparty: 'ManageCounterparty',
    ManageMarketPrice: 'ManageMarketPrice',
    ManageOrganization: 'ManageOrganization',
    ManageNotes: 'ManageNotes'
} as const;

export type CommodityManagementUserPermissions = typeof CommodityManagementUserPermissions[keyof typeof CommodityManagementUserPermissions];


/**
 * 
 * @export
 * @interface CommodityModel
 */
export interface CommodityModel {
    /**
     * 
     * @type {number}
     * @memberof CommodityModel
     */
    'commodityId': number;
    /**
     * 
     * @type {string}
     * @memberof CommodityModel
     */
    'commodityName': string;
    /**
     * 
     * @type {Unit}
     * @memberof CommodityModel
     */
    'displayUnit': Unit;
    /**
     * 
     * @type {number}
     * @memberof CommodityModel
     */
    'displayPrecision': number;
}
/**
 * 
 * @export
 * @interface CommodityScenarioVarianceModel
 */
export interface CommodityScenarioVarianceModel {
    /**
     * 
     * @type {CommodityModel}
     * @memberof CommodityScenarioVarianceModel
     */
    'commodity'?: CommodityModel;
    /**
     * 
     * @type {ContractDate}
     * @memberof CommodityScenarioVarianceModel
     */
    'budgetDate'?: ContractDate;
    /**
     * 
     * @type {Variance}
     * @memberof CommodityScenarioVarianceModel
     */
    'variance'?: Variance;
    /**
     * 
     * @type {Variance}
     * @memberof CommodityScenarioVarianceModel
     */
    'whatIfPositionsVariance'?: Variance;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CommodityType = {
    NaturalGas: 'NaturalGas',
    Electricity: 'Electricity',
    Currencies: 'Currencies',
    Fuel: 'Fuel',
    Dairy: 'Dairy',
    Livestock: 'Livestock',
    Fibers: 'Fibers',
    Metals: 'Metals',
    Coffee: 'Coffee',
    Agriculture: 'Agriculture',
    Stocks: 'Stocks',
    Coal: 'Coal',
    Other: 'Other',
    CropBasis: 'CropBasis',
    CarbonAllowance: 'CarbonAllowance',
    Favorites: 'Favorites'
} as const;

export type CommodityType = typeof CommodityType[keyof typeof CommodityType];


/**
 * 
 * @export
 * @interface ComponentMarketPrice
 */
export interface ComponentMarketPrice {
    /**
     * 
     * @type {ComponentType}
     * @memberof ComponentMarketPrice
     */
    'componentType': ComponentType;
    /**
     * 
     * @type {number}
     * @memberof ComponentMarketPrice
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ComponentMarketPrice
     */
    'volatility'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentMarketPrice
     */
    'delta'?: number | null;
    /**
     * 
     * @type {Unit}
     * @memberof ComponentMarketPrice
     */
    'unit': Unit;
    /**
     * 
     * @type {Currency}
     * @memberof ComponentMarketPrice
     */
    'currency': Currency;
    /**
     * 
     * @type {MarketPriceProvider}
     * @memberof ComponentMarketPrice
     */
    'marketPriceProvider'?: MarketPriceProvider;
    /**
     * 
     * @type {number}
     * @memberof ComponentMarketPrice
     */
    'marketViewProductId'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ComponentType = {
    None: 'None',
    AllIn: 'AllIn',
    Underlying: 'Underlying',
    Basis: 'Basis'
} as const;

export type ComponentType = typeof ComponentType[keyof typeof ComponentType];


/**
 * 
 * @export
 * @interface ContractDate
 */
export interface ContractDate {
    /**
     * 
     * @type {number}
     * @memberof ContractDate
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContractDate
     */
    'month'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractDate
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @interface CounterpartyModel
 */
export interface CounterpartyModel {
    /**
     * 
     * @type {number}
     * @memberof CounterpartyModel
     */
    'counterpartyId': number;
    /**
     * 
     * @type {string}
     * @memberof CounterpartyModel
     */
    'counterpartyName': string;
    /**
     * 
     * @type {number}
     * @memberof CounterpartyModel
     */
    'creditLimit'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CounterpartyModel
     */
    'netting'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CounterpartyModel
     */
    'setoff'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CounterpartyModel
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Currency = {
    Usd: 'USD',
    Cad: 'CAD',
    Eur: 'EUR',
    Aud: 'AUD',
    Fx: 'FX',
    Gbp: 'GBP',
    Brl: 'BRL',
    Nzd: 'NZD',
    Mxn: 'MXN'
} as const;

export type Currency = typeof Currency[keyof typeof Currency];


/**
 * 
 * @export
 * @interface CustomerReportModel
 */
export interface CustomerReportModel {
    /**
     * 
     * @type {number}
     * @memberof CustomerReportModel
     */
    'customerReportId': number;
    /**
     * 
     * @type {ReportType}
     * @memberof CustomerReportModel
     */
    'reportType': ReportType;
    /**
     * 
     * @type {string}
     * @memberof CustomerReportModel
     */
    'reportName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReportModel
     */
    'configuration': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReportModel
     */
    'userOverrides'?: string | null;
}
/**
 * 
 * @export
 * @interface DateRangeFilter
 */
export interface DateRangeFilter {
    /**
     * 
     * @type {string}
     * @memberof DateRangeFilter
     */
    'start'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateRangeFilter
     */
    'end'?: string | null;
}
/**
 * 
 * @export
 * @interface DealTicketModel
 */
export interface DealTicketModel {
    /**
     * 
     * @type {number}
     * @memberof DealTicketModel
     */
    'dealTicketId': number;
    /**
     * 
     * @type {string}
     * @memberof DealTicketModel
     */
    'tradeDate': string;
    /**
     * 
     * @type {string}
     * @memberof DealTicketModel
     */
    'dealTicketNumber'?: string | null;
    /**
     * 
     * @type {CommodityModel}
     * @memberof DealTicketModel
     */
    'commodity': CommodityModel;
    /**
     * 
     * @type {CounterpartyModel}
     * @memberof DealTicketModel
     */
    'counterparty': CounterpartyModel;
    /**
     * 
     * @type {PortfolioModel}
     * @memberof DealTicketModel
     */
    'portfolio': PortfolioModel;
    /**
     * 
     * @type {Array<PositionModel>}
     * @memberof DealTicketModel
     */
    'positions': Array<PositionModel>;
}
/**
 * 
 * @export
 * @interface DecisiveMarketPriceModel
 */
export interface DecisiveMarketPriceModel {
    /**
     * 
     * @type {string}
     * @memberof DecisiveMarketPriceModel
     */
    'quoteDate': string;
    /**
     * 
     * @type {string}
     * @memberof DecisiveMarketPriceModel
     */
    'productName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DecisiveMarketPriceModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {number}
     * @memberof DecisiveMarketPriceModel
     */
    'contractYear': number;
    /**
     * 
     * @type {string}
     * @memberof DecisiveMarketPriceModel
     */
    'expiration'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DecisiveMarketPriceModel
     */
    'closePrice': number;
    /**
     * 
     * @type {number}
     * @memberof DecisiveMarketPriceModel
     */
    'volatility'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DecisiveMarketPriceModel
     */
    'priceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DecisiveMarketPriceModel
     */
    'optionType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DecisiveMarketPriceModel
     */
    'strike'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DecisiveMarketPriceModel
     */
    'delta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DecisiveMarketPriceModel
     */
    'country'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ExpiryStatus = {
    All: 'All',
    Expired: 'Expired',
    Open: 'Open'
} as const;

export type ExpiryStatus = typeof ExpiryStatus[keyof typeof ExpiryStatus];


/**
 * 
 * @export
 * @interface FileResponse
 */
export interface FileResponse {
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'fileContents'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'contentType'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAuditTrailResponse
 */
export interface GetAuditTrailResponse {
    /**
     * 
     * @type {Array<AuditTrailModel>}
     * @memberof GetAuditTrailResponse
     */
    'rows'?: Array<AuditTrailModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetAuditTrailResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAuditTrailResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetAuditTrailResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetAuditTrailResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetAuditTrailResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCustomerDataResponse
 */
export interface GetCustomerDataResponse {
    /**
     * 
     * @type {Array<CommodityModel>}
     * @memberof GetCustomerDataResponse
     */
    'commodities'?: Array<CommodityModel> | null;
    /**
     * 
     * @type {Array<TierModel>}
     * @memberof GetCustomerDataResponse
     */
    'tiers'?: Array<TierModel> | null;
    /**
     * 
     * @type {Array<LocationModel>}
     * @memberof GetCustomerDataResponse
     */
    'locations'?: Array<LocationModel> | null;
    /**
     * 
     * @type {Array<LocationModel>}
     * @memberof GetCustomerDataResponse
     */
    'budgetingLocations'?: Array<LocationModel> | null;
    /**
     * 
     * @type {Array<CounterpartyModel>}
     * @memberof GetCustomerDataResponse
     */
    'counterparties'?: Array<CounterpartyModel> | null;
    /**
     * 
     * @type {Array<PortfolioModel>}
     * @memberof GetCustomerDataResponse
     */
    'portfolios'?: Array<PortfolioModel> | null;
    /**
     * 
     * @type {Array<Unit>}
     * @memberof GetCustomerDataResponse
     */
    'units'?: Array<Unit> | null;
    /**
     * 
     * @type {Array<Currency>}
     * @memberof GetCustomerDataResponse
     */
    'currencies'?: Array<Currency> | null;
    /**
     * 
     * @type {Array<PositionType>}
     * @memberof GetCustomerDataResponse
     */
    'positionTypes'?: Array<PositionType> | null;
    /**
     * 
     * @type {Array<OptionType>}
     * @memberof GetCustomerDataResponse
     */
    'optionTypes'?: Array<OptionType> | null;
    /**
     * 
     * @type {Array<OptionStyle>}
     * @memberof GetCustomerDataResponse
     */
    'optionStyles'?: Array<OptionStyle> | null;
}
/**
 * 
 * @export
 * @interface GetCustomerReportsResponse
 */
export interface GetCustomerReportsResponse {
    /**
     * 
     * @type {Array<CustomerReportModel>}
     * @memberof GetCustomerReportsResponse
     */
    'rows'?: Array<CustomerReportModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCustomerReportsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCustomerReportsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetCustomerReportsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetCustomerReportsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCustomerReportsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetCustomerSettingsResponse
 */
export interface GetCustomerSettingsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetCustomerSettingsResponse
     */
    'fiscalMonthOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCustomerSettingsResponse
     */
    'defaultActiveYear'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetCustomerSettingsResponse
     */
    'budgetsByLocation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCustomerSettingsResponse
     */
    'budgetsByCounterparty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCustomerSettingsResponse
     */
    'positionsByLocation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCustomerSettingsResponse
     */
    'positionsByCounterparty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCustomerSettingsResponse
     */
    'usesBudgetRounds'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCustomerSettingsResponse
     */
    'seperatePricingStrategiesForPositions'?: boolean;
    /**
     * 
     * @type {ContractDate}
     * @memberof GetCustomerSettingsResponse
     */
    'defaultActiveMonthStart'?: ContractDate;
    /**
     * 
     * @type {ContractDate}
     * @memberof GetCustomerSettingsResponse
     */
    'defaultActiveMonthEnd'?: ContractDate;
}
/**
 * 
 * @export
 * @interface GetDealTicketResponse
 */
export interface GetDealTicketResponse {
    /**
     * 
     * @type {DealTicketModel}
     * @memberof GetDealTicketResponse
     */
    'dealTicket'?: DealTicketModel;
}
/**
 * 
 * @export
 * @interface GetMarkToMarketReportResponse
 */
export interface GetMarkToMarketReportResponse {
    /**
     * 
     * @type {Array<PositionModel>}
     * @memberof GetMarkToMarketReportResponse
     */
    'rows'?: Array<PositionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetMarkToMarketReportResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMarkToMarketReportResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetMarkToMarketReportResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetMarkToMarketReportResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetMarkToMarketReportResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetMarketViewLastQuoteDateResponse
 */
export interface GetMarketViewLastQuoteDateResponse {
    /**
     * 
     * @type {number}
     * @memberof GetMarketViewLastQuoteDateResponse
     */
    'symbolId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMarketViewLastQuoteDateResponse
     */
    'quoteDate'?: string;
}
/**
 * 
 * @export
 * @interface GetMarketViewUserAuthorizationResponse
 */
export interface GetMarketViewUserAuthorizationResponse {
    /**
     * 
     * @type {MarketViewUserModel}
     * @memberof GetMarketViewUserAuthorizationResponse
     */
    'user'?: MarketViewUserModel;
}
/**
 * 
 * @export
 * @interface GetMarketViewUserResponse
 */
export interface GetMarketViewUserResponse {
    /**
     * 
     * @type {MarketViewUserModel}
     * @memberof GetMarketViewUserResponse
     */
    'user'?: MarketViewUserModel;
}
/**
 * 
 * @export
 * @interface GetScenarioReportResponse
 */
export interface GetScenarioReportResponse {
    /**
     * 
     * @type {Array<ScenarioVarianceModel>}
     * @memberof GetScenarioReportResponse
     */
    'rows'?: Array<ScenarioVarianceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetScenarioReportResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetScenarioReportResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetScenarioReportResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetScenarioReportResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetScenarioReportResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetTermsAndConditionsResponse
 */
export interface GetTermsAndConditionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetTermsAndConditionsResponse
     */
    'agreed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTermsAndConditionsResponse
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetTermsAndConditionsResponse
     */
    'version'?: number | null;
}
/**
 * 
 * @export
 * @interface GetTimeTriggerReportResponse
 */
export interface GetTimeTriggerReportResponse {
    /**
     * 
     * @type {Array<TimeTriggerReportModel>}
     * @memberof GetTimeTriggerReportResponse
     */
    'rows'?: Array<TimeTriggerReportModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetTimeTriggerReportResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTimeTriggerReportResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetTimeTriggerReportResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetTimeTriggerReportResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetTimeTriggerReportResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface GetVarianceReportResponse
 */
export interface GetVarianceReportResponse {
    /**
     * 
     * @type {Array<BudgetVarianceModel>}
     * @memberof GetVarianceReportResponse
     */
    'rows'?: Array<BudgetVarianceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetVarianceReportResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVarianceReportResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetVarianceReportResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof GetVarianceReportResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetVarianceReportResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListBudgetPricingStrategiesResponse
 */
export interface ListBudgetPricingStrategiesResponse {
    /**
     * 
     * @type {Array<BudgetPricingStrategyModel>}
     * @memberof ListBudgetPricingStrategiesResponse
     */
    'rows'?: Array<BudgetPricingStrategyModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetPricingStrategiesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListBudgetPricingStrategiesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListBudgetPricingStrategiesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetPricingStrategiesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetPricingStrategiesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListBudgetYearsResponse
 */
export interface ListBudgetYearsResponse {
    /**
     * 
     * @type {Array<YearDataModel>}
     * @memberof ListBudgetYearsResponse
     */
    'rows'?: Array<YearDataModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetYearsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListBudgetYearsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListBudgetYearsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetYearsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetYearsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListBudgetsResponse
 */
export interface ListBudgetsResponse {
    /**
     * 
     * @type {Array<BudgetModel>}
     * @memberof ListBudgetsResponse
     */
    'rows'?: Array<BudgetModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListBudgetsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListBudgetsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListBudgetsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityManagementSiteUsersResponse
 */
export interface ListCommodityManagementSiteUsersResponse {
    /**
     * 
     * @type {Array<CommodityManagementSiteUserModel>}
     * @memberof ListCommodityManagementSiteUsersResponse
     */
    'rows'?: Array<CommodityManagementSiteUserModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityManagementSiteUsersResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityManagementSiteUsersResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityManagementSiteUsersResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityManagementSiteUsersResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityManagementSiteUsersResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommodityManagementSitesResponse
 */
export interface ListCommodityManagementSitesResponse {
    /**
     * 
     * @type {Array<CommodityManagementSiteModel>}
     * @memberof ListCommodityManagementSitesResponse
     */
    'rows'?: Array<CommodityManagementSiteModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityManagementSitesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommodityManagementSitesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommodityManagementSitesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityManagementSitesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommodityManagementSitesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketViewCurrencySpotRatesResponse
 */
export interface ListMarketViewCurrencySpotRatesResponse {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: MarketViewSpotPriceModel; }; }}
     * @memberof ListMarketViewCurrencySpotRatesResponse
     */
    'currencyPairToSpotRates'?: { [key: string]: { [key: string]: MarketViewSpotPriceModel; }; } | null;
}
/**
 * 
 * @export
 * @interface ListMarketViewExpirationDatesRequest
 */
export interface ListMarketViewExpirationDatesRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ListMarketViewExpirationDatesRequest
     */
    'productIds': Array<number>;
}
/**
 * 
 * @export
 * @interface ListMarketViewExpirationDatesResponse
 */
export interface ListMarketViewExpirationDatesResponse {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof ListMarketViewExpirationDatesResponse
     */
    'productIdToContractDateToExpiration'?: { [key: string]: { [key: string]: string; }; } | null;
}
/**
 * 
 * @export
 * @interface ListMarketViewLastQuoteDateResponse
 */
export interface ListMarketViewLastQuoteDateResponse {
    /**
     * 
     * @type {Array<MarketViewLastQuoteDateModel>}
     * @memberof ListMarketViewLastQuoteDateResponse
     */
    'rows'?: Array<MarketViewLastQuoteDateModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewLastQuoteDateResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketViewLastQuoteDateResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketViewLastQuoteDateResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewLastQuoteDateResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewLastQuoteDateResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketViewPricesResponse
 */
export interface ListMarketViewPricesResponse {
    /**
     * 
     * @type {Array<MarketViewPriceModel>}
     * @memberof ListMarketViewPricesResponse
     */
    'rows'?: Array<MarketViewPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewPricesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketViewPricesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketViewPricesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewPricesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewPricesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketViewProductTypesResponse
 */
export interface ListMarketViewProductTypesResponse {
    /**
     * 
     * @type {Array<MarketViewProductTypeModel>}
     * @memberof ListMarketViewProductTypesResponse
     */
    'rows'?: Array<MarketViewProductTypeModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewProductTypesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketViewProductTypesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketViewProductTypesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewProductTypesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewProductTypesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketViewProductsResponse
 */
export interface ListMarketViewProductsResponse {
    /**
     * 
     * @type {Array<MarketViewProductModel>}
     * @memberof ListMarketViewProductsResponse
     */
    'rows'?: Array<MarketViewProductModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewProductsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketViewProductsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketViewProductsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewProductsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewProductsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketViewSavedQueryResponse
 */
export interface ListMarketViewSavedQueryResponse {
    /**
     * 
     * @type {Array<MarkItViewSavedQueryModel>}
     * @memberof ListMarketViewSavedQueryResponse
     */
    'rows'?: Array<MarkItViewSavedQueryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewSavedQueryResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketViewSavedQueryResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketViewSavedQueryResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewSavedQueryResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewSavedQueryResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketViewUsersResponse
 */
export interface ListMarketViewUsersResponse {
    /**
     * 
     * @type {Array<MarketViewUserModel>}
     * @memberof ListMarketViewUsersResponse
     */
    'rows'?: Array<MarketViewUserModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewUsersResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketViewUsersResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketViewUsersResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewUsersResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketViewUsersResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListNotesResponse
 */
export interface ListNotesResponse {
    /**
     * 
     * @type {Array<NoteModel>}
     * @memberof ListNotesResponse
     */
    'rows'?: Array<NoteModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListNotesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListNotesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListNotesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListNotesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListNotesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListPositionYearsResponse
 */
export interface ListPositionYearsResponse {
    /**
     * 
     * @type {Array<YearDataModel>}
     * @memberof ListPositionYearsResponse
     */
    'rows'?: Array<YearDataModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListPositionYearsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListPositionYearsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListPositionYearsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListPositionYearsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListPositionYearsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListPositionsResponse
 */
export interface ListPositionsResponse {
    /**
     * 
     * @type {Array<PositionModel>}
     * @memberof ListPositionsResponse
     */
    'rows'?: Array<PositionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListPositionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListPositionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListPositionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListPositionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListPositionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListScenarioMarketPricesFromUploadResponse
 */
export interface ListScenarioMarketPricesFromUploadResponse {
    /**
     * 
     * @type {string}
     * @memberof ListScenarioMarketPricesFromUploadResponse
     */
    'readException'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListScenarioMarketPricesFromUploadResponse
     */
    'warnings'?: Array<string> | null;
    /**
     * 
     * @type {Array<ScenarioMarketPriceExcelTemplateRow>}
     * @memberof ListScenarioMarketPricesFromUploadResponse
     */
    'rows'?: Array<ScenarioMarketPriceExcelTemplateRow> | null;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioMarketPricesFromUploadResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListScenarioMarketPricesFromUploadResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListScenarioMarketPricesFromUploadResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioMarketPricesFromUploadResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioMarketPricesFromUploadResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListWhatIfStrategyResponse
 */
export interface ListWhatIfStrategyResponse {
    /**
     * 
     * @type {Array<WhatIfStrategyModel>}
     * @memberof ListWhatIfStrategyResponse
     */
    'rows'?: Array<WhatIfStrategyModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListWhatIfStrategyResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListWhatIfStrategyResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListWhatIfStrategyResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListWhatIfStrategyResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListWhatIfStrategyResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface LocationModel
 */
export interface LocationModel {
    /**
     * 
     * @type {number}
     * @memberof LocationModel
     */
    'locationId': number;
    /**
     * 
     * @type {string}
     * @memberof LocationModel
     */
    'locationName': string;
    /**
     * 
     * @type {number}
     * @memberof LocationModel
     */
    'tierId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LocationModel
     */
    'tierName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LocationModel
     */
    'tierRank'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LocationModel
     */
    'parentLocationId'?: number | null;
}
/**
 * 
 * @export
 * @interface MarkItViewQuerySavedParameters
 */
export interface MarkItViewQuerySavedParameters {
    /**
     * 
     * @type {number}
     * @memberof MarkItViewQuerySavedParameters
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MarkItViewQuerySavedParameters
     */
    'daysToLookback'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarkItViewQuerySavedParameters
     */
    'priceQueryType'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MarkItViewQuerySavedParameters
     */
    'years': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MarkItViewQuerySavedParameters
     */
    'months': Array<number>;
    /**
     * 
     * @type {CommodityType}
     * @memberof MarkItViewQuerySavedParameters
     */
    'commodityType'?: CommodityType;
    /**
     * 
     * @type {number}
     * @memberof MarkItViewQuerySavedParameters
     */
    'productId'?: number | null;
    /**
     * 
     * @type {ContractDate}
     * @memberof MarkItViewQuerySavedParameters
     */
    'contractStartDate': ContractDate;
    /**
     * 
     * @type {ContractDate}
     * @memberof MarkItViewQuerySavedParameters
     */
    'contractEndDate': ContractDate;
    /**
     * 
     * @type {number}
     * @memberof MarkItViewQuerySavedParameters
     */
    'monthsAhead'?: number | null;
}
/**
 * 
 * @export
 * @interface MarkItViewSavedQueryModel
 */
export interface MarkItViewSavedQueryModel {
    /**
     * 
     * @type {number}
     * @memberof MarkItViewSavedQueryModel
     */
    'savedQueryId': number;
    /**
     * 
     * @type {string}
     * @memberof MarkItViewSavedQueryModel
     */
    'name': string;
    /**
     * 
     * @type {Array<MarkItViewQuerySavedParameters>}
     * @memberof MarkItViewSavedQueryModel
     */
    'queriesParameters': Array<MarkItViewQuerySavedParameters>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketPriceProvider = {
    None: 'None',
    MarketView: 'MarketView',
    CustomFlat: 'CustomFlat',
    CustomForward: 'CustomForward'
} as const;

export type MarketPriceProvider = typeof MarketPriceProvider[keyof typeof MarketPriceProvider];


/**
 * 
 * @export
 * @enum {string}
 */

export const MarketViewApplication = {
    MarketView: 'MarketView',
    Benchmark: 'Benchmark',
    Deciles: 'Deciles'
} as const;

export type MarketViewApplication = typeof MarketViewApplication[keyof typeof MarketViewApplication];


/**
 * 
 * @export
 * @interface MarketViewContractPriceModel
 */
export interface MarketViewContractPriceModel {
    /**
     * 
     * @type {ContractDate}
     * @memberof MarketViewContractPriceModel
     */
    'contractDate': ContractDate;
    /**
     * 
     * @type {number}
     * @memberof MarketViewContractPriceModel
     */
    'price'?: number | null;
}
/**
 * 
 * @export
 * @interface MarketViewLastQuoteDateModel
 */
export interface MarketViewLastQuoteDateModel {
    /**
     * 
     * @type {number}
     * @memberof MarketViewLastQuoteDateModel
     */
    'symbolId': number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewLastQuoteDateModel
     */
    'quoteDate': string;
}
/**
 * 
 * @export
 * @interface MarketViewPriceModel
 */
export interface MarketViewPriceModel {
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'priceId': number;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'productId': number;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'parentProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'symbolId': number;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'contractYear': number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewPriceModel
     */
    'quoteDate': string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewPriceModel
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'closePrice': number;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'highPrice': number;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'lowPrice': number;
    /**
     * 
     * @type {OptionStyle}
     * @memberof MarketViewPriceModel
     */
    'optionStyle'?: OptionStyle;
    /**
     * 
     * @type {OptionType}
     * @memberof MarketViewPriceModel
     */
    'optionType'?: OptionType;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'strike'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'volatility'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'delta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'gamma'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'theta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'vega'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewPriceModel
     */
    'rho'?: number | null;
    /**
     * 
     * @type {Unit}
     * @memberof MarketViewPriceModel
     */
    'unit': Unit;
    /**
     * 
     * @type {Currency}
     * @memberof MarketViewPriceModel
     */
    'currency': Currency;
    /**
     * 
     * @type {boolean}
     * @memberof MarketViewPriceModel
     */
    'isImpliedOption': boolean;
    /**
     * 
     * @type {ContractDate}
     * @memberof MarketViewPriceModel
     */
    'contractDate': ContractDate;
}
/**
 * 
 * @export
 * @interface MarketViewProductModel
 */
export interface MarketViewProductModel {
    /**
     * 
     * @type {number}
     * @memberof MarketViewProductModel
     */
    'productId': number;
    /**
     * 
     * @type {number}
     * @memberof MarketViewProductModel
     */
    'parentProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewProductModel
     */
    'symbolId': number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewProductModel
     */
    'name': string;
    /**
     * 
     * @type {CommodityType}
     * @memberof MarketViewProductModel
     */
    'commodityType': CommodityType;
    /**
     * 
     * @type {PriceType}
     * @memberof MarketViewProductModel
     */
    'priceType': PriceType;
    /**
     * 
     * @type {OptionStyle}
     * @memberof MarketViewProductModel
     */
    'optionStyle'?: OptionStyle;
    /**
     * 
     * @type {Unit}
     * @memberof MarketViewProductModel
     */
    'unit': Unit;
    /**
     * 
     * @type {Currency}
     * @memberof MarketViewProductModel
     */
    'currency': Currency;
    /**
     * 
     * @type {boolean}
     * @memberof MarketViewProductModel
     */
    'isFavorite': boolean;
}
/**
 * 
 * @export
 * @interface MarketViewProductTradingMonthsResponse
 */
export interface MarketViewProductTradingMonthsResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof MarketViewProductTradingMonthsResponse
     */
    'years'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MarketViewProductTradingMonthsResponse
     */
    'months'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface MarketViewProductTypeModel
 */
export interface MarketViewProductTypeModel {
    /**
     * 
     * @type {CommodityType}
     * @memberof MarketViewProductTypeModel
     */
    'commodityType': CommodityType;
}
/**
 * 
 * @export
 * @interface MarketViewQueryCalendarStripAverageResponse
 */
export interface MarketViewQueryCalendarStripAverageResponse {
    /**
     * 
     * @type {Array<MarketViewQuoteDateYearsModel>}
     * @memberof MarketViewQueryCalendarStripAverageResponse
     */
    'rows'?: Array<MarketViewQuoteDateYearsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryCalendarStripAverageResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewQueryCalendarStripAverageResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof MarketViewQueryCalendarStripAverageResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryCalendarStripAverageResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryCalendarStripAverageResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface MarketViewQueryForwardCurveResponse
 */
export interface MarketViewQueryForwardCurveResponse {
    /**
     * 
     * @type {string}
     * @memberof MarketViewQueryForwardCurveResponse
     */
    'quoteDate'?: string;
    /**
     * 
     * @type {Array<MarketViewContractPriceModel>}
     * @memberof MarketViewQueryForwardCurveResponse
     */
    'rows'?: Array<MarketViewContractPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryForwardCurveResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewQueryForwardCurveResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof MarketViewQueryForwardCurveResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryForwardCurveResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryForwardCurveResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface MarketViewQueryPriceResponse
 */
export interface MarketViewQueryPriceResponse {
    /**
     * 
     * @type {Array<MarketViewQuoteDateContractsModel>}
     * @memberof MarketViewQueryPriceResponse
     */
    'rows'?: Array<MarketViewQuoteDateContractsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryPriceResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewQueryPriceResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof MarketViewQueryPriceResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryPriceResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryPriceResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface MarketViewQueryRollingMonthAheadResponse
 */
export interface MarketViewQueryRollingMonthAheadResponse {
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryRollingMonthAheadResponse
     */
    'monthsAhead'?: number;
    /**
     * 
     * @type {Array<MarketViewQuoteDatePriceModel>}
     * @memberof MarketViewQueryRollingMonthAheadResponse
     */
    'rows'?: Array<MarketViewQuoteDatePriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryRollingMonthAheadResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewQueryRollingMonthAheadResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof MarketViewQueryRollingMonthAheadResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryRollingMonthAheadResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryRollingMonthAheadResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface MarketViewQuerySettlementsResponse
 */
export interface MarketViewQuerySettlementsResponse {
    /**
     * 
     * @type {Array<MarketViewSettlementPriceModel>}
     * @memberof MarketViewQuerySettlementsResponse
     */
    'rows'?: Array<MarketViewSettlementPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQuerySettlementsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewQuerySettlementsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof MarketViewQuerySettlementsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQuerySettlementsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQuerySettlementsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface MarketViewQueryStripAverageResponse
 */
export interface MarketViewQueryStripAverageResponse {
    /**
     * 
     * @type {DateRangeFilter}
     * @memberof MarketViewQueryStripAverageResponse
     */
    'quoteDateRange'?: DateRangeFilter;
    /**
     * 
     * @type {Array<MarketViewQuoteDatePriceModel>}
     * @memberof MarketViewQueryStripAverageResponse
     */
    'rows'?: Array<MarketViewQuoteDatePriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryStripAverageResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewQueryStripAverageResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof MarketViewQueryStripAverageResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryStripAverageResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQueryStripAverageResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface MarketViewQuoteDateContractsModel
 */
export interface MarketViewQuoteDateContractsModel {
    /**
     * 
     * @type {string}
     * @memberof MarketViewQuoteDateContractsModel
     */
    'quoteDate': string;
    /**
     * 
     * @type {Array<MarketViewContractPriceModel>}
     * @memberof MarketViewQuoteDateContractsModel
     */
    'contractPrices': Array<MarketViewContractPriceModel>;
}
/**
 * 
 * @export
 * @interface MarketViewQuoteDatePriceModel
 */
export interface MarketViewQuoteDatePriceModel {
    /**
     * 
     * @type {string}
     * @memberof MarketViewQuoteDatePriceModel
     */
    'quoteDate': string;
    /**
     * 
     * @type {number}
     * @memberof MarketViewQuoteDatePriceModel
     */
    'price': number;
}
/**
 * 
 * @export
 * @interface MarketViewQuoteDateYearsModel
 */
export interface MarketViewQuoteDateYearsModel {
    /**
     * 
     * @type {string}
     * @memberof MarketViewQuoteDateYearsModel
     */
    'quoteDate': string;
    /**
     * 
     * @type {Array<MarketViewYearPriceModel>}
     * @memberof MarketViewQuoteDateYearsModel
     */
    'yearPrices': Array<MarketViewYearPriceModel>;
}
/**
 * 
 * @export
 * @interface MarketViewSettlementPriceModel
 */
export interface MarketViewSettlementPriceModel {
    /**
     * 
     * @type {string}
     * @memberof MarketViewSettlementPriceModel
     */
    'quoteDate': string;
    /**
     * 
     * @type {ContractDate}
     * @memberof MarketViewSettlementPriceModel
     */
    'contractDate': ContractDate;
    /**
     * 
     * @type {number}
     * @memberof MarketViewSettlementPriceModel
     */
    'price'?: number | null;
}
/**
 * 
 * @export
 * @interface MarketViewSpotPriceModel
 */
export interface MarketViewSpotPriceModel {
    /**
     * 
     * @type {Currency}
     * @memberof MarketViewSpotPriceModel
     */
    'currencyFrom': Currency;
    /**
     * 
     * @type {Currency}
     * @memberof MarketViewSpotPriceModel
     */
    'currencyTo': Currency;
    /**
     * 
     * @type {ContractDate}
     * @memberof MarketViewSpotPriceModel
     */
    'contractDate': ContractDate;
    /**
     * 
     * @type {number}
     * @memberof MarketViewSpotPriceModel
     */
    'conversionRate': number;
}
/**
 * 
 * @export
 * @interface MarketViewUserModel
 */
export interface MarketViewUserModel {
    /**
     * 
     * @type {Array<CommodityManagementSiteAccessModel>}
     * @memberof MarketViewUserModel
     */
    'commodityManagementUserSiteAccesses'?: Array<CommodityManagementSiteAccessModel> | null;
    /**
     * 
     * @type {string}
     * @memberof MarketViewUserModel
     */
    'marketViewUserGuid': string;
    /**
     * 
     * @type {string}
     * @memberof MarketViewUserModel
     */
    'stoneXUserGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketViewUserModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketViewUserModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketViewUserModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MarketViewUserModel
     */
    'isAdministrator': boolean;
    /**
     * 
     * @type {Array<MarketViewApplication>}
     * @memberof MarketViewUserModel
     */
    'marketViewApplications': Array<MarketViewApplication>;
    /**
     * 
     * @type {GetTermsAndConditionsResponse}
     * @memberof MarketViewUserModel
     */
    'getTermsAndConditionsResponse': GetTermsAndConditionsResponse;
    /**
     * 
     * @type {string}
     * @memberof MarketViewUserModel
     */
    'displayName': string;
}
/**
 * 
 * @export
 * @interface MarketViewVolumeModel
 */
export interface MarketViewVolumeModel {
    /**
     * 
     * @type {number}
     * @memberof MarketViewVolumeModel
     */
    'userVolumeId': number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewVolumeModel
     */
    'name': string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof MarketViewVolumeModel
     */
    'volumes': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface MarketViewVolumesResponse
 */
export interface MarketViewVolumesResponse {
    /**
     * 
     * @type {Array<MarketViewVolumeModel>}
     * @memberof MarketViewVolumesResponse
     */
    'rows'?: Array<MarketViewVolumeModel> | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewVolumesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketViewVolumesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof MarketViewVolumesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof MarketViewVolumesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketViewVolumesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface MarketViewYearPriceModel
 */
export interface MarketViewYearPriceModel {
    /**
     * 
     * @type {number}
     * @memberof MarketViewYearPriceModel
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketViewYearPriceModel
     */
    'price'?: number | null;
}
/**
 * 
 * @export
 * @interface NoteModel
 */
export interface NoteModel {
    /**
     * 
     * @type {number}
     * @memberof NoteModel
     */
    'noteId': number;
    /**
     * 
     * @type {string}
     * @memberof NoteModel
     */
    'marketViewUsername': string;
    /**
     * 
     * @type {string}
     * @memberof NoteModel
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof NoteModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof NoteModel
     */
    'createdAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteModel
     */
    'modifiedAt'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OptionStyle = {
    American: 'American',
    European: 'European',
    Asian: 'Asian'
} as const;

export type OptionStyle = typeof OptionStyle[keyof typeof OptionStyle];


/**
 * 
 * @export
 * @enum {string}
 */

export const OptionType = {
    Call: 'Call',
    Put: 'Put'
} as const;

export type OptionType = typeof OptionType[keyof typeof OptionType];


/**
 * 
 * @export
 * @interface PortfolioModel
 */
export interface PortfolioModel {
    /**
     * 
     * @type {number}
     * @memberof PortfolioModel
     */
    'portfolioId': number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioModel
     */
    'portfolioName': string;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioModel
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface PositionModel
 */
export interface PositionModel {
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'positionId': number;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'dealTicketId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PositionModel
     */
    'tradeDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionModel
     */
    'dealTicketNumber'?: string | null;
    /**
     * 
     * @type {PositionType}
     * @memberof PositionModel
     */
    'positionType': PositionType;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'volume': number;
    /**
     * 
     * @type {Side}
     * @memberof PositionModel
     */
    'side': Side;
    /**
     * 
     * @type {Unit}
     * @memberof PositionModel
     */
    'unit': Unit;
    /**
     * 
     * @type {Currency}
     * @memberof PositionModel
     */
    'currency': Currency;
    /**
     * 
     * @type {boolean}
     * @memberof PositionModel
     */
    'isValidated': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PositionModel
     */
    'isLastDay': boolean;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'optionStrike'?: number | null;
    /**
     * 
     * @type {OptionType}
     * @memberof PositionModel
     */
    'optionType'?: OptionType;
    /**
     * 
     * @type {OptionStyle}
     * @memberof PositionModel
     */
    'optionStyle'?: OptionStyle;
    /**
     * 
     * @type {string}
     * @memberof PositionModel
     */
    'quoteDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionModel
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'marketPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'markToMarketPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'markToMarketValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'scenarioMarketPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'scenarioMarkToMarketPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'scenarioMarkToMarketValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'delta'?: number | null;
    /**
     * 
     * @type {CommodityModel}
     * @memberof PositionModel
     */
    'commodity': CommodityModel;
    /**
     * 
     * @type {LocationModel}
     * @memberof PositionModel
     */
    'location': LocationModel;
    /**
     * 
     * @type {Array<LocationModel>}
     * @memberof PositionModel
     */
    'locationHierarchy': Array<LocationModel>;
    /**
     * 
     * @type {CounterpartyModel}
     * @memberof PositionModel
     */
    'counterparty': CounterpartyModel;
    /**
     * 
     * @type {PortfolioModel}
     * @memberof PositionModel
     */
    'portfolio': PortfolioModel;
    /**
     * 
     * @type {boolean}
     * @memberof PositionModel
     */
    'isExpired'?: boolean | null;
    /**
     * 
     * @type {Array<ComponentMarketPrice>}
     * @memberof PositionModel
     */
    'componentMarketPrices': Array<ComponentMarketPrice>;
    /**
     * 
     * @type {PositionPricingStrategyModel}
     * @memberof PositionModel
     */
    'positionPricingStrategy': PositionPricingStrategyModel;
    /**
     * 
     * @type {string}
     * @memberof PositionModel
     */
    'positionTypeOrOption': string;
    /**
     * 
     * @type {ContractDate}
     * @memberof PositionModel
     */
    'budgetDate': ContractDate;
    /**
     * 
     * @type {ContractDate}
     * @memberof PositionModel
     */
    'contractDate': ContractDate;
    /**
     * 
     * @type {ContractDate}
     * @memberof PositionModel
     */
    'effectiveContractDate': ContractDate;
    /**
     * 
     * @type {number}
     * @memberof PositionModel
     */
    'effectiveVolume': number;
}
/**
 * 
 * @export
 * @interface PositionPricingComponentModel
 */
export interface PositionPricingComponentModel {
    /**
     * 
     * @type {number}
     * @memberof PositionPricingComponentModel
     */
    'positionPricingComponentId': number;
    /**
     * 
     * @type {number}
     * @memberof PositionPricingComponentModel
     */
    'positionPricingStrategyId': number;
    /**
     * 
     * @type {ComponentType}
     * @memberof PositionPricingComponentModel
     */
    'componentType': ComponentType;
    /**
     * 
     * @type {MarketPriceProvider}
     * @memberof PositionPricingComponentModel
     */
    'marketPriceProvider'?: MarketPriceProvider;
    /**
     * 
     * @type {number}
     * @memberof PositionPricingComponentModel
     */
    'marketViewProductId'?: number | null;
}
/**
 * 
 * @export
 * @interface PositionPricingStrategyModel
 */
export interface PositionPricingStrategyModel {
    /**
     * 
     * @type {number}
     * @memberof PositionPricingStrategyModel
     */
    'positionPricingStrategyId': number;
    /**
     * 
     * @type {number}
     * @memberof PositionPricingStrategyModel
     */
    'budgetPricingStrategyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PositionPricingStrategyModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PositionPricingStrategyModel
     */
    'commodityId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionPricingStrategyModel
     */
    'locationId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionPricingStrategyModel
     */
    'counterpartyId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PositionPricingStrategyModel
     */
    'isLastDay': boolean;
    /**
     * 
     * @type {Unit}
     * @memberof PositionPricingStrategyModel
     */
    'bushelUnit'?: Unit;
    /**
     * 
     * @type {number}
     * @memberof PositionPricingStrategyModel
     */
    'bushelVolume'?: number | null;
    /**
     * 
     * @type {Unit}
     * @memberof PositionPricingStrategyModel
     */
    'contractUnit'?: Unit;
    /**
     * 
     * @type {number}
     * @memberof PositionPricingStrategyModel
     */
    'contractVolume'?: number | null;
    /**
     * 
     * @type {Array<PositionPricingComponentModel>}
     * @memberof PositionPricingStrategyModel
     */
    'components': Array<PositionPricingComponentModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PositionType = {
    AllIn: 'AllIn',
    Future: 'Future',
    Option: 'Option',
    Physical: 'Physical',
    Underlying: 'Underlying',
    Basis: 'Basis',
    Swap: 'Swap',
    Index: 'Index'
} as const;

export type PositionType = typeof PositionType[keyof typeof PositionType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PriceType = {
    None: 'None',
    Future: 'Future',
    Option: 'Option',
    Physical: 'Physical',
    Basis: 'Basis',
    OnPeakEast: 'OnPeakEast',
    WrapEast: 'WrapEast',
    OnPeakWest: 'OnPeakWest',
    WrapWest: 'WrapWest',
    AroundTheClock: 'AroundTheClock',
    StaticPrice: 'StaticPrice',
    Daily: 'Daily',
    Cm: 'CM',
    Cm1: 'CM1'
} as const;

export type PriceType = typeof PriceType[keyof typeof PriceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ReportType = {
    MarkToMarket: 'MarkToMarket',
    VarianceToBudget: 'VarianceToBudget',
    Scenario: 'Scenario',
    TimeTrigger: 'TimeTrigger',
    ProfitAndLoss: 'ProfitAndLoss',
    Summary: 'Summary'
} as const;

export type ReportType = typeof ReportType[keyof typeof ReportType];


/**
 * 
 * @export
 * @interface ScenarioMarketPriceExcelTemplateRow
 */
export interface ScenarioMarketPriceExcelTemplateRow {
    /**
     * 
     * @type {number}
     * @memberof ScenarioMarketPriceExcelTemplateRow
     */
    'marketPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ScenarioMarketPriceExcelTemplateRow
     */
    'budgetYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScenarioMarketPriceExcelTemplateRow
     */
    'budgetMonth'?: number | null;
    /**
     * 
     * @type {ComponentType}
     * @memberof ScenarioMarketPriceExcelTemplateRow
     */
    'componentType'?: ComponentType;
    /**
     * 
     * @type {ScenarioMarketPriceModel}
     * @memberof ScenarioMarketPriceExcelTemplateRow
     */
    'model': ScenarioMarketPriceModel;
    /**
     * 
     * @type {number}
     * @memberof ScenarioMarketPriceExcelTemplateRow
     */
    'row': number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ScenarioMarketPriceExcelTemplateRow
     */
    'cellValues': { [key: string]: any; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ScenarioMarketPriceExcelTemplateRow
     */
    'fieldErrors': { [key: string]: Array<string>; };
    /**
     * 
     * @type {boolean}
     * @memberof ScenarioMarketPriceExcelTemplateRow
     */
    'isSuccessful': boolean;
}
/**
 * 
 * @export
 * @interface ScenarioMarketPriceModel
 */
export interface ScenarioMarketPriceModel {
    /**
     * 
     * @type {number}
     * @memberof ScenarioMarketPriceModel
     */
    'marketPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ScenarioMarketPriceModel
     */
    'budgetYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScenarioMarketPriceModel
     */
    'budgetMonth'?: number | null;
    /**
     * 
     * @type {ComponentType}
     * @memberof ScenarioMarketPriceModel
     */
    'componentType'?: ComponentType;
}
/**
 * 
 * @export
 * @interface ScenarioVarianceModel
 */
export interface ScenarioVarianceModel {
    /**
     * 
     * @type {number}
     * @memberof ScenarioVarianceModel
     */
    'stressIncrement': number;
    /**
     * 
     * @type {StressIncrementType}
     * @memberof ScenarioVarianceModel
     */
    'stresIncrementType': StressIncrementType;
    /**
     * 
     * @type {Array<CommodityScenarioVarianceModel>}
     * @memberof ScenarioVarianceModel
     */
    'commodityScenarios': Array<CommodityScenarioVarianceModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Side = {
    Buy: 'Buy',
    Sell: 'Sell'
} as const;

export type Side = typeof Side[keyof typeof Side];


/**
 * 
 * @export
 * @enum {string}
 */

export const SortDirection = {
    Asc: 'Asc',
    Desc: 'Desc',
    None: 'None'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];


/**
 * 
 * @export
 * @enum {string}
 */

export const StressIncrementType = {
    Percent: 'Percent',
    Price: 'Price'
} as const;

export type StressIncrementType = typeof StressIncrementType[keyof typeof StressIncrementType];


/**
 * 
 * @export
 * @interface TierModel
 */
export interface TierModel {
    /**
     * 
     * @type {number}
     * @memberof TierModel
     */
    'tierId': number;
    /**
     * 
     * @type {string}
     * @memberof TierModel
     */
    'tierName': string;
    /**
     * 
     * @type {number}
     * @memberof TierModel
     */
    'tierRank': number;
}
/**
 * 
 * @export
 * @interface TimeTriggerModel
 */
export interface TimeTriggerModel {
    /**
     * 
     * @type {ContractDate}
     * @memberof TimeTriggerModel
     */
    'contractMonth'?: ContractDate;
    /**
     * 
     * @type {number}
     * @memberof TimeTriggerModel
     */
    'minimumHedge'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeTriggerModel
     */
    'maximumHedge'?: number;
}
/**
 * 
 * @export
 * @interface TimeTriggerReportModel
 */
export interface TimeTriggerReportModel {
    /**
     * 
     * @type {Array<TimeTriggerModel>}
     * @memberof TimeTriggerReportModel
     */
    'timeTriggers': Array<TimeTriggerModel>;
    /**
     * 
     * @type {ContractDate}
     * @memberof TimeTriggerReportModel
     */
    'budgetMonth': ContractDate;
    /**
     * 
     * @type {number}
     * @memberof TimeTriggerReportModel
     */
    'budgetVolume': number;
    /**
     * 
     * @type {number}
     * @memberof TimeTriggerReportModel
     */
    'hedgedVolume': number;
    /**
     * 
     * @type {number}
     * @memberof TimeTriggerReportModel
     */
    'totalMinimumHedge': number;
    /**
     * 
     * @type {number}
     * @memberof TimeTriggerReportModel
     */
    'totalMaximumHedge': number;
    /**
     * 
     * @type {number}
     * @memberof TimeTriggerReportModel
     */
    'totalHedgePerentage': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Unit = {
    None: 'None',
    Mmbtu: 'MMBTu',
    Mw: 'MW',
    Ton: 'Ton',
    Tonne: 'Tonne',
    Bbl: 'Bbl',
    Bu: 'Bu',
    Gal: 'Gal',
    Lb: 'Lb',
    Cwt: 'Cwt',
    Sc: 'Sc',
    Contract: 'Contract',
    Kg: 'Kg',
    Lt: 'Lt',
    Mwh: 'MWh',
    Toz: 'Toz',
    Gj: 'Gj',
    KgMs: 'KgMs',
    Allowance: 'Allowance'
} as const;

export type Unit = typeof Unit[keyof typeof Unit];


/**
 * 
 * @export
 * @interface UpdateBudgetModel
 */
export interface UpdateBudgetModel {
    /**
     * 
     * @type {number}
     * @memberof UpdateBudgetModel
     */
    'budgetId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBudgetModel
     */
    'commodityId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBudgetModel
     */
    'pricingStrategyId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBudgetModel
     */
    'volume': number;
    /**
     * 
     * @type {Unit}
     * @memberof UpdateBudgetModel
     */
    'unit': Unit;
    /**
     * 
     * @type {Currency}
     * @memberof UpdateBudgetModel
     */
    'currency': Currency;
    /**
     * 
     * @type {ContractDate}
     * @memberof UpdateBudgetModel
     */
    'budgetDate': ContractDate;
    /**
     * 
     * @type {ContractDate}
     * @memberof UpdateBudgetModel
     */
    'contractDate'?: ContractDate;
    /**
     * 
     * @type {number}
     * @memberof UpdateBudgetModel
     */
    'locationId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateBudgetModel
     */
    'counterpartyId'?: number | null;
    /**
     * 
     * @type {Array<UpdateBudgetPriceModel>}
     * @memberof UpdateBudgetModel
     */
    'budgetPrices': Array<UpdateBudgetPriceModel>;
}
/**
 * 
 * @export
 * @interface UpdateBudgetPriceModel
 */
export interface UpdateBudgetPriceModel {
    /**
     * 
     * @type {number}
     * @memberof UpdateBudgetPriceModel
     */
    'budgetPriceId': number;
    /**
     * 
     * @type {ComponentType}
     * @memberof UpdateBudgetPriceModel
     */
    'componentType': ComponentType;
    /**
     * 
     * @type {number}
     * @memberof UpdateBudgetPriceModel
     */
    'price': number;
    /**
     * 
     * @type {Unit}
     * @memberof UpdateBudgetPriceModel
     */
    'unit': Unit;
}
/**
 * 
 * @export
 * @interface UpdateBudgetsRequest
 */
export interface UpdateBudgetsRequest {
    /**
     * 
     * @type {Array<UpdateBudgetModel>}
     * @memberof UpdateBudgetsRequest
     */
    'budgets'?: Array<UpdateBudgetModel> | null;
}
/**
 * 
 * @export
 * @interface UpdateCommodityResponse
 */
export interface UpdateCommodityResponse {
    /**
     * 
     * @type {CommodityModel}
     * @memberof UpdateCommodityResponse
     */
    'commodity'?: CommodityModel;
}
/**
 * 
 * @export
 * @interface UpdateCounterpartyResponse
 */
export interface UpdateCounterpartyResponse {
    /**
     * 
     * @type {CounterpartyModel}
     * @memberof UpdateCounterpartyResponse
     */
    'counterparty'?: CounterpartyModel;
}
/**
 * 
 * @export
 * @interface UpdateDealTicketRequest
 */
export interface UpdateDealTicketRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateDealTicketRequest
     */
    'dealTicketId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDealTicketRequest
     */
    'tradeDate': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealTicketRequest
     */
    'commodityId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealTicketRequest
     */
    'counterpartyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealTicketRequest
     */
    'portfolioId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDealTicketRequest
     */
    'dealTicketNumber'?: string | null;
    /**
     * 
     * @type {Array<UpdatePositionModel>}
     * @memberof UpdateDealTicketRequest
     */
    'positions'?: Array<UpdatePositionModel> | null;
}
/**
 * 
 * @export
 * @interface UpdateLocationResponse
 */
export interface UpdateLocationResponse {
    /**
     * 
     * @type {LocationModel}
     * @memberof UpdateLocationResponse
     */
    'location'?: LocationModel;
}
/**
 * 
 * @export
 * @interface UpdateMarketViewUserResponse
 */
export interface UpdateMarketViewUserResponse {
    /**
     * 
     * @type {MarketViewUserModel}
     * @memberof UpdateMarketViewUserResponse
     */
    'user'?: MarketViewUserModel;
}
/**
 * 
 * @export
 * @interface UpdateNoteResponse
 */
export interface UpdateNoteResponse {
    /**
     * 
     * @type {NoteModel}
     * @memberof UpdateNoteResponse
     */
    'note'?: NoteModel;
}
/**
 * 
 * @export
 * @interface UpdatePortfolioResponse
 */
export interface UpdatePortfolioResponse {
    /**
     * 
     * @type {PortfolioModel}
     * @memberof UpdatePortfolioResponse
     */
    'portfolio'?: PortfolioModel;
}
/**
 * 
 * @export
 * @interface UpdatePositionModel
 */
export interface UpdatePositionModel {
    /**
     * 
     * @type {number}
     * @memberof UpdatePositionModel
     */
    'positionId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdatePositionModel
     */
    'locationId'?: number | null;
    /**
     * 
     * @type {ContractDate}
     * @memberof UpdatePositionModel
     */
    'budgetDate'?: ContractDate;
    /**
     * 
     * @type {ContractDate}
     * @memberof UpdatePositionModel
     */
    'contractDate'?: ContractDate;
    /**
     * 
     * @type {number}
     * @memberof UpdatePositionModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePositionModel
     */
    'volume': number;
    /**
     * 
     * @type {PositionType}
     * @memberof UpdatePositionModel
     */
    'positionType'?: PositionType;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePositionModel
     */
    'isLastDay'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdatePositionModel
     */
    'optionStrike'?: number | null;
    /**
     * 
     * @type {OptionType}
     * @memberof UpdatePositionModel
     */
    'optionType'?: OptionType;
    /**
     * 
     * @type {OptionStyle}
     * @memberof UpdatePositionModel
     */
    'optionStyle'?: OptionStyle;
    /**
     * 
     * @type {Side}
     * @memberof UpdatePositionModel
     */
    'side': Side;
    /**
     * 
     * @type {Unit}
     * @memberof UpdatePositionModel
     */
    'unit': Unit;
    /**
     * 
     * @type {Currency}
     * @memberof UpdatePositionModel
     */
    'currency': Currency;
}
/**
 * 
 * @export
 * @interface UpdateTierResponse
 */
export interface UpdateTierResponse {
    /**
     * 
     * @type {TierModel}
     * @memberof UpdateTierResponse
     */
    'tier'?: TierModel;
}
/**
 * 
 * @export
 * @interface UpdateWhatIfPositionModel
 */
export interface UpdateWhatIfPositionModel {
    /**
     * 
     * @type {number}
     * @memberof UpdateWhatIfPositionModel
     */
    'whatIfPositionId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateWhatIfPositionModel
     */
    'commodityId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWhatIfPositionModel
     */
    'budgetMonth': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWhatIfPositionModel
     */
    'budgetYear': number;
    /**
     * 
     * @type {PositionType}
     * @memberof UpdateWhatIfPositionModel
     */
    'positionType': PositionType;
    /**
     * 
     * @type {Side}
     * @memberof UpdateWhatIfPositionModel
     */
    'side': Side;
    /**
     * 
     * @type {number}
     * @memberof UpdateWhatIfPositionModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWhatIfPositionModel
     */
    'volume': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWhatIfPositionModel
     */
    'optionStrike'?: number | null;
    /**
     * 
     * @type {OptionType}
     * @memberof UpdateWhatIfPositionModel
     */
    'optionType'?: OptionType;
    /**
     * 
     * @type {OptionStyle}
     * @memberof UpdateWhatIfPositionModel
     */
    'optionStyle'?: OptionStyle;
    /**
     * 
     * @type {Unit}
     * @memberof UpdateWhatIfPositionModel
     */
    'unit': Unit;
}
/**
 * 
 * @export
 * @interface UpdateWhatIfStrategyRequest
 */
export interface UpdateWhatIfStrategyRequest {
    /**
     * 
     * @type {Array<UpdateWhatIfPositionModel>}
     * @memberof UpdateWhatIfStrategyRequest
     */
    'positions': Array<UpdateWhatIfPositionModel>;
    /**
     * 
     * @type {number}
     * @memberof UpdateWhatIfStrategyRequest
     */
    'whatIfStrategyId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateWhatIfStrategyRequest
     */
    'whatIfStrategyName': string;
}
/**
 * 
 * @export
 * @interface UpdateWhatIfStrategyResponse
 */
export interface UpdateWhatIfStrategyResponse {
    /**
     * 
     * @type {WhatIfStrategyModel}
     * @memberof UpdateWhatIfStrategyResponse
     */
    'whatIfStrategy'?: WhatIfStrategyModel;
}
/**
 * 
 * @export
 * @interface Variance
 */
export interface Variance {
    /**
     * 
     * @type {Array<VarianceComponent>}
     * @memberof Variance
     */
    'components'?: Array<VarianceComponent> | null;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'allInHedgedPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'allInHedgedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'allInHedgedVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'blendedPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'blendedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'budgetPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'budgetValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'budgetVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'effectiveBudgetVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'hedgedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'marketPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'marketValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'openValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'profitAndLossPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'profitAndLossValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'varianceToBudgetPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'varianceToBudgetValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'varianceToMarketPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Variance
     */
    'varianceToMarketValue'?: number;
    /**
     * 
     * @type {Unit}
     * @memberof Variance
     */
    'unit'?: Unit;
    /**
     * 
     * @type {Currency}
     * @memberof Variance
     */
    'currency'?: Currency;
}
/**
 * 
 * @export
 * @interface VarianceComponent
 */
export interface VarianceComponent {
    /**
     * 
     * @type {ComponentType}
     * @memberof VarianceComponent
     */
    'componentType'?: ComponentType;
    /**
     * 
     * @type {string}
     * @memberof VarianceComponent
     */
    'componentSubType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'blendedPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'blendedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'budgetPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'budgetValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'budgetVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'componentHedgedVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'effectiveBudgetVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'hedgedPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'hedgedPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'hedgedValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'hedgedVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'marketPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'marketValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'netOpenVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'openPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'openValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'openVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'profitAndLossValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'varianceToBudgetPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'varianceToBudgetValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'varianceToMarketPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'varianceToMarketValue'?: number;
    /**
     * 
     * @type {PositionType}
     * @memberof VarianceComponent
     */
    'positionType'?: PositionType;
    /**
     * 
     * @type {OptionType}
     * @memberof VarianceComponent
     */
    'optionType'?: OptionType;
    /**
     * 
     * @type {string}
     * @memberof VarianceComponent
     */
    'positionDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'premium'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'markToMarketValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'markToMarketPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'fixedHedgedVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'fixedVarianceToMarketValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'callHedgedVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'callStrikePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'quoteMonthFixedHedgedVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'quoteMonthFixedHedgedPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'quoteMonthCallHedgedVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'quoteMonthCallPremiumPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof VarianceComponent
     */
    'quoteMonthCallStrikePrice'?: number;
}
/**
 * 
 * @export
 * @interface WhatIfPositionModel
 */
export interface WhatIfPositionModel {
    /**
     * 
     * @type {number}
     * @memberof WhatIfPositionModel
     */
    'whatIfPositionId': number;
    /**
     * 
     * @type {number}
     * @memberof WhatIfPositionModel
     */
    'commodityId': number;
    /**
     * 
     * @type {CommodityModel}
     * @memberof WhatIfPositionModel
     */
    'commodity': CommodityModel;
    /**
     * 
     * @type {number}
     * @memberof WhatIfPositionModel
     */
    'budgetMonth': number;
    /**
     * 
     * @type {number}
     * @memberof WhatIfPositionModel
     */
    'budgetYear': number;
    /**
     * 
     * @type {PositionType}
     * @memberof WhatIfPositionModel
     */
    'positionType': PositionType;
    /**
     * 
     * @type {Side}
     * @memberof WhatIfPositionModel
     */
    'side': Side;
    /**
     * 
     * @type {number}
     * @memberof WhatIfPositionModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof WhatIfPositionModel
     */
    'volume': number;
    /**
     * 
     * @type {number}
     * @memberof WhatIfPositionModel
     */
    'optionStrike'?: number | null;
    /**
     * 
     * @type {OptionType}
     * @memberof WhatIfPositionModel
     */
    'optionType'?: OptionType;
    /**
     * 
     * @type {OptionStyle}
     * @memberof WhatIfPositionModel
     */
    'optionStyle'?: OptionStyle;
    /**
     * 
     * @type {Unit}
     * @memberof WhatIfPositionModel
     */
    'unit': Unit;
    /**
     * 
     * @type {ContractDate}
     * @memberof WhatIfPositionModel
     */
    'budgetDate': ContractDate;
}
/**
 * 
 * @export
 * @interface WhatIfStrategyModel
 */
export interface WhatIfStrategyModel {
    /**
     * 
     * @type {number}
     * @memberof WhatIfStrategyModel
     */
    'whatIfStrategyId': number;
    /**
     * 
     * @type {string}
     * @memberof WhatIfStrategyModel
     */
    'whatIfStrategyName': string;
    /**
     * 
     * @type {Array<WhatIfPositionModel>}
     * @memberof WhatIfStrategyModel
     */
    'positions': Array<WhatIfPositionModel>;
}
/**
 * 
 * @export
 * @interface YearDataModel
 */
export interface YearDataModel {
    /**
     * 
     * @type {number}
     * @memberof YearDataModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof YearDataModel
     */
    'count': number;
}

/**
 * CommodityManagementApi - axios parameter creator
 * @export
 */
export const CommodityManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} commodityName 
         * @param {Unit} displayUnit 
         * @param {number} displayPrecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommodity: async (commodityName: string, displayUnit: Unit, displayPrecision: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commodityName' is not null or undefined
            assertParamExists('addCommodity', 'commodityName', commodityName)
            // verify required parameter 'displayUnit' is not null or undefined
            assertParamExists('addCommodity', 'displayUnit', displayUnit)
            // verify required parameter 'displayPrecision' is not null or undefined
            assertParamExists('addCommodity', 'displayPrecision', displayPrecision)
            const localVarPath = `/commodity-management/commodities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commodityName !== undefined) {
                localVarQueryParameter['CommodityName'] = commodityName;
            }

            if (displayUnit !== undefined) {
                localVarQueryParameter['DisplayUnit'] = displayUnit;
            }

            if (displayPrecision !== undefined) {
                localVarQueryParameter['DisplayPrecision'] = displayPrecision;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [counterpartyName] 
         * @param {number} [creditLimit] 
         * @param {boolean} [netting] 
         * @param {boolean} [setoff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCounterparty: async (counterpartyName?: string, creditLimit?: number, netting?: boolean, setoff?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/counterparties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (counterpartyName !== undefined) {
                localVarQueryParameter['CounterpartyName'] = counterpartyName;
            }

            if (creditLimit !== undefined) {
                localVarQueryParameter['CreditLimit'] = creditLimit;
            }

            if (netting !== undefined) {
                localVarQueryParameter['Netting'] = netting;
            }

            if (setoff !== undefined) {
                localVarQueryParameter['Setoff'] = setoff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationName 
         * @param {number} tierId 
         * @param {number} [parentLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLocation: async (locationName: string, tierId: number, parentLocationId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationName' is not null or undefined
            assertParamExists('addLocation', 'locationName', locationName)
            // verify required parameter 'tierId' is not null or undefined
            assertParamExists('addLocation', 'tierId', tierId)
            const localVarPath = `/commodity-management/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationName !== undefined) {
                localVarQueryParameter['LocationName'] = locationName;
            }

            if (tierId !== undefined) {
                localVarQueryParameter['TierId'] = tierId;
            }

            if (parentLocationId !== undefined) {
                localVarQueryParameter['ParentLocationId'] = parentLocationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddNoteRequest} [addNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNote: async (addNoteRequest?: AddNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolio: async (portfolioName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioName' is not null or undefined
            assertParamExists('addPortfolio', 'portfolioName', portfolioName)
            const localVarPath = `/commodity-management/portfolios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (portfolioName !== undefined) {
                localVarQueryParameter['PortfolioName'] = portfolioName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tierRank 
         * @param {string} [tierName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTier: async (tierRank: number, tierName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tierRank' is not null or undefined
            assertParamExists('addTier', 'tierRank', tierRank)
            const localVarPath = `/commodity-management/tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tierName !== undefined) {
                localVarQueryParameter['TierName'] = tierName;
            }

            if (tierRank !== undefined) {
                localVarQueryParameter['TierRank'] = tierRank;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDealTicketRequest} [updateDealTicketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTrade: async (updateDealTicketRequest?: UpdateDealTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/dealTickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDealTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commodityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommodity: async (commodityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commodityId' is not null or undefined
            assertParamExists('deleteCommodity', 'commodityId', commodityId)
            const localVarPath = `/commodity-management/commodities/{commodityId}`
                .replace(`{${"commodityId"}}`, encodeURIComponent(String(commodityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} counterpartyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCounterparty: async (counterpartyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'counterpartyId' is not null or undefined
            assertParamExists('deleteCounterparty', 'counterpartyId', counterpartyId)
            const localVarPath = `/commodity-management/counterparties/{counterpartyId}`
                .replace(`{${"counterpartyId"}}`, encodeURIComponent(String(counterpartyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation: async (locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('deleteLocation', 'locationId', locationId)
            const localVarPath = `/commodity-management/locations/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote: async (noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('deleteNote', 'noteId', noteId)
            const localVarPath = `/commodity-management/notes/{NoteId}`
                .replace(`{${"NoteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolio: async (portfolioId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('deletePortfolio', 'portfolioId', portfolioId)
            const localVarPath = `/commodity-management/portfolios/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTier: async (tierId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tierId' is not null or undefined
            assertParamExists('deleteTier', 'tierId', tierId)
            const localVarPath = `/commodity-management/tiers/{tierId}`
                .replace(`{${"tierId"}}`, encodeURIComponent(String(tierId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} whatIfStrategyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhatIfStrategy: async (whatIfStrategyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'whatIfStrategyId' is not null or undefined
            assertParamExists('deleteWhatIfStrategy', 'whatIfStrategyId', whatIfStrategyId)
            const localVarPath = `/commodity-management/what-if-strategies/{whatIfStrategyId}`
                .replace(`{${"whatIfStrategyId"}}`, encodeURIComponent(String(whatIfStrategyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityType 
         * @param {string} entityId 
         * @param {boolean} [includeUnchangedFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTrail: async (entityType: string, entityId: string, includeUnchangedFields?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getAuditTrail', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getAuditTrail', 'entityId', entityId)
            const localVarPath = `/{entityType}/{entityId}`
                .replace(`{${"entityType"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeUnchangedFields !== undefined) {
                localVarQueryParameter['IncludeUnchangedFields'] = includeUnchangedFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} [entityType] 
         * @param {boolean} [includeUnchangedFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBudgetAuditTrail: async (entityId: string, entityType?: string, includeUnchangedFields?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getBudgetAuditTrail', 'entityId', entityId)
            const localVarPath = `/budgets/{entityId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['EntityType'] = entityType;
            }

            if (includeUnchangedFields !== undefined) {
                localVarQueryParameter['IncludeUnchangedFields'] = includeUnchangedFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/customer/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerReports: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/customer/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/customer/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dealTicketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealTicket: async (dealTicketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealTicketId' is not null or undefined
            assertParamExists('getDealTicket', 'dealTicketId', dealTicketId)
            const localVarPath = `/commodity-management/dealTickets/{dealTicketId}`
                .replace(`{${"dealTicketId"}}`, encodeURIComponent(String(dealTicketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {ExpiryStatus} [expiryStatus] 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkToMarketExcelReport: async (quoteDate: string, customerReportId: number, expiryStatus?: ExpiryStatus, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteDate' is not null or undefined
            assertParamExists('getMarkToMarketExcelReport', 'quoteDate', quoteDate)
            // verify required parameter 'customerReportId' is not null or undefined
            assertParamExists('getMarkToMarketExcelReport', 'customerReportId', customerReportId)
            const localVarPath = `/commodity-management/reports/mark-to-market/{quoteDate}/export`
                .replace(`{${"quoteDate"}}`, encodeURIComponent(String(quoteDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerReportId !== undefined) {
                localVarQueryParameter['CustomerReportId'] = customerReportId;
            }

            if (expiryStatus !== undefined) {
                localVarQueryParameter['ExpiryStatus'] = expiryStatus;
            }

            if (budgetStartMonthYear !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Year'] = budgetStartMonthYear;
            }

            if (budgetStartMonthMonth !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Month'] = budgetStartMonthMonth;
            }

            if (budgetStartMonthLabel !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Label'] = budgetStartMonthLabel;
            }

            if (budgetEndMonthYear !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Year'] = budgetEndMonthYear;
            }

            if (budgetEndMonthMonth !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Month'] = budgetEndMonthMonth;
            }

            if (budgetEndMonthLabel !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Label'] = budgetEndMonthLabel;
            }

            if (scenarioMarketPrices) {
                localVarQueryParameter['ScenarioMarketPrices'] = scenarioMarketPrices;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {ExpiryStatus} [expiryStatus] 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkToMarketReport: async (quoteDate: string, customerReportId: number, expiryStatus?: ExpiryStatus, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteDate' is not null or undefined
            assertParamExists('getMarkToMarketReport', 'quoteDate', quoteDate)
            // verify required parameter 'customerReportId' is not null or undefined
            assertParamExists('getMarkToMarketReport', 'customerReportId', customerReportId)
            const localVarPath = `/commodity-management/reports/mark-to-market/{quoteDate}`
                .replace(`{${"quoteDate"}}`, encodeURIComponent(String(quoteDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerReportId !== undefined) {
                localVarQueryParameter['CustomerReportId'] = customerReportId;
            }

            if (expiryStatus !== undefined) {
                localVarQueryParameter['ExpiryStatus'] = expiryStatus;
            }

            if (budgetStartMonthYear !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Year'] = budgetStartMonthYear;
            }

            if (budgetStartMonthMonth !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Month'] = budgetStartMonthMonth;
            }

            if (budgetStartMonthLabel !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Label'] = budgetStartMonthLabel;
            }

            if (budgetEndMonthYear !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Year'] = budgetEndMonthYear;
            }

            if (budgetEndMonthMonth !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Month'] = budgetEndMonthMonth;
            }

            if (budgetEndMonthLabel !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Label'] = budgetEndMonthLabel;
            }

            if (scenarioMarketPrices) {
                localVarQueryParameter['ScenarioMarketPrices'] = scenarioMarketPrices;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} [entityType] 
         * @param {boolean} [includeUnchangedFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionAuditTrail: async (entityId: string, entityType?: string, includeUnchangedFields?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getPositionAuditTrail', 'entityId', entityId)
            const localVarPath = `/positions/{entityId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['EntityType'] = entityType;
            }

            if (includeUnchangedFields !== undefined) {
                localVarQueryParameter['IncludeUnchangedFields'] = includeUnchangedFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Currency} [currency] 
         * @param {StressIncrementType} [stressIncrementType] 
         * @param {Array<number>} [stressIncrements] 
         * @param {Array<number>} [whatIfStrategyIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioExcelReport: async (quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, currency?: Currency, stressIncrementType?: StressIncrementType, stressIncrements?: Array<number>, whatIfStrategyIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteDate' is not null or undefined
            assertParamExists('getScenarioExcelReport', 'quoteDate', quoteDate)
            // verify required parameter 'customerReportId' is not null or undefined
            assertParamExists('getScenarioExcelReport', 'customerReportId', customerReportId)
            const localVarPath = `/commodity-management/reports/scenario/{quoteDate}/export`
                .replace(`{${"quoteDate"}}`, encodeURIComponent(String(quoteDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerReportId !== undefined) {
                localVarQueryParameter['CustomerReportId'] = customerReportId;
            }

            if (budgetStartMonthYear !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Year'] = budgetStartMonthYear;
            }

            if (budgetStartMonthMonth !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Month'] = budgetStartMonthMonth;
            }

            if (budgetStartMonthLabel !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Label'] = budgetStartMonthLabel;
            }

            if (budgetEndMonthYear !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Year'] = budgetEndMonthYear;
            }

            if (budgetEndMonthMonth !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Month'] = budgetEndMonthMonth;
            }

            if (budgetEndMonthLabel !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Label'] = budgetEndMonthLabel;
            }

            if (commoditiesFilter) {
                localVarQueryParameter['CommoditiesFilter'] = commoditiesFilter;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (stressIncrementType !== undefined) {
                localVarQueryParameter['StressIncrementType'] = stressIncrementType;
            }

            if (stressIncrements) {
                localVarQueryParameter['StressIncrements'] = stressIncrements;
            }

            if (whatIfStrategyIds) {
                localVarQueryParameter['WhatIfStrategyIds'] = whatIfStrategyIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioMarketPricesExcelTemplate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/scenario-market-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Currency} [currency] 
         * @param {StressIncrementType} [stressIncrementType] 
         * @param {Array<number>} [stressIncrements] 
         * @param {Array<number>} [whatIfStrategyIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioReport: async (quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, currency?: Currency, stressIncrementType?: StressIncrementType, stressIncrements?: Array<number>, whatIfStrategyIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteDate' is not null or undefined
            assertParamExists('getScenarioReport', 'quoteDate', quoteDate)
            // verify required parameter 'customerReportId' is not null or undefined
            assertParamExists('getScenarioReport', 'customerReportId', customerReportId)
            const localVarPath = `/commodity-management/reports/scenario/{quoteDate}`
                .replace(`{${"quoteDate"}}`, encodeURIComponent(String(quoteDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerReportId !== undefined) {
                localVarQueryParameter['CustomerReportId'] = customerReportId;
            }

            if (budgetStartMonthYear !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Year'] = budgetStartMonthYear;
            }

            if (budgetStartMonthMonth !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Month'] = budgetStartMonthMonth;
            }

            if (budgetStartMonthLabel !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Label'] = budgetStartMonthLabel;
            }

            if (budgetEndMonthYear !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Year'] = budgetEndMonthYear;
            }

            if (budgetEndMonthMonth !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Month'] = budgetEndMonthMonth;
            }

            if (budgetEndMonthLabel !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Label'] = budgetEndMonthLabel;
            }

            if (commoditiesFilter) {
                localVarQueryParameter['CommoditiesFilter'] = commoditiesFilter;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (stressIncrementType !== undefined) {
                localVarQueryParameter['StressIncrementType'] = stressIncrementType;
            }

            if (stressIncrements) {
                localVarQueryParameter['StressIncrements'] = stressIncrements;
            }

            if (whatIfStrategyIds) {
                localVarQueryParameter['WhatIfStrategyIds'] = whatIfStrategyIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} numberOfMonths 
         * @param {number} minimumPercentage 
         * @param {number} maximumPercentage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeTriggerReport: async (quoteDate: string, numberOfMonths: number, minimumPercentage: number, maximumPercentage: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteDate' is not null or undefined
            assertParamExists('getTimeTriggerReport', 'quoteDate', quoteDate)
            // verify required parameter 'numberOfMonths' is not null or undefined
            assertParamExists('getTimeTriggerReport', 'numberOfMonths', numberOfMonths)
            // verify required parameter 'minimumPercentage' is not null or undefined
            assertParamExists('getTimeTriggerReport', 'minimumPercentage', minimumPercentage)
            // verify required parameter 'maximumPercentage' is not null or undefined
            assertParamExists('getTimeTriggerReport', 'maximumPercentage', maximumPercentage)
            const localVarPath = `/commodity-management/reports/time-trigger/{quoteDate}`
                .replace(`{${"quoteDate"}}`, encodeURIComponent(String(quoteDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (numberOfMonths !== undefined) {
                localVarQueryParameter['NumberOfMonths'] = numberOfMonths;
            }

            if (minimumPercentage !== undefined) {
                localVarQueryParameter['MinimumPercentage'] = minimumPercentage;
            }

            if (maximumPercentage !== undefined) {
                localVarQueryParameter['MaximumPercentage'] = maximumPercentage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Currency} [currency] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVarianceExcelReport: async (quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, currency?: Currency, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteDate' is not null or undefined
            assertParamExists('getVarianceExcelReport', 'quoteDate', quoteDate)
            // verify required parameter 'customerReportId' is not null or undefined
            assertParamExists('getVarianceExcelReport', 'customerReportId', customerReportId)
            const localVarPath = `/commodity-management/reports/variance/{quoteDate}/export`
                .replace(`{${"quoteDate"}}`, encodeURIComponent(String(quoteDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerReportId !== undefined) {
                localVarQueryParameter['CustomerReportId'] = customerReportId;
            }

            if (budgetStartMonthYear !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Year'] = budgetStartMonthYear;
            }

            if (budgetStartMonthMonth !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Month'] = budgetStartMonthMonth;
            }

            if (budgetStartMonthLabel !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Label'] = budgetStartMonthLabel;
            }

            if (budgetEndMonthYear !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Year'] = budgetEndMonthYear;
            }

            if (budgetEndMonthMonth !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Month'] = budgetEndMonthMonth;
            }

            if (budgetEndMonthLabel !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Label'] = budgetEndMonthLabel;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Currency} [currency] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVarianceReport: async (quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, currency?: Currency, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteDate' is not null or undefined
            assertParamExists('getVarianceReport', 'quoteDate', quoteDate)
            // verify required parameter 'customerReportId' is not null or undefined
            assertParamExists('getVarianceReport', 'customerReportId', customerReportId)
            const localVarPath = `/commodity-management/reports/variance/{quoteDate}`
                .replace(`{${"quoteDate"}}`, encodeURIComponent(String(quoteDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerReportId !== undefined) {
                localVarQueryParameter['CustomerReportId'] = customerReportId;
            }

            if (budgetStartMonthYear !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Year'] = budgetStartMonthYear;
            }

            if (budgetStartMonthMonth !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Month'] = budgetStartMonthMonth;
            }

            if (budgetStartMonthLabel !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Label'] = budgetStartMonthLabel;
            }

            if (budgetEndMonthYear !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Year'] = budgetEndMonthYear;
            }

            if (budgetEndMonthMonth !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Month'] = budgetEndMonthMonth;
            }

            if (budgetEndMonthLabel !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Label'] = budgetEndMonthLabel;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [locationId] 
         * @param {number} [commodityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBudgetYears: async (locationId?: number, commodityId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/budgets/years`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['LocationId'] = locationId;
            }

            if (commodityId !== undefined) {
                localVarQueryParameter['CommodityId'] = commodityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [quoteDate] 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Array<number>} [locationsFilter] 
         * @param {Array<number>} [counterpartiesFilter] 
         * @param {boolean} [includeExpirationDates] 
         * @param {boolean} [includePositions] 
         * @param {boolean} [includeMarketPrices] 
         * @param {Currency} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBudgets: async (quoteDate?: string, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, locationsFilter?: Array<number>, counterpartiesFilter?: Array<number>, includeExpirationDates?: boolean, includePositions?: boolean, includeMarketPrices?: boolean, currency?: Currency, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/budgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quoteDate !== undefined) {
                localVarQueryParameter['QuoteDate'] = (quoteDate as any instanceof Date) ?
                    (quoteDate as any).toISOString() :
                    quoteDate;
            }

            if (budgetStartMonthYear !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Year'] = budgetStartMonthYear;
            }

            if (budgetStartMonthMonth !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Month'] = budgetStartMonthMonth;
            }

            if (budgetStartMonthLabel !== undefined) {
                localVarQueryParameter['BudgetStartMonth.Label'] = budgetStartMonthLabel;
            }

            if (budgetEndMonthYear !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Year'] = budgetEndMonthYear;
            }

            if (budgetEndMonthMonth !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Month'] = budgetEndMonthMonth;
            }

            if (budgetEndMonthLabel !== undefined) {
                localVarQueryParameter['BudgetEndMonth.Label'] = budgetEndMonthLabel;
            }

            if (commoditiesFilter) {
                localVarQueryParameter['CommoditiesFilter'] = commoditiesFilter;
            }

            if (locationsFilter) {
                localVarQueryParameter['LocationsFilter'] = locationsFilter;
            }

            if (counterpartiesFilter) {
                localVarQueryParameter['CounterpartiesFilter'] = counterpartiesFilter;
            }

            if (includeExpirationDates !== undefined) {
                localVarQueryParameter['IncludeExpirationDates'] = includeExpirationDates;
            }

            if (includePositions !== undefined) {
                localVarQueryParameter['IncludePositions'] = includePositions;
            }

            if (includeMarketPrices !== undefined) {
                localVarQueryParameter['IncludeMarketPrices'] = includeMarketPrices;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [commodityId] 
         * @param {number} [locationId] 
         * @param {number} [counterpartyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPositionYears: async (commodityId?: number, locationId?: number, counterpartyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/positions/years`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commodityId !== undefined) {
                localVarQueryParameter['CommodityId'] = commodityId;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['LocationId'] = locationId;
            }

            if (counterpartyId !== undefined) {
                localVarQueryParameter['CounterpartyId'] = counterpartyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [quoteDate] 
         * @param {boolean} [includeExpirationDates] 
         * @param {boolean} [includeMarketPrices] 
         * @param {boolean} [useDisplayUnits] 
         * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Array<number>} [locationsFilter] 
         * @param {Array<number>} [counterpartiesFilter] 
         * @param {string} [tradeDateStartFilter] 
         * @param {string} [tradeDateEndFilter] 
         * @param {number} [budgetMonthStartFilterYear] 
         * @param {number} [budgetMonthStartFilterMonth] 
         * @param {string} [budgetMonthStartFilterLabel] 
         * @param {number} [budgetMonthEndFilterYear] 
         * @param {number} [budgetMonthEndFilterMonth] 
         * @param {string} [budgetMonthEndFilterLabel] 
         * @param {number} [contractMonthStartFilterYear] 
         * @param {number} [contractMonthStartFilterMonth] 
         * @param {string} [contractMonthStartFilterLabel] 
         * @param {number} [contractMonthEndFilterYear] 
         * @param {number} [contractMonthEndFilterMonth] 
         * @param {string} [contractMonthEndFilterLabel] 
         * @param {Array<PositionType>} [positionTypesFilter] 
         * @param {string} [dealTicketNumberFilter] 
         * @param {number} [dealTicketIdFilter] 
         * @param {Side} [sideFilter] 
         * @param {boolean} [validatedFilter] 
         * @param {Array<Currency>} [currencyFilter] 
         * @param {Currency} [currency] 
         * @param {ExpiryStatus} [expiryStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPositions: async (quoteDate?: string, includeExpirationDates?: boolean, includeMarketPrices?: boolean, useDisplayUnits?: boolean, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, commoditiesFilter?: Array<number>, locationsFilter?: Array<number>, counterpartiesFilter?: Array<number>, tradeDateStartFilter?: string, tradeDateEndFilter?: string, budgetMonthStartFilterYear?: number, budgetMonthStartFilterMonth?: number, budgetMonthStartFilterLabel?: string, budgetMonthEndFilterYear?: number, budgetMonthEndFilterMonth?: number, budgetMonthEndFilterLabel?: string, contractMonthStartFilterYear?: number, contractMonthStartFilterMonth?: number, contractMonthStartFilterLabel?: string, contractMonthEndFilterYear?: number, contractMonthEndFilterMonth?: number, contractMonthEndFilterLabel?: string, positionTypesFilter?: Array<PositionType>, dealTicketNumberFilter?: string, dealTicketIdFilter?: number, sideFilter?: Side, validatedFilter?: boolean, currencyFilter?: Array<Currency>, currency?: Currency, expiryStatus?: ExpiryStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quoteDate !== undefined) {
                localVarQueryParameter['QuoteDate'] = (quoteDate as any instanceof Date) ?
                    (quoteDate as any).toISOString() :
                    quoteDate;
            }

            if (includeExpirationDates !== undefined) {
                localVarQueryParameter['IncludeExpirationDates'] = includeExpirationDates;
            }

            if (includeMarketPrices !== undefined) {
                localVarQueryParameter['IncludeMarketPrices'] = includeMarketPrices;
            }

            if (useDisplayUnits !== undefined) {
                localVarQueryParameter['UseDisplayUnits'] = useDisplayUnits;
            }

            if (scenarioMarketPrices) {
                localVarQueryParameter['ScenarioMarketPrices'] = scenarioMarketPrices;
            }

            if (commoditiesFilter) {
                localVarQueryParameter['CommoditiesFilter'] = commoditiesFilter;
            }

            if (locationsFilter) {
                localVarQueryParameter['LocationsFilter'] = locationsFilter;
            }

            if (counterpartiesFilter) {
                localVarQueryParameter['CounterpartiesFilter'] = counterpartiesFilter;
            }

            if (tradeDateStartFilter !== undefined) {
                localVarQueryParameter['TradeDateStartFilter'] = (tradeDateStartFilter as any instanceof Date) ?
                    (tradeDateStartFilter as any).toISOString() :
                    tradeDateStartFilter;
            }

            if (tradeDateEndFilter !== undefined) {
                localVarQueryParameter['TradeDateEndFilter'] = (tradeDateEndFilter as any instanceof Date) ?
                    (tradeDateEndFilter as any).toISOString() :
                    tradeDateEndFilter;
            }

            if (budgetMonthStartFilterYear !== undefined) {
                localVarQueryParameter['BudgetMonthStartFilter.Year'] = budgetMonthStartFilterYear;
            }

            if (budgetMonthStartFilterMonth !== undefined) {
                localVarQueryParameter['BudgetMonthStartFilter.Month'] = budgetMonthStartFilterMonth;
            }

            if (budgetMonthStartFilterLabel !== undefined) {
                localVarQueryParameter['BudgetMonthStartFilter.Label'] = budgetMonthStartFilterLabel;
            }

            if (budgetMonthEndFilterYear !== undefined) {
                localVarQueryParameter['BudgetMonthEndFilter.Year'] = budgetMonthEndFilterYear;
            }

            if (budgetMonthEndFilterMonth !== undefined) {
                localVarQueryParameter['BudgetMonthEndFilter.Month'] = budgetMonthEndFilterMonth;
            }

            if (budgetMonthEndFilterLabel !== undefined) {
                localVarQueryParameter['BudgetMonthEndFilter.Label'] = budgetMonthEndFilterLabel;
            }

            if (contractMonthStartFilterYear !== undefined) {
                localVarQueryParameter['ContractMonthStartFilter.Year'] = contractMonthStartFilterYear;
            }

            if (contractMonthStartFilterMonth !== undefined) {
                localVarQueryParameter['ContractMonthStartFilter.Month'] = contractMonthStartFilterMonth;
            }

            if (contractMonthStartFilterLabel !== undefined) {
                localVarQueryParameter['ContractMonthStartFilter.Label'] = contractMonthStartFilterLabel;
            }

            if (contractMonthEndFilterYear !== undefined) {
                localVarQueryParameter['ContractMonthEndFilter.Year'] = contractMonthEndFilterYear;
            }

            if (contractMonthEndFilterMonth !== undefined) {
                localVarQueryParameter['ContractMonthEndFilter.Month'] = contractMonthEndFilterMonth;
            }

            if (contractMonthEndFilterLabel !== undefined) {
                localVarQueryParameter['ContractMonthEndFilter.Label'] = contractMonthEndFilterLabel;
            }

            if (positionTypesFilter) {
                localVarQueryParameter['PositionTypesFilter'] = positionTypesFilter;
            }

            if (dealTicketNumberFilter !== undefined) {
                localVarQueryParameter['DealTicketNumberFilter'] = dealTicketNumberFilter;
            }

            if (dealTicketIdFilter !== undefined) {
                localVarQueryParameter['DealTicketIdFilter'] = dealTicketIdFilter;
            }

            if (sideFilter !== undefined) {
                localVarQueryParameter['SideFilter'] = sideFilter;
            }

            if (validatedFilter !== undefined) {
                localVarQueryParameter['ValidatedFilter'] = validatedFilter;
            }

            if (currencyFilter) {
                localVarQueryParameter['CurrencyFilter'] = currencyFilter;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (expiryStatus !== undefined) {
                localVarQueryParameter['ExpiryStatus'] = expiryStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPricingStrategies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/pricing-strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} scenarioMarketPricesFile 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {boolean} [validationResponseIsValid] 
         * @param {string} [validationResponseValidationMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScenarioMarketPricesFromUpload: async (scenarioMarketPricesFile: any, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, validationResponseIsValid?: boolean, validationResponseValidationMessage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scenarioMarketPricesFile' is not null or undefined
            assertParamExists('listScenarioMarketPricesFromUpload', 'scenarioMarketPricesFile', scenarioMarketPricesFile)
            const localVarPath = `/commodity-management/scenario-market-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (scenarioMarketPricesFile !== undefined) { 
                localVarFormParams.append('ScenarioMarketPricesFile', scenarioMarketPricesFile as any);
            }
    
            if (sortColumn !== undefined) { 
                localVarFormParams.append('SortColumn', sortColumn as any);
            }
    
            if (sortDirection !== undefined) { 
                localVarFormParams.append('SortDirection', new Blob([JSON.stringify(sortDirection)], { type: "application/json", }));
            }
    
            if (take !== undefined) { 
                localVarFormParams.append('Take', take as any);
            }
    
            if (skip !== undefined) { 
                localVarFormParams.append('Skip', skip as any);
            }
    
            if (validationResponseIsValid !== undefined) { 
                localVarFormParams.append('ValidationResponse.IsValid', validationResponseIsValid as any);
            }
    
            if (validationResponseValidationMessage !== undefined) { 
                localVarFormParams.append('ValidationResponse.ValidationMessage', validationResponseValidationMessage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWhatIfStrategies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/what-if-strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBudgetsRequest} [updateBudgetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBudgets: async (updateBudgetsRequest?: UpdateBudgetsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/budgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBudgetsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commodityId 
         * @param {string} commodityName 
         * @param {Unit} displayUnit 
         * @param {number} displayPrecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommodity: async (commodityId: string, commodityName: string, displayUnit: Unit, displayPrecision: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commodityId' is not null or undefined
            assertParamExists('updateCommodity', 'commodityId', commodityId)
            // verify required parameter 'commodityName' is not null or undefined
            assertParamExists('updateCommodity', 'commodityName', commodityName)
            // verify required parameter 'displayUnit' is not null or undefined
            assertParamExists('updateCommodity', 'displayUnit', displayUnit)
            // verify required parameter 'displayPrecision' is not null or undefined
            assertParamExists('updateCommodity', 'displayPrecision', displayPrecision)
            const localVarPath = `/commodity-management/commodities/{commodityId}`
                .replace(`{${"commodityId"}}`, encodeURIComponent(String(commodityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commodityName !== undefined) {
                localVarQueryParameter['CommodityName'] = commodityName;
            }

            if (displayUnit !== undefined) {
                localVarQueryParameter['DisplayUnit'] = displayUnit;
            }

            if (displayPrecision !== undefined) {
                localVarQueryParameter['DisplayPrecision'] = displayPrecision;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} counterpartyId 
         * @param {string} counterpartyName 
         * @param {number} [creditLimit] 
         * @param {boolean} [netting] 
         * @param {boolean} [setoff] 
         * @param {boolean} [active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCounterparty: async (counterpartyId: string, counterpartyName: string, creditLimit?: number, netting?: boolean, setoff?: boolean, active?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'counterpartyId' is not null or undefined
            assertParamExists('updateCounterparty', 'counterpartyId', counterpartyId)
            // verify required parameter 'counterpartyName' is not null or undefined
            assertParamExists('updateCounterparty', 'counterpartyName', counterpartyName)
            const localVarPath = `/commodity-management/counterparties/{counterpartyId}`
                .replace(`{${"counterpartyId"}}`, encodeURIComponent(String(counterpartyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (counterpartyName !== undefined) {
                localVarQueryParameter['CounterpartyName'] = counterpartyName;
            }

            if (creditLimit !== undefined) {
                localVarQueryParameter['CreditLimit'] = creditLimit;
            }

            if (netting !== undefined) {
                localVarQueryParameter['Netting'] = netting;
            }

            if (setoff !== undefined) {
                localVarQueryParameter['Setoff'] = setoff;
            }

            if (active !== undefined) {
                localVarQueryParameter['Active'] = active;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerReportId 
         * @param {string} [overrides] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerReportUserOverrides: async (customerReportId: number, overrides?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerReportId' is not null or undefined
            assertParamExists('updateCustomerReportUserOverrides', 'customerReportId', customerReportId)
            const localVarPath = `/commodity-management/customer/reports/overrides`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerReportId !== undefined) {
                localVarQueryParameter['CustomerReportId'] = customerReportId;
            }

            if (overrides !== undefined) {
                localVarQueryParameter['Overrides'] = overrides;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dealTicketId 
         * @param {Array<number>} [validatedPositionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDealTicketPositionValidation: async (dealTicketId: string, validatedPositionIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealTicketId' is not null or undefined
            assertParamExists('updateDealTicketPositionValidation', 'dealTicketId', dealTicketId)
            const localVarPath = `/commodity-management/dealTickets/{dealTicketId}/validated`
                .replace(`{${"dealTicketId"}}`, encodeURIComponent(String(dealTicketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (validatedPositionIds) {
                localVarQueryParameter['ValidatedPositionIds'] = validatedPositionIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} locationName 
         * @param {number} tierId 
         * @param {number} [parentLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (locationId: string, locationName: string, tierId: number, parentLocationId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('updateLocation', 'locationId', locationId)
            // verify required parameter 'locationName' is not null or undefined
            assertParamExists('updateLocation', 'locationName', locationName)
            // verify required parameter 'tierId' is not null or undefined
            assertParamExists('updateLocation', 'tierId', tierId)
            const localVarPath = `/commodity-management/locations/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationName !== undefined) {
                localVarQueryParameter['LocationName'] = locationName;
            }

            if (tierId !== undefined) {
                localVarQueryParameter['TierId'] = tierId;
            }

            if (parentLocationId !== undefined) {
                localVarQueryParameter['ParentLocationId'] = parentLocationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} noteId 
         * @param {string} subject 
         * @param {string} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: async (noteId: string, subject: string, content: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('updateNote', 'noteId', noteId)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('updateNote', 'subject', subject)
            // verify required parameter 'content' is not null or undefined
            assertParamExists('updateNote', 'content', content)
            const localVarPath = `/commodity-management/notes/{NoteId}`
                .replace(`{${"NoteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subject !== undefined) {
                localVarQueryParameter['Subject'] = subject;
            }

            if (content !== undefined) {
                localVarQueryParameter['Content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} portfolioName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortfolio: async (portfolioId: string, portfolioName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'portfolioId' is not null or undefined
            assertParamExists('updatePortfolio', 'portfolioId', portfolioId)
            // verify required parameter 'portfolioName' is not null or undefined
            assertParamExists('updatePortfolio', 'portfolioName', portfolioName)
            const localVarPath = `/commodity-management/portfolios/{portfolioId}`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (portfolioName !== undefined) {
                localVarQueryParameter['PortfolioName'] = portfolioName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tierId 
         * @param {string} tierName 
         * @param {number} tierRank 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTier: async (tierId: string, tierName: string, tierRank: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tierId' is not null or undefined
            assertParamExists('updateTier', 'tierId', tierId)
            // verify required parameter 'tierName' is not null or undefined
            assertParamExists('updateTier', 'tierName', tierName)
            // verify required parameter 'tierRank' is not null or undefined
            assertParamExists('updateTier', 'tierRank', tierRank)
            const localVarPath = `/commodity-management/tiers/{tierId}`
                .replace(`{${"tierId"}}`, encodeURIComponent(String(tierId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tierName !== undefined) {
                localVarQueryParameter['TierName'] = tierName;
            }

            if (tierRank !== undefined) {
                localVarQueryParameter['TierRank'] = tierRank;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWhatIfStrategyRequest} [updateWhatIfStrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhatIfStrategy: async (updateWhatIfStrategyRequest?: UpdateWhatIfStrategyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commodity-management/what-if-strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWhatIfStrategyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommodityManagementApi - functional programming interface
 * @export
 */
export const CommodityManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommodityManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} commodityName 
         * @param {Unit} displayUnit 
         * @param {number} displayPrecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCommodity(commodityName: string, displayUnit: Unit, displayPrecision: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCommodityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCommodity(commodityName, displayUnit, displayPrecision, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [counterpartyName] 
         * @param {number} [creditLimit] 
         * @param {boolean} [netting] 
         * @param {boolean} [setoff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCounterparty(counterpartyName?: string, creditLimit?: number, netting?: boolean, setoff?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCounterpartyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCounterparty(counterpartyName, creditLimit, netting, setoff, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationName 
         * @param {number} tierId 
         * @param {number} [parentLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLocation(locationName: string, tierId: number, parentLocationId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddLocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLocation(locationName, tierId, parentLocationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddNoteRequest} [addNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNote(addNoteRequest?: AddNoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNote(addNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPortfolio(portfolioName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddPortfolioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPortfolio(portfolioName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tierRank 
         * @param {string} [tierName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTier(tierRank: number, tierName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddTierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTier(tierRank, tierName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDealTicketRequest} [updateDealTicketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTrade(updateDealTicketRequest?: UpdateDealTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTrade(updateDealTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} commodityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommodity(commodityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommodity(commodityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} counterpartyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCounterparty(counterpartyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCounterparty(counterpartyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocation(locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocation(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNote(noteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNote(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePortfolio(portfolioId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePortfolio(portfolioId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTier(tierId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTier(tierId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} whatIfStrategyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWhatIfStrategy(whatIfStrategyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWhatIfStrategy(whatIfStrategyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityType 
         * @param {string} entityId 
         * @param {boolean} [includeUnchangedFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditTrail(entityType: string, entityId: string, includeUnchangedFields?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuditTrailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditTrail(entityType, entityId, includeUnchangedFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} [entityType] 
         * @param {boolean} [includeUnchangedFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBudgetAuditTrail(entityId: string, entityType?: string, includeUnchangedFields?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuditTrailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBudgetAuditTrail(entityId, entityType, includeUnchangedFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerReports(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerReportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerReports(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dealTicketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDealTicket(dealTicketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDealTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDealTicket(dealTicketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {ExpiryStatus} [expiryStatus] 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarkToMarketExcelReport(quoteDate: string, customerReportId: number, expiryStatus?: ExpiryStatus, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarkToMarketExcelReport(quoteDate, customerReportId, expiryStatus, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, scenarioMarketPrices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {ExpiryStatus} [expiryStatus] 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarkToMarketReport(quoteDate: string, customerReportId: number, expiryStatus?: ExpiryStatus, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarkToMarketReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarkToMarketReport(quoteDate, customerReportId, expiryStatus, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, scenarioMarketPrices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} [entityType] 
         * @param {boolean} [includeUnchangedFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositionAuditTrail(entityId: string, entityType?: string, includeUnchangedFields?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuditTrailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPositionAuditTrail(entityId, entityType, includeUnchangedFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Currency} [currency] 
         * @param {StressIncrementType} [stressIncrementType] 
         * @param {Array<number>} [stressIncrements] 
         * @param {Array<number>} [whatIfStrategyIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioExcelReport(quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, currency?: Currency, stressIncrementType?: StressIncrementType, stressIncrements?: Array<number>, whatIfStrategyIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioExcelReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, commoditiesFilter, currency, stressIncrementType, stressIncrements, whatIfStrategyIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioMarketPricesExcelTemplate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioMarketPricesExcelTemplate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Currency} [currency] 
         * @param {StressIncrementType} [stressIncrementType] 
         * @param {Array<number>} [stressIncrements] 
         * @param {Array<number>} [whatIfStrategyIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioReport(quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, currency?: Currency, stressIncrementType?: StressIncrementType, stressIncrements?: Array<number>, whatIfStrategyIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScenarioReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, commoditiesFilter, currency, stressIncrementType, stressIncrements, whatIfStrategyIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} numberOfMonths 
         * @param {number} minimumPercentage 
         * @param {number} maximumPercentage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeTriggerReport(quoteDate: string, numberOfMonths: number, minimumPercentage: number, maximumPercentage: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTimeTriggerReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeTriggerReport(quoteDate, numberOfMonths, minimumPercentage, maximumPercentage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Currency} [currency] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVarianceExcelReport(quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, currency?: Currency, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVarianceExcelReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, currency, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Currency} [currency] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVarianceReport(quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, currency?: Currency, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVarianceReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVarianceReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, currency, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [locationId] 
         * @param {number} [commodityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBudgetYears(locationId?: number, commodityId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBudgetYearsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBudgetYears(locationId, commodityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [quoteDate] 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Array<number>} [locationsFilter] 
         * @param {Array<number>} [counterpartiesFilter] 
         * @param {boolean} [includeExpirationDates] 
         * @param {boolean} [includePositions] 
         * @param {boolean} [includeMarketPrices] 
         * @param {Currency} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBudgets(quoteDate?: string, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, locationsFilter?: Array<number>, counterpartiesFilter?: Array<number>, includeExpirationDates?: boolean, includePositions?: boolean, includeMarketPrices?: boolean, currency?: Currency, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBudgetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBudgets(quoteDate, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, commoditiesFilter, locationsFilter, counterpartiesFilter, includeExpirationDates, includePositions, includeMarketPrices, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNotesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [commodityId] 
         * @param {number} [locationId] 
         * @param {number} [counterpartyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPositionYears(commodityId?: number, locationId?: number, counterpartyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPositionYearsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPositionYears(commodityId, locationId, counterpartyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [quoteDate] 
         * @param {boolean} [includeExpirationDates] 
         * @param {boolean} [includeMarketPrices] 
         * @param {boolean} [useDisplayUnits] 
         * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Array<number>} [locationsFilter] 
         * @param {Array<number>} [counterpartiesFilter] 
         * @param {string} [tradeDateStartFilter] 
         * @param {string} [tradeDateEndFilter] 
         * @param {number} [budgetMonthStartFilterYear] 
         * @param {number} [budgetMonthStartFilterMonth] 
         * @param {string} [budgetMonthStartFilterLabel] 
         * @param {number} [budgetMonthEndFilterYear] 
         * @param {number} [budgetMonthEndFilterMonth] 
         * @param {string} [budgetMonthEndFilterLabel] 
         * @param {number} [contractMonthStartFilterYear] 
         * @param {number} [contractMonthStartFilterMonth] 
         * @param {string} [contractMonthStartFilterLabel] 
         * @param {number} [contractMonthEndFilterYear] 
         * @param {number} [contractMonthEndFilterMonth] 
         * @param {string} [contractMonthEndFilterLabel] 
         * @param {Array<PositionType>} [positionTypesFilter] 
         * @param {string} [dealTicketNumberFilter] 
         * @param {number} [dealTicketIdFilter] 
         * @param {Side} [sideFilter] 
         * @param {boolean} [validatedFilter] 
         * @param {Array<Currency>} [currencyFilter] 
         * @param {Currency} [currency] 
         * @param {ExpiryStatus} [expiryStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPositions(quoteDate?: string, includeExpirationDates?: boolean, includeMarketPrices?: boolean, useDisplayUnits?: boolean, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, commoditiesFilter?: Array<number>, locationsFilter?: Array<number>, counterpartiesFilter?: Array<number>, tradeDateStartFilter?: string, tradeDateEndFilter?: string, budgetMonthStartFilterYear?: number, budgetMonthStartFilterMonth?: number, budgetMonthStartFilterLabel?: string, budgetMonthEndFilterYear?: number, budgetMonthEndFilterMonth?: number, budgetMonthEndFilterLabel?: string, contractMonthStartFilterYear?: number, contractMonthStartFilterMonth?: number, contractMonthStartFilterLabel?: string, contractMonthEndFilterYear?: number, contractMonthEndFilterMonth?: number, contractMonthEndFilterLabel?: string, positionTypesFilter?: Array<PositionType>, dealTicketNumberFilter?: string, dealTicketIdFilter?: number, sideFilter?: Side, validatedFilter?: boolean, currencyFilter?: Array<Currency>, currency?: Currency, expiryStatus?: ExpiryStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPositionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPositions(quoteDate, includeExpirationDates, includeMarketPrices, useDisplayUnits, scenarioMarketPrices, commoditiesFilter, locationsFilter, counterpartiesFilter, tradeDateStartFilter, tradeDateEndFilter, budgetMonthStartFilterYear, budgetMonthStartFilterMonth, budgetMonthStartFilterLabel, budgetMonthEndFilterYear, budgetMonthEndFilterMonth, budgetMonthEndFilterLabel, contractMonthStartFilterYear, contractMonthStartFilterMonth, contractMonthStartFilterLabel, contractMonthEndFilterYear, contractMonthEndFilterMonth, contractMonthEndFilterLabel, positionTypesFilter, dealTicketNumberFilter, dealTicketIdFilter, sideFilter, validatedFilter, currencyFilter, currency, expiryStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPricingStrategies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBudgetPricingStrategiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPricingStrategies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} scenarioMarketPricesFile 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {boolean} [validationResponseIsValid] 
         * @param {string} [validationResponseValidationMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listScenarioMarketPricesFromUpload(scenarioMarketPricesFile: any, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, validationResponseIsValid?: boolean, validationResponseValidationMessage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListScenarioMarketPricesFromUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listScenarioMarketPricesFromUpload(scenarioMarketPricesFile, sortColumn, sortDirection, take, skip, validationResponseIsValid, validationResponseValidationMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWhatIfStrategies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWhatIfStrategyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWhatIfStrategies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBudgetsRequest} [updateBudgetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBudgets(updateBudgetsRequest?: UpdateBudgetsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBudgets(updateBudgetsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} commodityId 
         * @param {string} commodityName 
         * @param {Unit} displayUnit 
         * @param {number} displayPrecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommodity(commodityId: string, commodityName: string, displayUnit: Unit, displayPrecision: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCommodityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommodity(commodityId, commodityName, displayUnit, displayPrecision, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} counterpartyId 
         * @param {string} counterpartyName 
         * @param {number} [creditLimit] 
         * @param {boolean} [netting] 
         * @param {boolean} [setoff] 
         * @param {boolean} [active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCounterparty(counterpartyId: string, counterpartyName: string, creditLimit?: number, netting?: boolean, setoff?: boolean, active?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCounterpartyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCounterparty(counterpartyId, counterpartyName, creditLimit, netting, setoff, active, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerReportId 
         * @param {string} [overrides] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerReportUserOverrides(customerReportId: number, overrides?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerReportUserOverrides(customerReportId, overrides, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dealTicketId 
         * @param {Array<number>} [validatedPositionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDealTicketPositionValidation(dealTicketId: string, validatedPositionIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDealTicketPositionValidation(dealTicketId, validatedPositionIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} locationName 
         * @param {number} tierId 
         * @param {number} [parentLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(locationId: string, locationName: string, tierId: number, parentLocationId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateLocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocation(locationId, locationName, tierId, parentLocationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} noteId 
         * @param {string} subject 
         * @param {string} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNote(noteId: string, subject: string, content: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNote(noteId, subject, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} portfolioName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePortfolio(portfolioId: string, portfolioName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePortfolioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePortfolio(portfolioId, portfolioName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tierId 
         * @param {string} tierName 
         * @param {number} tierRank 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTier(tierId: string, tierName: string, tierRank: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateTierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTier(tierId, tierName, tierRank, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateWhatIfStrategyRequest} [updateWhatIfStrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWhatIfStrategy(updateWhatIfStrategyRequest?: UpdateWhatIfStrategyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateWhatIfStrategyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWhatIfStrategy(updateWhatIfStrategyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommodityManagementApi - factory interface
 * @export
 */
export const CommodityManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommodityManagementApiFp(configuration)
    return {
        /**
         * 
         * @param {string} commodityName 
         * @param {Unit} displayUnit 
         * @param {number} displayPrecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommodity(commodityName: string, displayUnit: Unit, displayPrecision: number, options?: any): AxiosPromise<AddCommodityResponse> {
            return localVarFp.addCommodity(commodityName, displayUnit, displayPrecision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [counterpartyName] 
         * @param {number} [creditLimit] 
         * @param {boolean} [netting] 
         * @param {boolean} [setoff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCounterparty(counterpartyName?: string, creditLimit?: number, netting?: boolean, setoff?: boolean, options?: any): AxiosPromise<AddCounterpartyResponse> {
            return localVarFp.addCounterparty(counterpartyName, creditLimit, netting, setoff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationName 
         * @param {number} tierId 
         * @param {number} [parentLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLocation(locationName: string, tierId: number, parentLocationId?: number, options?: any): AxiosPromise<AddLocationResponse> {
            return localVarFp.addLocation(locationName, tierId, parentLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddNoteRequest} [addNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNote(addNoteRequest?: AddNoteRequest, options?: any): AxiosPromise<AddNoteResponse> {
            return localVarFp.addNote(addNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPortfolio(portfolioName: string, options?: any): AxiosPromise<AddPortfolioResponse> {
            return localVarFp.addPortfolio(portfolioName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} tierRank 
         * @param {string} [tierName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTier(tierRank: number, tierName?: string, options?: any): AxiosPromise<AddTierResponse> {
            return localVarFp.addTier(tierRank, tierName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDealTicketRequest} [updateDealTicketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTrade(updateDealTicketRequest?: UpdateDealTicketRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addTrade(updateDealTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commodityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommodity(commodityId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCommodity(commodityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} counterpartyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCounterparty(counterpartyId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCounterparty(counterpartyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation(locationId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteLocation(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote(noteId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteNote(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePortfolio(portfolioId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deletePortfolio(portfolioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTier(tierId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteTier(tierId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} whatIfStrategyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhatIfStrategy(whatIfStrategyId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteWhatIfStrategy(whatIfStrategyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityType 
         * @param {string} entityId 
         * @param {boolean} [includeUnchangedFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTrail(entityType: string, entityId: string, includeUnchangedFields?: boolean, options?: any): AxiosPromise<GetAuditTrailResponse> {
            return localVarFp.getAuditTrail(entityType, entityId, includeUnchangedFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} [entityType] 
         * @param {boolean} [includeUnchangedFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBudgetAuditTrail(entityId: string, entityType?: string, includeUnchangedFields?: boolean, options?: any): AxiosPromise<GetAuditTrailResponse> {
            return localVarFp.getBudgetAuditTrail(entityId, entityType, includeUnchangedFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerData(options?: any): AxiosPromise<GetCustomerDataResponse> {
            return localVarFp.getCustomerData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerReports(options?: any): AxiosPromise<GetCustomerReportsResponse> {
            return localVarFp.getCustomerReports(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSettings(options?: any): AxiosPromise<GetCustomerSettingsResponse> {
            return localVarFp.getCustomerSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dealTicketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealTicket(dealTicketId: string, options?: any): AxiosPromise<GetDealTicketResponse> {
            return localVarFp.getDealTicket(dealTicketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {ExpiryStatus} [expiryStatus] 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkToMarketExcelReport(quoteDate: string, customerReportId: number, expiryStatus?: ExpiryStatus, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.getMarkToMarketExcelReport(quoteDate, customerReportId, expiryStatus, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, scenarioMarketPrices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {ExpiryStatus} [expiryStatus] 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkToMarketReport(quoteDate: string, customerReportId: number, expiryStatus?: ExpiryStatus, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, options?: any): AxiosPromise<GetMarkToMarketReportResponse> {
            return localVarFp.getMarkToMarketReport(quoteDate, customerReportId, expiryStatus, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, scenarioMarketPrices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} [entityType] 
         * @param {boolean} [includeUnchangedFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionAuditTrail(entityId: string, entityType?: string, includeUnchangedFields?: boolean, options?: any): AxiosPromise<GetAuditTrailResponse> {
            return localVarFp.getPositionAuditTrail(entityId, entityType, includeUnchangedFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Currency} [currency] 
         * @param {StressIncrementType} [stressIncrementType] 
         * @param {Array<number>} [stressIncrements] 
         * @param {Array<number>} [whatIfStrategyIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioExcelReport(quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, currency?: Currency, stressIncrementType?: StressIncrementType, stressIncrements?: Array<number>, whatIfStrategyIds?: Array<number>, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.getScenarioExcelReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, commoditiesFilter, currency, stressIncrementType, stressIncrements, whatIfStrategyIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioMarketPricesExcelTemplate(options?: any): AxiosPromise<FileResponse> {
            return localVarFp.getScenarioMarketPricesExcelTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Currency} [currency] 
         * @param {StressIncrementType} [stressIncrementType] 
         * @param {Array<number>} [stressIncrements] 
         * @param {Array<number>} [whatIfStrategyIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioReport(quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, currency?: Currency, stressIncrementType?: StressIncrementType, stressIncrements?: Array<number>, whatIfStrategyIds?: Array<number>, options?: any): AxiosPromise<GetScenarioReportResponse> {
            return localVarFp.getScenarioReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, commoditiesFilter, currency, stressIncrementType, stressIncrements, whatIfStrategyIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} numberOfMonths 
         * @param {number} minimumPercentage 
         * @param {number} maximumPercentage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeTriggerReport(quoteDate: string, numberOfMonths: number, minimumPercentage: number, maximumPercentage: number, options?: any): AxiosPromise<GetTimeTriggerReportResponse> {
            return localVarFp.getTimeTriggerReport(quoteDate, numberOfMonths, minimumPercentage, maximumPercentage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Currency} [currency] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVarianceExcelReport(quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, currency?: Currency, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<FileResponse> {
            return localVarFp.getVarianceExcelReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, currency, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {number} customerReportId 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Currency} [currency] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVarianceReport(quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, currency?: Currency, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<GetVarianceReportResponse> {
            return localVarFp.getVarianceReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, currency, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [locationId] 
         * @param {number} [commodityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBudgetYears(locationId?: number, commodityId?: number, options?: any): AxiosPromise<ListBudgetYearsResponse> {
            return localVarFp.listBudgetYears(locationId, commodityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [quoteDate] 
         * @param {number} [budgetStartMonthYear] 
         * @param {number} [budgetStartMonthMonth] 
         * @param {string} [budgetStartMonthLabel] 
         * @param {number} [budgetEndMonthYear] 
         * @param {number} [budgetEndMonthMonth] 
         * @param {string} [budgetEndMonthLabel] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Array<number>} [locationsFilter] 
         * @param {Array<number>} [counterpartiesFilter] 
         * @param {boolean} [includeExpirationDates] 
         * @param {boolean} [includePositions] 
         * @param {boolean} [includeMarketPrices] 
         * @param {Currency} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBudgets(quoteDate?: string, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, locationsFilter?: Array<number>, counterpartiesFilter?: Array<number>, includeExpirationDates?: boolean, includePositions?: boolean, includeMarketPrices?: boolean, currency?: Currency, options?: any): AxiosPromise<ListBudgetsResponse> {
            return localVarFp.listBudgets(quoteDate, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, commoditiesFilter, locationsFilter, counterpartiesFilter, includeExpirationDates, includePositions, includeMarketPrices, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotes(options?: any): AxiosPromise<ListNotesResponse> {
            return localVarFp.listNotes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [commodityId] 
         * @param {number} [locationId] 
         * @param {number} [counterpartyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPositionYears(commodityId?: number, locationId?: number, counterpartyId?: number, options?: any): AxiosPromise<ListPositionYearsResponse> {
            return localVarFp.listPositionYears(commodityId, locationId, counterpartyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [quoteDate] 
         * @param {boolean} [includeExpirationDates] 
         * @param {boolean} [includeMarketPrices] 
         * @param {boolean} [useDisplayUnits] 
         * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
         * @param {Array<number>} [commoditiesFilter] 
         * @param {Array<number>} [locationsFilter] 
         * @param {Array<number>} [counterpartiesFilter] 
         * @param {string} [tradeDateStartFilter] 
         * @param {string} [tradeDateEndFilter] 
         * @param {number} [budgetMonthStartFilterYear] 
         * @param {number} [budgetMonthStartFilterMonth] 
         * @param {string} [budgetMonthStartFilterLabel] 
         * @param {number} [budgetMonthEndFilterYear] 
         * @param {number} [budgetMonthEndFilterMonth] 
         * @param {string} [budgetMonthEndFilterLabel] 
         * @param {number} [contractMonthStartFilterYear] 
         * @param {number} [contractMonthStartFilterMonth] 
         * @param {string} [contractMonthStartFilterLabel] 
         * @param {number} [contractMonthEndFilterYear] 
         * @param {number} [contractMonthEndFilterMonth] 
         * @param {string} [contractMonthEndFilterLabel] 
         * @param {Array<PositionType>} [positionTypesFilter] 
         * @param {string} [dealTicketNumberFilter] 
         * @param {number} [dealTicketIdFilter] 
         * @param {Side} [sideFilter] 
         * @param {boolean} [validatedFilter] 
         * @param {Array<Currency>} [currencyFilter] 
         * @param {Currency} [currency] 
         * @param {ExpiryStatus} [expiryStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPositions(quoteDate?: string, includeExpirationDates?: boolean, includeMarketPrices?: boolean, useDisplayUnits?: boolean, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, commoditiesFilter?: Array<number>, locationsFilter?: Array<number>, counterpartiesFilter?: Array<number>, tradeDateStartFilter?: string, tradeDateEndFilter?: string, budgetMonthStartFilterYear?: number, budgetMonthStartFilterMonth?: number, budgetMonthStartFilterLabel?: string, budgetMonthEndFilterYear?: number, budgetMonthEndFilterMonth?: number, budgetMonthEndFilterLabel?: string, contractMonthStartFilterYear?: number, contractMonthStartFilterMonth?: number, contractMonthStartFilterLabel?: string, contractMonthEndFilterYear?: number, contractMonthEndFilterMonth?: number, contractMonthEndFilterLabel?: string, positionTypesFilter?: Array<PositionType>, dealTicketNumberFilter?: string, dealTicketIdFilter?: number, sideFilter?: Side, validatedFilter?: boolean, currencyFilter?: Array<Currency>, currency?: Currency, expiryStatus?: ExpiryStatus, options?: any): AxiosPromise<ListPositionsResponse> {
            return localVarFp.listPositions(quoteDate, includeExpirationDates, includeMarketPrices, useDisplayUnits, scenarioMarketPrices, commoditiesFilter, locationsFilter, counterpartiesFilter, tradeDateStartFilter, tradeDateEndFilter, budgetMonthStartFilterYear, budgetMonthStartFilterMonth, budgetMonthStartFilterLabel, budgetMonthEndFilterYear, budgetMonthEndFilterMonth, budgetMonthEndFilterLabel, contractMonthStartFilterYear, contractMonthStartFilterMonth, contractMonthStartFilterLabel, contractMonthEndFilterYear, contractMonthEndFilterMonth, contractMonthEndFilterLabel, positionTypesFilter, dealTicketNumberFilter, dealTicketIdFilter, sideFilter, validatedFilter, currencyFilter, currency, expiryStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPricingStrategies(options?: any): AxiosPromise<ListBudgetPricingStrategiesResponse> {
            return localVarFp.listPricingStrategies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} scenarioMarketPricesFile 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {boolean} [validationResponseIsValid] 
         * @param {string} [validationResponseValidationMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScenarioMarketPricesFromUpload(scenarioMarketPricesFile: any, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, validationResponseIsValid?: boolean, validationResponseValidationMessage?: string, options?: any): AxiosPromise<ListScenarioMarketPricesFromUploadResponse> {
            return localVarFp.listScenarioMarketPricesFromUpload(scenarioMarketPricesFile, sortColumn, sortDirection, take, skip, validationResponseIsValid, validationResponseValidationMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWhatIfStrategies(options?: any): AxiosPromise<ListWhatIfStrategyResponse> {
            return localVarFp.listWhatIfStrategies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBudgetsRequest} [updateBudgetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBudgets(updateBudgetsRequest?: UpdateBudgetsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateBudgets(updateBudgetsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commodityId 
         * @param {string} commodityName 
         * @param {Unit} displayUnit 
         * @param {number} displayPrecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommodity(commodityId: string, commodityName: string, displayUnit: Unit, displayPrecision: number, options?: any): AxiosPromise<UpdateCommodityResponse> {
            return localVarFp.updateCommodity(commodityId, commodityName, displayUnit, displayPrecision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} counterpartyId 
         * @param {string} counterpartyName 
         * @param {number} [creditLimit] 
         * @param {boolean} [netting] 
         * @param {boolean} [setoff] 
         * @param {boolean} [active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCounterparty(counterpartyId: string, counterpartyName: string, creditLimit?: number, netting?: boolean, setoff?: boolean, active?: boolean, options?: any): AxiosPromise<UpdateCounterpartyResponse> {
            return localVarFp.updateCounterparty(counterpartyId, counterpartyName, creditLimit, netting, setoff, active, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerReportId 
         * @param {string} [overrides] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerReportUserOverrides(customerReportId: number, overrides?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateCustomerReportUserOverrides(customerReportId, overrides, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dealTicketId 
         * @param {Array<number>} [validatedPositionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDealTicketPositionValidation(dealTicketId: string, validatedPositionIds?: Array<number>, options?: any): AxiosPromise<object> {
            return localVarFp.updateDealTicketPositionValidation(dealTicketId, validatedPositionIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {string} locationName 
         * @param {number} tierId 
         * @param {number} [parentLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(locationId: string, locationName: string, tierId: number, parentLocationId?: number, options?: any): AxiosPromise<UpdateLocationResponse> {
            return localVarFp.updateLocation(locationId, locationName, tierId, parentLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} noteId 
         * @param {string} subject 
         * @param {string} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote(noteId: string, subject: string, content: string, options?: any): AxiosPromise<UpdateNoteResponse> {
            return localVarFp.updateNote(noteId, subject, content, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} portfolioId 
         * @param {string} portfolioName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePortfolio(portfolioId: string, portfolioName: string, options?: any): AxiosPromise<UpdatePortfolioResponse> {
            return localVarFp.updatePortfolio(portfolioId, portfolioName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tierId 
         * @param {string} tierName 
         * @param {number} tierRank 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTier(tierId: string, tierName: string, tierRank: number, options?: any): AxiosPromise<UpdateTierResponse> {
            return localVarFp.updateTier(tierId, tierName, tierRank, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWhatIfStrategyRequest} [updateWhatIfStrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhatIfStrategy(updateWhatIfStrategyRequest?: UpdateWhatIfStrategyRequest, options?: any): AxiosPromise<UpdateWhatIfStrategyResponse> {
            return localVarFp.updateWhatIfStrategy(updateWhatIfStrategyRequest, options).then((request) => request(axios, basePath));
        },
    };
};



/**
 * CommodityManagementApi - object-oriented interface
 * @export
 * @class CommodityManagementApi
 * @extends {BaseAPI}
 */
export class CommodityManagementApi extends BaseAPI {
    /**
     * 
     * @param {string} commodityName 
     * @param {Unit} displayUnit 
     * @param {number} displayPrecision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public addCommodity(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), commodityName: string, displayUnit: Unit, displayPrecision: number, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).addCommodity(commodityName, displayUnit, displayPrecision, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} [counterpartyName] 
     * @param {number} [creditLimit] 
     * @param {boolean} [netting] 
     * @param {boolean} [setoff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public addCounterparty(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), counterpartyName?: string, creditLimit?: number, netting?: boolean, setoff?: boolean, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).addCounterparty(counterpartyName, creditLimit, netting, setoff, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} locationName 
     * @param {number} tierId 
     * @param {number} [parentLocationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public addLocation(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), locationName: string, tierId: number, parentLocationId?: number, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).addLocation(locationName, tierId, parentLocationId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {AddNoteRequest} [addNoteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public addNote(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), addNoteRequest?: AddNoteRequest, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).addNote(addNoteRequest, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} portfolioName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public addPortfolio(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), portfolioName: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).addPortfolio(portfolioName, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {number} tierRank 
     * @param {string} [tierName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public addTier(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), tierRank: number, tierName?: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).addTier(tierRank, tierName, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {UpdateDealTicketRequest} [updateDealTicketRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public addTrade(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), updateDealTicketRequest?: UpdateDealTicketRequest, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).addTrade(updateDealTicketRequest, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} commodityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public deleteCommodity(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), commodityId: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).deleteCommodity(commodityId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} counterpartyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public deleteCounterparty(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), counterpartyId: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).deleteCounterparty(counterpartyId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public deleteLocation(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), locationId: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).deleteLocation(locationId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public deleteNote(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), noteId: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).deleteNote(noteId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public deletePortfolio(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), portfolioId: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).deletePortfolio(portfolioId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} tierId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public deleteTier(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), tierId: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).deleteTier(tierId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} whatIfStrategyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public deleteWhatIfStrategy(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), whatIfStrategyId: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).deleteWhatIfStrategy(whatIfStrategyId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} entityType 
     * @param {string} entityId 
     * @param {boolean} [includeUnchangedFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getAuditTrail(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), entityType: string, entityId: string, includeUnchangedFields?: boolean, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getAuditTrail(entityType, entityId, includeUnchangedFields, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} entityId 
     * @param {string} [entityType] 
     * @param {boolean} [includeUnchangedFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getBudgetAuditTrail(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), entityId: string, entityType?: string, includeUnchangedFields?: boolean, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getBudgetAuditTrail(entityId, entityType, includeUnchangedFields, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getCustomerData(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getCustomerData(options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getCustomerReports(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getCustomerReports(options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getCustomerSettings(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getCustomerSettings(options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} dealTicketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getDealTicket(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), dealTicketId: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getDealTicket(dealTicketId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} quoteDate 
     * @param {number} customerReportId 
     * @param {ExpiryStatus} [expiryStatus] 
     * @param {number} [budgetStartMonthYear] 
     * @param {number} [budgetStartMonthMonth] 
     * @param {string} [budgetStartMonthLabel] 
     * @param {number} [budgetEndMonthYear] 
     * @param {number} [budgetEndMonthMonth] 
     * @param {string} [budgetEndMonthLabel] 
     * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getMarkToMarketExcelReport(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), quoteDate: string, customerReportId: number, expiryStatus?: ExpiryStatus, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getMarkToMarketExcelReport(quoteDate, customerReportId, expiryStatus, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, scenarioMarketPrices, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} quoteDate 
     * @param {number} customerReportId 
     * @param {ExpiryStatus} [expiryStatus] 
     * @param {number} [budgetStartMonthYear] 
     * @param {number} [budgetStartMonthMonth] 
     * @param {string} [budgetStartMonthLabel] 
     * @param {number} [budgetEndMonthYear] 
     * @param {number} [budgetEndMonthMonth] 
     * @param {string} [budgetEndMonthLabel] 
     * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getMarkToMarketReport(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), quoteDate: string, customerReportId: number, expiryStatus?: ExpiryStatus, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getMarkToMarketReport(quoteDate, customerReportId, expiryStatus, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, scenarioMarketPrices, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} entityId 
     * @param {string} [entityType] 
     * @param {boolean} [includeUnchangedFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getPositionAuditTrail(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), entityId: string, entityType?: string, includeUnchangedFields?: boolean, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getPositionAuditTrail(entityId, entityType, includeUnchangedFields, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} quoteDate 
     * @param {number} customerReportId 
     * @param {number} [budgetStartMonthYear] 
     * @param {number} [budgetStartMonthMonth] 
     * @param {string} [budgetStartMonthLabel] 
     * @param {number} [budgetEndMonthYear] 
     * @param {number} [budgetEndMonthMonth] 
     * @param {string} [budgetEndMonthLabel] 
     * @param {Array<number>} [commoditiesFilter] 
     * @param {Currency} [currency] 
     * @param {StressIncrementType} [stressIncrementType] 
     * @param {Array<number>} [stressIncrements] 
     * @param {Array<number>} [whatIfStrategyIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getScenarioExcelReport(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, currency?: Currency, stressIncrementType?: StressIncrementType, stressIncrements?: Array<number>, whatIfStrategyIds?: Array<number>, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getScenarioExcelReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, commoditiesFilter, currency, stressIncrementType, stressIncrements, whatIfStrategyIds, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getScenarioMarketPricesExcelTemplate(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getScenarioMarketPricesExcelTemplate(options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} quoteDate 
     * @param {number} customerReportId 
     * @param {number} [budgetStartMonthYear] 
     * @param {number} [budgetStartMonthMonth] 
     * @param {string} [budgetStartMonthLabel] 
     * @param {number} [budgetEndMonthYear] 
     * @param {number} [budgetEndMonthMonth] 
     * @param {string} [budgetEndMonthLabel] 
     * @param {Array<number>} [commoditiesFilter] 
     * @param {Currency} [currency] 
     * @param {StressIncrementType} [stressIncrementType] 
     * @param {Array<number>} [stressIncrements] 
     * @param {Array<number>} [whatIfStrategyIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getScenarioReport(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, currency?: Currency, stressIncrementType?: StressIncrementType, stressIncrements?: Array<number>, whatIfStrategyIds?: Array<number>, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getScenarioReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, commoditiesFilter, currency, stressIncrementType, stressIncrements, whatIfStrategyIds, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} quoteDate 
     * @param {number} numberOfMonths 
     * @param {number} minimumPercentage 
     * @param {number} maximumPercentage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getTimeTriggerReport(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), quoteDate: string, numberOfMonths: number, minimumPercentage: number, maximumPercentage: number, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getTimeTriggerReport(quoteDate, numberOfMonths, minimumPercentage, maximumPercentage, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} quoteDate 
     * @param {number} customerReportId 
     * @param {number} [budgetStartMonthYear] 
     * @param {number} [budgetStartMonthMonth] 
     * @param {string} [budgetStartMonthLabel] 
     * @param {number} [budgetEndMonthYear] 
     * @param {number} [budgetEndMonthMonth] 
     * @param {string} [budgetEndMonthLabel] 
     * @param {Currency} [currency] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getVarianceExcelReport(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, currency?: Currency, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getVarianceExcelReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, currency, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} quoteDate 
     * @param {number} customerReportId 
     * @param {number} [budgetStartMonthYear] 
     * @param {number} [budgetStartMonthMonth] 
     * @param {string} [budgetStartMonthLabel] 
     * @param {number} [budgetEndMonthYear] 
     * @param {number} [budgetEndMonthMonth] 
     * @param {string} [budgetEndMonthLabel] 
     * @param {Currency} [currency] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public getVarianceReport(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), quoteDate: string, customerReportId: number, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, currency?: Currency, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).getVarianceReport(quoteDate, customerReportId, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, currency, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {number} [locationId] 
     * @param {number} [commodityId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public listBudgetYears(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), locationId?: number, commodityId?: number, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).listBudgetYears(locationId, commodityId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} [quoteDate] 
     * @param {number} [budgetStartMonthYear] 
     * @param {number} [budgetStartMonthMonth] 
     * @param {string} [budgetStartMonthLabel] 
     * @param {number} [budgetEndMonthYear] 
     * @param {number} [budgetEndMonthMonth] 
     * @param {string} [budgetEndMonthLabel] 
     * @param {Array<number>} [commoditiesFilter] 
     * @param {Array<number>} [locationsFilter] 
     * @param {Array<number>} [counterpartiesFilter] 
     * @param {boolean} [includeExpirationDates] 
     * @param {boolean} [includePositions] 
     * @param {boolean} [includeMarketPrices] 
     * @param {Currency} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public listBudgets(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), quoteDate?: string, budgetStartMonthYear?: number, budgetStartMonthMonth?: number, budgetStartMonthLabel?: string, budgetEndMonthYear?: number, budgetEndMonthMonth?: number, budgetEndMonthLabel?: string, commoditiesFilter?: Array<number>, locationsFilter?: Array<number>, counterpartiesFilter?: Array<number>, includeExpirationDates?: boolean, includePositions?: boolean, includeMarketPrices?: boolean, currency?: Currency, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).listBudgets(quoteDate, budgetStartMonthYear, budgetStartMonthMonth, budgetStartMonthLabel, budgetEndMonthYear, budgetEndMonthMonth, budgetEndMonthLabel, commoditiesFilter, locationsFilter, counterpartiesFilter, includeExpirationDates, includePositions, includeMarketPrices, currency, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public listNotes(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).listNotes(options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {number} [commodityId] 
     * @param {number} [locationId] 
     * @param {number} [counterpartyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public listPositionYears(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), commodityId?: number, locationId?: number, counterpartyId?: number, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).listPositionYears(commodityId, locationId, counterpartyId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} [quoteDate] 
     * @param {boolean} [includeExpirationDates] 
     * @param {boolean} [includeMarketPrices] 
     * @param {boolean} [useDisplayUnits] 
     * @param {Array<ScenarioMarketPriceModel>} [scenarioMarketPrices] 
     * @param {Array<number>} [commoditiesFilter] 
     * @param {Array<number>} [locationsFilter] 
     * @param {Array<number>} [counterpartiesFilter] 
     * @param {string} [tradeDateStartFilter] 
     * @param {string} [tradeDateEndFilter] 
     * @param {number} [budgetMonthStartFilterYear] 
     * @param {number} [budgetMonthStartFilterMonth] 
     * @param {string} [budgetMonthStartFilterLabel] 
     * @param {number} [budgetMonthEndFilterYear] 
     * @param {number} [budgetMonthEndFilterMonth] 
     * @param {string} [budgetMonthEndFilterLabel] 
     * @param {number} [contractMonthStartFilterYear] 
     * @param {number} [contractMonthStartFilterMonth] 
     * @param {string} [contractMonthStartFilterLabel] 
     * @param {number} [contractMonthEndFilterYear] 
     * @param {number} [contractMonthEndFilterMonth] 
     * @param {string} [contractMonthEndFilterLabel] 
     * @param {Array<PositionType>} [positionTypesFilter] 
     * @param {string} [dealTicketNumberFilter] 
     * @param {number} [dealTicketIdFilter] 
     * @param {Side} [sideFilter] 
     * @param {boolean} [validatedFilter] 
     * @param {Array<Currency>} [currencyFilter] 
     * @param {Currency} [currency] 
     * @param {ExpiryStatus} [expiryStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public listPositions(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), quoteDate?: string, includeExpirationDates?: boolean, includeMarketPrices?: boolean, useDisplayUnits?: boolean, scenarioMarketPrices?: Array<ScenarioMarketPriceModel>, commoditiesFilter?: Array<number>, locationsFilter?: Array<number>, counterpartiesFilter?: Array<number>, tradeDateStartFilter?: string, tradeDateEndFilter?: string, budgetMonthStartFilterYear?: number, budgetMonthStartFilterMonth?: number, budgetMonthStartFilterLabel?: string, budgetMonthEndFilterYear?: number, budgetMonthEndFilterMonth?: number, budgetMonthEndFilterLabel?: string, contractMonthStartFilterYear?: number, contractMonthStartFilterMonth?: number, contractMonthStartFilterLabel?: string, contractMonthEndFilterYear?: number, contractMonthEndFilterMonth?: number, contractMonthEndFilterLabel?: string, positionTypesFilter?: Array<PositionType>, dealTicketNumberFilter?: string, dealTicketIdFilter?: number, sideFilter?: Side, validatedFilter?: boolean, currencyFilter?: Array<Currency>, currency?: Currency, expiryStatus?: ExpiryStatus, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).listPositions(quoteDate, includeExpirationDates, includeMarketPrices, useDisplayUnits, scenarioMarketPrices, commoditiesFilter, locationsFilter, counterpartiesFilter, tradeDateStartFilter, tradeDateEndFilter, budgetMonthStartFilterYear, budgetMonthStartFilterMonth, budgetMonthStartFilterLabel, budgetMonthEndFilterYear, budgetMonthEndFilterMonth, budgetMonthEndFilterLabel, contractMonthStartFilterYear, contractMonthStartFilterMonth, contractMonthStartFilterLabel, contractMonthEndFilterYear, contractMonthEndFilterMonth, contractMonthEndFilterLabel, positionTypesFilter, dealTicketNumberFilter, dealTicketIdFilter, sideFilter, validatedFilter, currencyFilter, currency, expiryStatus, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public listPricingStrategies(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).listPricingStrategies(options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {any} scenarioMarketPricesFile 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {boolean} [validationResponseIsValid] 
     * @param {string} [validationResponseValidationMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public listScenarioMarketPricesFromUpload(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), scenarioMarketPricesFile: any, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, validationResponseIsValid?: boolean, validationResponseValidationMessage?: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).listScenarioMarketPricesFromUpload(scenarioMarketPricesFile, sortColumn, sortDirection, take, skip, validationResponseIsValid, validationResponseValidationMessage, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public listWhatIfStrategies(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).listWhatIfStrategies(options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {UpdateBudgetsRequest} [updateBudgetsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public updateBudgets(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), updateBudgetsRequest?: UpdateBudgetsRequest, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).updateBudgets(updateBudgetsRequest, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} commodityId 
     * @param {string} commodityName 
     * @param {Unit} displayUnit 
     * @param {number} displayPrecision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public updateCommodity(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), commodityId: string, commodityName: string, displayUnit: Unit, displayPrecision: number, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).updateCommodity(commodityId, commodityName, displayUnit, displayPrecision, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} counterpartyId 
     * @param {string} counterpartyName 
     * @param {number} [creditLimit] 
     * @param {boolean} [netting] 
     * @param {boolean} [setoff] 
     * @param {boolean} [active] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public updateCounterparty(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), counterpartyId: string, counterpartyName: string, creditLimit?: number, netting?: boolean, setoff?: boolean, active?: boolean, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).updateCounterparty(counterpartyId, counterpartyName, creditLimit, netting, setoff, active, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {number} customerReportId 
     * @param {string} [overrides] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public updateCustomerReportUserOverrides(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), customerReportId: number, overrides?: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).updateCustomerReportUserOverrides(customerReportId, overrides, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} dealTicketId 
     * @param {Array<number>} [validatedPositionIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public updateDealTicketPositionValidation(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), dealTicketId: string, validatedPositionIds?: Array<number>, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).updateDealTicketPositionValidation(dealTicketId, validatedPositionIds, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} locationId 
     * @param {string} locationName 
     * @param {number} tierId 
     * @param {number} [parentLocationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public updateLocation(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), locationId: string, locationName: string, tierId: number, parentLocationId?: number, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).updateLocation(locationId, locationName, tierId, parentLocationId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} noteId 
     * @param {string} subject 
     * @param {string} content 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public updateNote(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), noteId: string, subject: string, content: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).updateNote(noteId, subject, content, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} portfolioId 
     * @param {string} portfolioName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public updatePortfolio(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), portfolioId: string, portfolioName: string, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).updatePortfolio(portfolioId, portfolioName, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} tierId 
     * @param {string} tierName 
     * @param {number} tierRank 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public updateTier(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), tierId: string, tierName: string, tierRank: number, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).updateTier(tierId, tierName, tierRank, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {UpdateWhatIfStrategyRequest} [updateWhatIfStrategyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommodityManagementApi
     */
    public updateWhatIfStrategy(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), updateWhatIfStrategyRequest?: UpdateWhatIfStrategyRequest, options?: AxiosRequestConfig) {
        const promise = CommodityManagementApiFp(this.configuration).updateWhatIfStrategy(updateWhatIfStrategyRequest, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationHealth(options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.getApplicationHealth(options).then((request) => request(axios, basePath));
        },
    };
};



/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public getApplicationHealth(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), options?: AxiosRequestConfig) {
        const promise = HealthApiFp(this.configuration).getApplicationHealth(options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }
}


/**
 * MarketViewApi - axios parameter creator
 * @export
 */
export const MarketViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [siteGuid] 
         * @param {string} [siteName] 
         * @param {string} [customerName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommodityManagementSite: async (siteGuid?: string, siteName?: string, customerName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-view/commodity-management/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (siteGuid !== undefined) {
                localVarQueryParameter['SiteGuid'] = siteGuid;
            }

            if (siteName !== undefined) {
                localVarQueryParameter['SiteName'] = siteName;
            }

            if (customerName !== undefined) {
                localVarQueryParameter['CustomerName'] = customerName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {string} userGuid 
         * @param {string} [email] 
         * @param {boolean} [isSiteManager] 
         * @param {Array<CommodityManagementUserPermissions>} [permissions] 
         * @param {boolean} [accessDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommodityManagementUserSiteAccess: async (siteGuid: string, userGuid: string, email?: string, isSiteManager?: boolean, permissions?: Array<CommodityManagementUserPermissions>, accessDisabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteGuid' is not null or undefined
            assertParamExists('addCommodityManagementUserSiteAccess', 'siteGuid', siteGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('addCommodityManagementUserSiteAccess', 'userGuid', userGuid)
            const localVarPath = `/market-view/commodity-management/sites/{siteGuid}/{userGuid}`
                .replace(`{${"siteGuid"}}`, encodeURIComponent(String(siteGuid)))
                .replace(`{${"userGuid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (isSiteManager !== undefined) {
                localVarQueryParameter['IsSiteManager'] = isSiteManager;
            }

            if (permissions) {
                localVarQueryParameter['Permissions'] = permissions;
            }

            if (accessDisabled !== undefined) {
                localVarQueryParameter['AccessDisabled'] = accessDisabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} savedQueryId 
         * @param {string} name 
         * @param {Array<MarkItViewQuerySavedParameters>} markItViewQuerySavedParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketViewSavedQuery: async (savedQueryId: number, name: string, markItViewQuerySavedParameters: Array<MarkItViewQuerySavedParameters>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'savedQueryId' is not null or undefined
            assertParamExists('addMarketViewSavedQuery', 'savedQueryId', savedQueryId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addMarketViewSavedQuery', 'name', name)
            // verify required parameter 'markItViewQuerySavedParameters' is not null or undefined
            assertParamExists('addMarketViewSavedQuery', 'markItViewQuerySavedParameters', markItViewQuerySavedParameters)
            const localVarPath = `/market-view/saved-queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (savedQueryId !== undefined) {
                localVarQueryParameter['SavedQueryId'] = savedQueryId;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markItViewQuerySavedParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [stoneXUserGuid] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {Array<MarketViewApplication>} [userSolutionsAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketViewUser: async (email: string, stoneXUserGuid?: string, firstName?: string, lastName?: string, userSolutionsAccess?: Array<MarketViewApplication>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('addMarketViewUser', 'email', email)
            const localVarPath = `/market-view/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (stoneXUserGuid !== undefined) {
                localVarQueryParameter['StoneXUserGuid'] = stoneXUserGuid;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['FirstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['LastName'] = lastName;
            }

            if (userSolutionsAccess) {
                localVarQueryParameter['UserSolutionsAccess'] = userSolutionsAccess;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {AddMarketViewUserFavoritedProductRequest} [addMarketViewUserFavoritedProductRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketViewUserFavoritedProduct: async (productId: string, addMarketViewUserFavoritedProductRequest?: AddMarketViewUserFavoritedProductRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('addMarketViewUserFavoritedProduct', 'productId', productId)
            const localVarPath = `/market-view/products/{productId}/favorites`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMarketViewUserFavoritedProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userVolumeId 
         * @param {string} name 
         * @param {{ [key: string]: number; }} volumes 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketViewVolume: async (userVolumeId: number, name: string, volumes: { [key: string]: number; }, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userVolumeId' is not null or undefined
            assertParamExists('addMarketViewVolume', 'userVolumeId', userVolumeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addMarketViewVolume', 'name', name)
            // verify required parameter 'volumes' is not null or undefined
            assertParamExists('addMarketViewVolume', 'volumes', volumes)
            const localVarPath = `/market-view/volumes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userVolumeId !== undefined) {
                localVarQueryParameter['UserVolumeId'] = userVolumeId;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (volumes !== undefined) {
                localVarQueryParameter['Volumes'] = volumes;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AgreeToMarketViewTermsAndConditionsRequest} [agreeToMarketViewTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToDemeterTermsAndConditions: async (agreeToMarketViewTermsAndConditionsRequest?: AgreeToMarketViewTermsAndConditionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-view/users/terms-and-conditions/agree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreeToMarketViewTermsAndConditionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {string} userGuid 
         * @param {string} commodityManagementUserGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommodityManagementUserSiteAccess: async (siteGuid: string, userGuid: string, commodityManagementUserGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteGuid' is not null or undefined
            assertParamExists('deleteCommodityManagementUserSiteAccess', 'siteGuid', siteGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('deleteCommodityManagementUserSiteAccess', 'userGuid', userGuid)
            // verify required parameter 'commodityManagementUserGuid' is not null or undefined
            assertParamExists('deleteCommodityManagementUserSiteAccess', 'commodityManagementUserGuid', commodityManagementUserGuid)
            const localVarPath = `/market-view/commodity-management/sites/{siteGuid}/{userGuid}`
                .replace(`{${"siteGuid"}}`, encodeURIComponent(String(siteGuid)))
                .replace(`{${"userGuid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commodityManagementUserGuid !== undefined) {
                localVarQueryParameter['CommodityManagementUserGuid'] = commodityManagementUserGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarketViewUserFavoritedProduct: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteMarketViewUserFavoritedProduct', 'productId', productId)
            const localVarPath = `/market-view/products/{productId}/favorites`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDecisiveBasisPrices: async (startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getDecisiveBasisPrices', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getDecisiveBasisPrices', 'endDate', endDate)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getDecisiveBasisPrices', 'commodity', commodity)
            const localVarPath = `/market-view/decisive/mfmbasisprices/{startDate}/{endDate}/{commodity}`
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (markToMarketDate !== undefined) {
                localVarQueryParameter['MarkToMarketDate'] = (markToMarketDate as any instanceof Date) ?
                    (markToMarketDate as any).toISOString() :
                    markToMarketDate;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDecisiveFutureBasisPricesRangeByCommodity: async (startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getDecisiveFutureBasisPricesRangeByCommodity', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getDecisiveFutureBasisPricesRangeByCommodity', 'endDate', endDate)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getDecisiveFutureBasisPricesRangeByCommodity', 'commodity', commodity)
            const localVarPath = `/market-view/decisive/mfmfuturesbasisprices/{startDate}/{endDate}/{commodity}`
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (markToMarketDate !== undefined) {
                localVarQueryParameter['MarkToMarketDate'] = (markToMarketDate as any instanceof Date) ?
                    (markToMarketDate as any).toISOString() :
                    markToMarketDate;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDecisiveFuturePricesRangeByCommodity: async (startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getDecisiveFuturePricesRangeByCommodity', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getDecisiveFuturePricesRangeByCommodity', 'endDate', endDate)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getDecisiveFuturePricesRangeByCommodity', 'commodity', commodity)
            const localVarPath = `/market-view/decisive/mfmfuturesprices/{startDate}/{endDate}/{commodity}`
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (markToMarketDate !== undefined) {
                localVarQueryParameter['MarkToMarketDate'] = (markToMarketDate as any instanceof Date) ?
                    (markToMarketDate as any).toISOString() :
                    markToMarketDate;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDecisiveSpecialtyCropPricesRangeByCommodity: async (startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getDecisiveSpecialtyCropPricesRangeByCommodity', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getDecisiveSpecialtyCropPricesRangeByCommodity', 'endDate', endDate)
            // verify required parameter 'commodity' is not null or undefined
            assertParamExists('getDecisiveSpecialtyCropPricesRangeByCommodity', 'commodity', commodity)
            const localVarPath = `/market-view/decisive/mfmspecialtycrops/{startDate}/{endDate}/{commodity}`
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)))
                .replace(`{${"commodity"}}`, encodeURIComponent(String(commodity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (markToMarketDate !== undefined) {
                localVarQueryParameter['MarkToMarketDate'] = (markToMarketDate as any instanceof Date) ?
                    (markToMarketDate as any).toISOString() :
                    markToMarketDate;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} symbolId 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewLastQuoteDate: async (symbolId: string, asOfDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'symbolId' is not null or undefined
            assertParamExists('getMarketViewLastQuoteDate', 'symbolId', symbolId)
            const localVarPath = `/market-view/prices/{symbolId}/last-quote-dates`
                .replace(`{${"symbolId"}}`, encodeURIComponent(String(symbolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (asOfDate !== undefined) {
                localVarQueryParameter['AsOfDate'] = (asOfDate as any instanceof Date) ?
                    (asOfDate as any).toISOString() :
                    asOfDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketViewUserGuid 
         * @param {string} [stoneXUserGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewUser: async (marketViewUserGuid: string, stoneXUserGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewUserGuid' is not null or undefined
            assertParamExists('getMarketViewUser', 'marketViewUserGuid', marketViewUserGuid)
            const localVarPath = `/market-view/users/{marketViewUserGuid}`
                .replace(`{${"marketViewUserGuid"}}`, encodeURIComponent(String(marketViewUserGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stoneXUserGuid !== undefined) {
                localVarQueryParameter['StoneXUserGuid'] = stoneXUserGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityManagementSiteUsers: async (siteGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteGuid' is not null or undefined
            assertParamExists('listCommodityManagementSiteUsers', 'siteGuid', siteGuid)
            const localVarPath = `/market-view/commodity-management/sites/{siteGuid}/users`
                .replace(`{${"siteGuid"}}`, encodeURIComponent(String(siteGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityManagementSites: async (sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-view/commodity-management/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {Array<Currency>} currencies 
         * @param {Array<ContractDate>} contractMonths 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewCurrencySpotRates: async (quoteDate: string, currencies: Array<Currency>, contractMonths: Array<ContractDate>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quoteDate' is not null or undefined
            assertParamExists('listMarketViewCurrencySpotRates', 'quoteDate', quoteDate)
            // verify required parameter 'currencies' is not null or undefined
            assertParamExists('listMarketViewCurrencySpotRates', 'currencies', currencies)
            // verify required parameter 'contractMonths' is not null or undefined
            assertParamExists('listMarketViewCurrencySpotRates', 'contractMonths', contractMonths)
            const localVarPath = `/market-view/prices/spot-rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quoteDate !== undefined) {
                localVarQueryParameter['QuoteDate'] = (quoteDate as any instanceof Date) ?
                    (quoteDate as any).toISOString() :
                    quoteDate;
            }

            if (currencies) {
                localVarQueryParameter['Currencies'] = currencies;
            }

            if (contractMonths) {
                localVarQueryParameter['ContractMonths'] = contractMonths;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListMarketViewExpirationDatesRequest} [listMarketViewExpirationDatesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewExpirationDates: async (listMarketViewExpirationDatesRequest?: ListMarketViewExpirationDatesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-view/expiration-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listMarketViewExpirationDatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} quoteDate 
         * @param {number} [numberOfMonths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewForwardCurveQuery: async (productId: string, quoteDate: string, numberOfMonths?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('listMarketViewForwardCurveQuery', 'productId', productId)
            // verify required parameter 'quoteDate' is not null or undefined
            assertParamExists('listMarketViewForwardCurveQuery', 'quoteDate', quoteDate)
            const localVarPath = `/market-view/prices/{productId}/forward-curve`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quoteDate !== undefined) {
                localVarQueryParameter['QuoteDate'] = (quoteDate as any instanceof Date) ?
                    (quoteDate as any).toISOString() :
                    quoteDate;
            }

            if (numberOfMonths !== undefined) {
                localVarQueryParameter['NumberOfMonths'] = numberOfMonths;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} symbolIds 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewLastQuoteDate: async (symbolIds: Array<number>, asOfDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'symbolIds' is not null or undefined
            assertParamExists('listMarketViewLastQuoteDate', 'symbolIds', symbolIds)
            const localVarPath = `/market-view/prices/last-quote-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbolIds) {
                localVarQueryParameter['SymbolIds'] = symbolIds;
            }

            if (asOfDate !== undefined) {
                localVarQueryParameter['AsOfDate'] = (asOfDate as any instanceof Date) ?
                    (asOfDate as any).toISOString() :
                    asOfDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {Array<number>} years 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {number} [userVolumeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewPriceQueryCalendarStripAvearge: async (productId: string, years: Array<number>, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, userVolumeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('listMarketViewPriceQueryCalendarStripAvearge', 'productId', productId)
            // verify required parameter 'years' is not null or undefined
            assertParamExists('listMarketViewPriceQueryCalendarStripAvearge', 'years', years)
            const localVarPath = `/market-view/prices/{productId}/calendar-strip-average`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quoteDateRangeStart !== undefined) {
                localVarQueryParameter['QuoteDateRange.Start'] = (quoteDateRangeStart as any instanceof Date) ?
                    (quoteDateRangeStart as any).toISOString().substr(0,10) :
                    quoteDateRangeStart;
            }

            if (quoteDateRangeEnd !== undefined) {
                localVarQueryParameter['QuoteDateRange.End'] = (quoteDateRangeEnd as any instanceof Date) ?
                    (quoteDateRangeEnd as any).toISOString().substr(0,10) :
                    quoteDateRangeEnd;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (userVolumeId !== undefined) {
                localVarQueryParameter['UserVolumeId'] = userVolumeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} monthsAhead 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewPriceQueryRollingMonthAhead: async (productId: string, monthsAhead: number, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('listMarketViewPriceQueryRollingMonthAhead', 'productId', productId)
            // verify required parameter 'monthsAhead' is not null or undefined
            assertParamExists('listMarketViewPriceQueryRollingMonthAhead', 'monthsAhead', monthsAhead)
            const localVarPath = `/market-view/prices/{productId}/rolling-month-ahead`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quoteDateRangeStart !== undefined) {
                localVarQueryParameter['QuoteDateRange.Start'] = (quoteDateRangeStart as any instanceof Date) ?
                    (quoteDateRangeStart as any).toISOString().substr(0,10) :
                    quoteDateRangeStart;
            }

            if (quoteDateRangeEnd !== undefined) {
                localVarQueryParameter['QuoteDateRange.End'] = (quoteDateRangeEnd as any instanceof Date) ?
                    (quoteDateRangeEnd as any).toISOString().substr(0,10) :
                    quoteDateRangeEnd;
            }

            if (monthsAhead !== undefined) {
                localVarQueryParameter['MonthsAhead'] = monthsAhead;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {Array<number>} years 
         * @param {Array<number>} months 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewPrices: async (productId: string, years: Array<number>, months: Array<number>, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('listMarketViewPrices', 'productId', productId)
            // verify required parameter 'years' is not null or undefined
            assertParamExists('listMarketViewPrices', 'years', years)
            // verify required parameter 'months' is not null or undefined
            assertParamExists('listMarketViewPrices', 'months', months)
            const localVarPath = `/market-view/prices/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quoteDateRangeStart !== undefined) {
                localVarQueryParameter['QuoteDateRange.Start'] = (quoteDateRangeStart as any instanceof Date) ?
                    (quoteDateRangeStart as any).toISOString().substr(0,10) :
                    quoteDateRangeStart;
            }

            if (quoteDateRangeEnd !== undefined) {
                localVarQueryParameter['QuoteDateRange.End'] = (quoteDateRangeEnd as any instanceof Date) ?
                    (quoteDateRangeEnd as any).toISOString().substr(0,10) :
                    quoteDateRangeEnd;
            }

            if (years) {
                localVarQueryParameter['Years'] = years;
            }

            if (months) {
                localVarQueryParameter['Months'] = months;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} symbolId 
         * @param {string} contractYear 
         * @param {string} contractMonth 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewPricesBySymbol: async (symbolId: string, contractYear: string, contractMonth: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'symbolId' is not null or undefined
            assertParamExists('listMarketViewPricesBySymbol', 'symbolId', symbolId)
            // verify required parameter 'contractYear' is not null or undefined
            assertParamExists('listMarketViewPricesBySymbol', 'contractYear', contractYear)
            // verify required parameter 'contractMonth' is not null or undefined
            assertParamExists('listMarketViewPricesBySymbol', 'contractMonth', contractMonth)
            const localVarPath = `/market-view/prices/{symbolId}/{contractYear}/{contractMonth}`
                .replace(`{${"symbolId"}}`, encodeURIComponent(String(symbolId)))
                .replace(`{${"contractYear"}}`, encodeURIComponent(String(contractYear)))
                .replace(`{${"contractMonth"}}`, encodeURIComponent(String(contractMonth)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {number} [contractDateRangeStartYear] 
         * @param {number} [contractDateRangeStartMonth] 
         * @param {number} [contractDateRangeEndYear] 
         * @param {number} [contractDateRangeEndMonth] 
         * @param {number} [contractDateRangeStartContractDateYear] 
         * @param {number} [contractDateRangeStartContractDateMonth] 
         * @param {string} [contractDateRangeStartContractDateLabel] 
         * @param {number} [contractDateRangeEndContractDateYear] 
         * @param {number} [contractDateRangeEndContractDateMonth] 
         * @param {string} [contractDateRangeEndContractDateLabel] 
         * @param {number} [userVolumeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewPricesStripAverage: async (productId: string, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, contractDateRangeStartYear?: number, contractDateRangeStartMonth?: number, contractDateRangeEndYear?: number, contractDateRangeEndMonth?: number, contractDateRangeStartContractDateYear?: number, contractDateRangeStartContractDateMonth?: number, contractDateRangeStartContractDateLabel?: string, contractDateRangeEndContractDateYear?: number, contractDateRangeEndContractDateMonth?: number, contractDateRangeEndContractDateLabel?: string, userVolumeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('listMarketViewPricesStripAverage', 'productId', productId)
            const localVarPath = `/market-view/prices/{productId}/strip-average`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quoteDateRangeStart !== undefined) {
                localVarQueryParameter['QuoteDateRange.Start'] = (quoteDateRangeStart as any instanceof Date) ?
                    (quoteDateRangeStart as any).toISOString().substr(0,10) :
                    quoteDateRangeStart;
            }

            if (quoteDateRangeEnd !== undefined) {
                localVarQueryParameter['QuoteDateRange.End'] = (quoteDateRangeEnd as any instanceof Date) ?
                    (quoteDateRangeEnd as any).toISOString().substr(0,10) :
                    quoteDateRangeEnd;
            }

            if (contractDateRangeStartYear !== undefined) {
                localVarQueryParameter['ContractDateRange.StartYear'] = contractDateRangeStartYear;
            }

            if (contractDateRangeStartMonth !== undefined) {
                localVarQueryParameter['ContractDateRange.StartMonth'] = contractDateRangeStartMonth;
            }

            if (contractDateRangeEndYear !== undefined) {
                localVarQueryParameter['ContractDateRange.EndYear'] = contractDateRangeEndYear;
            }

            if (contractDateRangeEndMonth !== undefined) {
                localVarQueryParameter['ContractDateRange.EndMonth'] = contractDateRangeEndMonth;
            }

            if (contractDateRangeStartContractDateYear !== undefined) {
                localVarQueryParameter['ContractDateRange.StartContractDate.Year'] = contractDateRangeStartContractDateYear;
            }

            if (contractDateRangeStartContractDateMonth !== undefined) {
                localVarQueryParameter['ContractDateRange.StartContractDate.Month'] = contractDateRangeStartContractDateMonth;
            }

            if (contractDateRangeStartContractDateLabel !== undefined) {
                localVarQueryParameter['ContractDateRange.StartContractDate.Label'] = contractDateRangeStartContractDateLabel;
            }

            if (contractDateRangeEndContractDateYear !== undefined) {
                localVarQueryParameter['ContractDateRange.EndContractDate.Year'] = contractDateRangeEndContractDateYear;
            }

            if (contractDateRangeEndContractDateMonth !== undefined) {
                localVarQueryParameter['ContractDateRange.EndContractDate.Month'] = contractDateRangeEndContractDateMonth;
            }

            if (contractDateRangeEndContractDateLabel !== undefined) {
                localVarQueryParameter['ContractDateRange.EndContractDate.Label'] = contractDateRangeEndContractDateLabel;
            }

            if (userVolumeId !== undefined) {
                localVarQueryParameter['UserVolumeId'] = userVolumeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewProductTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-view/products/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CommodityType} [commodityType] 
         * @param {Array<number>} [productIds] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewProducts: async (commodityType?: CommodityType, productIds?: Array<number>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-view/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commodityType !== undefined) {
                localVarQueryParameter['CommodityType'] = commodityType;
            }

            if (productIds) {
                localVarQueryParameter['ProductIds'] = productIds;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewSavedQuery: async (sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-view/saved-queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewSettlementPrices: async (productId: string, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('listMarketViewSettlementPrices', 'productId', productId)
            const localVarPath = `/market-view/prices/{productId}/settlements`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quoteDateRangeStart !== undefined) {
                localVarQueryParameter['QuoteDateRange.Start'] = (quoteDateRangeStart as any instanceof Date) ?
                    (quoteDateRangeStart as any).toISOString().substr(0,10) :
                    quoteDateRangeStart;
            }

            if (quoteDateRangeEnd !== undefined) {
                localVarQueryParameter['QuoteDateRange.End'] = (quoteDateRangeEnd as any instanceof Date) ?
                    (quoteDateRangeEnd as any).toISOString().substr(0,10) :
                    quoteDateRangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [marketViewUserGuids] 
         * @param {boolean} [includeSites] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewUsers: async (marketViewUserGuids?: Array<string>, includeSites?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-view/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (marketViewUserGuids) {
                localVarQueryParameter['MarketViewUserGuids'] = marketViewUserGuids;
            }

            if (includeSites !== undefined) {
                localVarQueryParameter['IncludeSites'] = includeSites;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewVolumes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-view/volumes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketViewProductTradingMonths: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('marketViewProductTradingMonths', 'productId', productId)
            const localVarPath = `/market-view/products/{productId}/trading-months`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketViewUserAuthorizationCallback: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-view/authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {string} userGuid 
         * @param {string} commodityManagementUserGuid 
         * @param {boolean} [isSiteManager] 
         * @param {boolean} [accessDisabled] 
         * @param {Array<CommodityManagementUserPermissions>} [permissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommodityManagementUser: async (siteGuid: string, userGuid: string, commodityManagementUserGuid: string, isSiteManager?: boolean, accessDisabled?: boolean, permissions?: Array<CommodityManagementUserPermissions>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteGuid' is not null or undefined
            assertParamExists('updateCommodityManagementUser', 'siteGuid', siteGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateCommodityManagementUser', 'userGuid', userGuid)
            // verify required parameter 'commodityManagementUserGuid' is not null or undefined
            assertParamExists('updateCommodityManagementUser', 'commodityManagementUserGuid', commodityManagementUserGuid)
            const localVarPath = `/market-view/commodity-management/sites/{siteGuid}/{userGuid}`
                .replace(`{${"siteGuid"}}`, encodeURIComponent(String(siteGuid)))
                .replace(`{${"userGuid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commodityManagementUserGuid !== undefined) {
                localVarQueryParameter['CommodityManagementUserGuid'] = commodityManagementUserGuid;
            }

            if (isSiteManager !== undefined) {
                localVarQueryParameter['IsSiteManager'] = isSiteManager;
            }

            if (accessDisabled !== undefined) {
                localVarQueryParameter['AccessDisabled'] = accessDisabled;
            }

            if (permissions) {
                localVarQueryParameter['Permissions'] = permissions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketViewUserGuid 
         * @param {string} [email] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {boolean} [isAdministrator] 
         * @param {Array<MarketViewApplication>} [marketViewApplications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketViewUser: async (marketViewUserGuid: string, email?: string, firstName?: string, lastName?: string, isAdministrator?: boolean, marketViewApplications?: Array<MarketViewApplication>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketViewUserGuid' is not null or undefined
            assertParamExists('updateMarketViewUser', 'marketViewUserGuid', marketViewUserGuid)
            const localVarPath = `/market-view/users/{marketViewUserGuid}`
                .replace(`{${"marketViewUserGuid"}}`, encodeURIComponent(String(marketViewUserGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['FirstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['LastName'] = lastName;
            }

            if (isAdministrator !== undefined) {
                localVarQueryParameter['IsAdministrator'] = isAdministrator;
            }

            if (marketViewApplications) {
                localVarQueryParameter['MarketViewApplications'] = marketViewApplications;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketViewApi - functional programming interface
 * @export
 */
export const MarketViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketViewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [siteGuid] 
         * @param {string} [siteName] 
         * @param {string} [customerName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCommodityManagementSite(siteGuid?: string, siteName?: string, customerName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCommodityManagementSite(siteGuid, siteName, customerName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {string} userGuid 
         * @param {string} [email] 
         * @param {boolean} [isSiteManager] 
         * @param {Array<CommodityManagementUserPermissions>} [permissions] 
         * @param {boolean} [accessDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCommodityManagementUserSiteAccess(siteGuid: string, userGuid: string, email?: string, isSiteManager?: boolean, permissions?: Array<CommodityManagementUserPermissions>, accessDisabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCommodityManagementUserSiteAccess(siteGuid, userGuid, email, isSiteManager, permissions, accessDisabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} savedQueryId 
         * @param {string} name 
         * @param {Array<MarkItViewQuerySavedParameters>} markItViewQuerySavedParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMarketViewSavedQuery(savedQueryId: number, name: string, markItViewQuerySavedParameters: Array<MarkItViewQuerySavedParameters>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddMarketViewSavedQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMarketViewSavedQuery(savedQueryId, name, markItViewQuerySavedParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [stoneXUserGuid] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {Array<MarketViewApplication>} [userSolutionsAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMarketViewUser(email: string, stoneXUserGuid?: string, firstName?: string, lastName?: string, userSolutionsAccess?: Array<MarketViewApplication>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddMarketViewUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMarketViewUser(email, stoneXUserGuid, firstName, lastName, userSolutionsAccess, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {AddMarketViewUserFavoritedProductRequest} [addMarketViewUserFavoritedProductRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMarketViewUserFavoritedProduct(productId: string, addMarketViewUserFavoritedProductRequest?: AddMarketViewUserFavoritedProductRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMarketViewUserFavoritedProduct(productId, addMarketViewUserFavoritedProductRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userVolumeId 
         * @param {string} name 
         * @param {{ [key: string]: number; }} volumes 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMarketViewVolume(userVolumeId: number, name: string, volumes: { [key: string]: number; }, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddMarketViewVolumeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMarketViewVolume(userVolumeId, name, volumes, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AgreeToMarketViewTermsAndConditionsRequest} [agreeToMarketViewTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agreeToDemeterTermsAndConditions(agreeToMarketViewTermsAndConditionsRequest?: AgreeToMarketViewTermsAndConditionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agreeToDemeterTermsAndConditions(agreeToMarketViewTermsAndConditionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {string} userGuid 
         * @param {string} commodityManagementUserGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommodityManagementUserSiteAccess(siteGuid: string, userGuid: string, commodityManagementUserGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommodityManagementUserSiteAccess(siteGuid, userGuid, commodityManagementUserGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMarketViewUserFavoritedProduct(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMarketViewUserFavoritedProduct(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDecisiveBasisPrices(startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DecisiveMarketPriceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDecisiveBasisPrices(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDecisiveFutureBasisPricesRangeByCommodity(startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DecisiveMarketPriceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDecisiveFutureBasisPricesRangeByCommodity(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDecisiveFuturePricesRangeByCommodity(startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DecisiveMarketPriceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDecisiveFuturePricesRangeByCommodity(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDecisiveSpecialtyCropPricesRangeByCommodity(startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DecisiveMarketPriceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDecisiveSpecialtyCropPricesRangeByCommodity(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} symbolId 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketViewLastQuoteDate(symbolId: string, asOfDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketViewLastQuoteDateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketViewLastQuoteDate(symbolId, asOfDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketViewUserGuid 
         * @param {string} [stoneXUserGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketViewUser(marketViewUserGuid: string, stoneXUserGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketViewUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketViewUser(marketViewUserGuid, stoneXUserGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityManagementSiteUsers(siteGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityManagementSiteUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityManagementSiteUsers(siteGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommodityManagementSites(sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommodityManagementSitesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommodityManagementSites(sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {Array<Currency>} currencies 
         * @param {Array<ContractDate>} contractMonths 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewCurrencySpotRates(quoteDate: string, currencies: Array<Currency>, contractMonths: Array<ContractDate>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketViewCurrencySpotRatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewCurrencySpotRates(quoteDate, currencies, contractMonths, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ListMarketViewExpirationDatesRequest} [listMarketViewExpirationDatesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewExpirationDates(listMarketViewExpirationDatesRequest?: ListMarketViewExpirationDatesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketViewExpirationDatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewExpirationDates(listMarketViewExpirationDatesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} quoteDate 
         * @param {number} [numberOfMonths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewForwardCurveQuery(productId: string, quoteDate: string, numberOfMonths?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewQueryForwardCurveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewForwardCurveQuery(productId, quoteDate, numberOfMonths, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} symbolIds 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewLastQuoteDate(symbolIds: Array<number>, asOfDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketViewLastQuoteDateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewLastQuoteDate(symbolIds, asOfDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {Array<number>} years 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {number} [userVolumeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewPriceQueryCalendarStripAvearge(productId: string, years: Array<number>, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, userVolumeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewQueryCalendarStripAverageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewPriceQueryCalendarStripAvearge(productId, years, quoteDateRangeStart, quoteDateRangeEnd, userVolumeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} monthsAhead 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewPriceQueryRollingMonthAhead(productId: string, monthsAhead: number, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewQueryRollingMonthAheadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewPriceQueryRollingMonthAhead(productId, monthsAhead, quoteDateRangeStart, quoteDateRangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {Array<number>} years 
         * @param {Array<number>} months 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewPrices(productId: string, years: Array<number>, months: Array<number>, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewQueryPriceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewPrices(productId, years, months, quoteDateRangeStart, quoteDateRangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} symbolId 
         * @param {string} contractYear 
         * @param {string} contractMonth 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewPricesBySymbol(symbolId: string, contractYear: string, contractMonth: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketViewPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewPricesBySymbol(symbolId, contractYear, contractMonth, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {number} [contractDateRangeStartYear] 
         * @param {number} [contractDateRangeStartMonth] 
         * @param {number} [contractDateRangeEndYear] 
         * @param {number} [contractDateRangeEndMonth] 
         * @param {number} [contractDateRangeStartContractDateYear] 
         * @param {number} [contractDateRangeStartContractDateMonth] 
         * @param {string} [contractDateRangeStartContractDateLabel] 
         * @param {number} [contractDateRangeEndContractDateYear] 
         * @param {number} [contractDateRangeEndContractDateMonth] 
         * @param {string} [contractDateRangeEndContractDateLabel] 
         * @param {number} [userVolumeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewPricesStripAverage(productId: string, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, contractDateRangeStartYear?: number, contractDateRangeStartMonth?: number, contractDateRangeEndYear?: number, contractDateRangeEndMonth?: number, contractDateRangeStartContractDateYear?: number, contractDateRangeStartContractDateMonth?: number, contractDateRangeStartContractDateLabel?: string, contractDateRangeEndContractDateYear?: number, contractDateRangeEndContractDateMonth?: number, contractDateRangeEndContractDateLabel?: string, userVolumeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewQueryStripAverageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewPricesStripAverage(productId, quoteDateRangeStart, quoteDateRangeEnd, contractDateRangeStartYear, contractDateRangeStartMonth, contractDateRangeEndYear, contractDateRangeEndMonth, contractDateRangeStartContractDateYear, contractDateRangeStartContractDateMonth, contractDateRangeStartContractDateLabel, contractDateRangeEndContractDateYear, contractDateRangeEndContractDateMonth, contractDateRangeEndContractDateLabel, userVolumeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewProductTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketViewProductTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewProductTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CommodityType} [commodityType] 
         * @param {Array<number>} [productIds] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewProducts(commodityType?: CommodityType, productIds?: Array<number>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketViewProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewProducts(commodityType, productIds, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewSavedQuery(sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketViewSavedQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewSavedQuery(sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewSettlementPrices(productId: string, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewQuerySettlementsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewSettlementPrices(productId, quoteDateRangeStart, quoteDateRangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [marketViewUserGuids] 
         * @param {boolean} [includeSites] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewUsers(marketViewUserGuids?: Array<string>, includeSites?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketViewUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewUsers(marketViewUserGuids, includeSites, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketViewVolumes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewVolumesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketViewVolumes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketViewProductTradingMonths(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketViewProductTradingMonthsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketViewProductTradingMonths(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketViewUserAuthorizationCallback(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketViewUserAuthorizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketViewUserAuthorizationCallback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {string} userGuid 
         * @param {string} commodityManagementUserGuid 
         * @param {boolean} [isSiteManager] 
         * @param {boolean} [accessDisabled] 
         * @param {Array<CommodityManagementUserPermissions>} [permissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommodityManagementUser(siteGuid: string, userGuid: string, commodityManagementUserGuid: string, isSiteManager?: boolean, accessDisabled?: boolean, permissions?: Array<CommodityManagementUserPermissions>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommodityManagementUser(siteGuid, userGuid, commodityManagementUserGuid, isSiteManager, accessDisabled, permissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketViewUserGuid 
         * @param {string} [email] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {boolean} [isAdministrator] 
         * @param {Array<MarketViewApplication>} [marketViewApplications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMarketViewUser(marketViewUserGuid: string, email?: string, firstName?: string, lastName?: string, isAdministrator?: boolean, marketViewApplications?: Array<MarketViewApplication>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateMarketViewUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMarketViewUser(marketViewUserGuid, email, firstName, lastName, isAdministrator, marketViewApplications, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketViewApi - factory interface
 * @export
 */
export const MarketViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketViewApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [siteGuid] 
         * @param {string} [siteName] 
         * @param {string} [customerName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommodityManagementSite(siteGuid?: string, siteName?: string, customerName?: string, options?: any): AxiosPromise<object> {
            return localVarFp.addCommodityManagementSite(siteGuid, siteName, customerName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {string} userGuid 
         * @param {string} [email] 
         * @param {boolean} [isSiteManager] 
         * @param {Array<CommodityManagementUserPermissions>} [permissions] 
         * @param {boolean} [accessDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommodityManagementUserSiteAccess(siteGuid: string, userGuid: string, email?: string, isSiteManager?: boolean, permissions?: Array<CommodityManagementUserPermissions>, accessDisabled?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.addCommodityManagementUserSiteAccess(siteGuid, userGuid, email, isSiteManager, permissions, accessDisabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} savedQueryId 
         * @param {string} name 
         * @param {Array<MarkItViewQuerySavedParameters>} markItViewQuerySavedParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketViewSavedQuery(savedQueryId: number, name: string, markItViewQuerySavedParameters: Array<MarkItViewQuerySavedParameters>, options?: any): AxiosPromise<AddMarketViewSavedQueryResponse> {
            return localVarFp.addMarketViewSavedQuery(savedQueryId, name, markItViewQuerySavedParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [stoneXUserGuid] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {Array<MarketViewApplication>} [userSolutionsAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketViewUser(email: string, stoneXUserGuid?: string, firstName?: string, lastName?: string, userSolutionsAccess?: Array<MarketViewApplication>, options?: any): AxiosPromise<AddMarketViewUserResponse> {
            return localVarFp.addMarketViewUser(email, stoneXUserGuid, firstName, lastName, userSolutionsAccess, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {AddMarketViewUserFavoritedProductRequest} [addMarketViewUserFavoritedProductRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketViewUserFavoritedProduct(productId: string, addMarketViewUserFavoritedProductRequest?: AddMarketViewUserFavoritedProductRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addMarketViewUserFavoritedProduct(productId, addMarketViewUserFavoritedProductRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userVolumeId 
         * @param {string} name 
         * @param {{ [key: string]: number; }} volumes 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMarketViewVolume(userVolumeId: number, name: string, volumes: { [key: string]: number; }, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<AddMarketViewVolumeResponse> {
            return localVarFp.addMarketViewVolume(userVolumeId, name, volumes, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AgreeToMarketViewTermsAndConditionsRequest} [agreeToMarketViewTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToDemeterTermsAndConditions(agreeToMarketViewTermsAndConditionsRequest?: AgreeToMarketViewTermsAndConditionsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.agreeToDemeterTermsAndConditions(agreeToMarketViewTermsAndConditionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {string} userGuid 
         * @param {string} commodityManagementUserGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommodityManagementUserSiteAccess(siteGuid: string, userGuid: string, commodityManagementUserGuid: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCommodityManagementUserSiteAccess(siteGuid, userGuid, commodityManagementUserGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarketViewUserFavoritedProduct(productId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteMarketViewUserFavoritedProduct(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDecisiveBasisPrices(startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<Array<DecisiveMarketPriceModel>> {
            return localVarFp.getDecisiveBasisPrices(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDecisiveFutureBasisPricesRangeByCommodity(startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<Array<DecisiveMarketPriceModel>> {
            return localVarFp.getDecisiveFutureBasisPricesRangeByCommodity(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDecisiveFuturePricesRangeByCommodity(startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<Array<DecisiveMarketPriceModel>> {
            return localVarFp.getDecisiveFuturePricesRangeByCommodity(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} commodity 
         * @param {string} [markToMarketDate] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDecisiveSpecialtyCropPricesRangeByCommodity(startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<Array<DecisiveMarketPriceModel>> {
            return localVarFp.getDecisiveSpecialtyCropPricesRangeByCommodity(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} symbolId 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewLastQuoteDate(symbolId: string, asOfDate?: string, options?: any): AxiosPromise<GetMarketViewLastQuoteDateResponse> {
            return localVarFp.getMarketViewLastQuoteDate(symbolId, asOfDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketViewUserGuid 
         * @param {string} [stoneXUserGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketViewUser(marketViewUserGuid: string, stoneXUserGuid?: string, options?: any): AxiosPromise<GetMarketViewUserResponse> {
            return localVarFp.getMarketViewUser(marketViewUserGuid, stoneXUserGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityManagementSiteUsers(siteGuid: string, options?: any): AxiosPromise<ListCommodityManagementSiteUsersResponse> {
            return localVarFp.listCommodityManagementSiteUsers(siteGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommodityManagementSites(sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListCommodityManagementSitesResponse> {
            return localVarFp.listCommodityManagementSites(sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} quoteDate 
         * @param {Array<Currency>} currencies 
         * @param {Array<ContractDate>} contractMonths 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewCurrencySpotRates(quoteDate: string, currencies: Array<Currency>, contractMonths: Array<ContractDate>, options?: any): AxiosPromise<ListMarketViewCurrencySpotRatesResponse> {
            return localVarFp.listMarketViewCurrencySpotRates(quoteDate, currencies, contractMonths, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListMarketViewExpirationDatesRequest} [listMarketViewExpirationDatesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewExpirationDates(listMarketViewExpirationDatesRequest?: ListMarketViewExpirationDatesRequest, options?: any): AxiosPromise<ListMarketViewExpirationDatesResponse> {
            return localVarFp.listMarketViewExpirationDates(listMarketViewExpirationDatesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} quoteDate 
         * @param {number} [numberOfMonths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewForwardCurveQuery(productId: string, quoteDate: string, numberOfMonths?: number, options?: any): AxiosPromise<MarketViewQueryForwardCurveResponse> {
            return localVarFp.listMarketViewForwardCurveQuery(productId, quoteDate, numberOfMonths, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} symbolIds 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewLastQuoteDate(symbolIds: Array<number>, asOfDate?: string, options?: any): AxiosPromise<ListMarketViewLastQuoteDateResponse> {
            return localVarFp.listMarketViewLastQuoteDate(symbolIds, asOfDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {Array<number>} years 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {number} [userVolumeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewPriceQueryCalendarStripAvearge(productId: string, years: Array<number>, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, userVolumeId?: number, options?: any): AxiosPromise<MarketViewQueryCalendarStripAverageResponse> {
            return localVarFp.listMarketViewPriceQueryCalendarStripAvearge(productId, years, quoteDateRangeStart, quoteDateRangeEnd, userVolumeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {number} monthsAhead 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewPriceQueryRollingMonthAhead(productId: string, monthsAhead: number, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options?: any): AxiosPromise<MarketViewQueryRollingMonthAheadResponse> {
            return localVarFp.listMarketViewPriceQueryRollingMonthAhead(productId, monthsAhead, quoteDateRangeStart, quoteDateRangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {Array<number>} years 
         * @param {Array<number>} months 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewPrices(productId: string, years: Array<number>, months: Array<number>, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options?: any): AxiosPromise<MarketViewQueryPriceResponse> {
            return localVarFp.listMarketViewPrices(productId, years, months, quoteDateRangeStart, quoteDateRangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} symbolId 
         * @param {string} contractYear 
         * @param {string} contractMonth 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewPricesBySymbol(symbolId: string, contractYear: string, contractMonth: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListMarketViewPricesResponse> {
            return localVarFp.listMarketViewPricesBySymbol(symbolId, contractYear, contractMonth, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {number} [contractDateRangeStartYear] 
         * @param {number} [contractDateRangeStartMonth] 
         * @param {number} [contractDateRangeEndYear] 
         * @param {number} [contractDateRangeEndMonth] 
         * @param {number} [contractDateRangeStartContractDateYear] 
         * @param {number} [contractDateRangeStartContractDateMonth] 
         * @param {string} [contractDateRangeStartContractDateLabel] 
         * @param {number} [contractDateRangeEndContractDateYear] 
         * @param {number} [contractDateRangeEndContractDateMonth] 
         * @param {string} [contractDateRangeEndContractDateLabel] 
         * @param {number} [userVolumeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewPricesStripAverage(productId: string, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, contractDateRangeStartYear?: number, contractDateRangeStartMonth?: number, contractDateRangeEndYear?: number, contractDateRangeEndMonth?: number, contractDateRangeStartContractDateYear?: number, contractDateRangeStartContractDateMonth?: number, contractDateRangeStartContractDateLabel?: string, contractDateRangeEndContractDateYear?: number, contractDateRangeEndContractDateMonth?: number, contractDateRangeEndContractDateLabel?: string, userVolumeId?: number, options?: any): AxiosPromise<MarketViewQueryStripAverageResponse> {
            return localVarFp.listMarketViewPricesStripAverage(productId, quoteDateRangeStart, quoteDateRangeEnd, contractDateRangeStartYear, contractDateRangeStartMonth, contractDateRangeEndYear, contractDateRangeEndMonth, contractDateRangeStartContractDateYear, contractDateRangeStartContractDateMonth, contractDateRangeStartContractDateLabel, contractDateRangeEndContractDateYear, contractDateRangeEndContractDateMonth, contractDateRangeEndContractDateLabel, userVolumeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewProductTypes(options?: any): AxiosPromise<ListMarketViewProductTypesResponse> {
            return localVarFp.listMarketViewProductTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CommodityType} [commodityType] 
         * @param {Array<number>} [productIds] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewProducts(commodityType?: CommodityType, productIds?: Array<number>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListMarketViewProductsResponse> {
            return localVarFp.listMarketViewProducts(commodityType, productIds, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewSavedQuery(sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListMarketViewSavedQueryResponse> {
            return localVarFp.listMarketViewSavedQuery(sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [quoteDateRangeStart] 
         * @param {string} [quoteDateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewSettlementPrices(productId: string, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options?: any): AxiosPromise<MarketViewQuerySettlementsResponse> {
            return localVarFp.listMarketViewSettlementPrices(productId, quoteDateRangeStart, quoteDateRangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [marketViewUserGuids] 
         * @param {boolean} [includeSites] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewUsers(marketViewUserGuids?: Array<string>, includeSites?: boolean, options?: any): AxiosPromise<ListMarketViewUsersResponse> {
            return localVarFp.listMarketViewUsers(marketViewUserGuids, includeSites, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketViewVolumes(options?: any): AxiosPromise<MarketViewVolumesResponse> {
            return localVarFp.listMarketViewVolumes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketViewProductTradingMonths(productId: string, options?: any): AxiosPromise<MarketViewProductTradingMonthsResponse> {
            return localVarFp.marketViewProductTradingMonths(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketViewUserAuthorizationCallback(options?: any): AxiosPromise<GetMarketViewUserAuthorizationResponse> {
            return localVarFp.marketViewUserAuthorizationCallback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} siteGuid 
         * @param {string} userGuid 
         * @param {string} commodityManagementUserGuid 
         * @param {boolean} [isSiteManager] 
         * @param {boolean} [accessDisabled] 
         * @param {Array<CommodityManagementUserPermissions>} [permissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommodityManagementUser(siteGuid: string, userGuid: string, commodityManagementUserGuid: string, isSiteManager?: boolean, accessDisabled?: boolean, permissions?: Array<CommodityManagementUserPermissions>, options?: any): AxiosPromise<object> {
            return localVarFp.updateCommodityManagementUser(siteGuid, userGuid, commodityManagementUserGuid, isSiteManager, accessDisabled, permissions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketViewUserGuid 
         * @param {string} [email] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {boolean} [isAdministrator] 
         * @param {Array<MarketViewApplication>} [marketViewApplications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketViewUser(marketViewUserGuid: string, email?: string, firstName?: string, lastName?: string, isAdministrator?: boolean, marketViewApplications?: Array<MarketViewApplication>, options?: any): AxiosPromise<UpdateMarketViewUserResponse> {
            return localVarFp.updateMarketViewUser(marketViewUserGuid, email, firstName, lastName, isAdministrator, marketViewApplications, options).then((request) => request(axios, basePath));
        },
    };
};



/**
 * MarketViewApi - object-oriented interface
 * @export
 * @class MarketViewApi
 * @extends {BaseAPI}
 */
export class MarketViewApi extends BaseAPI {
    /**
     * 
     * @param {string} [siteGuid] 
     * @param {string} [siteName] 
     * @param {string} [customerName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public addCommodityManagementSite(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), siteGuid?: string, siteName?: string, customerName?: string, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).addCommodityManagementSite(siteGuid, siteName, customerName, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} siteGuid 
     * @param {string} userGuid 
     * @param {string} [email] 
     * @param {boolean} [isSiteManager] 
     * @param {Array<CommodityManagementUserPermissions>} [permissions] 
     * @param {boolean} [accessDisabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public addCommodityManagementUserSiteAccess(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), siteGuid: string, userGuid: string, email?: string, isSiteManager?: boolean, permissions?: Array<CommodityManagementUserPermissions>, accessDisabled?: boolean, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).addCommodityManagementUserSiteAccess(siteGuid, userGuid, email, isSiteManager, permissions, accessDisabled, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {number} savedQueryId 
     * @param {string} name 
     * @param {Array<MarkItViewQuerySavedParameters>} markItViewQuerySavedParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public addMarketViewSavedQuery(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), savedQueryId: number, name: string, markItViewQuerySavedParameters: Array<MarkItViewQuerySavedParameters>, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).addMarketViewSavedQuery(savedQueryId, name, markItViewQuerySavedParameters, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} email 
     * @param {string} [stoneXUserGuid] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {Array<MarketViewApplication>} [userSolutionsAccess] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public addMarketViewUser(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), email: string, stoneXUserGuid?: string, firstName?: string, lastName?: string, userSolutionsAccess?: Array<MarketViewApplication>, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).addMarketViewUser(email, stoneXUserGuid, firstName, lastName, userSolutionsAccess, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} productId 
     * @param {AddMarketViewUserFavoritedProductRequest} [addMarketViewUserFavoritedProductRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public addMarketViewUserFavoritedProduct(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), productId: string, addMarketViewUserFavoritedProductRequest?: AddMarketViewUserFavoritedProductRequest, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).addMarketViewUserFavoritedProduct(productId, addMarketViewUserFavoritedProductRequest, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {number} userVolumeId 
     * @param {string} name 
     * @param {{ [key: string]: number; }} volumes 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public addMarketViewVolume(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), userVolumeId: number, name: string, volumes: { [key: string]: number; }, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).addMarketViewVolume(userVolumeId, name, volumes, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {AgreeToMarketViewTermsAndConditionsRequest} [agreeToMarketViewTermsAndConditionsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public agreeToDemeterTermsAndConditions(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), agreeToMarketViewTermsAndConditionsRequest?: AgreeToMarketViewTermsAndConditionsRequest, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).agreeToDemeterTermsAndConditions(agreeToMarketViewTermsAndConditionsRequest, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} siteGuid 
     * @param {string} userGuid 
     * @param {string} commodityManagementUserGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public deleteCommodityManagementUserSiteAccess(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), siteGuid: string, userGuid: string, commodityManagementUserGuid: string, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).deleteCommodityManagementUserSiteAccess(siteGuid, userGuid, commodityManagementUserGuid, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public deleteMarketViewUserFavoritedProduct(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), productId: string, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).deleteMarketViewUserFavoritedProduct(productId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} commodity 
     * @param {string} [markToMarketDate] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public getDecisiveBasisPrices(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).getDecisiveBasisPrices(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} commodity 
     * @param {string} [markToMarketDate] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public getDecisiveFutureBasisPricesRangeByCommodity(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).getDecisiveFutureBasisPricesRangeByCommodity(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} commodity 
     * @param {string} [markToMarketDate] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public getDecisiveFuturePricesRangeByCommodity(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).getDecisiveFuturePricesRangeByCommodity(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} commodity 
     * @param {string} [markToMarketDate] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public getDecisiveSpecialtyCropPricesRangeByCommodity(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), startDate: string, endDate: string, commodity: string, markToMarketDate?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).getDecisiveSpecialtyCropPricesRangeByCommodity(startDate, endDate, commodity, markToMarketDate, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} symbolId 
     * @param {string} [asOfDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public getMarketViewLastQuoteDate(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), symbolId: string, asOfDate?: string, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).getMarketViewLastQuoteDate(symbolId, asOfDate, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} marketViewUserGuid 
     * @param {string} [stoneXUserGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public getMarketViewUser(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), marketViewUserGuid: string, stoneXUserGuid?: string, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).getMarketViewUser(marketViewUserGuid, stoneXUserGuid, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} siteGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listCommodityManagementSiteUsers(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), siteGuid: string, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listCommodityManagementSiteUsers(siteGuid, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listCommodityManagementSites(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listCommodityManagementSites(sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} quoteDate 
     * @param {Array<Currency>} currencies 
     * @param {Array<ContractDate>} contractMonths 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewCurrencySpotRates(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), quoteDate: string, currencies: Array<Currency>, contractMonths: Array<ContractDate>, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewCurrencySpotRates(quoteDate, currencies, contractMonths, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {ListMarketViewExpirationDatesRequest} [listMarketViewExpirationDatesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewExpirationDates(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), listMarketViewExpirationDatesRequest?: ListMarketViewExpirationDatesRequest, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewExpirationDates(listMarketViewExpirationDatesRequest, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} productId 
     * @param {string} quoteDate 
     * @param {number} [numberOfMonths] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewForwardCurveQuery(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), productId: string, quoteDate: string, numberOfMonths?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewForwardCurveQuery(productId, quoteDate, numberOfMonths, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {Array<number>} symbolIds 
     * @param {string} [asOfDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewLastQuoteDate(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), symbolIds: Array<number>, asOfDate?: string, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewLastQuoteDate(symbolIds, asOfDate, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} productId 
     * @param {Array<number>} years 
     * @param {string} [quoteDateRangeStart] 
     * @param {string} [quoteDateRangeEnd] 
     * @param {number} [userVolumeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewPriceQueryCalendarStripAvearge(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), productId: string, years: Array<number>, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, userVolumeId?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewPriceQueryCalendarStripAvearge(productId, years, quoteDateRangeStart, quoteDateRangeEnd, userVolumeId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} productId 
     * @param {number} monthsAhead 
     * @param {string} [quoteDateRangeStart] 
     * @param {string} [quoteDateRangeEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewPriceQueryRollingMonthAhead(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), productId: string, monthsAhead: number, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewPriceQueryRollingMonthAhead(productId, monthsAhead, quoteDateRangeStart, quoteDateRangeEnd, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} productId 
     * @param {Array<number>} years 
     * @param {Array<number>} months 
     * @param {string} [quoteDateRangeStart] 
     * @param {string} [quoteDateRangeEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewPrices(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), productId: string, years: Array<number>, months: Array<number>, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewPrices(productId, years, months, quoteDateRangeStart, quoteDateRangeEnd, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} symbolId 
     * @param {string} contractYear 
     * @param {string} contractMonth 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewPricesBySymbol(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), symbolId: string, contractYear: string, contractMonth: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewPricesBySymbol(symbolId, contractYear, contractMonth, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} productId 
     * @param {string} [quoteDateRangeStart] 
     * @param {string} [quoteDateRangeEnd] 
     * @param {number} [contractDateRangeStartYear] 
     * @param {number} [contractDateRangeStartMonth] 
     * @param {number} [contractDateRangeEndYear] 
     * @param {number} [contractDateRangeEndMonth] 
     * @param {number} [contractDateRangeStartContractDateYear] 
     * @param {number} [contractDateRangeStartContractDateMonth] 
     * @param {string} [contractDateRangeStartContractDateLabel] 
     * @param {number} [contractDateRangeEndContractDateYear] 
     * @param {number} [contractDateRangeEndContractDateMonth] 
     * @param {string} [contractDateRangeEndContractDateLabel] 
     * @param {number} [userVolumeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewPricesStripAverage(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), productId: string, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, contractDateRangeStartYear?: number, contractDateRangeStartMonth?: number, contractDateRangeEndYear?: number, contractDateRangeEndMonth?: number, contractDateRangeStartContractDateYear?: number, contractDateRangeStartContractDateMonth?: number, contractDateRangeStartContractDateLabel?: string, contractDateRangeEndContractDateYear?: number, contractDateRangeEndContractDateMonth?: number, contractDateRangeEndContractDateLabel?: string, userVolumeId?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewPricesStripAverage(productId, quoteDateRangeStart, quoteDateRangeEnd, contractDateRangeStartYear, contractDateRangeStartMonth, contractDateRangeEndYear, contractDateRangeEndMonth, contractDateRangeStartContractDateYear, contractDateRangeStartContractDateMonth, contractDateRangeStartContractDateLabel, contractDateRangeEndContractDateYear, contractDateRangeEndContractDateMonth, contractDateRangeEndContractDateLabel, userVolumeId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewProductTypes(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewProductTypes(options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {CommodityType} [commodityType] 
     * @param {Array<number>} [productIds] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewProducts(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), commodityType?: CommodityType, productIds?: Array<number>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewProducts(commodityType, productIds, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewSavedQuery(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewSavedQuery(sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} productId 
     * @param {string} [quoteDateRangeStart] 
     * @param {string} [quoteDateRangeEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewSettlementPrices(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), productId: string, quoteDateRangeStart?: string, quoteDateRangeEnd?: string, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewSettlementPrices(productId, quoteDateRangeStart, quoteDateRangeEnd, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {Array<string>} [marketViewUserGuids] 
     * @param {boolean} [includeSites] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewUsers(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), marketViewUserGuids?: Array<string>, includeSites?: boolean, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewUsers(marketViewUserGuids, includeSites, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public listMarketViewVolumes(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).listMarketViewVolumes(options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public marketViewProductTradingMonths(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), productId: string, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).marketViewProductTradingMonths(productId, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public marketViewUserAuthorizationCallback(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).marketViewUserAuthorizationCallback(options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} siteGuid 
     * @param {string} userGuid 
     * @param {string} commodityManagementUserGuid 
     * @param {boolean} [isSiteManager] 
     * @param {boolean} [accessDisabled] 
     * @param {Array<CommodityManagementUserPermissions>} [permissions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public updateCommodityManagementUser(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), siteGuid: string, userGuid: string, commodityManagementUserGuid: string, isSiteManager?: boolean, accessDisabled?: boolean, permissions?: Array<CommodityManagementUserPermissions>, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).updateCommodityManagementUser(siteGuid, userGuid, commodityManagementUserGuid, isSiteManager, accessDisabled, permissions, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }

    /**
     * 
     * @param {string} marketViewUserGuid 
     * @param {string} [email] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {boolean} [isAdministrator] 
     * @param {Array<MarketViewApplication>} [marketViewApplications] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketViewApi
     */
    public updateMarketViewUser(loadingStateSetter: null | undefined | ((state: LoadingState) => void | undefined | null), marketViewUserGuid: string, email?: string, firstName?: string, lastName?: string, isAdministrator?: boolean, marketViewApplications?: Array<MarketViewApplication>, options?: AxiosRequestConfig) {
        const promise = MarketViewApiFp(this.configuration).updateMarketViewUser(marketViewUserGuid, email, firstName, lastName, isAdministrator, marketViewApplications, options).then((request) => request(this.axios, this.basePath));

        if (loadingStateSetter) {
            loadingStateSetter(LoadingState.Loading);
            promise.then(q => loadingStateSetter(LoadingState.Loaded));
            promise.catch(q => loadingStateSetter(LoadingState.Failed));
        }

        return promise;
    }
}


