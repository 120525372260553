import { HotTable } from '@handsontable/react-wrapper';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import Handsontable from 'handsontable';
import { CellChange, CellValue, ChangeSource, RangeType } from 'handsontable/common';
import "handsontable/dist/handsontable.full.min.css";
import { DropdownEditor } from 'handsontable/editors';
import { ColumnSettings } from 'handsontable/settings';
import 'handsontable/styles/ht-theme-main.min.css';
import { toNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import { CommodityModel, CounterpartyModel, Currency, GetCustomerDataResponse, GetCustomerSettingsResponse, PortfolioModel, PositionType, Unit, UpdateDealTicketRequest, UpdatePositionModel } from '../../../../../Generated/Commodity-Management-Api';
import { months, monthToNumber } from '../../../../../Helpers';
import { useLoadingState } from '../../../../../Hooks';
import { RootState, useApplicationSelector } from '../../../../../Redux/ReduxStore';
import { StoneXDivider, StoneXRow } from '../../../../StoneX';
import StoneXMainPage from '../../../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXAutocomplete, StoneXButton, StoneXDatePicker, StoneXLabeledItem, StoneXNumber, StoneXSelect, StoneXTextField } from '../../../../StoneXMui';
import { PositionRow } from './PositionTypes';

interface AveragePosition {
  unit?: Unit;
  currency?: Currency;
  volume?: number;
  price?: number;
}

export default function UpdatePositions() {

  const dealTicketLoadingState = useLoadingState();
  const { enqueueSnackbar } = useSnackbar();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const totalVolumeRef = useRef<HTMLDivElement>(null);
  const averagePriceRef = useRef<HTMLDivElement>(null);
  const [average, setAverage] = useState<AveragePosition>();
  const previousAverageRef = useRef<AveragePosition>();
  const [selectedRowCount, setSelectedRowCount] = useState<number>(1);

  const customerData = useApplicationSelector((state: RootState): GetCustomerDataResponse => state.commodityManagement.customerData);
  const customerSettings = useApplicationSelector((state: RootState): GetCustomerSettingsResponse => state.commodityManagement.customerSettings);

  const [counterpartyError, setCounterpartyError] = useState<string>();
  const [tradeDateError, setTradeDateError] = useState<string>();
  const [commodityError, setCommodityError] = useState<string>();
  const [portfolioError, setPortfolioError] = useState<string>();
  const [dealTicketError, setDealTicketError] = useState<string>();

  const [tradeDate, setTradeDate] = useState<Date | null>(dayjs().toDate());
  const [dealTicketId, setDealTicketId] = useState<number>(0);
  const [selectedCommodity, setSelectedCommodity] = useState<CommodityModel | null>(null);
  const [selectedCounterparty, setSelectedCounterparty] = useState<CounterpartyModel | null>(null);
  const [selectedPortfolio, setSelectedPortfolio] = useState<PortfolioModel | null>(null);
  const [dealTicketNumber, setDealTicketNumber] = useState<string>('');
  
  // Manually hide contract dates for now, for Seminole. Will consider proper implementation once we understand other customer requirements.
  const hideContractDate = useMemo(() => true, []);
  const [hasOptions, setHasOptions] = useState<boolean>(false);
  const [columnsToHide, setColumnsToHide] = useState<number[]>([]);

  const [positions, setPositions] = useState<PositionRow[]>([]);

  const hotRef = useRef<any>(null);

  useEffect(getDealTicketFromSearchParamaters, []);
  const onTradeDateChange = (value: Date | null): void => setTradeDate(value);

  function getDealTicketFromSearchParamaters() {
    const dealTicketId = new URLSearchParams(location.search).get('dealTicketId');

    if (dealTicketId) {
      CommodityManagementApi.getDealTicket(dealTicketLoadingState.setLoadingState, dealTicketId)
        .then((res) => {
          const dealTicket = res.data.dealTicket!;

          setDealTicketId(dealTicket.dealTicketId);
          setTradeDate(dayjs(dealTicket.tradeDate).toDate());
          setSelectedCommodity(dealTicket.commodity);
          setDealTicketNumber(dealTicket.dealTicketNumber ?? '');

          if (dealTicket.portfolio) {
            setSelectedPortfolio(dealTicket.portfolio);
          }

          if (dealTicket.counterparty) {
            setSelectedCounterparty(dealTicket.counterparty);
          }

          setPositions(dealTicket.positions);
          setHasOptions(dealTicket.positions.some(x => x.positionType === PositionType.Option));
        })
        .catch(() => {
          enqueueSnackbar('Failed to load deal ticket', { variant: 'error' });
          setPositions([{currency: 'USD'}]);
      });
    } 
    else {
      // Add empty row if no deal ticket id is provided
      setPositions([{currency: 'USD'}]);
    }
  }

  function updatePositions() {

    const hotInstance = hotRef.current.hotInstance;

    let hasError = false;

    hotInstance.validateCells((valid: boolean) => {
      hasError = !valid;
    });

    if (!selectedCommodity) {
      setCommodityError('Commodity is required.');
      hasError = true;
    } else {
      setCommodityError(undefined);
    }

    if (!selectedCounterparty && customerSettings.positionsByCounterparty) {
      setCounterpartyError('Counterparty is required.');
      hasError = true;
    } else {
      setCounterpartyError(undefined);
    }

    if (!selectedPortfolio) {
      setPortfolioError('Portfolio is required.');
      hasError = true;
    } else {
      setPortfolioError(undefined);
    }

    if (!dealTicketNumber.trim()) {
      setDealTicketError('Deal Ticket Number is required.');
      hasError = true;
    } else {
      setDealTicketError(undefined);
    }

    if (!tradeDate) {
      setTradeDateError('Trade Date is required.');
      hasError = true;
    } else {
      setTradeDateError(undefined);
    }

    if (hasError) {
      enqueueSnackbar('Please fill in all required fields.', { variant: 'error' });
      return;
    }

    const data = hotInstance.getData();
    const dealTicket = {
      dealTicketId: dealTicketId,
      tradeDate: dayjs(tradeDate).format(),
      commodityId: selectedCommodity?.commodityId,
      counterpartyId: selectedCounterparty?.counterpartyId,
      portfolioId: selectedPortfolio?.portfolioId,
      dealTicketNumber: dealTicketNumber,
      positions: data.map((row: any[], rowIndex: number) => {

        const budgetYear = hotInstance.getDataAtRowProp(rowIndex, 'budgetDate.year') ?? undefined;
        const budgetMonth = monthToNumber(hotInstance.getDataAtRowProp(rowIndex, 'budgetDate.month')) ?? undefined;
        const budgetDate = {year: budgetYear, month: budgetMonth};

        const contractYear = hotInstance.getDataAtRowProp(rowIndex, 'contractDate.year') ?? undefined;
        const contractMonth = monthToNumber(hotInstance.getDataAtRowProp(rowIndex, 'contractDate.month')) ?? undefined;
        const contractDate = contractYear && contractMonth ? {year: contractYear, month: contractMonth} : undefined;

        return {
          positionId: hotInstance.getDataAtRowProp(rowIndex, 'positionId'),
          locationId: hotInstance.getDataAtRowProp(rowIndex, 'locationId') ?? undefined,
          budgetDate: budgetDate,
          contractDate: contractDate,
          price: hotInstance.getDataAtRowProp(rowIndex, 'price') ? toNumber(hotInstance.getDataAtRowProp(rowIndex, 'price')) : undefined,
          volume: hotInstance.getDataAtRowProp(rowIndex, 'volume') ? toNumber(hotInstance.getDataAtRowProp(rowIndex, 'volume')) : undefined,
          optionStrike: hotInstance.getDataAtRowProp(rowIndex, 'optionStrike') ? toNumber(hotInstance.getDataAtRowProp(rowIndex, 'optionStrike')) : undefined,
          optionStyle: hotInstance.getDataAtRowProp(rowIndex, 'optionStyle') ?? undefined,
          side: hotInstance.getDataAtRowProp(rowIndex, 'side') ?? undefined,
          unit: hotInstance.getDataAtRowProp(rowIndex, 'unit') ?? undefined,
          currency: hotInstance.getDataAtRowProp(rowIndex, 'currency') ?? undefined,
        } as UpdatePositionModel;
      }),
    } as UpdateDealTicketRequest;

    setIsSaving(true);
    CommodityManagementApi.addTrade(null, dealTicket)
      .then(res => {
      enqueueSnackbar('Dealticket was successfully updated', {variant: 'success'});
      setIsSaving(false);
      const hotInstance = hotRef.current.hotInstance;
      hotInstance.alter('remove_row', 0, hotInstance.countRows()); 
      addRows(1); 
      })
      .catch(res => {
      enqueueSnackbar('An error occured while saving the dealticket', {variant: 'error'});
      setIsSaving(false);
      });
  }

  const addRows = (count: number) => {
    if (hotRef.current) {
      const hotInstance = hotRef.current.hotInstance;
      let lastRow = hotInstance.getDataAtRow(hotInstance.countRows() - 1);
      const newRows = [];

      for (let i = 0; i < count; i++) {
        const newRow = { ...lastRow };
        const budgetMonthColIndex = hotInstance.propToCol('budgetDate.month');
        const budgetYearColIndex = hotInstance.propToCol('budgetDate.year');
        const contractMonthColIndex = hotInstance.propToCol('contractDate.month');
        const contractYearColIndex = hotInstance.propToCol('contractDate.year');

        const currentBudgetMonthIndex = months.indexOf(lastRow[budgetMonthColIndex]);
        const currentContractMonthIndex = months.indexOf(lastRow[contractMonthColIndex]);

        newRow[budgetMonthColIndex] = currentBudgetMonthIndex !== -1 ? months[(currentBudgetMonthIndex + 1) % 12] : null;
        newRow[budgetYearColIndex] = currentBudgetMonthIndex === 11 && !isNaN(parseInt(lastRow[budgetYearColIndex], 10)) ? parseInt(lastRow[budgetYearColIndex], 10) + 1 : lastRow[budgetYearColIndex];
        newRow[contractMonthColIndex] = currentContractMonthIndex !== -1 ? months[(currentContractMonthIndex + 1) % 12] : null;
        newRow[contractYearColIndex] = currentContractMonthIndex === 11 && !isNaN(parseInt(lastRow[contractYearColIndex], 10)) ? parseInt(lastRow[contractYearColIndex], 10) + 1 : lastRow[contractYearColIndex];

        newRows.push(Object.values(newRow));
        lastRow = newRow; // Update lastRow to the newly created row
      }

      hotInstance.alter("insert_row_below", null, count);
      hotInstance.populateFromArray(hotInstance.countRows() - count, 0, newRows);

      // Set all new rows as valid
      const startRow = hotInstance.countRows() - count;
      const totalCols = hotInstance.countCols();
      
      for (let row = startRow; row < hotInstance.countRows(); row++) {
        for (let col = 0; col < totalCols; col++) {
          hotInstance.setCellMeta(row, col, 'valid', true);
        }
      }
      hotInstance.render();
    }
  };

  const DeleteRenderer = function (instance: any, TD: any, row: any, col: any, prop: any, value: any, cellProperties: any) {
    TD.innerHTML = ""; // Clear previous content
    const span = document.createElement("span");
    span.innerText = "X";
    span.style.cursor = "pointer";
    span.style.color = "black";
    span.onclick = () => instance.alter("remove_row", row); // Remove the row
    TD.appendChild(span);
  };

  class CustomDropdown extends DropdownEditor {
    limitDropdownIfNeeded(spaceAvailable: any, dropdownHeight: any): void {
      super.limitDropdownIfNeeded(spaceAvailable, dropdownHeight);
      const newHeight = (this.cellProperties?.source?.length ?? 0) * 40;
      this.htEditor.updateSettings({ height: newHeight + 2, width: '100%' });
    }
  }

  function yearValidator(value: CellValue, callback: (valid: boolean) => void) {
    const year = parseInt(value as string, 10);
    const isValidYear = !isNaN(year) && year > 0;
  
    if (value === null || value === undefined || value === '' || !isValidYear) {
      callback(false); // Invalid
    } else {
      callback(true); // Valid
    }
  }

  function numericValidator(value: CellValue, callback: (valid: boolean) => void) {
    const number = parseFloat(value as string);
    const isValidVolume = !isNaN(number) && number > 0;
  
    if (value === null || value === undefined || value === '' || !isValidVolume) {
      callback(false); // Invalid
    } else {
      callback(true); // Valid
    }
  }

  function updateColumnsToHide() {
    const hotInstance = hotRef.current?.hotInstance;
    
    if (!hotInstance) {
      setColumnsToHide([]);
      return;
    }

    let hidden = [];

    if (hideContractDate) {
      const contractYearTypeColIndex = hotInstance.propToCol('contractDate.year');
      const contractMonthTypeColIndex = hotInstance.propToCol('contractDate.month');
      hidden.push(...[contractYearTypeColIndex, contractMonthTypeColIndex]);
    }

    if (!hasOptions) {
      const optionTypeColIndex = hotInstance.propToCol('optionType');
      const optionStyleColIndex = hotInstance.propToCol('optionStyle');
      const optionStrikeColIndex = hotInstance.propToCol('optionStrike');
      hidden.push(...[optionTypeColIndex, optionStyleColIndex, optionStrikeColIndex]);
    }

    setColumnsToHide(hidden);
  }

  useEffect(updateColumnsToHide, [hasOptions, hideContractDate, hotRef.current?.hotInstance, hotRef.current?.hotInstance?.data?.length]);

  function setDefaultUnits() {
    if (!hotRef.current?.hotInstance || !selectedCommodity?.displayUnit) {
      return;
    }

    const hotInstance = hotRef.current.hotInstance;
    const data = hotInstance.getData();
    const unitColIndex = hotInstance.propToCol('unit');

    // Check if any row has a unit value
    const hasAnyUnit = data.some((row: any) => row[unitColIndex]);
    
    // If no units are set, set all rows to the commodity's display unit
    if (!hasAnyUnit) {
      const rowCount = hotInstance.countRows();
      for (let row = 0; row < rowCount; row++) {
        hotInstance.setDataAtCell(row, unitColIndex, selectedCommodity.displayUnit);
      }
    }
  }

  useEffect(setDefaultUnits, [selectedCommodity]);

  // Register the Custom Editor
  Handsontable.editors.registerEditor("customDropdown", CustomDropdown);
  Handsontable.validators.registerValidator('yearValidator', yearValidator);
  Handsontable.validators.registerValidator('numericValidator', numericValidator);
  
  const columns: ColumnSettings[] = [
    {
      data: 'side',
      type: 'dropdown',
      editor: 'customDropdown',
      source: ["Buy", "Sell"],
      validator: (value, callback) => callback(!!value && ["Buy", "Sell"].includes(value))
    },
    {
      data: 'positionType',
      type: 'dropdown',
      editor: 'customDropdown',
      source: customerData.positionTypes ?? [],
      autoComplete: {
        strict: false,
        filteringCaseSensitive: false,
      },
      validator: (value, callback) => callback(!!value && (customerData.positionTypes ?? []).includes(value))
    },
    {
      data: 'budgetDate.month',
      type: 'dropdown',
      editor: 'customDropdown',
      source: months,
      validator: (value, callback) => callback(!!value && months.includes(value))
    },
    {
      data: 'budgetDate.year',
      className: 'numeric',
      validator: 'yearValidator', 
    },
    {
      data: 'contractDate.month',
      type: 'dropdown',
      editor: 'customDropdown',
      source: months,
      validator: (value, callback) => {
        if (hideContractDate) {
          return callback(true);
        }
        callback(!!value && months.includes(value));
      }
    },
    {
      data: 'contractDate.year',
      className: 'numeric',
      validator: (value, callback) => {
        if (hideContractDate) {
          return callback(true);
        }
        yearValidator(value, (isValid) => callback(!!value && isValid));
      }
    },
    {
      data: 'optionType',
      type: 'dropdown',
      editor: 'customDropdown',
      source: customerData.optionTypes ?? [],
      validator: function (value, callback) {
        const positionType = this.instance.getDataAtRow(this.row)[1]; 
        if (positionType === PositionType.Option && !(!!value && (customerData.optionTypes ?? []).includes(value))) {
          callback(false);
        } else {
          callback(true);
        }
      }
    },
    {
      data: 'optionStyle',
      type: 'dropdown',
      editor: 'customDropdown',
      source: customerData.optionStyles ?? [],
      validator: function (value, callback) {
        const positionType = this.instance.getDataAtRow(this.row)[1]; 
        if (positionType === PositionType.Option && !(!!value && (customerData.optionStyles ?? []).includes(value))) {
          callback(false);
        } else {
          callback(true);
        }
      }
    },
    {
      data: 'optionStrike',
      validator: function (value, callback) {
        const positionType = this.instance.getDataAtRow(this.row)[1]; 
        if (positionType === PositionType.Option) {
          numericValidator(value, callback);
        } else {
          callback(true);
        }
      }
    },
    {
      data: 'volume',
      className: 'numeric',
      validator: 'numericValidator',
    },
    {
      data: 'price',
      className: 'numeric',
      validator: 'numericValidator',
    },
    {
      data: 'unit',
      type: 'dropdown',
      editor: 'customDropdown',
      source: customerData.units ?? [],
      validator: (value, callback) => callback(!!value && (customerData.units ?? []).includes(value))
    },
    {
      data: 'currency',
      type: 'dropdown',
      editor: 'customDropdown',
      source: customerData.currencies ?? [],
      validator: (value, callback) => callback(!!value && (customerData.currencies ?? []).includes(value))
    },
    {
      data: 12,
      editor: false,
      renderer: DeleteRenderer, // Custom delete button
    },
  ];

  function calculateAverage() {

    const hotInstance = hotRef.current?.hotInstance;
    const data: any[][] = hotInstance.getData();

    const volumeColIndex = hotInstance.propToCol('volume');
    const priceColIndex = hotInstance.propToCol('price');
    const unitColIndex = hotInstance.propToCol('unit');
    const currencyColIndex = hotInstance.propToCol('currency');

    const totalVolume = data
      .filter(row => !!row[volumeColIndex])
      .map(row => parseFloat(row[volumeColIndex]))
      .reduce((previous, current) => previous + current, 0);

    const averagePrice = data
    .filter(row => !!row[volumeColIndex] && !!row[priceColIndex])
    .map(row => (parseFloat(row[volumeColIndex]) * parseFloat(row[priceColIndex])) / totalVolume)
    .reduce((previous, current) => previous + current, 0);

    const firstCurrencySet = data.find(row => !!row[currencyColIndex])?.[currencyColIndex];
    const isMultipleCurrencies = data.some(row => !!row[currencyColIndex] && row[currencyColIndex] != firstCurrencySet);

    const firstUnitSet = data.find(row => !!row[unitColIndex])?.[unitColIndex];
    const isMultipleUnits = data.some(row => !!row[unitColIndex] && row[unitColIndex] != firstUnitSet);

    if (isMultipleCurrencies || isMultipleUnits) {
      if (previousAverageRef.current !== undefined) {
        previousAverageRef.current = undefined;
        setAverage(undefined);
      }
      return;
    }

    const newAverage = {
      currency: firstCurrencySet,
      unit: firstUnitSet,
      volume: totalVolume,
      price: averagePrice,
    };

    if (JSON.stringify(previousAverageRef.current) !== JSON.stringify(newAverage)) {
      previousAverageRef.current = newAverage;
      setAverage(newAverage);
    }
  }

  function alignAverageDivs() {

    setTimeout(() => {

      if (!hotRef.current || !totalVolumeRef.current || !averagePriceRef.current) {
        return;
      }
  
      const hotInstance = hotRef.current.hotInstance;
      const allThElements = document.querySelectorAll("thead tr th");
  
      const volumeColIndex = hotInstance.propToCol('volume');
      const priceColIndex = hotInstance.propToCol('price');
  
      const visibleVolumeColIndex = getVisibleColumnIndex(volumeColIndex);
      const visiblePriceColIndex = getVisibleColumnIndex(priceColIndex);
  
      const volumeHeaderElement = allThElements[visibleVolumeColIndex] as HTMLElement;
      const priceHeaderElement = allThElements[visiblePriceColIndex] as HTMLElement;
  
      // console.log('aligning divs begin', volumeHeaderElement.getBoundingClientRect(), priceHeaderElement.getBoundingClientRect());

      if (volumeHeaderElement && totalVolumeRef.current) {
        const volumeHeaderRect = volumeHeaderElement.getBoundingClientRect();
        totalVolumeRef.current.style.width = `${volumeHeaderElement.offsetWidth}px`;
        totalVolumeRef.current.style.left = `${volumeHeaderRect.left}px`;
        totalVolumeRef.current.style.visibility = 'visible';
        totalVolumeRef.current.style.position = 'absolute';
      } else {
        totalVolumeRef.current.style.visibility = 'hidden';
      }
  
      if (priceHeaderElement && averagePriceRef.current) {
        const priceHeaderRect = priceHeaderElement.getBoundingClientRect();
        averagePriceRef.current.style.width = `${priceHeaderElement.offsetWidth}px`;
        averagePriceRef.current.style.left = `${priceHeaderRect.left}px`;
        averagePriceRef.current.style.visibility = 'visible';
        averagePriceRef.current.style.position = 'absolute';
      } else {
        averagePriceRef.current.style.visibility = 'hidden';
      }

    }, 0)
  }

  function getVisibleColumnIndex(colIndex: number): number {
    const hiddenColumns = columnsToHide.filter(index => index < colIndex);
    return colIndex - hiddenColumns.length;
  }

  function handleAfterChange(changes: CellChange[] | null, source: ChangeSource) {

    if (changes) {
      const hot = hotRef.current.hotInstance;
      const data = hot.getData();

      const positionTypeColIndex = hot.propToCol('positionType');
      const optionTypeColIndex = hot.propToCol('optionType');
      const optionStyleColIndex = hot.propToCol('optionStyle');
      const optionStrikeColIndex = hot.propToCol('optionStrike');

      setHasOptions(data.some((x: any[]) => x[positionTypeColIndex] === PositionType.Option));

      changes.forEach(([row, prop, oldValue, newValue]) => {
        if (prop === 'positionType' && oldValue !== newValue) {
          if (newValue !== PositionType.Option) {
            hot.setDataAtCell(row, optionTypeColIndex, null);
            hot.setDataAtCell(row, optionStyleColIndex, null);
            hot.setDataAtCell(row, optionStrikeColIndex, null);
          } 
        }
      });
    }

    calculateAverage();
    alignAverageDivs();
  }
  
  const cells = (row: any, col: any, prop: any) => {

    const cellProperties = {} as any;
    const hotInstance = hotRef.current.hotInstance;
    const positionType = hotInstance.getDataAtRow(row)[1];
  
    const readonlyCells = ['optionType', 'optionStyle', 'optionStrike'];

    if (isSaving) {
      cellProperties.readOnly = true;
      return cellProperties;
    }

    if (readonlyCells.includes(prop)) {
      if (positionType === PositionType.Option) {
        cellProperties.readOnly = false;
        cellProperties.className = '';
      }
      else{
        cellProperties.readOnly = true;
        cellProperties.className = 'htDisabled';
      }
    }
  
    return cellProperties;
  };

  function beforePaste(data: CellValue[][], coords: RangeType[]) {
    const monthMap: { [key: string]: number } = {
      "january": 1, "jan": 1,
      "february": 2, "feb": 2,
      "march": 3, "mar": 3,
      "april": 4, "apr": 4,
      "may": 5,
      "june": 6, "jun": 6,
      "july": 7, "jul": 7,
      "august": 8, "aug": 8,
      "september": 9, "sep": 9,
      "october": 10, "oct": 10,
      "november": 11, "nov": 11,
      "december": 12, "dec": 12
    };

    const hotInstance = hotRef.current.hotInstance;
    const budgetMonthCol = hotInstance.propToCol('budgetDate.month');
    const contractMonthCol = hotInstance.propToCol('contractDate.month');
    const volumeCol = hotInstance.propToCol('volume');
    const priceCol = hotInstance.propToCol('price');

    coords.forEach((range) => {
      const startCol = range.startCol;

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          const value = String(data[i][j]).toLowerCase();

          // Process month columns
          if (startCol === budgetMonthCol || startCol === contractMonthCol) {
            if (monthMap[value] !== undefined) {
              data[i][j] = months[monthMap[value] - 1];
            }
            const monthNum = parseInt(value);
            if (!isNaN(monthNum) && monthNum >= 1 && monthNum <= 12) {
              data[i][j] = months[monthNum - 1];
            }
          }

          // Process numeric columns
          if (startCol === volumeCol || startCol === priceCol) {
            data[i][j] = value.replace(/[^0-9.]/g, '');
          }
        }
      }
    });
  }

  return (
    <StoneXMainPage isLoading={dealTicketLoadingState.isLoading()}>
      <StoneXRow align='start'>
        <StoneXDatePicker 
          value={tradeDate} 
          onChange={onTradeDateChange} 
          label="Trade Date" 
          error={tradeDateError} 
          disabled={isSaving}
        />

        <StoneXAutocomplete
          options={customerData?.commodities ?? []}
          getOptionLabel={(q) => q.commodityName}
          value={selectedCommodity}
          onChange={(e: any, commodity: CommodityModel | null) => setSelectedCommodity(commodity)}
          isOptionEqualToValue={(a, b) => a.commodityId == b.commodityId}
          label="Commodity"
          showRequired
          error={commodityError}
          disabled={isSaving}
        />

        {customerSettings.positionsByCounterparty && (
          <StoneXAutocomplete
            options={customerData?.counterparties ?? []}
            getOptionLabel={(q) => q.counterpartyName!}
            value={selectedCounterparty}
            onChange={(e: any, counterparty: CounterpartyModel | null) => setSelectedCounterparty(counterparty)}
            isOptionEqualToValue={(a, b) => a.counterpartyId == b.counterpartyId}
            label="Counterparty"
            showRequired={customerSettings.positionsByCounterparty}
            error={counterpartyError}
            disabled={isSaving}
          />
        )}

        <StoneXAutocomplete
          options={customerData?.portfolios ?? []}
          getOptionLabel={(q) => q.portfolioName!}
          value={selectedPortfolio}
          onChange={(e: any, portfolio: PortfolioModel | null) => setSelectedPortfolio(portfolio)}
          isOptionEqualToValue={(a, b) => a.portfolioId == b.portfolioId}
          label="Portfolio"
          showRequired
          error={portfolioError}
          disabled={isSaving}
        />

        <StoneXTextField 
          type="text" 
          label="Deal Ticket" 
          value={dealTicketNumber} 
          onChange={(event) => setDealTicketNumber(event.target.value)} 
          showRequired
          error={dealTicketError}
          disabled={isSaving}
        />

        <div className="pull-right nested-row-item">
          <StoneXButton 
            onClick={updatePositions} 
            disabled={dealTicketLoadingState.isLoading()} 
            isWorking={isSaving}>{dealTicketId ? "Save" : "Add"}
          </StoneXButton>
        </div>
      </StoneXRow>
      <StoneXDivider />
      
      <HotTable
        ref={hotRef}
        data={useMemo(() => positions.map(position => ({
          ...position,
          budgetDate: {
        ...position.budgetDate,
        month: position.budgetDate?.month ? months[position.budgetDate.month - 1] : null
          },
          contractDate: {
        ...position.contractDate,
        month: position.contractDate?.month ? months[position.contractDate.month - 1] : null
          }
        })), [positions])}
        stretchH="all"
        width="100%"
        height="auto"
        colHeaders={[
          "Side",
          "Position Type",
          "Budget Month",
          "Budget Year",
          "Contract Month",
          "Contract Year",
          "Option Type",
          "Option Style",
          "Option Strike",
          "Quantity",
          "Price",
          "Unit",
          "Currency",
          ""
        ]}
        columns={columns}
        autoWrapRow={true}
        autoWrapCol={true}
        rowHeights={40}
        columnHeaderHeight={48}
        minSpareRows={0}
        hiddenColumns={{
          columns: columnsToHide,
          indicators: false,
          copyPasteEnabled: false
        }}
        cells={cells}
        afterChange={handleAfterChange} 
        init={updateColumnsToHide}
        afterColumnResize={alignAverageDivs}
        afterRemoveRow={calculateAverage}
        afterCreateRow={calculateAverage}
        afterRefreshDimensions={alignAverageDivs}
        afterColumnSequenceChange={alignAverageDivs}
        beforePaste={beforePaste}
        licenseKey="non-commercial-and-evaluation"
      />

      <div>
        <div ref={totalVolumeRef} style={{display: 'inline-block', textAlign: 'right', visibility: 'visible'}}>
          <StoneXLabeledItem label="Total Volume">
            <div style={{display: 'inline-block'}}>
            < StoneXNumber number={average?.volume} decimals={0}/> {average?.unit}
            </div>
          </StoneXLabeledItem>
        </div>

        <div ref={averagePriceRef} style={{display: 'inline-block', textAlign: 'right', visibility: 'visible'}}>
          <StoneXLabeledItem label="Weighted Avg">
            <StoneXNumber number={average?.price} decimals={selectedCommodity?.displayPrecision ?? 2} currency={average?.currency}/>
          </StoneXLabeledItem>
        </div>
      </div>

      <br />

      <StoneXRow align="center">
        <Button onClick={() => addRows(selectedRowCount)}>
          Add Row
        </Button>
          <StoneXSelect
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            value={selectedRowCount}
            width={80}
            onChange={(e: any) => {
              setSelectedRowCount(1);
              addRows(e);
            }} 
            getId={x => x} 
            getOptionLabel={x => x}          
          />
          <label>Rows</label>
      </StoneXRow>
    </StoneXMainPage>
  );
}
