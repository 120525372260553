import { SubdirectoryArrowRightOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { difference } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { CommodityManagementApi } from "../../../../../../Apis/Apis";
import { CustomerReportModel } from "../../../../../../Generated/Commodity-Management-Api";
import { useApplicationDispatch } from "../../../../../../Redux/ReduxStore";
import { setCustomerReportUserOverride } from "../../../../../../Redux/Slices/CommodityManagementSlice";
import { GroupingOption } from "../../../../../../Types";
import { StoneXDivider, StoneXRow } from "../../../../../StoneX";
import { StoneXAutocomplete, StoneXModal } from "../../../../../StoneXMui";
import { StoneXTransferList } from "../../../../../StoneXMui/StoneXTransferList/StoneXTransferList";
import { ChartType, VarianceColumns, VarianceReportColumn, VarianceReportConfiguration, VarianceReportUserOverride } from "./Types";

interface VariancePositionReportSettingsModalProps {
    customerReport: CustomerReportModel;
    open: boolean;
    groupingOptions: GroupingOption[];
    onClose: () => void;
    onSave: (customerReport: CustomerReportModel) => void;
}

export default function VarianceReportSettingsModal(props: VariancePositionReportSettingsModalProps) {

    const { customerReport, open, groupingOptions, onClose, onSave } = props;
    const dispatch = useApplicationDispatch();

    const reportConfiguration = useMemo(() => JSON.parse(customerReport.configuration!) as VarianceReportConfiguration, [customerReport]);
    const [ userOverrides, setUserOverrides ] = useState<VarianceReportUserOverride | undefined>(customerReport.userOverrides ? JSON.parse(customerReport.userOverrides) : undefined);
    useEffect(updateUserOverrides, [customerReport]);
    
    const [ hiddenColumns, setHiddenColumns ] = useState<VarianceReportColumn[]>([]);
    const [ activeColumns, setActiveColumns ] = useState<VarianceReportColumn[]>([]);
    useEffect(updateColumns, [reportConfiguration, userOverrides]);

    const [ updatedActiveColumns, setUpdatedActiveColumns ] = useState<VarianceReportColumn[]>();
    // const [ updatedHiddenColumns, setUpdatedHiddenColumns ] = useState<PositionReportColumn[]>();

    const chartTypes = useMemo<ChartType[]>(() => ['Variance', 'Fixed/Call Coverage'], []);
    const [chartType, setChartType] = useState<ChartType | null>(reportConfiguration?.chart?.chartType ?? userOverrides?.chart?.chartType ?? null);

    const positionColumnOrderMap = useMemo<{[key: string]: number}>(() => {
      const keys = Object.keys(VarianceColumns);
      const map: {[key: string]: number} = {};
      keys.forEach((value: string, index) => map[value] = index);
      return map; 
    }, []);

    const [ level1GroupingOption, setLevel1GroupingOption ] = useState<GroupingOption | null>('Commodity');
    const [ level2GroupingOption, setLevel2GroupingOption ] = useState<GroupingOption | null>(reportConfiguration?.behavior?.grouping?.[1] ?? userOverrides?.behavior?.grouping?.[1] ?? null);

    function save() {
      const activeColumnsToSave = updatedActiveColumns ?? activeColumns;
      // const hiddenColumnsToSave = updatedHiddenColumns ?? hiddenColumns;

      // Alwasys overwrite grouping option as there currently is no way to detect if user meant to override those settings.
      const groupingOptionsToSave = [ level1GroupingOption, level2GroupingOption ].filter(x => x !== null) ?? undefined;

      const userSettings = {
        behavior: {
          grouping: groupingOptionsToSave
        },
        columns: {
          active: activeColumnsToSave,
        },
        chart: {
          chart: chartType,
        }
      } as VarianceReportUserOverride;

      const userOverrides = JSON.stringify(userSettings);

      CommodityManagementApi.updateCustomerReportUserOverrides(undefined, customerReport.customerReportId, userOverrides);

      dispatch(
        setCustomerReportUserOverride({
          customerReportId: customerReport.customerReportId, 
          userOverrides: userOverrides
        })
      )

      onSave({...customerReport, userOverrides: userOverrides});
    }
    
    function updateUserOverrides() {
      setUserOverrides(customerReport.userOverrides ? JSON.parse(customerReport.userOverrides) as VarianceReportUserOverride : undefined)
    }

    function updateColumns() {
      const availableColumns = reportConfiguration.columns.available ?? [];
      const activeColumns = userOverrides?.columns?.active ?? reportConfiguration.columns.active ?? [];
      const hiddenColumns = difference(availableColumns, activeColumns);
      setHiddenColumns(hiddenColumns);
      setActiveColumns(activeColumns);
    }

    function reset() {
      // Reset to customer settings. Empty the overrides
      setUserOverrides(undefined);
      updateColumns();
      setLevel1GroupingOption(reportConfiguration?.behavior?.grouping?.[0] ?? null);
      setLevel2GroupingOption(reportConfiguration?.behavior?.grouping?.[1] ?? null);
    }

    function close() {
      reset();
      onClose();
    }

    function onTransferListUpdate(hiddenItems: VarianceReportColumn[], activeItems: VarianceReportColumn[]) {
      // setUpdatedHiddenColumns(hiddenItems);
      setUpdatedActiveColumns(activeItems);
    }

    return (
        <StoneXModal header={customerReport.reportName + ' Report Configuration'} width='850px' open={open} onClose={onClose}>
          
          <h2>Rows</h2>

          <p></p>

          <StoneXRow>
            <StoneXAutocomplete 
              label="Group By"
              disabled
              options={groupingOptions} 
              value={level1GroupingOption}
              onUpdate={setLevel1GroupingOption}
              getOptionLabel={x => x} 
            />
          </StoneXRow>
          
          {level2GroupingOption || (groupingOptions && groupingOptions.length) > 0 && 
            <StoneXRow align="start">
              <SubdirectoryArrowRightOutlined />
              <StoneXAutocomplete 
                label="Then By (Optional)"
                options={groupingOptions} 
                value={level2GroupingOption}
                onUpdate={setLevel2GroupingOption}
                getOptionLabel={x => x} 
              />
            </StoneXRow>
          }

          <StoneXRow>
            <StoneXAutocomplete
              label="Chart Type"
              options={chartTypes}
              value={chartType}
              getOptionLabel={x => x}
              onUpdate={setChartType}
            />
          </StoneXRow>
          

          <StoneXDivider />

          <h2>Columns</h2>

          <p>Click on one or multiple items to select, then use the arrows to move. </p>

          <StoneXTransferList 
            leftList={hiddenColumns} 
            leftHeader="Available Columns"
            rightList={activeColumns}
            rightHeader="Active Columns"
            getId={x => VarianceColumns[x].colId}
            getGroup={x => VarianceColumns[x].parentHeader}
            getSortingOrder={x => positionColumnOrderMap[x]}
            getOptionLabel={x => VarianceColumns[x].columnName ?? VarianceColumns[x].headerName ?? x}
            onUpdate={onTransferListUpdate}
          />

          <StoneXRow justify="end" marginDirection='top'>
            <Button variant="outlined" onClick={close}> Cancel </Button>
            <Button variant="outlined" onClick={reset}> Reset </Button>
            <Button onClick={save}> Set </Button>
          </StoneXRow>
        </StoneXModal>
    );
}
    
