import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface StoneXButtonProps extends ButtonProps {
  isWorking?: boolean;
}
export function StoneXButton({ children, startIcon, isWorking = false, disabled, ...rest }: StoneXButtonProps) {
  return (
    <Button 
      startIcon={isWorking 
        ? <CircularProgress size={20} sx={{ padding: '0 10' }} /> 
        : startIcon } 
      disabled={isWorking || disabled} 
      {...rest}>
      {children}
    </Button>
  );
}