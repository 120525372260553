import { List, ListItemButton, ListItemText } from '@mui/material';
import { useMemo, useRef } from 'react';
import { StoneXLabeledItem } from '..';

interface StoneXSelectListProps<T> {
  label: string;
  list: T[];
  selectedItems?: T[];
  onSelect: (selected: T[], controlPressed: boolean, shiftPressed: boolean) => void;
  getId: (item: T) => string | number;
  getGroup?: (item: T) => string | number | undefined;
  getOptionLabel: (item: T) => string | number;
}

export function StoneXSelectList<T>(props: StoneXSelectListProps<T>) {
  const { label, list, selectedItems, onSelect, getId, getGroup, getOptionLabel } = props;

  type IdMap = { [key: string | number]: boolean };

  const selectedIdMap = useMemo<IdMap>(() => {
    if (!selectedItems) return {};
    return selectedItems.reduce((map, item) => {
      map[getId(item)] = true;
      return map;
    }, {} as IdMap);
  }, [selectedItems, getId]);

  const flattenedList = useMemo(() => {
    return list.map((item, index) => ({ item, index }));
  }, [list]);

  const groupedList = useMemo(() => {
    return list.reduce((groups, item) => {
      const group = getGroup ? getGroup(item) : undefined;
      const existingGroup = groups.find(([key]) => key === group);

      if (existingGroup) {
        existingGroup[1].push(item);
      } else {
        groups.push([group, [item]]);
      }
      return groups;
    }, [] as [string | number | undefined, T[]][]);
  }, [list, getGroup]);

  const lastSelectedIndex = useRef<number | undefined>(undefined);

  function getItemsInRange(startIndex: number, endIndex: number): T[] {
    const start = Math.min(startIndex, endIndex);
    const end = Math.max(startIndex, endIndex);
    return flattenedList
      .slice(start, end + 1)
      .map(({ item }) => item)
      .filter(item => !selectedIdMap[getId(item)]);
  }

  function select(item: T, controlPressed: boolean, shiftPressed: boolean) {
    const currentIndex = flattenedList.findIndex(x => getId(x.item) === getId(item));
    
    if (shiftPressed && lastSelectedIndex.current !== undefined) {
      const itemsToSelect = getItemsInRange(lastSelectedIndex.current, currentIndex);
      onSelect(itemsToSelect, controlPressed, shiftPressed);
    } else {
      lastSelectedIndex.current = currentIndex;
      onSelect([item], controlPressed, shiftPressed);
    }
  }

  function selectGroup(group: [string | number | undefined, T[]], controlPressed: boolean, shiftPressed: boolean) {
    const firstItemInGroup = group[1][0];
    const currentIndex = flattenedList.findIndex(x => getId(x.item) === getId(firstItemInGroup));
    
    if (shiftPressed && lastSelectedIndex.current !== undefined) {
      const itemsToSelect = getItemsInRange(lastSelectedIndex.current, currentIndex + group[1].length - 1);
      onSelect(itemsToSelect, controlPressed, shiftPressed);
    } else {
      const unselectedItems = group[1].filter(x => !selectedIdMap[getId(x)]);
      lastSelectedIndex.current = currentIndex;
      onSelect(unselectedItems, controlPressed, shiftPressed);
    }
  }

  return (
    <StoneXLabeledItem label={label} fullWidth fullHeight>
      <List style={{height: '100%', padding: 0, border: '1px solid var(--box-border-color)'}}>
        {groupedList.map((group, index) => (
          <div key={index}>
            {group[0] !== undefined && (
              <ListItemButton 
                sx={{height: '40px'}} 
                onClick={(event) => selectGroup(group, event.ctrlKey, event.shiftKey)}>
                  <strong>{group[0]}</strong>
              </ListItemButton>
            )}
            {group[1].map((item) => (
              <ListItemButton 
                sx={{
                  height: '40px',
                  paddingLeft: group[0] !== undefined ? '32px' : undefined
                }} 
                key={getId(item)} 
                selected={selectedIdMap[getId(item)]} 
                onClick={(event) => select(item, event.ctrlKey, event.shiftKey)}>
                  <ListItemText primary={getOptionLabel(item)} />
              </ListItemButton>
            ))}
          </div>
        ))}
      </List>
    </StoneXLabeledItem>
  );
}