import { groupBy, sum } from 'lodash';
import { BudgetVarianceModel } from '../../../../../../Generated/Commodity-Management-Api';
import { ModelToRowTransformer } from '../../../../../../Helpers/ModelToRowTransformer';
import { VarianceReportRow } from './Types';

export class BudgetVarianceModelToRowTransformer extends ModelToRowTransformer {
    static budgetVarianceModelToRow(budgetVariance: BudgetVarianceModel, quoteDate: Date): VarianceReportRow {
        const underlyingComponent = budgetVariance.components.find((x) => x.componentType == 'Underlying' && x.componentSubType == null);
        const underlyingFixedComponent = budgetVariance.components.find((x) => x.componentType?.toString() == 'Underlying' && x.componentSubType == "Fixed");
        const underlyingCallComponent = budgetVariance.components.find((x) => x.componentType?.toString() == 'Underlying' && x.componentSubType == "Call");
        const underlyingNewFixedComponent = budgetVariance.components.find((x) => x.componentType?.toString() == 'Underlying' && x.componentSubType == "QuoteMonthFixed");
        const underlyingNewCallComponent = budgetVariance.components.find((x) => x.componentType?.toString() == 'Underlying' && x.componentSubType == "QuoteMonthCall");
        const basisComponent = budgetVariance.components.find((x) => x.componentType == 'Basis' && x.componentSubType == "");

        return {
            path: [''],

            // Budgets
            budgetValue: budgetVariance.budgetValue,

            // Tags
            commodity: budgetVariance.commodity,
            budgetDate: budgetVariance.budgetDate,
            location: budgetVariance.location,
            locationHierarchy: budgetVariance.locationHierarchy,

            // For single column structure
            // rowBudgetVolume: 0,
            // rowBudgetPrice: 0,
            // rowHedgedVolume: 0,
            // rowHedgedPercent: 0,
            // rowHedgedPrice: 0,
            // rowMarketPrice: 0,
            // rowOpenVolume: 0,
            // rowBlendedPrice: 0,

            // All In
            effectiveBudgetVolume: budgetVariance.effectiveBudgetVolume,
            budgetVolume: budgetVariance.volume,
            budgetPrice: budgetVariance.price,
            hedgedVolume: budgetVariance.allInHedgedVolume,
            hedgedPercent: budgetVariance.allInHedgedPercent,
            hedgedPrice: budgetVariance.allInHedgedPrice,
            marketPrice: budgetVariance.marketPrice!,
            openVolume: 0, // Todo: How do you determine all in open volume? Can you?
            blendedPrice: budgetVariance.blendedPrice,
            blendedValue: budgetVariance.blendedValue,

            // Underlying
            underlyingBudgetPrice: underlyingComponent?.budgetPrice,
            underlyingHedgedVolume: underlyingComponent?.hedgedVolume,
            underlyingHedgedPercent: underlyingComponent?.hedgedPercent,
            underlyingHedgedPrice: underlyingComponent?.hedgedPrice,
            underlyingMarketPrice: underlyingComponent?.marketPrice,
            underlyingOpenVolume: underlyingComponent?.openVolume,
            underlyingBlendedPrice: underlyingComponent?.blendedPrice,
            underlyingBlendedValue: underlyingComponent?.blendedValue,

            underlyingFixedHedgeVolume: underlyingFixedComponent?.hedgedVolume,
            underlyingFixedHedgedPercent: underlyingFixedComponent?.hedgedPercent,
            underlyingFixedHedgedPrice: underlyingFixedComponent?.hedgedPrice,
            fixedMarketValue: underlyingFixedComponent?.varianceToMarketValue,

            underlyingCallHedgeVolume: underlyingCallComponent?.hedgedVolume,
            underlyingCallStrikePrice: underlyingCallComponent?.callStrikePrice,
            optionMarketValue: underlyingCallComponent?.markToMarketValue,
            netOptionPremium: underlyingCallComponent?.premium,

            quoteMonthFixedHedgedVolume: underlyingNewFixedComponent?.hedgedVolume,
            quoteMonthFixedHedgedPrice: underlyingNewFixedComponent?.hedgedPrice,
            quoteMonthCallHedgedVolume: underlyingNewCallComponent?.hedgedVolume,
            quoteMonthCallPremiumPrice: underlyingNewCallComponent?.hedgedPrice,
            quoteMonthCallStrikePrice: underlyingNewCallComponent?.callStrikePrice,

            // Basis
            basisBudgetPrice: basisComponent?.budgetPrice,
            basisHedgedVolume: basisComponent?.hedgedVolume,
            basisHedgedPercent: basisComponent?.hedgedPercent,
            basisHedgedPrice: basisComponent?.hedgedPrice,
            basisMarketPrice: basisComponent?.marketPrice,
            basisOpenVolume: basisComponent?.openVolume,
            basisBlendedPrice: basisComponent?.blendedPrice,
            basisBlendedValue: basisComponent?.blendedValue,

            // Variance
            marketValue: budgetVariance.marketValue,
            varianceToBudgetPrice: budgetVariance.varianceToBudgetPrice,
            varianceToBudgetValue: budgetVariance.varianceToBudgetValue,
            varianceToMarketPrice: budgetVariance.varianceToMarketPrice,
            varianceToMarketValue: budgetVariance.varianceToMarketValue,

            markToMarketValue: budgetVariance.markToMarketValue
        };
    }

    static aggregateVarianceReportRows(rows: VarianceReportRow[], path: string[]): VarianceReportRow {
        // Need to know current grouping & parent groupings

        // Certain fields can only be aggregated if one of the parent groupByOptions is 'commodity'
        // And would also have to assumes units & currencies are the same

        const isSameCommodity = super.areAllPropertiesSame(rows, 'commodity');
        const isSameLocation = super.areAllPropertiesSame(rows, 'location');
        const isSameBudgetDate = super.areAllPropertiesSame(rows, 'budgetDate');
        return {
            path: path,
            isAggregated: true,

            // Budgets
            budgetValue: sum(rows.map(x => x.budgetValue)),

            // Tags
            commodity: isSameCommodity ? rows[0]?.commodity : undefined,
            budgetDate: isSameBudgetDate ? rows[0]?.budgetDate : undefined,
            location: isSameLocation ? rows[0]?.location : undefined,

            // For single column structure
            // rowBudgetVolume: 0,
            // rowBudgetPrice: 0,
            // rowHedgedVolume: 0,
            // rowHedgedPercent: 0,
            // rowHedgedPrice: 0,
            // rowMarketPrice: 0,
            // rowOpenVolume: 0,
            // rowBlendedPrice: 0,

            // All In
            effectiveBudgetVolume: sum(rows.map((x) => x.effectiveBudgetVolume)),
            budgetVolume: sum(rows.map((x) => x.budgetVolume)),
            budgetPrice: super.weightedAverage(rows, x => x.budgetPrice, x => x.effectiveBudgetVolume),
            hedgedVolume: sum(rows.map((x) => x.hedgedVolume)),
            hedgedPercent: super.weightedAverage(rows, x => x.hedgedPercent, x => x.effectiveBudgetVolume), // ToDo: Double check this. It should produce the same result but not sure.
            hedgedPrice: super.weightedAverage(rows, x => x.hedgedPrice, x => x.hedgedVolume),
            marketPrice: super.weightedAverage(rows, x => x.marketPrice, x => x.openVolume), // If total open volume is 0, then this is weighted on budget volume?
            openVolume: sum(rows.map((x) => x.openVolume)),
            blendedPrice: super.weightedAverage(rows, x => x.blendedPrice, x => x.effectiveBudgetVolume), // This used to be weighted by component volume, but that doesn't make sense
            blendedValue: sum(rows.map((x) => x.blendedValue)),

            // Underlying
            underlyingBudgetPrice: super.weightedAverage(rows, x => x.underlyingBudgetPrice, x => x.effectiveBudgetVolume),
            underlyingHedgedVolume: sum(rows.map((x) => x.underlyingHedgedVolume)),
            underlyingHedgedPercent: super.weightedAverage(rows, x => x.underlyingHedgedPercent, x => x.effectiveBudgetVolume), // ToDo: Double check this. It should produce the same result but not sure.
            underlyingHedgedPrice: super.weightedAverage(rows, x => x.underlyingHedgedPrice, x => x.underlyingHedgedVolume),
            underlyingMarketPrice: super.weightedAverage(rows, x => x.underlyingMarketPrice, x => x.underlyingOpenVolume), // If total open volume is 0, then this is weighted on budget volume?
            underlyingOpenVolume: sum(rows.map((x) => x.underlyingOpenVolume)),
            underlyingBlendedPrice: super.weightedAverage(rows, x => x.underlyingBlendedPrice, x => x.effectiveBudgetVolume), // This used to be weighted by component volume, but that doesn't make sense
            underlyingBlendedValue: sum(rows.map((x) => x.underlyingBlendedValue)),
            underlyingFixedHedgeVolume: sum(rows.map((x) => x.underlyingFixedHedgeVolume)),
            quoteMonthFixedHedgedVolume: sum(rows.map((x) => x.quoteMonthFixedHedgedVolume)),
            quoteMonthFixedHedgedPrice: super.weightedAverage(rows, x => x.quoteMonthFixedHedgedPrice, x => x.quoteMonthFixedHedgedVolume),
            quoteMonthCallHedgedVolume: sum(rows.map((x) => x.quoteMonthCallHedgedVolume)),
            quoteMonthCallPremiumPrice: super.weightedAverage(rows, x => x.quoteMonthCallPremiumPrice, x => x.quoteMonthCallHedgedVolume),
            quoteMonthCallStrikePrice: super.weightedAverage(rows, x => x.quoteMonthCallStrikePrice, x => x.quoteMonthCallHedgedVolume),
            underlyingCallStrikePrice: super.weightedAverage(rows, x => x.underlyingCallStrikePrice, x => x.underlyingCallHedgeVolume),
            fixedMarketValue: sum(rows.map((x) => x.fixedMarketValue)),
            optionMarketValue: sum(rows.map((x) => x.optionMarketValue)),
            netOptionPremium: sum(rows.map((x) => x.netOptionPremium)),
            // Basis
            basisBudgetPrice: super.weightedAverage(rows, x => x.basisBudgetPrice, x => x.effectiveBudgetVolume),
            basisHedgedVolume: sum(rows.map((x) => x.basisHedgedVolume)),
            basisHedgedPercent: super.weightedAverage(rows, x => x.hedgedPercent, x => x.effectiveBudgetVolume), // ToDo: Double check this. It should produce the same result but not sure.
            basisHedgedPrice: super.weightedAverage(rows, x => x.basisHedgedPrice, x => x.basisHedgedVolume),
            basisMarketPrice: super.weightedAverage(rows, x => x.basisMarketPrice, x => x.basisOpenVolume), // If total open volume is 0, then this is weighted on budget volume?
            basisOpenVolume: sum(rows.map((x) => x.basisOpenVolume)),
            basisBlendedPrice: super.weightedAverage(rows, x => x.basisBlendedPrice, x => x.effectiveBudgetVolume), // This used to be weighted by component volume, but that doesn't make sense
            basisBlendedValue: sum(rows.map((x) => x.basisBlendedValue)),

            // Variance
            marketValue: sum(rows.map(x => x.marketValue)),
            varianceToBudgetPrice: super.weightedAverage(rows, x => x.varianceToBudgetPrice, x => x.effectiveBudgetVolume), // Weighting by effective budget volume (actual volume or budget volume)
            varianceToBudgetValue: sum(rows.map((x) => x.varianceToBudgetValue)),
            varianceToMarketPrice: super.weightedAverage(rows, x => x.varianceToBudgetPrice, x => x.effectiveBudgetVolume), // Weighting by effective budget volume (actual volume or budget volume)
            varianceToMarketValue: sum(rows.map((x) => x.varianceToMarketValue)),
            markToMarketValue: sum(rows.map((x) => x.markToMarketValue))
        } as VarianceReportRow;
    }

    static getTotalByMonth(rows: VarianceReportRow[], path: string[]): VarianceReportRow[] {
        const rowsByMonth = groupBy(rows, x => x.budgetDate!.label!);

        // Add totals group
        const totalsByMonth = Object.entries(rowsByMonth).map(([key, rows]) => {
            const monthPath = [...path, 'Totals by month', key];
            const monthRow = this.aggregateVarianceReportRows(rows, monthPath);
            return monthRow;
        })

        return totalsByMonth;
    }
}