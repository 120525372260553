import { CircularProgress } from '@mui/material'
import { ReactNode } from 'react'

export default function StoneXMainPage(props: {children?: ReactNode, isLoading?: boolean}) {
  return (
    <div className='main-page'>
      {props.isLoading && <CircularProgress />}
      {!props.isLoading && props?.children}
    </div>
  )
}
