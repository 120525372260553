import { MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { NoteModel } from '../../../../Generated/Commodity-Management-Api';
import { StoneXPopupMenu } from '../../../StoneXMui';
import NoteUpdate from './NoteUpdate';
import './NotesPage.css';

interface NoteProps {
  note?: NoteModel;
  onSave: (note: NoteModel) => void;
  onDeleteStart: (note: NoteModel) => void;
}

export default function Note(props: NoteProps) {
  const { note, onSave, onDeleteStart } = props;

  const [isEditing, setIsEditing] = useState<boolean>(note === undefined); // Edit the note if it's a new note to be added.

  function internalOnSave(note: NoteModel) {
    setIsEditing(false);
    onSave(note);
  }

  if (isEditing) {
    return <NoteUpdate note={note} onSave={internalOnSave} onCancel={() => setIsEditing(false)} />;
  } else {
    return (
      <div key={note!.noteId} className="note-container">
        <div className="note-header">
          <h3>{note!.subject}</h3>
          <span>
            <span className="note-date">{note!.createdAt && dayjs(note!.createdAt).format('MM/DD/YY hh:mmA')}</span>
            <StoneXPopupMenu iconButtonProps={{size: 'small', sx: {display: 'inline'}}}>
                <MenuItem onClick={() => setIsEditing(true)}>Edit</MenuItem>
                <MenuItem onClick={() => onDeleteStart(note!)}>Delete</MenuItem>
            </StoneXPopupMenu>
          </span>
        </div>
        <div className="note-body">{note!.content}</div>
        <span className="note-attribution">-- {note!.marketViewUsername}</span>
      </div>
    );
  }
}
