import { Store } from '@reduxjs/toolkit';
import { LicenseManager } from 'ag-grid-enterprise';
import axios, { AxiosRequestConfig } from 'axios';
import { registerAllModules } from 'handsontable/registry';
import LoggingService from '../Core/Logging/LoggingService';
import store from '../Redux/ReduxStore';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

// Service that will initialize the application with base settings to be able to bootstrap and start it.
class ApplicationInitializationService {
    private registerApiInterceptorsCalled = false;

    private stores: { [key: string]: Store } = { default: store };

    getStore = (storeId?: string): Store => this.stores[storeId ?? 'default'];

    registerAll = (isMono: boolean, newStore: Store, storeId?: string): void => {
        // applicationSettings.isMono = isMono;

        this.registerReduxStore(newStore, storeId);
        this.registerAgGridLicense();
        this.registerApiInterceptors();
        this.registerHandsontable();
        
        dayjs.extend(utc);
    };

    registerReduxStore = (newStore: Store, storeId?: string): void => {
        this.stores[storeId ?? 'default'] = newStore;

        // Always set the default store to the first one that gets registered.
        if (!this.stores.default || this.stores.default === store) {
            this.stores.default = newStore;
        }
    };

    registerAgGridLicense = (): void => {
        LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-053353}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{StoneX_Group_Inc}_is_granted_a_{Multiple_Applications}_Developer_License_for_{10}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{16_March_2025}____[v3]_[01]_MTc0MjA4MzIwMDAwMA==50f969d0e2994e9040e005298bbcc30a");
    };

    registerHandsontable = (): void => {
        registerAllModules();
    }

    registerApiInterceptors = (): void => {
        if (this.registerApiInterceptorsCalled) {
            return;
        }

        axios.defaults.headers.common['Content-Type'] = 'application/json';
        // axios.defaults.headers.common['Raven-Environment'] = applicationSettings.ravenEnvironment;
        // axios.defaults.headers.common['Rift-Environment'] = applicationSettings.ravenEnvironment;

        axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
            const oktaString = localStorage.getItem('okta-token-storage');
            const accessToken = oktaString ? JSON.parse(oktaString)?.accessToken?.accessToken : null;

            if (accessToken) {
                config!.headers!.Authorization = `Bearer ${accessToken}`;
            }

            const siteGuid = this.getStore().getState().activeSite.site?.siteGuid;

            if (siteGuid) {
                config!.headers!['X-Site-Guid'] = siteGuid;
            }

            return config;
        });

        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                LoggingService.trackException(error, `HTTP Error Response (${error.response?.status})`);

                return Promise.reject(error);
            },
        );

        this.registerApiInterceptorsCalled = true;
    };
}

// Intercept all ajax calls because we need to re-write the lightstreamer endpoints.
// We cannot modify the lightstreamer code to have separate HTTP and Websockets prefixes.
// Therefore we have to use interception to make this work. This allows it to work with
// the storefront.
// DO NOT MAKE THIS AN ARROW FUNCTION!
// if (applicationSettings.environment === 'local' || applicationSettings.environment === 'production') {
//     const originalOpen = XMLHttpRequest.prototype.open;
//     XMLHttpRequest.prototype.open = function open(method: string, url: any, isAsync?: boolean, username?: string, password?: string) {
//         let newUrl = url;
//         if (url.indexOf('/lightstreamer/') > 0) {
//             newUrl = url.replace('/lightstreamer/', '/lightstreamer-session/');
//         }

//         return originalOpen.call(this, method, newUrl, isAsync ?? false, username, password);
//     };
// }

const applicationInitializationService = new ApplicationInitializationService();

export default applicationInitializationService;
