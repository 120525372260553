import { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { CommodityManagementApi } from '../../../Apis/Apis';
import { CommodityManagementSiteAccessModel } from '../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../Hooks';
import { RootState, useApplicationDispatch, useApplicationSelector } from '../../../Redux/ReduxStore';
import { resetCommodityManagementState, setCommodityManagementState } from '../../../Redux/Slices/CommodityManagementSlice';
import { StoneXLoading } from '../../StoneX';
import UpdateBudgetsPage from './Budgets/UpdateBudgetsPage/UpdateBudgetsPage';
import ViewBudgetsPage from './Budgets/ViewBudgetsPage/ViewBudgetsPage';
import UpdatePositions from './Positions/UpdatePositionsPage/UpdatePositions';
import ViewPositionsPage from './Positions/ViewPositionsPage/ViewPositionsPage';
import ReportsPage from './Reports/ReportsPage';
import SettingsPage from './Settings/SettingsPage';
import NotesPage from './Notes/NotesPage';

interface CommodityManagementSolutionProps {
}

export default function CommodityManagementSolution(props: CommodityManagementSolutionProps) {

  const dispatch = useApplicationDispatch();
  const activeSite = useApplicationSelector((state: RootState): CommodityManagementSiteAccessModel => state.activeSite.site);

  const customerDataLoadingState = useLoadingState();
  const customerSettingsLoadingState = useLoadingState();
  const customerReportsLoadingState = useLoadingState();

  async function setSiteData() {
    const getCustomerDataPromise = CommodityManagementApi.getCustomerData(customerDataLoadingState.setLoadingState);
    const getCustomerSettingsPromise = CommodityManagementApi.getCustomerSettings(customerSettingsLoadingState.setLoadingState);
    const getCustomerReportsPromise = CommodityManagementApi.getCustomerReports(customerReportsLoadingState.setLoadingState);

    await Promise.all([getCustomerDataPromise, getCustomerSettingsPromise, getCustomerReportsPromise]);
    
    dispatch(
      setCommodityManagementState({
        customerData: (await getCustomerDataPromise).data,
        customerSettings: (await getCustomerSettingsPromise).data,
        customerReports: (await getCustomerReportsPromise).data.rows ?? []
      })
    )
  }

  function resetSiteData() {
    dispatch(resetCommodityManagementState());
  }

  useEffect(() => {
    if (!activeSite) return;
    // ToDo: Refresh this data every once in a while?
    setSiteData();
    return resetSiteData;
  }, [activeSite]);

  if (activeSite) {
    
    if (!customerDataLoadingState.isLoaded() || !customerSettingsLoadingState.isLoaded() || !customerReportsLoadingState.isLoaded()) {
      return <StoneXLoading show/>
    }

    return (
      // Setting the site's guid as the key will force the component to reload when the site changes
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} key={activeSite.siteGuid}>
        <Routes>
          <Route path="settings" element={<SettingsPage />} />
          <Route path="budgets" element={<ViewBudgetsPage />} />
          <Route path="budgets/update" element={<UpdateBudgetsPage />} />
          <Route path="positions" element={<ViewPositionsPage />} />
          <Route path="positions/update" element={<UpdatePositions />} />
          <Route path="reports" element={<ReportsPage />} />
          <Route path="notes" element={<NotesPage />} />
        </Routes>
      </div>
    );
  }
  else {
    return <h3>Please select a site to begin</h3>;
  }
}
