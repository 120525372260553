import { Button } from '@mui/material';
import { debounce, isEmpty, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CommodityManagementApi } from '../../../../Apis/Apis';
import { NoteModel } from '../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../Hooks';
import { StoneXLoading, StoneXRow } from '../../../StoneX';
import StoneXMainPage from '../../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXPagination } from '../../../StoneX/StoneXPagination/StoneXPagination';
import { StoneXDatePicker, StoneXSelect, StoneXTextField } from '../../../StoneXMui';
import Note from './Note';
import { NoteDeleteModal } from './NoteDeleteModal';
import NoteUpdate from './NoteUpdate';
import './NotesPage.css';
import dayjs from 'dayjs';

export default function NotesPage() {
  const loadingState = useLoadingState();
  const [notes, setNotes] = useState<NoteModel[]>();

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [dateStartFilter, setDateStartFilter] = useState<Date | null>(null);
  const [dateEndFilter, setDateEndFilter] = useState<Date | null>(null);
  const [authorFilter, setAuthorFilter] = useState<string | null>('');
  const authors = useMemo(() => uniq(notes?.map((x) => x.marketViewUsername)), [notes]);

  const [isAddingNote, setIsAddingNote] = useState<boolean>(false);
  const [paginatedNotes, setPaginatedNotes] = useState<NoteModel[]>();
  const [noteToBeDeleted, setNoteToBeDeleted] = useState<NoteModel>();
  const filteredNotes = useMemo(getFilteredNotes, [searchFilter, dateStartFilter, dateEndFilter, authorFilter, notes]);

  function getNotes() {
    CommodityManagementApi.listNotes(loadingState.setLoadingState).then((res) => setNotes(res.data.rows ?? []));
  }

  function onAdd(note: NoteModel) {
    setNotes((previous) => [note, ...(previous ?? [])]);
    setIsAddingNote(false);
  }

  function onAddCancel() {
    setIsAddingNote(false);
  }

  function onSave(note: NoteModel) {
    setNotes((previous) => previous?.map((x) => (x.noteId == note.noteId ? note : x)));
  }

  function onDelete(note: NoteModel) {
    setNotes((previous) => previous?.filter((x) => x.noteId !== note.noteId));
    closeDeleteNoteModal();
  }

  function showDeleteNoteModal(note: NoteModel) {
    setNoteToBeDeleted(note);
  }

  function closeDeleteNoteModal() {
    setNoteToBeDeleted(undefined);
  }

  function getFilteredNotes() {
    const regex = new RegExp(searchFilter, 'i');

    const hasMatch = (note: NoteModel): boolean => {
      if (regex.test(note.subject)) {
        return true;
      }
      if (regex.test(note.content)) {
        return true;
      }

      return false;
    };

    const isAfterStartFilter = (note: NoteModel): boolean => {
      const day = dayjs(note.createdAt);
      return day.isSame(dateStartFilter, 'day') || day.isAfter(dateStartFilter, 'day');
    };

    const isBeforeEndFilter = (note: NoteModel): boolean => {
      const day = dayjs(note.createdAt);
      return day.isSame(dateEndFilter, 'day') || day.isBefore(dateEndFilter, 'day');
    };

    const isSameAuthor = (note: NoteModel): boolean => {
      return note.marketViewUsername === authorFilter;
    };

    return notes?.filter((x) => {
      if (!isEmpty(searchFilter.trim()) && !hasMatch(x)) {
        return false;
      }

      if (dateStartFilter !== null && !isAfterStartFilter(x)) {
        return false;
      }

      if (dateEndFilter !== null && !isBeforeEndFilter(x)) {
        return false;
      }

      if (!isEmpty(authorFilter) && !isSameAuthor(x)) {
        return false;
      }

      return true;
    });
  }

  const setSearchFilterWithDebounce = debounce((filter: string) => {
    setSearchFilter(filter);
  }, 150);

  useEffect(getNotes, []);

  return (
    <StoneXMainPage>
      <StoneXRow>
        <h1>Notes</h1>
        <Button className="pull-right" onClick={() => setIsAddingNote(true)}>
          Add Note
        </Button>
      </StoneXRow>
      <StoneXLoading show={loadingState.isLoading()} />
      {loadingState.isLoaded() && (
        <div style={{ maxWidth: '1000px' }}>
          <StoneXRow>
            <StoneXTextField placeholder="Search" onUpdate={setSearchFilterWithDebounce} />
            {showFilters && (
              <>
                <StoneXDatePicker value={dateStartFilter} width="small" onChange={setDateStartFilter} label="Start Date" />
                <StoneXDatePicker value={dateEndFilter} width="small" onChange={setDateEndFilter} label="End Date" />
                <StoneXSelect
                  width="small"
                  value={authorFilter}
                  clearable
                  options={authors}
                  getId={(x) => x}
                  getOptionLabel={(x) => x}
                  onChange={setAuthorFilter}
                />
              </>
            )}
            <Button variant="text" onClick={() => setShowFilters((previous) => !previous)}>
              {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>
          </StoneXRow>
          {isAddingNote && <NoteUpdate onSave={onAdd} onCancel={onAddCancel} />}
          {paginatedNotes?.map((note) => (
            <Note key={note.noteId} note={note} onSave={onSave} onDeleteStart={showDeleteNoteModal} />
          ))}
          {notes && notes.length > 0 && filteredNotes?.length == 0 && <p>No notes match the filter criteria</p>}
          {notes && notes.length == 0 && <p>Add notes here that will be visible to other users</p>}
          <StoneXRow>
            <StoneXPagination className="pull-right" items={filteredNotes ?? []} onVisibleItemsUpdate={setPaginatedNotes} />
          </StoneXRow>
        </div>
      )}
      <NoteDeleteModal note={noteToBeDeleted!} open={noteToBeDeleted !== undefined} onClose={closeDeleteNoteModal} onDelete={onDelete} />
    </StoneXMainPage>
  );
}
