import { Add, Close, Description } from '@mui/icons-material';
import { Box, Tab, Tabs, Button, ButtonGroup, Chip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ColDef, GetRowIdParams, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import React, { ReactElement, ReactNode, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { MarkItViewApi } from '../../../../Apis/Apis';
import {
  MarketViewProductTypeModel,
  // MarketViewProductSymbolView,
  CommodityType,
  PriceType,
  Unit,
  CommodityManagementSiteModel,
} from '../../../../Generated/Mark-It-View-Api';
import { useListResource, useLoadingState, useMtmDate } from '../../../../Hooks';
import { StoneXAutocomplete, StoneXModal, StoneXTextField } from '../../../StoneXMui';
import StoneXMainPage from '../../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXRow } from '../../../StoneX';
import { EnumHelper } from '../../../../Helpers/EnumHelper';

export default function Sites() {
  const gridRef = useRef<AgGridReact<CommodityManagementSiteModel>>(null);
  const [sites, setSites] = useState<CommodityManagementSiteModel[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalSite, setModalSite] = useState<CommodityManagementSiteModel | undefined>();
  const saveSiteLoadingState = useLoadingState();

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  useEffect(() => {
    MarkItViewApi.listCommodityManagementSites(null).then((res) => setSites(res.data.rows ?? []));
  }, []);

  const columnDefs = [
    {
      headerName: 'Site Name',
      field: 'siteName',
      width: 150,
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Customer Name',
      field: 'customerName',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'DataBase Name',
      field: 'databaseName',
      sortable: true,
      width: 50,
      flex: 1,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 100,
      cellRenderer: (params: any) => {
        return (
          <ButtonGroup variant="text">
            <Button
              onClick={() => {
                updateSite(params.data);
              }}
            >
              Edit
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];
  function updateSite(site: CommodityManagementSiteModel) {
    setModalSite(site);
    setModalOpen(true);
  }

  function saveSite(siteToSave: CommodityManagementSiteModel) {
    if (!siteToSave.siteGuid) {
      //add
      MarkItViewApi.addCommodityManagementSite(saveSiteLoadingState.setLoadingState, siteToSave.siteGuid, siteToSave.siteName, siteToSave.customerName).then((res) =>
        setSites((previous) => [...previous, res.data as CommodityManagementSiteModel]),
      );
    } else {
      //todo
    }
  }
  function addSite() {
    setModalSite(undefined);
    setModalOpen(true);
  }
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      resizable: true,
      suppressMenu: true,
    };
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
  }, []);

  return (
    <StoneXMainPage>
      <StoneXRow>
        <h3>Sites</h3>
        <input type="text" id="filter-text-box" placeholder="Filter..." onChange={onFilterTextBoxChanged} />
        <Button className="pull-right" variant="outlined" onClick={addSite}>
          Add
        </Button>
      </StoneXRow>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          getRowId={(params: GetRowIdParams) => params.data.productId}
          enableRangeSelection
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          rowData={sites}
          pagination={true}
          paginationPageSize={20}
        />
      </div>

      <UpdateSiteModal open={modalOpen} onClose={() => setModalOpen(false)} onSiteSave={saveSite} site={modalSite} />
    </StoneXMainPage>
  );
}

interface UpdateSiteProps {
  open: boolean;
  onClose: () => void;
  onSiteSave: (site: CommodityManagementSiteModel) => void;
  site?: CommodityManagementSiteModel;
}

function UpdateSiteModal(props: UpdateSiteProps) {
  const { open, onClose, onSiteSave, site } = props;
  const [siteName, setSiteName] = useState<string>();
  const [customerName, setCustomerName] = useState<string>();

  const actionLabel = props.site ? 'Update' : 'Add';

  useEffect(() => {
    setSiteName(site?.siteName ?? '');
  }, [site]);
  useEffect(() => {
    setCustomerName(site?.customerName ?? '');
  }, [site]);

  function updateSite() {
    //todo: validate

    onSiteSave({
      siteGuid: '',
      oldId: 0,
      siteName: siteName!,
      customerName: customerName!,
    });

    onClose();
  }

  return (
    <StoneXModal header={actionLabel + ' Site'} open={open} onClose={onClose}>
      <StoneXRow>
        <StoneXTextField label="Name" value={siteName} onChange={(event: any) => setSiteName(event.target.value)} />
        <StoneXTextField label="Customer Name" value={customerName} onChange={(event: any) => setCustomerName(event.target.value)} />
      </StoneXRow>

      <StoneXRow justify="end">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={updateSite}>{actionLabel + ' Site'}</Button>
      </StoneXRow>
    </StoneXModal>
  );
}
