import { Button, ButtonGroup, Grid } from '@mui/material';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { CommodityManagementApi } from '../../../../../../Apis/Apis';
import {
  CustomerReportModel,
  GetCustomerDataResponse,
  GetCustomerSettingsResponse,
  PositionModel,
  TimeTriggerModel,
  TimeTriggerReportModel,
} from '../../../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../../../Hooks';
import { RootState, useApplicationSelector } from '../../../../../../Redux/ReduxStore';
import { StoneXLoading, StoneXRow } from '../../../../../StoneX';
import { StoneXButton, StoneXDatePicker, StoneXNumber, StoneXPercent, StoneXTextField } from '../../../../../StoneXMui';
import { ReportProps } from '../../ReportsPage';
import { PlayArrow, Download } from '@mui/icons-material';

export interface TimeTriggerReportProps {
  customerName?: string;
  customerReport: CustomerReportModel;
  customerData: GetCustomerDataResponse;
  customerSettings: GetCustomerSettingsResponse;
  quoteDate: Date;
}

export default function TimeTriggerReport(props: ReportProps) {
  const { customerReportId, quoteDate: mtmDate } = props;

  const customerReport = useApplicationSelector((state: RootState): CustomerReportModel => state.commodityManagement.customerReports.find((x: any) => x.customerReportId === customerReportId)!);

  const [quoteDate, setQuoteDate] = useState<Date | null>(mtmDate);
  const [quoteDateString, setQuoteDateString] = useState<string | null>();
  const [budgetMonthString, setBudgetMonthString] = useState<string | null>();
  const [numberOfMonths, setNumberOfMonths] = useState<number | null>(0);
  const [minimumPercentage, setMinimumPercentage] = useState<number | null>(0);
  const [maximumPercentage, setMaximumPercentage] = useState<number | null>(0);

  const reportLoadingState = useLoadingState();
  const [monthExpanded, setmonthExpanded] = useState<boolean | null>();
  const [triggers, setTriggers] = useState<TimeTriggerReportModel[]>([]);

  const [contractsToHedge, setContractsToHedge] = useState<TimeTriggerModel[]>([]);

  const [selectContractsToHedge, setSelectedContractsToHedge] = useState<TimeTriggerModel[]>([]);
  const [selectedBudgetMonth, setSelectedBudgetMonth] = useState<TimeTriggerReportModel>();

  const gridApiRef = useRef<AgGridReact>(null);

  const runReport = () => {
    setContractsToHedge([]);
    setmonthExpanded(false);
    CommodityManagementApi.getTimeTriggerReport(
      reportLoadingState.setLoadingState,
      quoteDateString!,
      numberOfMonths!,
      minimumPercentage!,
      maximumPercentage!,
    ).then((res) => setTriggers(res.data.rows ?? []));
  };
  const setContracts = () => {
    setContractsToHedge([]);
    var c = contractsToHedge;
    triggers.forEach((p) => {
      var currentContractMonth = p.timeTriggers.at(0)?.contractMonth;
      var t: TimeTriggerModel = {
        contractMonth: p.timeTriggers.at(p.timeTriggers.length - 1)?.contractMonth,
        minimumHedge: p.timeTriggers.find((f) => f.contractMonth == currentContractMonth)?.minimumHedge,
        maximumHedge: p.timeTriggers.find((f) => f.contractMonth == currentContractMonth)?.maximumHedge,
      };

      c.push(t);
    });
    setContractsToHedge(c);
    if (gridApiRef.current?.api != undefined) {
      gridApiRef.current?.api.setRowData(contractsToHedge);
    }
  };

  const showMonthBreakout = (index: number | null) => {
    var budgetMonth = triggers[index!];
    setSelectedContractsToHedge(budgetMonth.timeTriggers);
    setSelectedBudgetMonth(budgetMonth);
    setmonthExpanded(true);
  };

  const columns: ColDef<TimeTriggerModel>[] = [
    {
      colId: 'Contract Month',
      headerName: 'Contract Month',
      field: 'contractMonth.label',
      flex: 1,
    },
    {
      colId: 'Minimum Contracts',
      headerName: 'Minimum Contracts',
      field: 'minimumHedge',
      cellRenderer: (params: ICellRendererParams<PositionModel>) =>
        params.node.group ? null : <StoneXNumber number={params.value} decimals={0}></StoneXNumber>,
      flex: 1,
    },
    {
      colId: 'Maximum Contracts',
      headerName: 'Maximum Contracts',
      field: 'maximumHedge',
      cellRenderer: (params: ICellRendererParams<PositionModel>) =>
        params.node.group ? null : <StoneXNumber number={params.value} decimals={0}></StoneXNumber>,
      flex: 1,
    },
  ];

  const numberOfMonthsChange = (event: any) => {
    const isNumber = Number.isFinite(parseInt(event.target.value));
    const numberOfMonths = isNumber ? parseInt(event.target.value) : null;
    setNumberOfMonths(numberOfMonths);
  };
  const minimumChange = (event: any) => {
    const isNumber = Number.isFinite(parseInt(event.target.value));
    const minimum = isNumber ? parseInt(event.target.value) : null;
    setMinimumPercentage(minimum);
  };
  const MaximumChange = (event: any) => {
    const isNumber = Number.isFinite(parseInt(event.target.value));
    const maximum = isNumber ? parseInt(event.target.value) : null;
    setMaximumPercentage(maximum);
  };

  useEffect(() => {
    setQuoteDateString(quoteDate ? dayjs(quoteDate).format('YYYY-MM-DD') : null);
    //setBudgetMonthString(quoteDate ? dayjs(new Date(quoteDate.getFullYear(), quoteDate.getMonth(), 1)).add(1, 'month').format('YYYY-MM-DDTHH:mm:ss') : null);
  }, [quoteDate]);

  useEffect(() => {
    setNumberOfMonths(24);
    setMinimumPercentage(50);
    setMaximumPercentage(80);
  }, []);

  useEffect(() => {
    setContracts();
  }, [triggers]);

  const options: GridOptions<TimeTriggerModel> = {
    rowHeight: 30,
    domLayout: 'print',
  };

  return (
    <div>
      <StoneXRow>
        <StoneXDatePicker onChange={setQuoteDate} label="Quote Date" value={quoteDate} />
        <StoneXTextField label="Number of Months:" type="number" value={numberOfMonths} onChange={numberOfMonthsChange} />
        <StoneXTextField label="Minimum Percentage:" type="number" value={minimumPercentage} onChange={minimumChange} />
        <StoneXTextField label="Maximum Percentage:" type="number" value={maximumPercentage} onChange={MaximumChange} />
        
        <ButtonGroup>
          <StoneXButton variant="outlined" onClick={runReport} startIcon={<PlayArrow />} disabled={reportLoadingState.isLoading()}>Run</StoneXButton>
        </ButtonGroup>

      </StoneXRow>

      <StoneXLoading show={reportLoadingState.isLoading()} />

      <Grid container direction="row" xs="auto" item spacing={2}>
        <Grid item xs={6}>
          <div style={{ visibility: reportLoadingState.isLoaded() ? 'visible' : 'hidden', height: '500px' }} className="ag-theme-alpine">
            <AgGridReact
              ref={gridApiRef}
              columnDefs={columns}
              gridOptions={{ ...options, domLayout: 'autoHeight' }}
              rowData={contractsToHedge}
              onRowClicked={(e) => showMonthBreakout(e.rowIndex)}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ visibility: monthExpanded ? 'visible' : 'hidden', height: '500px' }} className="ag-theme-alpine">
            <h3 style={{ width: '100%', textAlign: 'center', backgroundColor: 'lightgray' }}>
              <span style={{ width: '100%', textAlign: 'center', backgroundColor: 'lightgray' }}>Schedule for {selectedBudgetMonth?.budgetMonth.label}</span>
            </h3>
            <h3 style={{ width: '100%', textAlign: 'center', backgroundColor: 'lightgray' }}>
              <span style={{ width: '30%', float: 'left', textAlign: 'left', backgroundColor: 'lightgray' }}>
                Budget Volume: <StoneXNumber number={selectedBudgetMonth?.budgetVolume}></StoneXNumber>
              </span>
              <span style={{ width: '30%', textAlign: 'center', backgroundColor: 'lightgray' }}>
                Hedged Volume: <StoneXNumber number={selectedBudgetMonth?.hedgedVolume}></StoneXNumber>
              </span>
              <span style={{ width: '30%', float: 'right', textAlign: 'right', backgroundColor: 'lightgray' }}>
                Hedged Percent: {<StoneXPercent number={selectedBudgetMonth?.totalHedgePerentage} />}
              </span>
            </h3>
            <h3 style={{ width: '100%', textAlign: 'center', backgroundColor: 'lightgray' }}>
              <StoneXRow>
                <span style={{ width: '100%', textAlign: 'center', backgroundColor: 'lightgray' }}>
                  Total Contracts for 50%: {selectedBudgetMonth?.totalMinimumHedge} | 80%: {selectedBudgetMonth?.totalMaximumHedge}
                </span>
              </StoneXRow>
            </h3>
            <AgGridReact columnDefs={columns} gridOptions={{ ...options, domLayout: 'autoHeight' }} rowData={selectContractsToHedge} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
