import { ArrowDownward, ArrowUpward, ChevronLeft, ChevronRight, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import { Button } from "@mui/material";
import { difference } from "lodash";
import { useEffect, useState } from "react";
import { StoneXSelectList } from "./StoneXSelectList";

interface StoneXTransferListProps<T> {
    leftList: T[];
    leftHeader: string;
    rightList: T[];
    rightHeader: string;
    getId: (item: T) => string | number;
    getSortingOrder?: (item: T) => number;
    getGroup?: (item: T) => string | number | undefined;
    getOptionLabel: (item: T) => string | number;
    onUpdate?: (leftItems: T[], rightItems: T[]) => void;
}

export function StoneXTransferList<T>(props: StoneXTransferListProps<T>) {

    const { leftList, leftHeader, rightList, rightHeader, getId, getSortingOrder, getGroup, getOptionLabel, onUpdate } = props;

    const [ modifedLeftList, setModifiedLeftList ] = useState<T[]>(leftList);
    const [ modifedRightList, setModifiedRightList ] = useState<T[]>(rightList);

    useEffect(() => setModifiedLeftList(leftList), [leftList]);
    useEffect(() => setModifiedRightList(rightList), [rightList]);
    
    const [ leftItemsSelected, setLeftItemsSelected ] = useState<T[]>([]);
    const [ rightItemsSelected, setRightItemsSelected ] = useState<T[]>([]);

    const squareButtonStyle = { minWidth: '50px', width: '50px', height: '50px', padding: 0 };

    function addToLeftItemsSelected(items: T[], controlPressed: boolean, shiftPressed: boolean) {
        if (controlPressed || shiftPressed) {
            setLeftItemsSelected([...leftItemsSelected, ...items]);
        } else {
            setLeftItemsSelected([...items]);
        }
    }

    function addToRightItemsSelected(items: T[], controlPressed: boolean, shiftPressed: boolean) {
        if (controlPressed || shiftPressed) {
            setRightItemsSelected([...rightItemsSelected, ...items]);
        } else {
            setRightItemsSelected([...items]);
        }
    }

    function getSortedList(list: T[]): T[] {
        if (getSortingOrder) {
            list.sort((a, b) => getSortingOrder(a) - getSortingOrder(b));
        }
        
        return list;
    }
    function transferToRightList() {
        const activeItems = [...modifedRightList, ...leftItemsSelected];
        setModifiedRightList(activeItems);

        const availableOptions = getSortedList(difference(modifedLeftList, leftItemsSelected));
        setModifiedLeftList(availableOptions);

        setRightItemsSelected([]);
        setLeftItemsSelected([]);
    }

    function transferToLeftList() {
        const availableItems = getSortedList([...modifedLeftList, ...rightItemsSelected])
        setModifiedLeftList(availableItems);

        const activeOptions = difference(modifedRightList, rightItemsSelected);
        setModifiedRightList(activeOptions);

        setRightItemsSelected([]);
        setLeftItemsSelected([]);
    }

    function increaseSelectedRightItemOrder() {
        // Create a copy of activeItemsList to avoid mutating the original array
        const newArray = [...modifedRightList];

        const indices = rightItemsSelected.map(x => newArray.indexOf(x)).sort((a, b) => a - b);
        
        // Iterate in reverse order over activeItemsSelected to move each element forward by 1 position in newArray
        for (let i = 0; i < rightItemsSelected.length; i++) {
            
            const indexToSwap = indices[i];

            if (indexToSwap == 0) {
                // Item is already at the top, can't raise it further
                continue;
            }

            // Swap the element with the previous one
            [newArray[indexToSwap], newArray[indexToSwap - 1]] = [newArray[indexToSwap - 1], newArray[indexToSwap]];
        }   

        setModifiedRightList(newArray);
    }

    function decreaseSelectedRightItemOrder() {
        // Create a copy of activeItemsList to avoid mutating the original array
        const newArray = [...modifedRightList];

        const indices = rightItemsSelected.map(x => newArray.indexOf(x)).sort((a, b) => a - b);

        // Go in reverse direction
        for (let i = rightItemsSelected.length - 1; i >= 0; i--) {

            const indexToSwap = indices[i];

            if (indexToSwap == newArray.length - 1) {
                // Item is already at the end, can't lower it further
                continue;
            }

            // Swap the element with the next one
            [newArray[indexToSwap], newArray[indexToSwap + 1]] = [newArray[indexToSwap + 1], newArray[indexToSwap]];
        }

        setModifiedRightList(newArray);
    }

    useEffect(() => onUpdate && onUpdate(modifedLeftList, modifedRightList), [modifedLeftList, modifedRightList]);

    return <div style={{display: 'flex', flexDirection: 'row', flexGrow: 1, gap: '20px'}}>
        <span style={{width: '100%'}}>
            <StoneXSelectList label={leftHeader} list={modifedLeftList} selectedItems={leftItemsSelected} onSelect={addToLeftItemsSelected} getId={getId} getGroup={getGroup} getOptionLabel={getOptionLabel} />
        </span>

        <span style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', gap: '10px'}}>
            <Button variant="outlined" onClick={transferToRightList} sx={squareButtonStyle}> <KeyboardArrowRight fontSize="large" sx={{fill: 'var(--icon-default-color)'}} /> </Button>
            <Button variant="outlined" onClick={transferToLeftList} sx={squareButtonStyle}> <KeyboardArrowLeft fontSize="large" sx={{fill: 'var(--icon-default-color)'}} /> </Button>
        </span>

        <span style={{width: '100%'}}>
            <StoneXSelectList label={rightHeader} list={modifedRightList} selectedItems={rightItemsSelected} onSelect={addToRightItemsSelected} getId={getId} getGroup={getGroup} getOptionLabel={getOptionLabel} />
        </span>
        
        <span style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', gap: '10px'}}>
            <Button variant="outlined" onClick={increaseSelectedRightItemOrder} sx={squareButtonStyle}> <KeyboardArrowUp fontSize="large" sx={{fill: 'var(--icon-default-color)'}} /> </Button>
            <Button variant="outlined" onClick={decreaseSelectedRightItemOrder} sx={squareButtonStyle}> <KeyboardArrowDown fontSize="large" sx={{fill: 'var(--icon-default-color)'}} /> </Button>
        </span>
    </div>

}