import { PlayArrow, Star, StarBorder } from '@mui/icons-material';
import { Button, CardActions, CardContent, FormControl, Grid, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { MarkItViewApi } from '../../../../../Apis/Apis';
import { EnumHelper } from '../../../../../Helpers';
import { useListResource, useLoadingState, useMtmDate, usePrevious } from '../../../../../Hooks';
import {
  CommodityType,
  ContractDate,
  MarketViewProductModel,
  MarketViewProductTradingMonthsResponse,
  MarketViewProductTypeModel,
} from '../../../../../Generated/Mark-It-View-Api';
import { MarkItViewQueryResult as MarkItViewQuery, PriceQueryResult, PriceQueryType, PriceQueryTypeOptions } from '../../../../../Types';
import { MarkItViewQueryParameters } from '../../../../../Types/MarkItViewQueryParameters';
import { markItViewQuerySchema } from '../../../../../Validations/queryFormValidation';
import { StoneXAutocomplete, StoneXDatePicker, StoneXMonthMultiselect, StoneXMultiselect } from '../../../../StoneXMui';
import { StoneXContractMonthPicker } from '../../../../StoneXMui/StoneXContractMonthPicker/StoneXContractMonthPicker';
import './QueryForm.scss';

interface QueryFormProps {
  id: number;
  onNewQuery: (query: MarkItViewQuery) => void;
  onUpdateQuery: (query: MarkItViewQuery) => void;
  onUpdateQueryParams: (query: MarkItViewQueryParameters) => void;
  savedQueryToLoad?: MarkItViewQueryParameters;
}

export default function QueryForm(props: QueryFormProps) {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const defaultParams = {
    id: props.id,
    startQuoteDate: props.savedQueryToLoad?.startQuoteDate ?? dayjs(useMtmDate()).subtract(1, 'year').toDate(),
    endQuoteDate: props.savedQueryToLoad?.endQuoteDate ?? useMtmDate(),
    mtmDate: props.savedQueryToLoad?.mtmDate ?? useMtmDate(),
    priceQueryType: props.savedQueryToLoad?.priceQueryType ?? PriceQueryType.Price,
    commodityType: CommodityType.Favorites,
    years: props.savedQueryToLoad?.years ?? [],
    months: props.savedQueryToLoad?.months ?? [],
    monthsAhead: props.savedQueryToLoad?.monthsAhead,
  } as MarkItViewQueryParameters;

  const [queryParams, setQueryParams] = useState<MarkItViewQueryParameters>(defaultParams);

  const commodityTypes = useListResource<MarketViewProductTypeModel, string>([], null, (item) => item.commodityType);
  const products = useListResource<MarketViewProductModel, number>([], null, (item) => item.productId);

  //Trading Months/Years
  const [tradingMonths, setTradingMonths] = useState<MarketViewProductTradingMonthsResponse>({ years: [], months: [] });
  const tradingMonthsLoadingState = useLoadingState();

  const [queryResult, setQueryResult] = useState<MarkItViewQuery>();
  const previousQueryResult = usePrevious(queryResult);
  const [hasSubmittedOnce, setHasSubmittedOnce] = useState<boolean>(false);
  const [isPerformingFirstLoad, setIsPerformingFirstLoad] = useState(!!props.savedQueryToLoad);
  const [productsUpdating, setProductsUpdating] = useState<{ [key: number]: boolean }>({});

  //report loading state
  const reportLoadingState = useLoadingState();

  const loadSavedQuery = (query: MarkItViewQueryParameters) => {
    // setIsPerformingFirstLoad(true);
    // loadProductTypes()
    //   .then(q => {
    //     commodityTypes.loadingState
    //     const commodityType = commodityTypes.items.find(q => q.commodityType == query.commodityType!);
    // })
    // loadProducts(query.commodityType!).then(() => {
    //   const selectedProduct = products.items.find((q) => q.productId == query.productId!);
    //   if (selectedProduct) {
    //     onProductChange(null, selectedProduct);
    //   }
    // });
  };

  const getClosestYearToCurrent = (years: number[]): number => {
    if (!years || years.length == 0) {
      return 0;
    }

    const currentYear = dayjs().year();
    const closest = years.reduce((a, b) => {
      return Math.abs(b - currentYear) < Math.abs(a - currentYear) ? b : a;
    });
    return closest;
  };

  const loadProductTypes = (): Promise<void> => {
    const promise = MarkItViewApi.listMarketViewProductTypes(commodityTypes.setLoadingState).then((response) => {
      if (response.data.rows) {
        commodityTypes.setItems(response.data.rows);

        if (queryParams.commodityType) {
          loadProducts(queryParams.commodityType);
        }

        if (isPerformingFirstLoad) {
          const selectedType = response.data.rows.find((q) => q.commodityType == props.savedQueryToLoad?.commodityType);
          if (selectedType) {
            setQueryParams((previous) => {
              return { ...previous, commodityType: selectedType.commodityType, product: null, productId: null };
            });
            // commodityTypes.setSelectedItem(selectedType);
            loadProducts(selectedType.commodityType);
          } else {
          }
        }
      }
    });

    return promise;
  };

  const loadProducts = (commodityType: CommodityType): Promise<void> => {
    const promise = MarkItViewApi.listMarketViewProducts(products.setLoadingState, commodityType).then((response) => {
      if (response.data.rows) {
        products.setItems(response.data.rows);

        if (isPerformingFirstLoad) {
          const selectedProduct = response.data.rows.find((q) => q.productId == props.savedQueryToLoad?.productId);
          if (selectedProduct) {
            setQueryParams((previous) => {
              return { ...previous, productId: selectedProduct.productId, product: selectedProduct };
            });
            products.setSelectedItem(selectedProduct);
            loadProductTradingMonths(selectedProduct.productId);
          }
        }
      }
    });

    return promise;
  };

  const loadProductTradingMonths = (productId: number): void => {
    MarkItViewApi.marketViewProductTradingMonths(tradingMonthsLoadingState.setLoadingState, productId.toString()).then((response) => {
      if (response.data) {
        setTradingMonths(response.data);

        if (isPerformingFirstLoad) {
          setIsPerformingFirstLoad(false);
        }
      }
    });
  };

  const onQueryParamsUpdate = () => {
    validateQuery(queryParams);
  };

  useEffect(() => {
    if (!isPerformingFirstLoad && props.savedQueryToLoad) {
      submit();
    }
  }, [isPerformingFirstLoad]);

  useEffect(() => {
    if (hasSubmittedOnce) {
      validateQuery(queryParams);
    }
    props.onUpdateQueryParams(queryParams);
  }, [queryParams]);

  const onStartQuoteDateChange = (value: Date | null): void => setQueryParams({ ...queryParams, startQuoteDate: value });
  const onEndQuoteDateChange = (value: Date | null): void => setQueryParams({ ...queryParams, endQuoteDate: value });
  const onMtmDateChange = (value: Date | null): void => setQueryParams({ ...queryParams, mtmDate: value });
  const onPriceQueryTypeChange = (event: any, value: any): void => setQueryParams({ ...queryParams, priceQueryType: value });
  const onYearsChange = (value: number[]): void => setQueryParams({ ...queryParams, years: value });
  const onMonthsChange = (value: number[]): void => setQueryParams({ ...queryParams, months: value });
  const onStartContractDateChange = (value: ContractDate | null): void => setQueryParams({ ...queryParams, startContractDate: value });
  const onEndContractDateChange = (value: ContractDate | null): void => setQueryParams({ ...queryParams, endContractDate: value });

  const onCommodityTypeChange = (event: any, value: CommodityType | null) => {
    setQueryParams({ ...queryParams, commodityType: value });
    // commodityTypes.setSelectedItem(value);

    if (value === null) {
      setQueryParams({ ...queryParams, product: null, productId: null });
    } else {
      loadProducts(value);
    }
  };

  const onProductChange = (event: any, product: MarketViewProductModel | null) => {
    if (product === null) {
      // products.clearSelected();
      setTradingMonths({});
      tradingMonthsLoadingState.reset();
      setQueryParams({ ...queryParams, productId: null, product: null });
      products.setSelectedItem(null);
    } else {
      // products.setSelectedItem(product);
      setQueryParams({ ...queryParams, productId: product.productId, product: product });
      loadProductTradingMonths(product.productId);
      products.setSelectedItem(product);
    }
    // onQueryParamsUpdate();
  };

  const toggleFavorite = (product: MarketViewProductModel): void => {
    product.isFavorite = !product.isFavorite;

    const callback = () => {
      const newSet = [...products.items.filter((q) => q.productId != product.productId), product];
      products.setItems(newSet);
      setProductsUpdating((previous) => ({ ...previous, [product.productId]: false }));
    };

    const savedFailedCallback = () => {
      setProductsUpdating((previous) => ({ ...previous, [product.productId]: false }));
    };

    if (product.isFavorite) {
      setProductsUpdating((previous) => ({ ...previous, [product.productId]: true }));
      MarkItViewApi.addMarketViewUserFavoritedProduct(null, String(product.productId)).then(callback).catch(savedFailedCallback);
    } else {
      setProductsUpdating((previous) => ({ ...previous, [product.productId]: true }));
      MarkItViewApi.deleteMarketViewUserFavoritedProduct(null, String(product.productId)).then(callback).catch(savedFailedCallback);
      MarkItViewApi.deleteMarketViewUserFavoritedProduct(null, String(product.productId)).then(callback).catch(savedFailedCallback);
    }
  };

  const onMonthsAheadChange = (event: any) => {
    const isNumber = Number.isFinite(parseInt(event.target.value));
    const monthsAhead = isNumber ? parseInt(event.target.value) : null;
    setQueryParams({ ...queryParams, monthsAhead: monthsAhead });
  };

  const validateQuery = (query: MarkItViewQueryParameters): boolean => {
    const validationErrors: { [key: string]: string } = {};

    try {
      markItViewQuerySchema.validateSync(query, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err: any) {
      err.inner.forEach((e: any) => {
        console.log(e.path, e.message);
        validationErrors[e.path] = e.message;
      });

      setErrors(validationErrors);

      return false;
    }

    // markItViewQuerySchema
    //   .validate(query, { abortEarly: false })
    //   .then((q) => {
    //     setErrors({});
    //   })
    //   .catch((err) => {
    //     err.inner.forEach((e: any) => {
    //       validationErrors[e.path] = e.message;
    //     });

    //     if (hasSubmittedOnce) {
    //       setErrors(validationErrors);
    //     }

    //     return false;
    //   });

    // return Object.keys(validationErrors).length === 0;
  };

  const submit = (): void => {
    setHasSubmittedOnce(true);

    if (!validateQuery(queryParams)) {
      return;
    }

    switch (queryParams.priceQueryType) {
      case PriceQueryType.Price:
        getPriceQuery();
        break;
      case PriceQueryType.StripAverage:
        getStripAverage();
        break;
      case PriceQueryType.CalendarStripAverage:
        getCalendarStripAverage();
        break;
      case PriceQueryType.RollingMonthAhead:
        getRollingMonthAhead();
        break;
      case PriceQueryType.Rolling12MonthStrip:
        getRolling12MonthAheadStrip();
        break;
      case PriceQueryType.ForwardCurve:
        getForwardCurve();
        break;
      case PriceQueryType.Settlement:
        getSettlementPrices();
        break;
    }
  };

  const getPriceQuery = () => {
    const startQuoteDateString = dayjs(queryParams.startQuoteDate).format('L');
    const endQuoteDateString = dayjs(queryParams.endQuoteDate).format('L');

    MarkItViewApi.listMarketViewPrices(
      reportLoadingState.setLoadingState,
      String(queryParams.productId!),
      queryParams.years!,
      queryParams.months!,
      startQuoteDateString,
      endQuoteDateString,
    ).then((response) => {
      onQueryUpdate(PriceQueryType.Price, queryParams.product!, response.data);
    });

    onQueryUpdate(PriceQueryType.Price, queryParams.product!);
  };

  const getStripAverage = () => {
    const startQuoteDateString = dayjs(queryParams.startQuoteDate).format('L');
    const endQuoteDateString = dayjs(queryParams.endQuoteDate).format('L');

    MarkItViewApi.listMarketViewPricesStripAverage(
      reportLoadingState.setLoadingState,
      String(queryParams.productId!),
      startQuoteDateString,
      endQuoteDateString,
      queryParams.startContractDate!.year!,
      queryParams.startContractDate!.month!,
      queryParams.endContractDate!.year!,
      queryParams.endContractDate!.month!,
    ).then((response) => onQueryUpdate(PriceQueryType.StripAverage, queryParams.product!, response.data));

    onQueryUpdate(PriceQueryType.StripAverage, queryParams.product!);
  };

  const getCalendarStripAverage = () => {
    const startQuoteDateString = dayjs(queryParams.startQuoteDate).format('L');
    const endQuoteDateString = dayjs(queryParams.endQuoteDate).format('L');

    MarkItViewApi.listMarketViewPriceQueryCalendarStripAvearge(
      reportLoadingState.setLoadingState,
      String(queryParams.productId!),
      queryParams.years!,
      startQuoteDateString,
      endQuoteDateString,
    ).then((response) => onQueryUpdate(PriceQueryType.CalendarStripAverage, queryParams.product!, response.data));

    onQueryUpdate(PriceQueryType.CalendarStripAverage, queryParams.product!);
  };

  const getRollingMonthAhead = () => {
    const startQuoteDateString = dayjs(queryParams.startQuoteDate).format('L');
    const endQuoteDateString = dayjs(queryParams.endQuoteDate).format('L');
    const rollingMonths = 1;

    MarkItViewApi.listMarketViewPriceQueryRollingMonthAhead(
      reportLoadingState.setLoadingState,
      String(queryParams.productId!),
      rollingMonths,
      startQuoteDateString,
      endQuoteDateString,
    ).then((response) => onQueryUpdate(PriceQueryType.RollingMonthAhead, queryParams.product!, response.data));

    onQueryUpdate(PriceQueryType.RollingMonthAhead, queryParams.product!);
  };

  const getRolling12MonthAheadStrip = () => {
    const startQuoteDateString = dayjs(queryParams.startQuoteDate).format('L');
    const endQuoteDateString = dayjs(queryParams.endQuoteDate).format('L');
    const rollingMonths = 12;

    MarkItViewApi.listMarketViewPriceQueryRollingMonthAhead(
      reportLoadingState.setLoadingState,
      String(queryParams.productId!),
      rollingMonths,
      startQuoteDateString,
      endQuoteDateString,
    ).then((response) => onQueryUpdate(PriceQueryType.Rolling12MonthStrip, queryParams.product!, response.data));

    onQueryUpdate(PriceQueryType.Rolling12MonthStrip, queryParams.product!);
  };

  const getForwardCurve = () => {
    const mtmDateString = dayjs(queryParams.endQuoteDate).format('L');

    MarkItViewApi.listMarketViewForwardCurveQuery(
      reportLoadingState.setLoadingState,
      String(queryParams.productId!),
      mtmDateString,
      queryParams.monthsAhead ?? undefined,
    ).then((response) => onQueryUpdate(PriceQueryType.ForwardCurve, queryParams.product!, response.data));

    onQueryUpdate(PriceQueryType.ForwardCurve, queryParams.product!);
  };

  const getSettlementPrices = () => {
    const startQuoteDateString = dayjs(queryParams.startQuoteDate).format('L');
    const endQuoteDateString = dayjs(queryParams.endQuoteDate).format('L');

    MarkItViewApi.listMarketViewSettlementPrices(
      reportLoadingState.setLoadingState,
      String(queryParams.productId!),
      startQuoteDateString,
      endQuoteDateString,
    ).then((response) => onQueryUpdate(PriceQueryType.Settlement, queryParams.product!, response.data));

    onQueryUpdate(PriceQueryType.ForwardCurve, queryParams.product!);
  }

  const onQueryUpdate = (queryType: PriceQueryType, product: MarketViewProductModel, prices?: PriceQueryResult) => {
    const query = {
      id: props.id,
      product: product,
      query: prices,
      queryType: queryType,
      hidden: false,
      loadingState: reportLoadingState,
    } as MarkItViewQuery;

    setQueryResult(query);
  };

  useEffect(() => {
    if (!queryResult) return;
    const updatedQuery = { ...queryResult, loadingState: reportLoadingState } as MarkItViewQuery;
    setQueryResult(updatedQuery);
  }, [reportLoadingState.loadingState]);

  useEffect(() => {
    if (!previousQueryResult && !queryResult) return;

    props.onUpdateQuery(queryResult!);
  }, [queryResult]);

  const renderPriceTypeMonthInput = (): ReactNode => {
    switch (queryParams.priceQueryType) {
      case PriceQueryType.Price:
        return priceQueryInput();
      case PriceQueryType.StripAverage:
        return stripAverageInput();
      case PriceQueryType.CalendarStripAverage:
        return calendarStripAverageInput();
      case PriceQueryType.ForwardCurve:
        return forwardCurveInput();
      case PriceQueryType.Settlement:
        return <></>;
      default:
        return <></>;
    }
  };

  const priceQueryInput = (): ReactNode => {
    return (
      <Grid container item direction="column" xs="auto" spacing={2}>
        <Grid item>
          <StoneXMultiselect
            list={tradingMonths!.years!}
            labelSelector={(q) => q}
            valueSelector={(q) => q}
            loading={tradingMonthsLoadingState.isLoading}
            resultSetter={onYearsChange}
            initial={queryParams.years}
            autoFocusValue={getClosestYearToCurrent(tradingMonths!.years!)}
            error={errors['years']}
            label="Years"
          />
        </Grid>
        <Grid item>
          <StoneXMonthMultiselect
            allowedMonths={tradingMonths!.months!}
            initial={queryParams.months}
            resultSetter={onMonthsChange}
            error={errors['months']}
          ></StoneXMonthMultiselect>
        </Grid>
      </Grid>
    );
  };

  const stripAverageInput = (): ReactNode => {
    return (
      <Grid container item direction="column" xs="auto" spacing={2}>
        <Grid item>
          <StoneXContractMonthPicker
            value={queryParams.startContractDate}
            onChange={onStartContractDateChange}
            label="Start Month"
            error={errors['startContractDate']}
          />
        </Grid>
        <Grid item>
          <StoneXContractMonthPicker
            value={queryParams.endContractDate}
            onChange={onEndContractDateChange}
            label="End Month"
            error={errors['endContractDate']}
          />
        </Grid>
      </Grid>
    );
  };

  const calendarStripAverageInput = (): ReactNode => {
    return (
      <Grid container item direction="column" xs="auto" spacing={2}>
        <Grid item>
          <StoneXMultiselect
            list={tradingMonths!.years!}
            labelSelector={(q) => q}
            valueSelector={(q) => q}
            loading={tradingMonthsLoadingState.isLoading}
            resultSetter={onYearsChange}
            initial={queryParams.years}
            autoFocusValue={getClosestYearToCurrent(tradingMonths!.years!)}
            error={errors['years']}
            label="Years"
          />
        </Grid>
      </Grid>
    );
  };

  const forwardCurveInput = (): ReactNode => {
    return (
      <Grid container item direction="column" xs="auto" spacing={2}>
        <Grid item>
          <FormControl sx={{ width: 300 }}>
            <TextField label="Months Ahead" type="number" onChange={onMonthsAheadChange} />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const renderDatePickers = (): ReactNode => {
    if (queryParams.priceQueryType === PriceQueryType.ForwardCurve) {
      return (
        <Grid container direction="column" xs="auto" item spacing={2}>
          <Grid item>
            <StoneXDatePicker value={queryParams.mtmDate!} onChange={onMtmDateChange} label="MTM Date" />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container direction="column" xs="auto" item spacing={2}>
        <Grid item>
          <StoneXDatePicker value={queryParams.startQuoteDate!} onChange={onStartQuoteDateChange} label="Start Quote Date" />
        </Grid>
        <Grid item>
          <StoneXDatePicker value={queryParams.endQuoteDate!} onChange={onEndQuoteDateChange} label="End Quote Date" />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (props.savedQueryToLoad) {
      // setIsPerformingFirstLoad(true);
      // loadSavedQuery(props.savedQueryToLoad);
    } else {
      // loadProductTypes();
    }

    loadProductTypes();
  }, []);

  return (
    <>
      <CardContent sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {renderDatePickers()}
          <Grid container direction="column" xs="auto" item spacing={2}>
            <Grid item>
              <StoneXAutocomplete
                options={PriceQueryTypeOptions}
                getOptionLabel={(priceQueryType) => priceQueryType ?? ''}
                value={queryParams.priceQueryType}
                onChange={onPriceQueryTypeChange}
                label="Price Query Type"
                error={errors['priceQueryType']}
              />
            </Grid>
          </Grid>
          <Grid container direction="column" xs="auto" item spacing={2}>
            <Grid item>
              <StoneXAutocomplete
                options={commodityTypes.items
                  .map((q) => q.commodityType)
                  .sort((a, b) => {
                    if (a == CommodityType.Favorites) return -1;
                    if (b == CommodityType.Favorites) return -1;
                    return a < b ? -1 : a > b ? 1 : 0;
                  })}
                getOptionLabel={(commodityType) => EnumHelper.enumToString(commodityType)}
                value={queryParams.commodityType}
                onChange={onCommodityTypeChange}
                loading={commodityTypes.isLoading}
                label="Commodity Types"
                error={errors['commodityType']}
              />
            </Grid>
            <Grid item>
              <StoneXAutocomplete
                options={orderBy(products.items, [(product) => product.commodityType, (product) => product.name])}
                getOptionLabel={(product) => product.name}
                key={'productId'}
                dropdownAutoWidth
                value={products.selectedItem}
                onChange={onProductChange}
                groupBy={queryParams.commodityType == CommodityType.Favorites ? (product) => EnumHelper.enumToString(product.commodityType) : undefined}
                loading={products.isLoading}
                listKey={'productId'}
                label="Products"
                error={errors['productId']}
                renderOption={(props, product) => (
                  <li {...props}>
                    {/* <Checkbox style={{marginRight: 8, padding: 0 }} checked={false} /> */}
                    <IconButton
                      onClick={(e: any) => {
                        e.stopPropagation();
                        // product.isFavorite = !product.isFavorite;
                        toggleFavorite(product);
                      }}
                      sx={{ padding: 0, margin: 0, marginRight: '8px' }}
                    >
                      {productsUpdating[product.productId] ? <CircularProgress size="1em" /> : product.isFavorite ? <Star /> : <StarBorder />}
                    </IconButton>
                    {product.name}
                  </li>
                )}
              />
            </Grid>
          </Grid>
          {renderPriceTypeMonthInput()}
        </Grid>
      </CardContent>

      <CardActions>
        <Button variant="outlined" onClick={submit} startIcon={<PlayArrow />}>
          Run Query
        </Button>
      </CardActions>
    </>
  );
}
