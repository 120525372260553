import { Button } from '@mui/material';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import { BudgetModel, BudgetPricingComponentModel, BudgetPricingStrategyModel, CommodityModel, CounterpartyModel, Currency, GetCustomerDataResponse, GetCustomerSettingsResponse, LocationModel, MarketPriceProvider } from '../../../../../Generated/Commodity-Management-Api';
import { Unit } from '../../../../../Generated/Mark-It-View-Api';
import { ContractDateHelper } from '../../../../../Helpers';
import { useLoadingState } from '../../../../../Hooks';
import { BudgetSelectionFormSchema } from '../../../../../Validations';
import { StoneXRow } from '../../../../StoneX';
import { StoneXAutocomplete, StoneXButton, StoneXTextField } from '../../../../StoneXMui';
import { BudgetRow } from './BudgetTypes';

interface BudgetSelectionFormProps {
  customerSettings: GetCustomerSettingsResponse;
  customerData: GetCustomerDataResponse;
  pricingStrategies: BudgetPricingStrategyModel[];
  isInEditMode: boolean;
  isSaving: boolean;
  onChangeIsInEditMode: (isInEditMode: boolean) => void;
  onBudgetsUpdated: (budgets: BudgetRow[]) => void;
  onEditStart: () => void;
  onEditCancel: () => void;
  onSave: () => void;
}

export default function BudgetSelectionForm(props: BudgetSelectionFormProps) {

  const { customerSettings, customerData, pricingStrategies, isInEditMode, isSaving, onChangeIsInEditMode, onBudgetsUpdated, onEditStart, onEditCancel, onSave } = props;

  const budgetLoadingState = useLoadingState();
  const [budgets, setBudgets] = useState<BudgetRow[]>([]);
  const [identifiedPricingStrategy, setIdentifiedPricingStrategy] = useState<BudgetPricingStrategyModel | null>();

  const [selectedCommodity, setSelectedCommodity] = useState<CommodityModel | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<LocationModel | null>(null);
  const [selectedCounterparty, setSelectedCounterparty] = useState<CounterpartyModel | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(customerSettings.defaultActiveYear!);
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);
  
  const [budgetSelectionFormIsValid, setBudgetSelectionFormIsValid] = useState(false);
  // const [isInEditMode, setIsInEditMode] = useState(props.isInEditMode);
  const [rowIdCounter, setRowIdCounter] = useState<number>(1);

  //attempt to identify a single pricing strategy
  useEffect(() => {
    identifyPricingStrategy();
  }, [selectedCommodity, selectedLocation, selectedCounterparty]);

  useEffect(() => {
    validateBudgetSelectionForm();
  }, [selectedCommodity, selectedLocation, selectedCounterparty, selectedYear, selectedUnit]);

  useEffect(() => {
    onBudgetsUpdated(budgets);
  }, [budgets])

  useEffect(() => {
    isInEditMode ? onEditStart() : onEditCancel();
  }, [isInEditMode])

  useEffect(() => {
    updateBudgetsFromSearchParameters();
  }, []);

  function identifyPricingStrategy() {
    const identifiedPricingStrategies = filter(pricingStrategies, (strategy) => {
      const commodityCondition = !strategy.commodityId || !selectedCommodity || strategy.commodityId == selectedCommodity?.commodityId;
      const locationCondition = !strategy.locationId || !selectedLocation || strategy.locationId == selectedLocation?.locationId;
      const counterpartyCondition = !strategy.counterpartyId || !selectedCounterparty || strategy.counterpartyId == selectedCounterparty?.counterpartyId;

      return commodityCondition && locationCondition && counterpartyCondition;
    });

    if (identifiedPricingStrategies.length === 1) {
      setIdentifiedPricingStrategy(identifiedPricingStrategies[0]);
    } else {
      setIdentifiedPricingStrategy(null);
    }
  }

  function validateBudgetSelectionForm() {
    BudgetSelectionFormSchema.validate({
      commodityId: selectedCommodity?.commodityId,
      locationId: selectedLocation?.locationId,
      counterpartyId: selectedCounterparty?.counterpartyId,
      pricingStrategyId: identifiedPricingStrategy?.budgetPricingStrategyId,
      year: selectedYear,
      unit: selectedUnit,
    })
      .then((success) => setBudgetSelectionFormIsValid(true))
      .catch((error) => {
        console.log(error, selectedUnit);
        setBudgetSelectionFormIsValid(false);
      });
  }

  function updateBudgetsFromSearchParameters() {
    const queryParams = new URLSearchParams(location.search);
    const getParams = (param: string) => (queryParams.get(param) ? parseInt(queryParams.get(param)!) : undefined);

    const fiscalYear = getParams('year');
    const commodityId = getParams('commodityId');
    const locationId = getParams('locationId');
    const counterpartyId = getParams('counterpartyId');

    if (fiscalYear) {
     getExistingBudgets(fiscalYear!, commodityId, locationId, counterpartyId).then((budgets) => {
       if (budgets.length > 0) {
         editExistingBudgets(budgets);
       }
     });
    }     
  }

  async function getExistingBudgets(year: number, commodityId?: number, locationId?: number, counterpartyId?: number): Promise<BudgetModel[]> {
    const startMonth = ContractDateHelper.getStartContractDate(year, customerSettings?.fiscalMonthOffset);
    const endMonth = ContractDateHelper.addMonths(startMonth, 11);

    try {
      const response = await CommodityManagementApi.listBudgets(
        budgetLoadingState.setLoadingState,
        undefined,
        startMonth.year,
        startMonth.month,
        undefined,
        endMonth.year,
        endMonth.month,
        undefined,
        commodityId ? [commodityId] : [],
        locationId ? [locationId] : [],
        counterpartyId ? [counterpartyId] : [],
        undefined,
        undefined,
        undefined,
        undefined
      );
      
      return response.data.rows ?? [];
    }
    catch (error) {
      console.log('An error occured', error);
      return [];
    }
  }

  function editExistingBudgets(budgets: BudgetModel[]) {
    if (!budgets.every((x) => x.commodity.commodityId == budgets[0].commodity.commodityId)) {
      return [];
    }

    if (!budgets.every((x) => x.location?.locationId == budgets[0].location?.locationId)) {
      return [];
    }

    if (!budgets.every((x) => x.counterparty?.counterpartyId == budgets[0].counterparty?.counterpartyId)) {
      return [];
    }

    if (!budgets.every((x) => x.unit == budgets[0].unit)) {
      return [];
    }

    setSelectedYear(ContractDateHelper.getFiscalYear(budgets[0].budgetDate, customerSettings.fiscalMonthOffset!));
    setSelectedLocation(budgets[0].location);
    setSelectedCommodity(budgets[0].commodity);
    setSelectedCounterparty(budgets[0].counterparty);
    setSelectedUnit(budgets[0].unit);
    let currentId = rowIdCounter;

    const budgetRows = budgets?.map((budget) => {
      const hasMarkItView = budget.budgetPricingStrategy.components.some((x) => x.marketPriceProvider == MarketPriceProvider.MarketView);
      return {
        rowId: (currentId++).toString(),
        ...budget,
        commodityId: budget.commodity.commodityId,
        locationId: budget.location?.locationId,
        counterpartyId: budget.counterparty?.counterpartyId,
        pricingStrategyId: budget.budgetPricingStrategy.budgetPricingStrategyId,
        hasContractDate: hasMarkItView,
      } as BudgetRow;
    });

    setRowIdCounter(currentId);
    setBudgets(budgetRows);
    onChangeIsInEditMode(true);
  }

  function editNewBudgets() {
    const startMonth = ContractDateHelper.getStartContractDate(selectedYear!, customerSettings?.fiscalMonthOffset);
    const hasMarkItView = identifiedPricingStrategy?.components.some((q) => q.marketPriceProvider === MarketPriceProvider.MarketView);
    let currentId = rowIdCounter;

    setBudgets(
      [...Array(12)].map((q, i) => {
        return {
          rowId: (currentId++).toString(),
          budgetId: 0,
          commodityId: selectedCommodity!.commodityId,
          locationId: selectedLocation?.locationId,
          counterpartyId: selectedCounterparty?.counterpartyId,
          pricingStrategyId: identifiedPricingStrategy!.budgetPricingStrategyId,
          volume: 0,
          unit: selectedUnit!,
          currency: Currency.Usd,
          budgetDate: ContractDateHelper.addMonths(startMonth, i),
          contractDate: undefined,
          hasContractDate: hasMarkItView,
          budgetPrices: identifiedPricingStrategy!.components.map((component: BudgetPricingComponentModel) => ({
            componentType: component.componentType,
            unit: selectedUnit!,
            price: 0,
          })),
        } as BudgetRow;
      }),
    );

    setRowIdCounter(currentId);
    onChangeIsInEditMode(true);
  }

  function beginEditingBudgets() {
    getExistingBudgets(selectedYear!, selectedCommodity?.commodityId, selectedLocation?.locationId, selectedCounterparty?.counterpartyId)
    .then((budgets) => {
      budgets.length > 0 
        ? editExistingBudgets(budgets)
        : editNewBudgets();
    });

  }

  function cancelEditingBudgets() {
    onChangeIsInEditMode(false);
    validateBudgetSelectionForm();
  }

  function setCommodityUnits() {
    if (!selectedCommodity || !selectedCommodity.displayUnit) {
      return;
    }

    setSelectedUnit(selectedCommodity.displayUnit);
  }

  useEffect(setCommodityUnits, [selectedCommodity]);

  return (
    <StoneXRow>
      <StoneXAutocomplete
        options={customerData.commodities ?? []}
        getOptionLabel={(q) => q.commodityName}
        value={selectedCommodity}
        onChange={(e: any, commodity: CommodityModel | null) => setSelectedCommodity(commodity)}
        disabled={isInEditMode}
        isOptionEqualToValue={(a, b) => a.commodityId == b.commodityId}
        label="Commodity"
      />

      {customerSettings.budgetsByLocation && (
        <StoneXAutocomplete
          options={customerData.budgetingLocations ?? []}
          getOptionLabel={(q) => q.locationName}
          value={selectedLocation}
          onChange={(e: any, location: LocationModel | null) => setSelectedLocation(location)}
          disabled={isInEditMode}
          label="Location"
        />
      )}

      {customerSettings.budgetsByCounterparty && (
        <StoneXAutocomplete
          options={customerData.counterparties ?? []}
          getOptionLabel={(q) => q.counterpartyName!}
          value={selectedCounterparty}
          onChange={(e: any, counterparty: CounterpartyModel | null) => setSelectedCounterparty(counterparty)}
          disabled={isInEditMode}
          label="Counterparty"
        />
      )}

      <StoneXAutocomplete
        options={customerData.units ?? []}
        getOptionLabel={(unit) => unit}
        label="Unit"
        value={selectedUnit}
        disabled={isInEditMode}
        onChange={(e: any, unit: Unit | null) => setSelectedUnit(unit)}
      />

      <StoneXTextField
        type="number"
        disabled={isInEditMode}
        value={selectedYear}
        min={2000}
        label="Year"
        onChange={(e: any) => setSelectedYear(e.target.value)}
      />


      {!isInEditMode && (
        <StoneXButton disabled={!budgetSelectionFormIsValid} onClick={beginEditingBudgets} isWorking={budgetLoadingState.isLoading()}>Add</StoneXButton>
      )}

      {isInEditMode && (
        <div className="pull-right nested-row-item">
          <Button variant="outlined" onClick={cancelEditingBudgets} disabled={isSaving}>Cancel</Button>
          <StoneXButton onClick={onSave} isWorking={isSaving}>Save</StoneXButton>
        </div>
      )}
    </StoneXRow>
  );
}
