import {
  MarketViewQueryCalendarStripAverageResponse,
  MarketViewQueryForwardCurveResponse,
  MarketViewQueryPriceResponse,
  MarketViewQueryRollingMonthAheadResponse,
  MarketViewQuerySettlementsResponse,
  MarketViewQueryStripAverageResponse,
} from '../Generated/Mark-It-View-Api';

enum PriceQueryType {
  Price = 'Price',
  StripAverage = 'Strip Average',
  CalendarStripAverage = 'Calendar Strip Average',
  RollingMonthAhead = 'Rolling Month Ahead',
  Rolling12MonthStrip = 'Rolling 12 Month Strip',
  ForwardCurve = 'Forward Curve',
  Settlement = "Settlement",
}

type PriceQueryResult =
  | MarketViewQueryPriceResponse
  | MarketViewQueryStripAverageResponse
  | MarketViewQueryCalendarStripAverageResponse
  | MarketViewQueryRollingMonthAheadResponse
  | MarketViewQueryForwardCurveResponse
  | MarketViewQuerySettlementsResponse;

const PriceQueryTypeOptions = Object.keys(PriceQueryType).map((key) => PriceQueryType[key as keyof typeof PriceQueryType]);

export type { PriceQueryResult };
export { PriceQueryType, PriceQueryTypeOptions };
