import { useEffect, useState } from 'react';
import { CommodityManagementSiteAccessModel } from '../../../Generated/Commodity-Management-Api';
import { RootState, useApplicationDispatch, useApplicationSelector } from '../../../Redux/ReduxStore';
import { resetActiveSite, setActiveSite } from '../../../Redux/Slices/ActiveSiteSlice';
import { StoneXAutocomplete } from '../../StoneXMui';

interface CommodityManagementSiteSelectorProps {
  onSiteDataLoading: () => void;
  onSiteDataLoaded: () => void;
}

export default function CommodityManagementSiteSelector() {
  const sites = useApplicationSelector((state: RootState): CommodityManagementSiteAccessModel[] => state.userAccess.commodityManagementUserSiteAccesses);
  const activeSite = useApplicationSelector((state: RootState): CommodityManagementSiteAccessModel => state.activeSite.site);
  const dispatch = useApplicationDispatch();

  const onlyOneSite = () => sites.length == 1;
  const moreThanOneSite = () => sites.length > 1;
  const [selectedSite, setSelectedSite] = useState<CommodityManagementSiteAccessModel | null>(activeSite);

  useEffect(() => {

    if (selectedSite == activeSite) {
      return;
    }

    if (selectedSite) {
      dispatch(setActiveSite({ site: selectedSite }));
    } else {
      dispatch(resetActiveSite());
    }

  }, [selectedSite]);

  useEffect(() => {

    if (activeSite) {
      setSelectedSite(activeSite);
      return;
    }

    if (onlyOneSite()) {
      setSelectedSite(sites[0]);
    } else {
      setSelectedSite(null);
    }
    
  }, []);

  function dropdown() {
    return (
        <StoneXAutocomplete
        options={sites}
        value={selectedSite}
        width='190px'
        variant='filled'
        getOptionLabel={(site) => site.siteName!}
        isOptionEqualToValue={(a, b) => a.siteGuid === b.siteGuid}
        onUpdate={setSelectedSite}
        placeholder="Please select a site"
        disableClearable
      />
    );
  }

  if (moreThanOneSite()) {
    return dropdown();
  } else {
    return <></>;
  }
}
